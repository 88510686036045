import { ApiReturnType } from "./";

const draftRoutes = {
    DRAFT_ORDER: (id: number | string): ApiReturnType => {
        return {
            url: `draft/order/${id}`,
            method: "get",
        };
    },
    DRAFT_PLAYER: (id: number | string): ApiReturnType => {
        return {
            url: `draft/select/${id}`,
            method: "post",
        };
    },
    FINISH_DRAFT: (id: number | string): ApiReturnType => {
        return {
            url: `draft/finish/${id}`,
            method: "post",
        };
    },
    GET_DRAFT_LIST: (id: number | string): ApiReturnType => {
        return {
            url: `draft/list/${id}`,
            method: "get",
        };
    },
    GET_DRAFT_RECAP: (id: number | string): ApiReturnType => {
        return {
            url: `draft/recap/${id}`,
            method: "get",
        };
    },
    RESET_DRAFT: (id: number | string): ApiReturnType => {
        return {
            url: `draft/reset/${id}`,
            method: "post",
        };
    },
    SAVE_DRAFT_NOTE: (id: number | string): ApiReturnType => {
        return {
            url: `draft/saveNote/${id}`,
            method: "post",
        };
    },
    START_DRAFT: (id: number | string): ApiReturnType => {
        return {
            url: `draft/start/${id}`,
            method: "post",
        };
    },
};

export default draftRoutes;
