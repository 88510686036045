import Echo from "laravel-echo";
//@ts-ignore
window.Pusher = require("pusher-js");

const echo = new Echo({
    broadcaster: "pusher",
    key: "e01a739dcadb476b5437",
    cluster: "us2",
    forceTLS: true,
});

export default echo;
