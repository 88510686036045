import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import { RootState } from "../store";

interface PrivateRouteProps extends RouteProps {}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...rest }) => {
    const user = useSelector((state: RootState) => state.user);

    if (user && user.isLoggedIn) {
        return <Route {...rest} />;
    } else {
        return <Redirect to="/" />;
    }
};

export default PrivateRoute;
