import React from "react";

import Pagination from "react-bootstrap/Pagination";

interface PaginationMenuProps {
    page: number;
    setPage: (page: number) => void;
    numPages: number;
}

const PaginationMenu: React.FC<PaginationMenuProps> = ({
    page,
    setPage,
    numPages,
}) => {
    const startingPagination = [1, 2, 3, 4, 5];

    if (numPages === 0) {
        return null;
    }

    return (
        <Pagination className="justify-content-center">
            {page > 1 && <Pagination.Prev onClick={() => setPage(page - 1)} />}

            {page <= 3 && (
                <>
                    {startingPagination.map((pageNum) => {
                        return pageNum <= numPages ? (
                            <Pagination.Item
                                key={pageNum}
                                active={page === pageNum ? true : false}
                                onClick={() => setPage(pageNum)}
                            >
                                {pageNum}
                            </Pagination.Item>
                        ) : null;
                    })}
                </>
            )}

            {page > 3 && (
                <>
                    <Pagination.Item onClick={() => setPage(page - 2)}>
                        {page - 2}
                    </Pagination.Item>
                    <Pagination.Item onClick={() => setPage(page - 1)}>
                        {page - 1}
                    </Pagination.Item>
                    <Pagination.Item active onClick={() => setPage(page)}>
                        {page}
                    </Pagination.Item>
                    {page + 1 <= numPages && (
                        <Pagination.Item onClick={() => setPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    )}
                    {page + 2 <= numPages && (
                        <Pagination.Item onClick={() => setPage(page + 2)}>
                            {page + 2}
                        </Pagination.Item>
                    )}
                </>
            )}

            {page < numPages && (
                <Pagination.Next onClick={() => setPage(page + 1)} />
            )}
        </Pagination>
    );
};

export default PaginationMenu;
