import React from "react";
import AutoLink from "@uiw/react-auto-link";

import Avatar from "@mui/material/Avatar";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Chat } from "../../models/Chat";
import Moment from "react-moment";
import {
    LikeButton,
    ReceivedMessage,
    ReceivedMessageWrapper,
    ReceivedUser,
} from "./styles/Chat.styled";
import { imageUrl } from "../../config";

interface ReceivedChatProps {
    message: Chat;
    likeChat: (id: number, liked: number) => void;
}

const ReceivedChat: React.FC<ReceivedChatProps> = ({ message, likeChat }) => {
    const likers = message.likers.map((liker) => {
        return `${liker.user.first_name ?? liker.user.first_name} ${
            liker.user.last_name ? liker.user.last_name.substr(0, 1) : ""
        }`;
    });

    return (
        <ReceivedMessageWrapper key={message.id}>
            {message.user_id > 0 && (
                <Avatar
                    src={
                        message.profile_pic &&
                        message.profile_pic.includes("https")
                            ? message.profile_pic
                            : `${imageUrl}${message.profile_pic}`
                    }
                />
            )}
            <Col>
                <ReceivedUser>
                    {message.user_id > 0 &&
                        `${message.first_name && message.first_name} ${
                            message.last_name &&
                            message.last_name.substring(0, 1)
                        }`}{" "}
                    <small>
                        {message.team_name ? `(${message.team_name}) ` : ` `}
                        <Moment format="MMMM D, YYYY [at] h:mm a">
                            {message.created_at}
                        </Moment>
                    </small>
                </ReceivedUser>
                <ReceivedMessage key={message.id}>
                    <AutoLink target="_blank" text={message.message} />
                </ReceivedMessage>
            </Col>
            <OverlayTrigger
                trigger={["hover", "focus"]}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    likers && likers.length > 0 ? (
                        <Tooltip style={{ zIndex: 150000 }} id="button-tooltip">
                            {likers.join(", ")}
                        </Tooltip>
                    ) : (
                        <Tooltip style={{ zIndex: 150000 }} id="button-tooltip">
                            No Likes
                        </Tooltip>
                    )
                }
            >
                <LikeButton
                    onClick={() =>
                        message.liked
                            ? likeChat(message.id, 0)
                            : likeChat(message.id, 1)
                    }
                    size={20}
                    color={message.liked ? "red" : "grey"}
                />
            </OverlayTrigger>
            <small>{message.likers.length}</small>
        </ReceivedMessageWrapper>
    );
};

export default ReceivedChat;
