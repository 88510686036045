import {
    LOG_IN_FULFILLED,
    LOG_OUT,
    UPDATE_USER,
    UserActionTypes,
} from "./types";

const INITIAL_STATE = {
    admin: false,
    api_token: null,
    app_popup: false,
    apple_id: "",
    articles: "",
    card_brand: "",
    card_last_four: "",
    created_at: null,
    dark_mode: false,
    email: "",
    expo_notification_token: "",
    fb_id: "",
    first_name: "",
    google_id: "",
    id: 0,
    last_name: "",
    log_scores: "",
    plus_league: 0,
    premium_league: 0,
    profile_pic: "",
    social_popup: false,
    sponsored: 0,
    stripe_id: "",
    trial_ends_at: null,
    unsubscribe: false,
    unsubscribe_article: false,
    unsubscribe_lineups: false,
    unsubscribe_scores: false,
    unsubscribe_shows: false,
    updated_at: null,
    updated_preferences: false,
    user_code: "",
    isLoggedIn: false,
};

export function userReducer(state = INITIAL_STATE, action: UserActionTypes) {
    switch (action.type) {
        case LOG_IN_FULFILLED:
            state = action.payload.user;
            return {
                ...state,
                isLoggedIn: true,
            };
        case UPDATE_USER:
            state = action.payload.user;
            return {
                ...state,
                isLoggedIn: true,
            };
        // case LOG_IN_REJECTED:
        //     state = {
        //         ...state,
        //         loginErr: action.payload,
        //     };
        //     return state;
        case LOG_OUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
