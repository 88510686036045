import React from "react";
import { Helmet } from "react-helmet-async";

import Alert from "react-bootstrap/Alert";

import { MainContainer, PageHeader } from "../atoms";
import ShowList from "../organisms/ShowList";

const AllShows: React.FC = () => {
    return (
        <MainContainer>
            <Helmet prioritizeSeoTags>
                <title>All Shows - RealTVFantasy</title>
                <meta
                    name="description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:url"
                    content={`https://realtvfantasy.com${window.location.pathname}`}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="Play Reality TV Fantasy - RealTVFantasy"
                />
                <meta
                    property="og:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
                <meta property="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:title"
                    content="Play Reality TV Fantasy - RealTVFantasy"
                />
                <meta
                    property="twitter:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="twitter:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
            </Helmet>
            <PageHeader>
                <h1>All Shows</h1>
            </PageHeader>
            <Alert variant="warning" className="mb-5">
                We will always be running leagues for The Challenge, Survivor,
                Big Brother, The Bachelor, The Bachelorette, Bachelor in
                Paradise and The Amazing Race. These shows are created once the
                cast list is finalized and released to the public.
            </Alert>
            <ShowList />
        </MainContainer>
    );
};

export default AllShows;
