import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { TableSkeleton } from "../atoms/Skeletons";
import LinkList from "../molecules/LinkList";
import PageTemplate from "../templates/PageTemplate";

const AdminArticlesList: React.FC = () => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const getScoresList = useApi(apiRoutes.GET_ARTICLES_LIST(), {
        responseKey: "list",
        onSuccess: (response) => {
            setList(response);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        getScoresList.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageTemplate
            header="Manage Articles"
            loading={loading}
            skeleton={
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            }
        >
            {list.length > 0 &&
                list.map((item, key) => {
                    return (
                        <div className="mb-4" key={key}>
                            <h4 className="text-capitalize">{item.title}:</h4>
                            {item.articles.length > 0 ? (
                                <LinkList list={item.articles} />
                            ) : (
                                <>
                                    <span>No articles for {item.title}</span>
                                </>
                            )}
                        </div>
                    );
                })}
            <Button href={`/admin/articles/add`}>Add New Article</Button>
        </PageTemplate>
    );
};

export default AdminArticlesList;
