import React from "react";

import configs from "../../config/configs";
import strings from "../../config/strings";
import { FullShow, ShowLimits } from "../../models/Shows";

import Select from "../atoms/Select";

interface LeagueWeeklyRedraftFormProps {
    show?: FullShow;
    budget?: boolean;
    showLimits?: ShowLimits | null;
}

const LeagueWeeklyRedraftForm: React.FC<LeagueWeeklyRedraftFormProps> = ({
    budget,
    show, //could be used in the future for rookie/gender limits
    showLimits,
}) => {
    let playersPerWeek = configs.leagues.playersPerWeek;
    let playerLimit = configs.leagues.playerLimit;
    if (showLimits) {
        playersPerWeek = playersPerWeek.slice(
            0,
            showLimits.max_players_per_week ?? 10
        );
        playerLimit = playerLimit.slice(
            0,
            showLimits.max_player_limit ? showLimits.max_player_limit + 1 : 6
        );
    }

    return (
        <>
            {!budget && (
                <Select
                    name="playersPerWeek"
                    label="Players Per Week:"
                    items={playersPerWeek}
                    formText={strings.createLeague.playersPerWeek}
                    field
                />
            )}
            <Select
                name="playerLimit"
                label="Player Limit:"
                items={playerLimit}
                formText={strings.createLeague.playerLimit}
                field
            />
        </>
    );
};

export default LeagueWeeklyRedraftForm;
