import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";

import { RootState } from "../../store";
import { LiveScore } from "../../models/LiveScores";
import { Show } from "../../models/Shows";
import { TeamLive } from "../../models/Scores";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { ModalStyled } from "../atoms";
import { ScoreTeamListSkeleton } from "../atoms/Skeletons";
import LiveCastRow from "../atoms/LiveCastRow";

interface LiveTeamModalProps {
    leagueId: string;
    episode: string;
    liveScores: LiveScore[];
    show: Show;
    showModal: boolean;
    setShowModal: (visible: boolean) => void;
}

const LiveTeamModal: React.FC<LiveTeamModalProps> = ({
    leagueId,
    episode,
    liveScores,
    show,
    showModal,
    setShowModal,
}) => {
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [teamLive, setTeamLive] = useState<TeamLive[]>([]);

    const getLiveTeam = useApi(apiRoutes.GET_TEAM_LIVE(leagueId, episode), {
        responseKey: "team",
        onSuccess: (response: TeamLive[]) => {
            setTeamLive(response);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        if (showModal) {
            setLoading(true);
            const date = new Date();
            const offsetInHours = date.getTimezoneOffset() / 60;
            if (
                offsetInHours >= timezoneOffsets.hawaii &&
                show.hawaii_offset > 0
            ) {
                getLiveTeam.request({
                    show_id: show.id,
                    hawaii: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.alaska &&
                show.alaska_offset > 0
            ) {
                getLiveTeam.request({
                    show_id: show.id,
                    alaska: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.pacific &&
                show.pacific_offset > 0
            ) {
                getLiveTeam.request({
                    show_id: show.id,
                    pacific: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.mountain &&
                show.mountain_offset > 0
            ) {
                getLiveTeam.request({
                    show_id: show.id,
                    mountain: true,
                });
            } else {
                getLiveTeam.request({ show_id: show.id });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, liveScores]);

    useEffect(() => {
        setLoading(true);
    }, [leagueId]);

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
            style={{ zIndex: 10000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Your Team:</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="m-3">
                    {loading ? (
                        <ScoreTeamListSkeleton />
                    ) : (
                        <>
                            {teamLive.length > 0 ? (
                                <>
                                    {teamLive.map((team, index) => {
                                        return (
                                            <LiveCastRow
                                                key={index}
                                                pic={team.pic}
                                                total={team.total}
                                                name={team.name}
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <div className="text-center">
                                    There are currently no scores
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default LiveTeamModal;
