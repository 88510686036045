import { NotificationList } from "../../models/Notifications";

export type NotificationState = NotificationList;

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export interface SetNotifications {
    type: typeof SET_NOTIFICATIONS;
    payload: NotificationState;
}

export interface DeleteNotification {
    type: typeof DELETE_NOTIFICATION;
    payload: string;
}

export type NotificationActionTypes = SetNotifications | DeleteNotification;
