import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Avatar from "@mui/material/Avatar";

import { RootState } from "../../store";
import { imageUrl } from "../../config";
import useApi from "../../hooks/useApi";
import {
    DraftRecap as DraftRecapModel,
    DraftRecapResponse,
} from "../../models/Draft";
import { League } from "../../models/Leagues";
import apiRoutes from "../../services/apiRoutes";

import { TableSkeleton } from "../atoms/Skeletons";
import Table from "../atoms/Table";
import PageTemplate from "../templates/PageTemplate";
import RoundHeader from "../atoms/RoundHeader";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

interface ParamTypes {
    id: string;
}

const DraftRecap: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const history = useHistory();
    const subscription = useSelector((state: RootState) => state.subscription);
    const [loading, setLoading] = useState<boolean>(true);
    const [league, setLeague] = useState<League>();
    const [recap, setRecap] = useState<DraftRecapModel[]>([]);
    const [leagueUserCount, setLeagueUserCount] = useState<number>(0);

    const draftRecapRequest = useApi(apiRoutes.GET_DRAFT_RECAP(id), {
        onSuccess: (response: DraftRecapResponse) => {
            setLeague(response.league);
            setRecap(response.recap);
            setLeagueUserCount(response.leagueUserCount);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        draftRecapRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const determineRound = (value: DraftRecapModel) => {
        if (value.draft_position === 1) {
            return (
                <RoundHeader
                    colspan={
                        subscription && hasPremiumSub(subscription.name) ? 4 : 3
                    }
                    round={1}
                />
            );
        } else {
            const round = Math.ceil(value.draft_position / leagueUserCount);
            const remainder = value.draft_position % leagueUserCount;
            if (remainder === 1) {
                return (
                    <RoundHeader
                        colspan={
                            subscription && hasPremiumSub(subscription.name)
                                ? 4
                                : 3
                        }
                        round={round}
                    />
                );
            } else {
                return null;
            }
        }
    };

    return (
        <PageTemplate
            header="Draft Recap"
            headerSecondary={league && league.name}
            loading={loading}
            skeleton={
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            }
        >
            <Button
                className="mb-4"
                variant="light"
                onClick={() =>
                    history.push(
                        `/myleagues/view/${league.id}${
                            league.id === "0" ||
                            league.id === 0 ||
                            league.id === "team" ||
                            league.id === "budget"
                                ? `/${league.show_id}`
                                : ""
                        }`
                    )
                }
            >
                Back
            </Button>
            {!loading && recap.length === 0 && (
                <div className="text-center">
                    There's no draft recap for this league.
                </div>
            )}
            {!loading && recap.length > 0 && (
                <Table>
                    <thead>
                        <tr className="header">
                            <th>Pick</th>
                            <th className="pl-4">Player</th>
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <th className="text-center">
                                        Avg Draft Position
                                    </th>
                                )}
                            <th className="text-center">Team</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recap.map((value) => {
                            return (
                                <>
                                    {determineRound(value)}
                                    <tr key={value.id} className="mt-4">
                                        <td>{value.draft_position}</td>
                                        <td>
                                            <Col>
                                                <Avatar
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                    }}
                                                    src={
                                                        value.pic
                                                            ? `${imageUrl}${value.pic}`
                                                            : ""
                                                    }
                                                />
                                                <div className="text-left">
                                                    {value.name}
                                                </div>
                                            </Col>
                                        </td>
                                        {subscription &&
                                            hasPremiumSub(
                                                subscription.name
                                            ) && (
                                                <td className="text-center">
                                                    {
                                                        value.average_draft_position
                                                    }
                                                </td>
                                            )}
                                        <td className="text-center">
                                            {value.team_name}
                                        </td>
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </PageTemplate>
    );
};

export default DraftRecap;
