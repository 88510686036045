import { ApiReturnType } from "./";

const preferencesRoutes = {
    SAVE_PREFERENCES: (): ApiReturnType => {
        return {
            url: `preferences/save`,
            method: "post",
        };
    },
    SAVE_UNSUBSCRIBE: (id: string): ApiReturnType => {
        return {
            url: `preferences/unsubscribe/${id}`,
            method: "post",
        };
    },
};

export default preferencesRoutes;
