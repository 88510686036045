import { ApiReturnType } from "./";

const castRoutes = {
    GET_ACTIVE_CAST: (show_id: string): ApiReturnType => {
        return {
            url: `cast/active/${show_id}`,
            method: "get",
        };
    },
    GET_CAST: (cast_id: string): ApiReturnType => {
        return {
            url: `cast/${cast_id}`,
            method: "get",
        };
    },
    GET_CAST_BY_SHOW: (show_id: string): ApiReturnType => {
        return {
            url: `cast/byShow/${show_id}`,
            method: "get",
        };
    },
    GET_CAST_STATS_BY_LEAGUE: (league_id: string): ApiReturnType => {
        return {
            url: `cast/stats/${league_id}`,
            method: "get",
        };
    },
};

export default castRoutes;
