import React from "react";
import Moment from "react-moment";

import Media from "react-bootstrap/Media";
import Avatar from "@mui/material/Avatar";
import VerifiedIcon from "@mui/icons-material/Verified";

import { CommentItem } from "../../models/Comments";

import { Small } from "../atoms";
import { imageUrl } from "../../config";

interface MessageProps {
    message: CommentItem;
}

const Message: React.FC<MessageProps> = ({ message }) => {
    return (
        <>
            <hr />
            <Media>
                <Avatar
                    src={
                        message.profile_pic &&
                        message.profile_pic.includes("https")
                            ? message.profile_pic
                            : `${imageUrl}${message.profile_pic}`
                    }
                />
                <Media.Body className="pl-3">
                    <div className="align-items-center d-flex">
                        {message.admin && (
                            <VerifiedIcon
                                color="success"
                                className="mr-1"
                                fontSize="small"
                            />
                        )}
                        {message.first_name && (
                            <span style={{ fontSize: 20 }}>
                                {message.first_name} {message.last_name}{" "}
                            </span>
                        )}
                        <Small className="ml-2 mt-1">
                            <Moment format="MMMM D, YYYY [at] h:mm a">
                                {message.created_at}
                            </Moment>
                        </Small>
                    </div>
                    <p>{message.comment}</p>
                </Media.Body>
            </Media>
        </>
    );
};

export default Message;
