import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Article } from "../../models/Articles";

import ArticleImage from "../atoms/ArticleImage";
import HTMLView from "../atoms/HTMLView";
import { AuthorLink } from "../atoms";
import PostedDate from "../molecules/PostedDate";

interface ArticleListItemProps {
    article: Article;
}

const ArticleListItem: React.FC<ArticleListItemProps> = ({ article }) => {
    return (
        <Row className="p-5">
            <Col md="6">
                <Link to={`/shows/article/${article.show_slug}/${article.id}`}>
                    <ArticleImage src={article.image} hover={true} />
                </Link>
            </Col>
            <Col md="6">
                <h3>
                    <Link
                        to={`/shows/article/${
                            article.show_slug ? article.show_slug : "general"
                        }/${article.id}`}
                    >
                        {article.title}
                    </Link>
                </h3>
                <p>
                    by{" "}
                    <AuthorLink
                        href={`/articles/author/${article.author_id}`}
                        variant="link"
                    >
                        {article.first_name} {article.last_name}
                    </AuthorLink>
                </p>
                <PostedDate date={article.created_at} />
                <HTMLView
                    content={`${article.description
                        .replace(/<img .*?>/g, "")
                        .replace(/<h[0-9]>/g, "")
                        .replace(/<\/h[0-9]>/g, "")
                        .substr(0, 400)}...`}
                />
            </Col>
        </Row>
    );
};

export default ArticleListItem;
