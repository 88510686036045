import { Subscription } from "../../models/Subscriptions";

export type SubscriptionState = Subscription;

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

export interface UpdateSubscription {
    type: typeof UPDATE_SUBSCRIPTION;
    payload: {
        subscription: Subscription;
    };
}

export type SubscriptionActionTypes = UpdateSubscription;
