import React, { useState } from "react";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fab from "@mui/material/Fab";
import DeleteIcon from "@mui/icons-material/Delete";

import { Score } from "../../models/Scores";

import Input from "../atoms/Input";
import Select from "../atoms/Select";
import AreYouSureModal from "../atoms/AreYouSureModal";
import { ShowFormFields } from "../pages/AdminShow";

interface ScoringInfoFormProps {
    index: number;
    score: Score;
    values: ShowFormFields;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const amounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const ScoringInfoForm: React.FC<ScoringInfoFormProps> = ({
    index,
    score,
    setFieldValue,
    values,
}) => {
    const [showDeleteScore, setShowDeleteScore] = useState<boolean>(false);
    const [selectedScore, setSelectedScore] = useState<null | number>(null);

    const showDeleteScoreModal = (id: number) => {
        setShowDeleteScore(true);
        setSelectedScore(id);
    };

    const deleteScore = (
        scoring: Score[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        if (selectedScore) {
            const newScoring = scoring.filter(
                (score) => score.id !== selectedScore
            );

            setShowDeleteScore(false);
            setSelectedScore(null);
            setFieldValue("scoring", newScoring);
        } else {
            toast.error("No score selected");
        }
    };

    return (
        <>
            <Row style={{ alignItems: "center" }} key={score.id}>
                <Col md={4}>
                    <Input
                        name={`scoring.${index}.description`}
                        label="Description:"
                        placeholder="Description"
                    />
                </Col>
                <Col md={3}>
                    <Input
                        name={`scoring.${index}.category`}
                        label="Category:"
                        placeholder="Category"
                    />
                </Col>
                <Col xs={6} md={2}>
                    <Input
                        name={`scoring.${index}.score`}
                        label="Score:"
                        placeholder="Score"
                        type="number"
                    />
                </Col>
                <Col xs={6} md={2}>
                    <Select
                        name={`scoring.${index}.amount`}
                        label="Amount:"
                        items={amounts}
                    />
                </Col>
                <Col md={1}>
                    <Fab
                        size="small"
                        onClick={() => showDeleteScoreModal(score.id)}
                    >
                        <DeleteIcon />
                    </Fab>
                </Col>
            </Row>
            <AreYouSureModal
                showModal={showDeleteScore}
                setShowModal={setShowDeleteScore}
                body={
                    "Are you sure you want to delete this scoring field?\n\nThis will delete all scores logged for this field already!"
                }
                onSuccess={() => deleteScore(values.scoring, setFieldValue)}
            />
        </>
    );
};

export default ScoringInfoForm;
