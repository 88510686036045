import React from "react";
import { LiveScoreTeams } from "../../models/LiveScores";

interface LiveScoreOwnedLeaguesProps {
    liveScoreTeams: LiveScoreTeams;
    castId: number;
}

const LiveScoreOwnedLeagues: React.FC<LiveScoreOwnedLeaguesProps> = ({
    liveScoreTeams,
    castId,
}) => {
    return (
        <>
            {liveScoreTeams[castId] !== undefined && (
                <div className="mt-3">
                    <small>
                        <b>Owned Leagues:</b>
                        {liveScoreTeams[castId].map((league) => {
                            return (
                                <div key={league.id}>
                                    <small>
                                        {league.name} ({league.draft_type})
                                    </small>
                                </div>
                            );
                        })}
                    </small>
                </div>
            )}
        </>
    );
};

export default LiveScoreOwnedLeagues;
