import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { ModalStyled } from ".";

interface AreYouSureProps {
    title?: string;
    primaryButton?: string;
    showModal: boolean;
    setShowModal: (visible: boolean) => void;
    body: string;
    onSuccess: () => void;
    onCancel?: () => void;
    submitting?: boolean;
    submitText?: string;
}

const AreYouSureModal: React.FC<AreYouSureProps> = ({
    title,
    showModal,
    setShowModal,
    body,
    onSuccess,
    submitting,
    submitText = "Submitting",
    primaryButton,
    onCancel,
}) => {
    const buttonText = primaryButton ? primaryButton : "Yes";

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title ? title : "Are You Sure?"}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3">
                    {body.split("\n").map((i, key) => {
                        return (
                            <div
                                dangerouslySetInnerHTML={{ __html: i }}
                                key={key}
                            />
                        );
                    })}
                </div>
                <Button
                    disabled={submitting}
                    variant="primary"
                    onClick={onSuccess}
                >
                    {submitting ? (
                        <>
                            {submitText}{" "}
                            <CircularProgress size={12} color="inherit" />
                        </>
                    ) : (
                        buttonText
                    )}
                </Button>{" "}
                <Button
                    variant="light"
                    onClick={() => {
                        if (onCancel) {
                            onCancel();
                        }
                        setShowModal(false);
                    }}
                >
                    No
                </Button>
            </Modal.Body>
        </ModalStyled>
    );
};

export default AreYouSureModal;
