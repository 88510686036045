import lightTheme from "../../themes/light";
import { LOG_OUT, UserActionTypes } from "../user/types";
import { SET_THEME, ThemeActionTypes } from "./types";

const INITIAL_STATE = lightTheme;

export function themeReducer(
    state = INITIAL_STATE,
    action: ThemeActionTypes | UserActionTypes
) {
    switch (action.type) {
        case SET_THEME:
            return action.payload;
        case LOG_OUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
