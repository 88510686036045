import React, { ChangeEvent } from "react";

import Form from "react-bootstrap/Form";

import { FormGroupWrapper } from ".";

interface NumPicksSelectProps {
    onChange: (value: string) => void;
    numPicks: number;
    selected: string | number;
}

const NumPicksSelect: React.FC<NumPicksSelectProps> = ({
    onChange,
    numPicks,
    selected,
}) => {
    return (
        <FormGroupWrapper>
            <Form.Label>
                Total Number of Draft Picks (not the number of contestants you
                want on each team):
            </Form.Label>
            <Form.Control
                as="select"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                }}
                value={selected}
            >
                <option value="N/A">N/A</option>
                {Array.from(Array(numPicks).keys(), (pick: number) => {
                    return (
                        <option key={pick + 1} value={pick + 1}>
                            {pick + 1}
                        </option>
                    );
                })}
            </Form.Control>
            <Form.Text muted>
                Total Number of draft picks in your draft. (ex: if you have 6
                people in your league and 25 contestants you would set it to 24
                so that each team can have 4 each.) If set to N/A, you will just
                draft until there's no contestants left
            </Form.Text>
        </FormGroupWrapper>
    );
};

export default NumPicksSelect;
