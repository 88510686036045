import React from "react";

import { Podcast } from "../../models/Podcast";

import { Col, Row } from "react-bootstrap";

import PodcastImage from "../atoms/PodcastImage";
import PostedDate from "../molecules/PostedDate";
import HTMLView from "../atoms/HTMLView";

interface PodcastListItemProps {
    podcast: Podcast;
}

const PodcastListItem: React.FC<PodcastListItemProps> = ({ podcast }) => {
    return (
        <Row className="p-5">
            <Col md="6">
                <a href={podcast.url} target="_blank" rel="noreferrer">
                    <PodcastImage src={podcast.image} hover={true} />
                </a>
            </Col>
            <Col md="6">
                <h3>
                    <a href={podcast.url} target="_blank" rel="noreferrer">
                        {`${podcast.show_id}: ${podcast.name}`}
                    </a>
                </h3>
                <PostedDate date={podcast.release_date} />
                <HTMLView
                    content={`${podcast.description
                        .replace(/<img .*?>/g, "")
                        .replace(/<h[0-9]>/g, "")
                        .replace(/<\/h[0-9]>/g, "")
                        .substr(0, 400)}...`}
                />
            </Col>
        </Row>
    );
};

export default PodcastListItem;
