import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import Form from "react-bootstrap/Form";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser, Team } from "../../models/LeagueUsers";

import { Well, FormGroupWrapper } from "../atoms";
import DraftListItem from "./DraftListItem";

interface DraftTeamProps {
    id: string;
    leagueUser: LeaguesLeagueUser;
    selectedPlayerId: number;
}

//on draft teams open, close out chat and vice versa
const DraftTeams: React.FC<DraftTeamProps> = ({
    id,
    leagueUser,
    selectedPlayerId,
}) => {
    const theme = useTheme();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [team, setTeam] = useState<Team[]>();
    const [teamList, setTeamList] = useState<LeaguesLeagueUser[]>();
    const [selectedTeam, setSelectedTeam] =
        useState<LeaguesLeagueUser>(leagueUser);

    const teamRequest = useApi(apiRoutes.GET_TEAM(), {
        responseKey: "team",
        onSuccess: (response: Team[]) => {
            setTeam(response);
            setLoaded(true);
        },
    });
    const leagueUsersRequest = useApi(
        apiRoutes.GET_LEAGUE_USERS_BY_LEAGUE(id),
        {
            responseKey: "leagueUsers",
            onSuccess: (response: LeaguesLeagueUser[]) => {
                const selected = response.filter((user) => {
                    return user.id === leagueUser.id;
                })[0];
                setTeamList(response);
                setSelectedTeam(selected);
                teamRequest.request({ id: selected.id });
            },
        }
    );

    useEffect(() => {
        leagueUsersRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (selectedPlayerId > 0 && selectedTeam) {
            teamRequest.request({ id: selectedTeam.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlayerId]);

    const handleChange = (event: any) => {
        //grab the new team and set it to cast
        const selected = teamList.filter((user: LeaguesLeagueUser) => {
            return user.id.toString() === event.target.value.toString();
        })[0];
        setSelectedTeam(selected);
        teamRequest.request({ id: selected.id });
    };

    return (
        <Well>
            {loaded && (
                <FormGroupWrapper>
                    <Form.Control
                        style={{
                            backgroundColor: theme.primary,
                            color: theme.textColor,
                        }}
                        as="select"
                        value={selectedTeam.id}
                        onChange={(e) => handleChange(e)}
                    >
                        {teamList &&
                            teamList.map((item: LeaguesLeagueUser) => {
                                return (
                                    <option key={item.id} value={item.id}>
                                        {item.team_name}
                                    </option>
                                );
                            })}
                    </Form.Control>
                    <div className="mt-3">
                        <h4 className="text-center">
                            {leagueUser.id === selectedTeam.id
                                ? "Your Team"
                                : `${selectedTeam.team_name}'s Team`}
                        </h4>
                        {(!team || team.length === 0) && (
                            <div className="text-center mt-3">
                                Team is empty
                            </div>
                        )}
                        {team && team.length > 0 && (
                            <List>
                                <Divider />
                                {team.map((player: Team) => {
                                    return (
                                        <DraftListItem
                                            draft
                                            key={player.id}
                                            player={player}
                                        />
                                    );
                                })}
                            </List>
                        )}
                    </div>
                </FormGroupWrapper>
            )}
        </Well>
    );
};

export default DraftTeams;
