import React from "react";

import Button from "react-bootstrap/Button";

import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";

import DraftNote from "../organisms/DraftNote";

interface DraftHeaderProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    setShowDraftOrderModal: (show: boolean) => void;
    setShowAysFinish: (show: boolean) => void;
    setShowAysReset: (show: boolean) => void;
    leagueRequest: {
        loading: boolean;
        data: any;
        error: any;
        request: (options?: {}, headers?: {}) => Promise<void>;
    };
}

const DraftHeader: React.FC<DraftHeaderProps> = ({
    league,
    leagueUser,
    setShowDraftOrderModal,
    setShowAysFinish,
    setShowAysReset,
    leagueRequest,
}) => {
    return (
        <>
            {!league.draft_started && leagueUser.commissioner ? (
                <Button
                    className="mr-2"
                    onClick={() => setShowDraftOrderModal(true)}
                >
                    Start Draft
                </Button>
            ) : (
                <>
                    {leagueUser && leagueUser.commissioner && (
                        <>
                            <Button
                                className="mr-2"
                                onClick={() => setShowAysFinish(true)}
                            >
                                Finish Draft
                            </Button>
                            <Button
                                className="mr-2"
                                onClick={() => setShowAysReset(true)}
                            >
                                Reset Draft
                            </Button>
                        </>
                    )}
                </>
            )}
            {leagueUser && leagueUser.commissioner && (
                <DraftNote
                    id={league.id.toString()}
                    note={league.draft_note}
                    onSuccess={() => leagueRequest.request()}
                />
            )}
        </>
    );
};

export default DraftHeader;
