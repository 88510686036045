import React, { ChangeEvent } from "react";

import Form from "react-bootstrap/Form";

import { DraftTypes } from "../../models/Shows";

import { FormGroupWrapper } from ".";

interface AnalyticsSelectProps {
    onChange: (value: string) => void;
    draftType?: DraftTypes;
    secondary: string;
    defaultValue?: string;
    tertiary?: string;
    fourth?: string;
    value: string;
}

const AnalyticsSelect: React.FC<AnalyticsSelectProps> = ({
    value,
    defaultValue,
    secondary,
    tertiary,
    fourth,
    draftType,
    onChange,
}) => {
    return (
        <FormGroupWrapper>
            <Form.Label>Sort By:</Form.Label>
            <Form.Control
                as="select"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                }}
                value={value}
            >
                {draftType && draftType === "Weekly Budget" && (
                    <option value="cost">Cost</option>
                )}
                <option value="name">
                    {defaultValue ? defaultValue : "Name"}
                </option>
                <option value="secondary">{secondary}</option>
                {tertiary && <option value="tertiary">{tertiary}</option>}
                {fourth && <option value="fourth">{fourth}</option>}
            </Form.Control>
        </FormGroupWrapper>
    );
};

export default AnalyticsSelect;
