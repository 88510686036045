import styled from "styled-components";

import Avatar from "@mui/material/Avatar";
import MuiListItemAvatar from "@mui/material/ListItemAvatar";

export const AvatarItem = styled(Avatar)`
    margin: 0px 10px;
`;

export const ListItemAvatar = styled(MuiListItemAvatar)`
    width: 90%;
`;

export const TableDataSmall = styled.td`
    width: 20%;
    text-align: center;
    vertical-align: middle;
`;

export const TableDataLarge = styled.td`
    width: 60%;
    text-align: center;
    vertical-align: middle;
`;
