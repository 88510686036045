import React from "react";
import { useDispatch } from "react-redux";
import { ErrorMessage, FastField, FastFieldProps } from "formik";

import Form from "react-bootstrap/Form";

import { setTheme } from "../../store/theme/actions";
import darkTheme from "../../themes/dark";
import lightTheme from "../../themes/light";

interface SwitchProps {
    name: string;
    label: string;
    formText?: string;
}

const Switch: React.FC<SwitchProps> = ({ name, label, formText }) => {
    const dispatch = useDispatch();

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps) => {
                let value = false;
                if (
                    field.value === 1 ||
                    field.value === true ||
                    (Array.isArray(field.value) && field.value.includes("on"))
                ) {
                    value = true;
                }

                //toggle dark mode if they are toggling
                if (name === "darkMode") {
                    dispatch(setTheme(value ? darkTheme : lightTheme));
                }

                return (
                    <Form.Group>
                        <Form.Check
                            id={`switch-${name}`}
                            type={"switch"}
                            checked={value}
                            onChange={() => form.setFieldValue(name, !value)}
                            label={label}
                        />
                        <Form.Control.Feedback type="invalid">
                            {meta.error && <ErrorMessage name={meta.error} />}
                        </Form.Control.Feedback>
                        {formText && <Form.Text muted>{formText}</Form.Text>}
                    </Form.Group>
                );
            }}
        </FastField>
    );
};

export default Switch;
