import React from "react";

import Avatar from "@mui/material/Avatar";

import { imageUrl } from "../../config";
import { ConfidenceTeam } from "../../models/LeagueUsers";

import { ErrorText, Strikethrough, SuccessText } from "../atoms";

interface ConfidencePlayerRowProps {
    player: ConfidenceTeam;
    currentEpisode: number;
    eliminatedOnly?: boolean;
}

const ConfidencePlayerRow: React.FC<ConfidencePlayerRowProps> = ({
    player,
    currentEpisode,
    eliminatedOnly,
}) => {
    const playerSurvived = (player: ConfidenceTeam) => {
        if (
            currentEpisode > player.episode &&
            (player.episode_eliminated === null ||
                player.episode_eliminated > player.episode)
        ) {
            return true;
        }

        return false;
    };

    const PlayerRow: React.FC<{ value: string; points?: boolean }> = ({
        value,
        points,
    }) => {
        if (eliminatedOnly) {
            return (
                <>
                    {playerSurvived(player) === true ? (
                        <ErrorText>
                            <Strikethrough>{value}</Strikethrough>
                        </ErrorText>
                    ) : (
                        <SuccessText>
                            {value}
                            {!points && <> (Eliminated)</>}
                        </SuccessText>
                    )}
                </>
            );
        } else {
            return (
                <>
                    {playerSurvived(player) === false ? (
                        <ErrorText>
                            <Strikethrough>{value}</Strikethrough>
                            {!points && <> (Eliminated)</>}
                        </ErrorText>
                    ) : (
                        <SuccessText>{value}</SuccessText>
                    )}
                </>
            );
        }
    };

    return (
        <tr>
            <td>
                <Avatar src={`${imageUrl}${player.pic}`} />
                {player.episode === currentEpisode ? (
                    player.name
                ) : (
                    <PlayerRow value={player.name} />
                )}
            </td>
            <td className="text-right pr-5">
                {player.episode === currentEpisode ? (
                    player.points
                ) : (
                    <PlayerRow value={player.points.toString()} points />
                )}
            </td>
        </tr>
    );
};

export default ConfidencePlayerRow;
