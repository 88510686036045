import { ApiReturnType } from "./";

const commentRoutes = {
    GET_SCORE_COMMENTS: (
        slug: string,
        episode: number | string
    ): ApiReturnType => {
        return {
            url: `comments/score/${slug}/${episode}`,
            method: "get",
        };
    },
    GET_LIVE_SCORE_COMMENTS: (id: string): ApiReturnType => {
        return {
            url: `comments/liveScore/${id}`,
            method: "get",
        };
    },
    SAVE_COMMENT: (
        type: "article" | "score",
        id: number | string
    ): ApiReturnType => {
        return {
            url: `comments/save/${type}/${id}`,
            method: "post",
        };
    },
};

export default commentRoutes;
