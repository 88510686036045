import styled from "styled-components";

export const ActionItemWrapper = styled.div`
    min-width: 120px;
    min-height: 70px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    background-color: ${(props) => props.theme.secondary};
`;
