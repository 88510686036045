const lightTheme = {
    primary: "#dfe6f1",
    secondary: "#fafafa",
    secondaryHeader: "#f1f1f1",
    border: "#bcc8df",
    textColor: "#000",
    selected: "#d4edda",
    inputColor: "#ffffff",
};

export default lightTheme;
