import React from "react";

import { Team } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";
import { Starters } from "../../models/TeamLeagueTeams";

import { ErrorText } from ".";

interface PlayerRowDataProps {
    eliminated: boolean;
    value: string | number | React.ReactElement;
    errorOverride?: string | number | React.ReactElement;
    player: Team | Starters;
    league: League;
}

const PlayerRowData: React.FC<PlayerRowDataProps> = ({
    eliminated,
    value,
    errorOverride,
    player,
    league,
}) => {
    return (
        <>
            {eliminated ? (
                <ErrorText>{errorOverride ? errorOverride : value}</ErrorText>
            ) : (
                value
            )}
            {!player.owned &&
                league.draft_type === "Full Draft" &&
                errorOverride &&
                " *"}
        </>
    );
};

export default PlayerRowData;
