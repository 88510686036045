import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";

import PageTemplate from "../templates/PageTemplate";

const TradingGuide: React.FC = () => {
    return (
        <PageTemplate header="Trading Guide">
            <Col md={12}>
                <p>How do I propose a trade?</p>
                <ul>
                    <li>
                        Navigate to your league's homepage. Once there, you will
                        click "League Actions", then "Propose Trade" button (you
                        will not be able to propose a trade whenever shows are
                        locked and scores are still being calculated). Click
                        that button and then you will be asked to choose what
                        team you want to trade with. Once you choose a team, you
                        will see your team and their team listed and you will
                        select the players from each team to trade. The last
                        thing you have to do is click "Finalize Trade" and then
                        you're good to go! This will send an email and
                        notification to the team you're trading with that lets
                        them know about the offer.
                    </li>
                </ul>
                <p>How do I cancel a proposed trade?</p>
                <ul>
                    <li>
                        Once a trade is proposed, you will have an option to
                        cancel it if you don't want to go through with the trade
                        anymore. To do this, you will first navigate to your
                        league's homepage. From there click "League Actions",
                        then "Trades" with a number above it that shows how many
                        trades you have open. This will bring you to a page that
                        will list out your trade offers. Under "Pending Trades"
                        you will have to find the trade you want to cancel and
                        then just click "Cancel." This will send an email and
                        notification to the person you were trading with to let
                        them know that the trade was cancelled.
                    </li>
                </ul>
                <p>How do I Accept/Decline a trade?</p>
                <ul>
                    <li>
                        Navigate to your league's homepage. From there you
                        should click on "League Actions", then "Trades". In the
                        "Trade Offers" section, find the trade you want to
                        accept or decline and then click "Accept" or "Decline."
                        If the trade is accepted, an email will be sent to
                        everyone in your league to let them know a trade has
                        been accepted. If the trade is declined, an email will
                        be sent to the person who you are trading with to notify
                        them that the trade was declined.
                    </li>
                </ul>
                <p>
                    Once I trade a player away, how will their points be
                    reflected on my team?
                </p>
                <ul>
                    <li>
                        Once you trade a player away, you will still see them on
                        your team page, but they will have an asterisk symbol
                        (*) next to their name to indicate that you no longer
                        own that player. You will still have all the points that
                        player has gained for your team up until the time you
                        traded them away, but you will not gain any of their
                        points in future episodes since they aren't on your team
                        anymore.
                    </li>
                </ul>
                <p>
                    Wait, I just accepted a trade and the player I received says
                    that they have 0 points?
                </p>
                <ul>
                    <li>
                        This would be correct. You only gain points that the
                        player will get from the current episode and future
                        episodes. You will not receive any of their points that
                        were reflected before the trade was accepted.
                    </li>
                </ul>
                <p>Can a player be traded multiple times during the season?</p>
                <ul>
                    <li>
                        Yes, a player can be traded as many times as you want
                        during the season. However, the only rule is that a
                        player can only be traded once during the week. For
                        example, if you have just acquired a player in a trade,
                        you cannot trade that player away during that same week
                        before scores are recorded.
                    </li>
                </ul>
                <p>
                    Can I trade multiple players for one player and vice versa?
                </p>
                <ul>
                    <li>
                        Yes, you can trade multiple players for one player or
                        multiple players. The only rule behind this is that you
                        have to trade at least one person. You cannot trade
                        nobody for someone else.
                    </li>
                </ul>
                <p>Do trades expire?</p>
                <ul>
                    <li>
                        Yes. All trades expire whenever the episode airs and
                        locks on the site. So, trades will expire every week
                        once the next episode starts.
                    </li>
                </ul>
                <p>
                    If you have any further questions, send us a message on our{" "}
                    <Link to="/contact">Contact Us</Link> page.
                </p>
            </Col>
        </PageTemplate>
    );
};

export default TradingGuide;
