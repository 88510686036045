import React from "react";

import Row from "react-bootstrap/Row";

import { Score } from "../../models/Scores";
import { ChallengeQuestion } from "../../models/ChallengeQuestions";

import { Well } from "../atoms";
import ScoringList from "./ScoringList";
import QuestionList from "./QuestionList";

interface ScoringListProps {
    questions: ChallengeQuestion[];
    scoring: Score[];
}

const ShowScoring: React.FC<ScoringListProps> = ({ scoring, questions }) => {
    return (
        <Well>
            <Row>
                <div className="col-md-12">
                    <h6>
                        All point totals are customizable whenever creating
                        league (these point totals are the default)
                    </h6>
                    {questions.length > 0 && (
                        <h6 className="font-italic mt-3">
                            Challenge questions are either season long questions
                            that are asked at the beginning of your league and
                            locked for the entire season, or are asked each
                            episode/week.
                        </h6>
                    )}
                </div>
                <QuestionList questions={questions} />
                <ScoringList scoring={scoring} />
            </Row>
        </Well>
    );
};

export default ShowScoring;
