import styled from "styled-components";

import ListItem from "@mui/material/ListItem";

export const Header = styled.div`
    position: fixed;
    top: 0;
    width: 90%;
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.textColor};
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    z-index: 100;
    height: 60px;
    text-align: center;
    border-bottom-right-radius: 20px;
`;

export const Body = styled.div`
    background-color: ${(props) => props.theme.inputColor};
    overflow: auto;
    position: fixed;
    width: 90%;
    bottom: 60px;
    height: calc(100% - 120px);
    top: 60px;
`;

export const Footer = styled.div`
    position: absolute;
    bottom: 0;
    padding: 10px;
    height: 60px;
    text-align: center;
    width: 90%;
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.textColor};
    border-bottom-right-radius: 20px;
`;

export const ListHeader = styled((props) => (
    <ListItem {...props}>{props.children}</ListItem>
))`
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.textColor};
`;
