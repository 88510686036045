import React, { useState } from "react";

import Image from "react-bootstrap/Image";

interface PodcastImageProps {
    src: string;
    hover?: boolean;
}

const PodcastImage: React.FC<PodcastImageProps> = (props) => {
    const [hover, setHover] = useState(false);

    return (
        <Image
            onMouseOver={() => props.hover && setHover(true)}
            onMouseOut={() => props.hover && setHover(false)}
            className={`mw-100 mb-3`}
            style={hover ? { opacity: 0.8 } : {}}
            src={`${props.src}`}
        />
    );
};

export default PodcastImage;
