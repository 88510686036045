import React from "react";
import { useSelector } from "react-redux";

import { Question } from "../../models/Questions";
import { QuestionAnswerResponse } from "../../models/QuestionAnswers";
import { LeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import { RootState } from "../../store";
import { League } from "../../models/Leagues";

import Table from "../atoms/Table";
import ChallengeQuestionListHeader from "../molecules/ChallengeQuestionListHeader";
import ChallengeQuestionRow from "../molecules/ChallengeQuestionRow";

interface ChallengeQuestionListProps {
    questions: Question[];
    responses: QuestionAnswerResponse[];
    episode: number;
    leagueUser: LeagueUser;
    league: League;
    show: Show;
}

const ChallengeQuestionList: React.FC<ChallengeQuestionListProps> = ({
    questions,
    responses,
    episode,
    leagueUser,
    show,
    league,
}) => {
    const user = useSelector((state: RootState) => state.user);

    const teamHidden = (question: Question) => {
        const coOwners =
            leagueUser.co_owners !== null
                ? leagueUser.co_owners.split(",")
                : [];
        if (question.type === "Season") {
            if (
                league.episode_started + 1 >= show.current_episode &&
                !show.locked
            ) {
                //TODO: might need to look into this a little more
                if (coOwners.some((item) => user.id.toString() === item)) {
                    return false;
                } else if (
                    leagueUser.user_id.toString() === user.id.toString()
                ) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            if (show.current_episode === episode && !show.locked) {
                if (coOwners.some((item) => user.id.toString() === item)) {
                    return false;
                } else if (
                    leagueUser.user_id.toString() === user.id.toString()
                ) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    };

    return (
        <>
            {questions.map((q, index) => {
                const userAnswers = responses.filter((sq) => {
                    return sq.question_id === q.id && sq.episode === episode;
                });

                return (
                    <div className="mt-3" key={index}>
                        <ChallengeQuestionListHeader
                            question={q}
                            episode={episode}
                        />
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-center">Answer(s)</th>
                                    <th className="text-center">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teamHidden(q) ? (
                                    <tr>
                                        <td className="text-center" colSpan={2}>
                                            Answers hidden until show is locked
                                            for the{" "}
                                            {show.type === "week"
                                                ? "week"
                                                : "episode"}
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {userAnswers.length <= 0 && (
                                            <tr>
                                                <td
                                                    className="text-center"
                                                    colSpan={2}
                                                >
                                                    No answers
                                                </td>
                                            </tr>
                                        )}
                                        {userAnswers.length > 0 &&
                                            userAnswers.map((ua, i) => {
                                                return (
                                                    <ChallengeQuestionRow
                                                        key={i}
                                                        score={q.score}
                                                        userAnswer={ua}
                                                        episode={episode}
                                                    />
                                                );
                                            })}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </div>
                );
            })}
        </>
    );
};

export default ChallengeQuestionList;
