import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "../../store";

import LoginModal from "./LoginModal";

interface LeagueButtonsProps {
    showId: number;
    slug: string;
}

const LeagueButtons: React.FC<LeagueButtonsProps> = ({ showId, slug }) => {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
    const [showLogin, setShowLogin] = useState<boolean>(false);

    return (
        <>
            {/* <Link to={`/myleagues/view/0/${showId}`}>
                <Button className="mb-2" block>
                    Public Weekly Redraft League
                </Button>
            </Link>
            <Link to={`/myleagues/view/team/${showId}`}>
                <Button className="mb-2" block>
                    Public Team League
                </Button>
            </Link> */}
            {!isLoggedIn && (
                <Button
                    onClick={() => setShowLogin(true)}
                    className="mb-2"
                    block
                >
                    Create New League
                </Button>
            )}
            {isLoggedIn && (
                <>
                    <Link to={`/myleagues/search?showId=${showId}`}>
                        <Button className="mb-2" block>
                            Find Public League
                        </Button>
                    </Link>
                    <Link to={`/myleagues/create/${slug}`}>
                        <Button className="mb-2" block>
                            Create New League
                        </Button>
                    </Link>
                </>
            )}
            <LoginModal
                show={showLogin}
                onHide={() => setShowLogin(false)}
                message={"You must login to create a private league"}
            />
        </>
    );
};

export default LeagueButtons;
