import { TimezoneOffset } from "../models/TimezoneOffset";

export const determineTimezone = (timezoneOffsets: TimezoneOffset) => {
    const date = new Date();
    const offsetInHours = date.getTimezoneOffset() / 60;
    if (offsetInHours >= timezoneOffsets.hawaii) {
        return { hawaii: true };
    } else if (offsetInHours >= timezoneOffsets.alaska) {
        return { alaska: true };
    } else if (offsetInHours >= timezoneOffsets.pacific) {
        return {
            pacific: true,
        };
    } else if (offsetInHours >= timezoneOffsets.mountain) {
        return {
            mountain: true,
        };
    } else {
        return {};
    }
};
