import { ApiReturnType } from "./";

const lineupRoutes = {
    GET_TEAM_LINEUP: (teamId: string): ApiReturnType => {
        return {
            url: `lineups/team/${teamId}`,
            method: "get",
        };
    },
    GET_WEEKLY_LINEUP: (teamId: string): ApiReturnType => {
        return {
            url: `lineups/weekly/${teamId}`,
            method: "get",
        };
    },
    SAVE_TEAM_LINEUP: (teamId: string): ApiReturnType => {
        return {
            url: `lineups/saveTeam/${teamId}`,
            method: "post",
        };
    },
    SAVE_WEEKLY_LINEUP: (teamId: string): ApiReturnType => {
        return {
            url: `lineups/saveWeekly/${teamId}`,
            method: "post",
        };
    },
    SELECT_TEAM_LINEUP: (teamId: string): ApiReturnType => {
        return {
            url: `lineups/selectTeam/${teamId}`,
            method: "post",
        };
    },
};

export default lineupRoutes;
