import React, { useState } from "react";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fab from "@mui/material/Fab";
import DeleteIcon from "@mui/icons-material/Delete";

import { ChallengeQuestion } from "../../models/ChallengeQuestions";

import Input from "../atoms/Input";
import Select from "../atoms/Select";
import AreYouSureModal from "../atoms/AreYouSureModal";
import { ShowFormFields } from "../pages/AdminShow";

interface ChallengeQuestionFormProps {
    index: number;
    question: ChallengeQuestion;
    values: ShowFormFields;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const ChallengeQuestionForm: React.FC<ChallengeQuestionFormProps> = ({
    index,
    question,
    setFieldValue,
    values,
}) => {
    const [showDeleteQuestion, setShowDeleteQuestion] =
        useState<boolean>(false);
    const [selectedQuestion, setSelectedQuestion] = useState<null | number>(
        null
    );

    const showDeleteScoreModal = (id: number) => {
        setShowDeleteQuestion(true);
        setSelectedQuestion(id);
    };

    const deleteQuestion = (
        questions: ChallengeQuestion[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        if (selectedQuestion) {
            const newQuestion = questions.filter(
                (q) => q.id !== selectedQuestion
            );

            setShowDeleteQuestion(false);
            setSelectedQuestion(null);
            setFieldValue("questions", newQuestion);
        } else {
            toast.error("No challenge question selected");
        }
    };

    return (
        <>
            <Row style={{ alignItems: "center" }} key={question.id}>
                <Col md={4}>
                    <Input
                        name={`questions.${index}.description`}
                        label="Description:"
                        placeholder="Description"
                    />
                </Col>
                <Col md={4}>
                    <Select
                        name={`questions.${index}.type`}
                        label="Type:"
                        items={["Episode", "Season"]}
                    />
                </Col>
                <Col md={3}>
                    <Input
                        name={`questions.${index}.score`}
                        label="Score:"
                        placeholder="Score"
                        type="number"
                    />
                </Col>
                <Col md={1}>
                    <Fab
                        size="small"
                        onClick={() => showDeleteScoreModal(question.id)}
                    >
                        <DeleteIcon />
                    </Fab>
                </Col>
            </Row>
            <AreYouSureModal
                showModal={showDeleteQuestion}
                setShowModal={setShowDeleteQuestion}
                body={
                    "Are you sure you want to delete this challenge question?\n\nThis will delete all scores logged for this question already!"
                }
                onSuccess={() =>
                    deleteQuestion(values.questions, setFieldValue)
                }
            />
        </>
    );
};

export default ChallengeQuestionForm;
