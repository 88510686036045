import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";

import useApi from "../../hooks/useApi";
import { Article as ArticleModel, ArticleView } from "../../models/Articles";
import { Show } from "../../models/Shows";
import apiRoutes from "../../services/apiRoutes";
import { CommentList } from "../../models/Comments";
import { RootState } from "../../store";

import ArticleImage from "../atoms/ArticleImage";
import HTMLView from "../atoms/HTMLView";
import { AuthorLink, VerticalPadding, Well } from "../atoms";
import CurrentShowLeagues from "../molecules/CurrentShowLeagues";
import LeagueButtons from "../molecules/LeagueButtons";
// import LeagueDifference from "../molecules/LeagueDifference";
import PostedDate from "../molecules/PostedDate";
import ShowLinkList from "../organisms/ShowLinkList";
import Comments from "../organisms/Comments";
import ArticleTemplate from "../templates/ArticleTemplate";
import { ArticleSkeleton } from "../atoms/Skeletons";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";

interface ParamTypes {
    slug: string;
    id: string;
}

const Article: React.FC = () => {
    const isAdmin = useSelector((state: RootState) => state.user.admin);
    const history = useHistory();

    const [article, setArticle] = useState<ArticleModel>();
    const [show, setShow] = useState<Show>();
    const [comments, setComments] = useState<CommentList>();
    const { id } = useParams<ParamTypes>();

    const getArticle = useApi(apiRoutes.GET_ARTICLE(id), {
        onSuccess,
    });

    useEffect(() => {
        getArticle.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSuccess(response: ArticleView) {
        if (
            response.articles.published ||
            (!response.articles.published && isAdmin)
        ) {
            setArticle(response.articles);
            setShow(response.articles.show);
            setComments(response.articles.comments);
        } else {
            history.push("/404");
        }
    }

    return (
        <ArticleTemplate
            header={article && article.title}
            headerSecondary={
                article && (
                    <React.Fragment>
                        by{" "}
                        <AuthorLink
                            href={`/articles/author/${article.author_id}`}
                            variant="link"
                        >
                            {article.first_name} {article.last_name}
                        </AuthorLink>
                    </React.Fragment>
                )
            }
            loading={getArticle.loading}
            sideCards={
                show && (
                    <>
                        {show.active && (
                            <Well>
                                {/* <LeagueDifference /> */}
                                <LeagueButtons
                                    showId={show.id}
                                    slug={show.slug}
                                />
                                <CurrentShowLeagues showId={show.id} />
                            </Well>
                        )}
                        <Well>
                            {/* <Ad /> */}
                            <GoogleAd slot="2424423133" height={280} />
                        </Well>
                        <ShowLinkList
                            type="articles"
                            show={show}
                            viewAll={`/shows/article/${show.slug}`}
                        />
                        <ShowLinkList type="scores" show={show} />
                    </>
                )
            }
            comments={
                comments &&
                article && (
                    <Comments
                        comments={comments}
                        id={article.id}
                        type={"article"}
                    />
                )
            }
            show={show && show}
            article={article && article}
            skeleton={<ArticleSkeleton />}
        >
            {!getArticle.loading && article && (
                <>
                    <PostedDate date={article.created_at} />
                    <ArticleImage src={article.image} />
                    <VerticalPadding />
                    {/* <Ad /> */}
                    <GoogleAd slot="2424423133" height={280} />
                    <HTMLView content={article.description} />
                </>
            )}
        </ArticleTemplate>
    );
};

export default Article;
