import React from "react";
import { useTheme } from "styled-components";
import { FastField, FastFieldProps } from "formik";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FormGroupWrapper } from ".";

interface SelectProps {
    name: string;
    label: string;
    amount: number;
    colored?: boolean;
    odd?: boolean;
}

const LogScoresSelect: React.FC<SelectProps> = ({
    name,
    label,
    amount,
    colored,
    odd,
}) => {
    const theme = useTheme();

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps) => {
                return (
                    <FormGroupWrapper
                        style={
                            colored
                                ? { backgroundColor: "green" }
                                : odd
                                ? { backgroundColor: theme.primary }
                                : {}
                        }
                        className="pt-3 pb-3 mb-0 pr-2 pl-2"
                        as={Row}
                    >
                        <Form.Label column sm={9} xs={8}>
                            {label}
                        </Form.Label>
                        <Col sm={3} xs={4}>
                            <Form.Control
                                as="select"
                                value={field.value}
                                onChange={form.handleChange(name)}
                                isInvalid={
                                    form.submitCount > 0 &&
                                    meta.error !== undefined
                                }
                            >
                                {Array.from(
                                    Array(amount + 1).keys(),
                                    (score: number) => {
                                        return (
                                            <option key={score} value={score}>
                                                {score}
                                            </option>
                                        );
                                    }
                                )}
                            </Form.Control>
                        </Col>
                    </FormGroupWrapper>
                );
            }}
        </FastField>
    );
};

export default LogScoresSelect;
