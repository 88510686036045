import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import { Alert } from "react-bootstrap";

import { DraftTypes } from "../../models/Shows";
import { ChallengeQuestion } from "../../models/ChallengeQuestions";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import ScoringList from "./ScoringList";
import { Well } from "../atoms";
import QuestionList from "./QuestionList";

interface LeagueScoringProps {
    leagueId: string;
    showId: number;
    draftType?: DraftTypes;
}

const LeagueScoring: React.FC<LeagueScoringProps> = ({
    leagueId,
    showId,
    draftType,
}) => {
    const [questions, setQuestions] = useState<ChallengeQuestion[]>([]);
    const [customQuestions, setCustomQuestions] = useState<ChallengeQuestion[]>(
        []
    );
    const leagueScoring = useApi(apiRoutes.GET_LEAGUE_SCORING(leagueId), {
        responseKey: "scoring",
    });
    const leagueQuestion = useApi(
        apiRoutes.GET_LEAGUE_QUESTIONS(leagueId, showId.toString()),
        {
            onSuccess: (response: {
                questions: ChallengeQuestion[];
                customQuestions: ChallengeQuestion[];
            }) => {
                setQuestions(response.questions);
                setCustomQuestions(response.customQuestions);
            },
        }
    );

    useEffect(() => {
        if (
            draftType !== "Confidence Pool" &&
            draftType !== "Eliminated Confidence Pool"
        ) {
            leagueScoring.request({ show_id: showId });
        }
        leagueQuestion.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showId, draftType]);

    return (
        <Well>
            <Row>
                {!leagueQuestion.loading && (
                    <QuestionList
                        questions={questions}
                        customQuestions={customQuestions}
                    />
                )}
                {!leagueScoring.loading && (
                    <ScoringList scoring={leagueScoring.data} />
                )}
                {(draftType === "Confidence Pool" ||
                    draftType === "Eliminated Confidence Pool") && (
                    <Alert className="m-3" variant="warning">
                        Scoring for {draftType} leagues are determined how you
                        rank contestants each week. Click on Make Picks to set
                        your lineup for this week.
                    </Alert>
                )}
            </Row>
        </Well>
    );
};

export default LeagueScoring;
