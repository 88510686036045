import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";
import { CustomChallengeQuestionsList } from "../../models/CustomScoring";
import { Show } from "../../models/Shows";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import PageTemplate from "../templates/PageTemplate";
import Input from "../atoms/Input";
import SubmitButton from "../atoms/SubmitButton";
import { TableSkeleton } from "../atoms/Skeletons";
import Select from "../atoms/Select";

interface ParamTypes {
    id: string;
}

const validationSchema = yup.object({
    scores: yup.array().of(
        yup.object().shape({
            id: yup.string(),
            type: yup.string(),
            description: yup.string(),
            score: yup
                .string()
                .matches(/^-?\d*$/g, "Invalid Score")
                .required("Invalid Score"),
        })
    ),
});

const EditCustomQuestions: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const history = useHistory();
    const [league, setLeague] = useState<League>();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState({
        scores: [],
    });

    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(id), {
        onSuccess,
    });
    const saveCustomScoringRequest = useApi(
        apiRoutes.SAVE_CUSTOM_CHALLENGE_QUESTIONS(id),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                toast.success(message);
                setSubmitting(false);
                history.push(`/myleagues/view/${id}?view=scoring`);
            },
            onFailure: (message: string) => {
                toast.error(message);
                setSubmitting(false);
            },
        }
    );

    useEffect(() => {
        leagueRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSuccess(response: {
        league: League;
        show: Show;
        leagueUser: LeaguesLeagueUser;
        canViewLeague: boolean;
        customChallengeQuestions: CustomChallengeQuestionsList;
        remainingCustomChallengeQuestions: number;
    }) {
        document.body.style.overflow = "auto";
        if (response.canViewLeague && response.leagueUser.commissioner) {
            setLeague(response.league);
            const scores = [...response.customChallengeQuestions];
            Array.from(
                Array(response.remainingCustomChallengeQuestions).keys(),
                (key: number) => {
                    return scores.push({
                        id: `new-${key}`,
                        type: "Episode",
                        show_id: response.show.id,
                        description: "",
                        score: 0,
                        score_id: `new-${key}`,
                    });
                }
            );
            setInitialValues({ scores: [...scores] });
            setLoading(false);
        } else {
            window.location.href = "/";
        }
    }

    return (
        <PageTemplate
            header="Edit Custom Challenge Questions"
            headerSecondary={league && league.name}
            loading={loading}
            skeleton={<TableSkeleton />}
        >
            <>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setSubmitting(true);
                        saveCustomScoringRequest.request({
                            scores: values.scores,
                        });
                    }}
                >
                    {({ values, handleSubmit, setFieldValue, isValid }) => (
                        <Form onSubmit={handleSubmit}>
                            <Col className="font-weight-bold mb-3">
                                The only possible answers to challenge questions
                                are contestants from the show.
                            </Col>
                            <Col className="font-weight-bold mb-3">
                                * There are two types of challenge questions:
                                Season and Episode. A Season challenge question
                                is a season-long challenge question that is
                                asked at the beginning of the season and cannot
                                be changed once submitted. An Episode challenge
                                question is asked every episode.
                            </Col>
                            {values.scores &&
                                values.scores.map((score, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Col key={score.id} md={12}>
                                                <Row className="p-1 mr-1 ml-1">
                                                    <Col xs={4}>
                                                        <Input
                                                            name={`scores.${index}.description`}
                                                            label="Description:"
                                                        />
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Select
                                                            name={`scores.${index}.type`}
                                                            label="* Type:"
                                                            items={[
                                                                "Episode",
                                                                "Season",
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col
                                                        className="text-right"
                                                        xs={4}
                                                    >
                                                        <Input
                                                            name={`scores.${index}.score`}
                                                            type="number"
                                                            label="Score:"
                                                            labelAlignLeft
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </React.Fragment>
                                    );
                                })}
                            <SubmitButton
                                title="Save Custom Challenge Questions"
                                submitText="Saving"
                                submitting={submitting}
                            />{" "}
                            <Link to={`/myleagues/view/${id}`}>
                                <Button variant="light">Cancel</Button>
                            </Link>
                        </Form>
                    )}
                </Formik>
            </>
        </PageTemplate>
    );
};

export default EditCustomQuestions;
