import React from "react";
import Moment from "react-moment";
import { IoMdTime } from "react-icons/io";

interface PostedDateProps {
    date: Date;
}

const PostedDate: React.FC<PostedDateProps> = ({ date }) => {
    return (
        <p>
            <IoMdTime /> Posted on{" "}
            <Moment format="MMMM D, YYYY [at] h:mm a">{date}</Moment>
        </p>
    );
};

export default PostedDate;
