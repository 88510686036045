import React from "react";
import { useTheme } from "styled-components";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { LoggedScoresReturn } from "../../models/ScoringLog";

import { CardStyled } from "../atoms";
import LogScoresEliminatedSelect from "../atoms/LogScoresEliminatedSelect";
import LogScoresList from "../atoms/LogScoresList";

interface LogScoresAccordionProps {
    log: LoggedScoresReturn[];
    values: any;
    custom?: boolean;
}

const LogScoresAccordion: React.FC<LogScoresAccordionProps> = ({
    log,
    values,
    custom,
}) => {
    const theme = useTheme();

    return (
        <Accordion className="mb-3">
            {log &&
                log.map((cast, index) => {
                    let category = "";
                    return (
                        <CardStyled
                            style={{
                                borderWidth: 1,
                                borderColor: theme.border,
                            }}
                            key={cast.id}
                        >
                            <Accordion.Toggle
                                style={{
                                    fontSize: 18,
                                    borderColor: theme.textColor,
                                }}
                                as={Card.Header}
                                eventKey={cast.id.toString()}
                            >
                                {cast.name}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={cast.id.toString()}>
                                <Card.Body>
                                    {!custom && (
                                        <LogScoresEliminatedSelect
                                            index={index}
                                            values={values}
                                        />
                                    )}
                                    <LogScoresList
                                        category={category}
                                        cast={cast}
                                        index={index}
                                        values={values}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </CardStyled>
                    );
                })}
        </Accordion>
    );
};

export default LogScoresAccordion;
