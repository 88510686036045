import React, { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import apiRoutes from "../../services/apiRoutes";
import { Podcast } from "../../models/Podcast";

import { Well } from "../atoms";
import { ArticleListSkeleton } from "../atoms/Skeletons";
import PaginationMenu from "../molecules/Pagination";
import PodcastListItem from "../organisms/PodcastListItem";
import { Link } from "react-router-dom";

const Podcasts: React.FC = () => {
    const query = useQuery();
    const limit = query.get("limit") ? parseInt(query.get("limit")) : 10;
    const [page, setPage] = useState<number>(
        query.get("page") ? parseInt(query.get("page")) : 1
    );
    const [podcasts, setPodcasts] = useState<Podcast[]>([]);
    const [numPages, setNumPages] = useState(0);
    const [loading, setLoading] = useState(true);

    const getPodcasts = useApi(apiRoutes.GET_PODCASTS(), {
        onSuccess: onSuccess,
    });

    function onSuccess(response: { episodes: Podcast[]; count: number }) {
        setPodcasts(response.episodes);
        setNumPages(Math.ceil(response.count / limit));
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        getPodcasts.request({ page, limit });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    if (loading) {
        return (
            <Well>
                <ArticleListSkeleton />
                <ArticleListSkeleton />
            </Well>
        );
    } else {
        return (
            <Well>
                <div className="d-flex justify-content-center">
                    <Link to={"/contact"}>
                        Want to become a sponsored podcast? Contact us
                    </Link>
                </div>
                {podcasts.length > 0 &&
                    podcasts.map((podcast: Podcast) => {
                        return (
                            <PodcastListItem
                                key={podcast.id}
                                podcast={podcast}
                            />
                        );
                    })}
                {podcasts.length === 0 && (
                    <p>There are currently no sponsored podcasts</p>
                )}
                <PaginationMenu
                    setPage={setPage}
                    page={page}
                    numPages={numPages}
                />
            </Well>
        );
    }
};

export default Podcasts;
