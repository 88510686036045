import styled from "styled-components";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/FormGroup";
import Card from "react-bootstrap/Card";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import MuiList from "@mui/material/List";
import Accordion from "@mui/material/Accordion";

import Spinner from "./Spinner";

const AuthorLink = styled(Button)`
    font-size: 80%;
    padding: 0px;
`;

const ErrorText = styled.span`
    color: red;
`;

const SuccessText = styled.span`
    color: #60af20;
`;

const MainContainer = styled.div`
    padding-top: 3em;
    padding-left: 0;
    padding-right: 0;
    margin-left: 7%;
    margin-right: 7%;
    @media (max-width: 1200px) {
        margin-left: 7%;
        margin-right: 7%;
    }
    @media (max-width: 992px) {
        margin-left: 5%;
        margin-right: 5%;
    }
`;

const ModalStyled = styled(Modal)`
    .modal-content {
        background-color: ${(props) => props.theme.inputColor};
        color: ${(props) => props.theme.textColor};
        border-radius: 20px;
    }

    .close {
        color: ${(props) => props.theme.textColor};
    }
`;

const PageHeader = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.secondary};
    padding-bottom: 9px;
    margin-top: 5em;
    margin-bottom: 0.5em;
`;

const SectionHeader = styled.h2`
    width: 100%;
    padding-bottom: 9px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid ${(props) => props.theme.secondary};
`;

const Small = styled.small`
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: #777;
`;

const Strikethrough = styled.span`
    text-decoration: line-through;
`;

const Well = styled.div`
    padding: 20px;
    margin-bottom: 20px;
    background-color: ${(props) => props.theme.secondary};
    border-radius: 20px;
`;

const InverseWell = styled.div`
    padding: 20px;
    margin-bottom: 20px;
    background-color: ${(props) => props.theme.primary};
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
`;

const FormGroupWrapper = styled(FormGroup)`
    .form-control {
        background: ${(props) => props.theme.inputColor};
        color: ${(props) => props.theme.textColor};
    }
`;

const VerticalPadding = styled.div`
    margin: 1em 0;
`;

const SubTabsWrapper = styled.div`
    & .nav-tabs {
        margin: 0;
        border-bottom: none;
        z-index: 10;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-bottom: 20px;
    }

    & .nav-tabs > a.nav-item {
        color: #8f9bb3;
        font-size: 20px;
    }

    & .nav-tabs > a.nav-item:hover {
        background-color: transparent;
        border: none;
        color: #0495ee;
        border-bottom: 3px solid #0495ee;
    }

    & .nav-tabs > a.active {
        background-color: transparent;
        border: none;
        color: #0495ee;
        border-bottom: 3px solid #0495ee;
    }
`;

const TabsWrapper = styled.div`
    & .tab-content .tab-pane div:not(.MuiAvatar-circular) {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    & .nav-tabs {
        margin: 0;
        border-bottom: none;
        z-index: 10;
        background: ${(props) => props.theme.secondaryHeader};
        border: 1px solid ${(props) => props.theme.border};
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    & .nav-tabs > a.nav-item {
        color: #8f9bb3;
    }

    & .nav-tabs > a.nav-item:hover {
        background-color: transparent;
        border: none;
        color: #0495ee;
        border-bottom: 3px solid #0495ee;
    }

    & .nav-tabs > a.active {
        background-color: transparent;
        border: none;
        color: #0495ee;
        border-bottom: 3px solid #0495ee;
    }
`;

const Sup = styled.sup`
    top: -20px;
    left: 2px;
    font-size: 20px;
`;

const NeutralScores = styled.span`
    font-weight: bold;
    color: ${(props) => props.theme.textColor};
    font-size: 16px;
`;

const PositiveScores = styled.span`
    font-weight: bold;
    color: green;
    font-size: 16px;
`;

const NegativeScores = styled.span`
    font-weight: bold;
    color: #ff3333;
    font-size: 16px;
`;

const TeamTableWrapper = styled.div`
    background-color: ${(props) => props.theme.primary};
    padding: 20px;
    border-radius: 10px !important;
    margin-bottom: 30px;
`;

const AccordionStyled = styled(Accordion)`
    background-color: ${(props) => props.theme.primary} !important;
    color: ${(props) => props.theme.textColor};
    border-radius: 10px !important;
    margin-top: 10px;
    padding: 10px;

    ::before {
        background-color: ${(props) => props.theme.secondary} !important;
    }

    .MuiAccordionSummary-content {
        color: ${(props) => props.theme.textColor};
        font-weight: bold;
        font-size: 20px;
        align-items: center;
    }

    .MuiAccordionSummary-expandIconWrapper {
        color: ${(props) => props.theme.textColor};
    }
`;

const CardStyled = styled(Card)`
    background-color: ${(props) => props.theme.secondary};
    border-radius: 20px;

    .card-header {
        border-bottom: none;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .card-footer {
        border-top: none !important;
    }
`;

const LineupPrimaryText = styled((props) => (
    <ListItemText {...props}>{props.children}</ListItemText>
))`
    .MuiListItemText-primary {
        width: 80%;
    }

    .MuiListItemText-secondary {
        color: ${(props) => props.theme.textColor};
    }
`;

const DraftItem = styled(ListItemButton)`
    &:hover {
        background-color: ${(props) => props.theme.primary} !important;
    }
`;

const ListContainer = styled(MuiList)`
    .MuiListItem-container:hover {
        background-color: ${(props) => props.theme.secondary};
    }
`;

export {
    AccordionStyled,
    AuthorLink,
    CardStyled,
    DraftItem,
    ErrorText,
    FormGroupWrapper,
    InverseWell,
    LineupPrimaryText,
    ListContainer,
    MainContainer,
    ModalStyled,
    NegativeScores,
    NeutralScores,
    PageHeader,
    PositiveScores,
    SectionHeader,
    Small,
    Spinner,
    Strikethrough,
    SubTabsWrapper,
    SuccessText,
    Sup,
    TabsWrapper,
    TeamTableWrapper,
    VerticalPadding,
    Well,
};
