import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { UserModel } from "../../models/User";
import { updateUser } from "../../store/user/actions";

import PriceCard from "../atoms/PriceCard";
import StripeModal from "../molecules/StripeModal";
import PageTemplate from "../templates/PageTemplate";
import { RootState } from "../../store";

const CreateYourOwnShow: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [showStripeModal, setShowStripeModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [type, setType] = useState<string>("free");
    const [amount, setAmount] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const elements = useElements();
    const stripe = useStripe();

    const createCustomShowRequest = useApi(apiRoutes.CREATE_NEW_SHOW(type), {
        onSuccess: (response: { user: UserModel; message: string }) => {
            setSubmitting(false);
            dispatch(updateUser({ ...response.user, isLoggedIn: true }));
            toast.success(response.message);
            history.push(`/build/${type}`);
        },
        onFailure: (message: string) => {
            setSubmitting(false);
            toast.error(message);
        },
    });
    const paymentIntentRequest = useApi(apiRoutes.GET_PAYMENT_INTENT(), {
        responseKey: "intent",
        onSuccess: async (response: { client_secret: string }) => {
            const clientSecret = response.client_secret;

            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            if (error) {
                toast.error(error.message);
                setSubmitting(false);
            } else {
                createCustomShowRequest.request();
            }
        },
        onFailure: () => {
            toast.error("There was an issue submitting payment");
            setSubmitting(false);
        },
    });

    const handleSubmit = (
        email: string,
        amount: number,
        description: string
    ) => {
        setSubmitting(true);
        if (!stripe || !elements) {
            toast.error("There was an issue submitting payment");
            setSubmitting(false);
            return;
        }

        paymentIntentRequest.request({ email, amount, description });
    };

    return (
        <PageTemplate header="Create Your Own Show" bareContent>
            <Alert variant="warning">
                <p className="mb-0">
                    <b>
                        Are you sure we don't already provide a league for the
                        show you're creating? View all of our shows{" "}
                        <Link to="/shows">here.</Link>
                    </b>
                    <br />
                    Having any issues, questions, or suggestions about creating
                    your own show? <Link to="/contact">Contact Us!</Link>
                </p>
            </Alert>
            <Row className="justify-content-center">
                <PriceCard
                    title="Basic"
                    listGroup={[
                        "Up to 15 Players",
                        "Up to 5 Scoring Fields",
                        "Up to 10 Week Season",
                        "Can be a Weekly Redraft, Team, Full Draft, or Weekly Budget League",
                        "Invite as many friends as you want to play!",
                    ]}
                    buttonText="Create"
                    onButtonPress={() => {
                        history.push("/build/free");
                    }}
                />
                <PriceCard
                    title="Plus"
                    price={5}
                    badge="Most Popular"
                    listGroup={[
                        "Up to 30 Players",
                        "Up to 15 Scoring Fields",
                        "Up to 20 Week Season",
                        "Can be a Weekly Redraft, Team, Full Draft, or Weekly Budget League",
                        "Invite as many friends as you want to play!",
                    ]}
                    buttonCount={user.plus_league}
                    buttonText="Create"
                    onButtonPress={() => {
                        if (user.plus_league > 0) {
                            history.push(`/build/plus`);
                        } else {
                            setType("plus");
                            setAmount(5);
                            setModalTitle("Plus League");
                            setShowStripeModal(true);
                        }
                    }}
                />
                <PriceCard
                    title="Premium"
                    price={10}
                    badge="Best Value"
                    listGroup={[
                        "Up to 50 Players",
                        "Up to 50 Scoring Fields",
                        "Up to 30 Week Season",
                        "Can be a Weekly Redraft, Team, Full Draft, or Weekly Budget League",
                        "Invite as many friends as you want to play!",
                    ]}
                    buttonText="Create"
                    buttonCount={user.premium_league}
                    onButtonPress={() => {
                        if (user.premium_league > 0) {
                            history.push(`/build/premium`);
                        } else {
                            setType("premium");
                            setAmount(10);
                            setModalTitle("Premium League");
                            setShowStripeModal(true);
                        }
                    }}
                />
            </Row>
            <StripeModal
                showModal={showStripeModal}
                setShowModal={setShowStripeModal}
                title={modalTitle}
                onSubmit={handleSubmit}
                amount={amount}
                submitting={submitting}
            />
        </PageTemplate>
    );
};

export default CreateYourOwnShow;
