import { combineReducers } from "redux";
import { notificationReducer } from "./notifications/reducer";
import { showReducer } from "./shows/reducer";
import { subscriptionReducer } from "./subscription/reducer";
import { themeReducer } from "./theme/reducer";
import { timezoneOffsetReducer } from "./timezoneOffsets/reducer";
import { userReducer } from "./user/reducer";

const rootReducer = combineReducers({
    user: userReducer,
    theme: themeReducer,
    shows: showReducer,
    notifications: notificationReducer,
    subscription: subscriptionReducer,
    timezoneOffsets: timezoneOffsetReducer,
});

export default rootReducer;
