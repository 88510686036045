import { SET_SHOWS, ShowActionTypes } from "./types";

const INITIAL_STATE = [];

export function showReducer(state = INITIAL_STATE, action: ShowActionTypes) {
    switch (action.type) {
        case SET_SHOWS:
            return action.payload;
        default:
            return state;
    }
}
