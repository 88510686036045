import React from "react";

import Select from "./Select";

interface LogScoresEliminatedSelectProps {
    values: any;
    index: number;
}

const LogScoresEliminatedSelect: React.FC<LogScoresEliminatedSelectProps> = ({
    values,
    index,
}) => {
    return (
        <Select
            backgroundColor={
                values.log[index].eliminated === "true" ||
                values.log[index].eliminated === true
                    ? "green"
                    : undefined
            }
            name={`log.${index}.eliminated`}
            label="Was the player eliminated?"
            items={[
                {
                    value: false,
                    label: "No",
                },
                {
                    value: true,
                    label: "Yes",
                },
            ]}
            selectKey={"value"}
            selectValue={"label"}
        />
    );
};

export default LogScoresEliminatedSelect;
