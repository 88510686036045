import React, { ChangeEvent } from "react";
import { FormikErrors } from "formik";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Input from "../atoms/Input";
import Select from "../atoms/Select";
import Checkbox from "../atoms/Checkbox";
import ImageUpload from "../atoms/ImageUpload";
import Wysiwyg from "../atoms/Wysiwyg";
import { ShowFormFields } from "../pages/AdminShow";
import Switch from "../atoms/Switch";

const offsets = [0, 1, 2, 3, 4, 5];

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];
const years = [
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
];
const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
const hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
];
const minutes = [0, 15, 30, 45];
const draftTypes = [
    "Weekly Redraft",
    "Team",
    "Full Draft",
    "Weekly Budget",
    "Confidence Pool",
    "Eliminated Confidence Pool",
];
const featured = ["Yes", "No"];

interface ShowInfoFormProps {
    values: ShowFormFields;
    errors: FormikErrors<ShowFormFields>;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const ShowInfoForm: React.FC<ShowInfoFormProps> = ({
    values,
    errors,
    setFieldValue,
}) => {
    const onImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue("image", files[0]);
        }
    };

    return (
        <Row>
            <Col md={6}>
                <Input name="title" label="Title:" placeholder="Title" />
            </Col>
            <Col md={6}>
                <Input name="slug" label="Slug:" placeholder="Slug" />
            </Col>
            <Col md={12}>
                <ImageUpload
                    name="image"
                    label="Image"
                    image={values.image}
                    handleChange={(e) => onImageChange(e, setFieldValue)}
                />
            </Col>
            <Col xs={4} md={4}>
                <Select name="day" label="Day:" items={daysOfWeek} />
            </Col>
            <Col xs={4} md={4}>
                <Select
                    name="hour"
                    label="Show Hour (Military Time CST):"
                    items={hours}
                />
            </Col>
            <Col xs={4} md={4}>
                <Select name="min" label="Show Minute:" items={minutes} />
            </Col>
            <Col xs={3} md={3}>
                <Select
                    name="pacificOffset"
                    label="PST Offset (hours):"
                    items={offsets}
                />
            </Col>
            <Col xs={3} md={3}>
                <Select
                    name="mountainOffset"
                    label="MST Offset (hours):"
                    items={offsets}
                />
            </Col>
            <Col xs={3} md={3}>
                <Select
                    name="hawaiiOffset"
                    label="HST Offset (hours):"
                    items={offsets}
                />
            </Col>
            <Col xs={3} md={3}>
                <Select
                    name="alaskaOffset"
                    label="AST Offset (hours):"
                    items={offsets}
                />
            </Col>
            <Col xs={4} md={4}>
                <Select name="dateMonth" label="Start Month:" items={months} />
            </Col>
            <Col xs={4} md={4}>
                <Select name="dateDay" label="Start Day:" items={days} />
            </Col>
            <Col xs={4} md={4}>
                <Select name="dateYear" label="Start Year:" items={years} />
            </Col>
            <Col xs={12}>
                <Form.Label>Draft Types:</Form.Label>
                {draftTypes.map((draftType) => {
                    return (
                        <Checkbox
                            key={draftType}
                            label={draftType}
                            name={draftType}
                            group="draftTypes"
                            groupList={values.draftTypes}
                        />
                    );
                })}
            </Col>
            <Col className="mt-2" xs={12}>
                <Switch name="genderLimits" label="Gender Limits" />
            </Col>
            <Col xs={12}>
                <Switch name="rookieLimits" label="Rookie Limits" />
            </Col>
            <Col xs={6} md={3}>
                <Select name="featured" label="Featured:" items={featured} />
            </Col>
            <Col xs={6} md={3}>
                <Input name="channel" label="Channel:" placeholder="Channel" />
            </Col>
            <Col xs={6} md={3}>
                <Input
                    name="episodes"
                    label="Total Episodes:"
                    placeholder="Total Episodes"
                    type="number"
                />
            </Col>
            <Col xs={6} md={3}>
                <Input
                    name="currentEpisode"
                    label="Current Episode:"
                    placeholder="Current Episode"
                    type="number"
                />
            </Col>
            <Col md={12}>
                <Wysiwyg
                    height={200}
                    label="Description"
                    name={"description"}
                    value={values.description}
                    setValue={(value) => setFieldValue("description", value)}
                    error={errors.description}
                />
            </Col>
            <Col md={12}>
                <Wysiwyg
                    height={200}
                    label="Overview"
                    name={"overview"}
                    value={values.overview}
                    setValue={(value) => setFieldValue("overview", value)}
                    error={errors.overview}
                />
            </Col>
        </Row>
    );
};

export default ShowInfoForm;
