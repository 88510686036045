import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";

import Form from "react-bootstrap/Form";

import { RootState } from "../../store";

import SubmitButton from "../atoms/SubmitButton";
import TextArea from "../atoms/TextArea";

interface MessageFormProps {
    label?: string;
    placeholder?: string;
    submitting: boolean;
    onSubmit: (comment: string) => void;
}

const validationSchema = yup.object().shape({
    comment: yup.string().required("Please enter a comment"),
});

const MessageForm: React.FC<MessageFormProps> = (props) => {
    const user = useSelector((state: RootState) => state.user);

    const initialValues = {
        comment: "",
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                await props.onSubmit(values.comment);
                resetForm();
            }}
        >
            {({ values, errors, handleChange, submitCount, handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    {user.isLoggedIn ? (
                        <TextArea
                            name="comment"
                            label={
                                props.label ? props.label : "Leave a Comment:"
                            }
                        />
                    ) : (
                        <>
                            <Form.Label>Leave a Comment:</Form.Label>
                            <p>You must be logged in to comment</p>
                        </>
                    )}
                    {user.isLoggedIn && (
                        <SubmitButton
                            title="Send"
                            submitting={props.submitting}
                            submitText="Sending"
                            disabled={
                                props.submitting ||
                                (submitCount > 0 && !!errors.comment)
                            }
                        />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default MessageForm;
