import { ApiReturnType } from "./";

const answerRoutes = {
    GET_ANSWERS: (leagueUserId: string): ApiReturnType => {
        return {
            url: `answers/${leagueUserId}`,
            method: "get",
        };
    },
    SAVE_ANSWERS: (leagueUserId: string): ApiReturnType => {
        return {
            url: `answers/save/${leagueUserId}`,
            method: "post",
        };
    },
};

export default answerRoutes;
