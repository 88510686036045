import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import Col from "react-bootstrap/Col";
import Avatar from "@mui/material/Avatar";
import Button from "react-bootstrap/Button";

import { imageUrl } from "../../config";
import { League } from "../../models/Leagues";
import { CastLeagueScoring } from "../../models/Cast";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";

import { ErrorText, Strikethrough } from "../atoms";
import SocialLinks from "../atoms/SocialLinks";

interface StatsRowProps {
    stats: CastLeagueScoring;
    league: League;
    categories: string[];
    removeFromWishlist: (id: number) => void;
    addToWishlist: (id: number) => void;
    leagueUser?: LeaguesLeagueUser;
    currentEpisode: number;
    episode: number;
}

const StatsRow: React.FC<StatsRowProps> = ({
    stats,
    league,
    categories,
    removeFromWishlist,
    addToWishlist,
    leagueUser,
    currentEpisode,
    episode,
}) => {
    const history = useHistory();
    const [eliminated, setEliminated] = useState<boolean>(false);

    useEffect(() => {
        if (episode > 0) {
            if (episode !== currentEpisode) {
                setEliminated(
                    stats.episode_eliminated !== null &&
                        stats.episode_eliminated <= episode
                        ? true
                        : false
                );
            } else {
                setEliminated(false);
            }
        } else {
            setEliminated(
                stats.episode_eliminated !== null &&
                    stats.episode_eliminated < currentEpisode
                    ? true
                    : false
            );
        }
    }, [stats, episode, currentEpisode]);

    return (
        <tr
            onClick={() =>
                history.push(
                    `/shows/castscores/${stats.id}?league_id=${league.id}`
                )
            }
        >
            <td>
                <Col>
                    <Avatar
                        style={{ width: 60, height: 60 }}
                        src={stats.pic ? `${imageUrl}${stats.pic}` : ""}
                    />
                    {eliminated ? (
                        <ErrorText>
                            <Strikethrough>{`${stats.first_name} ${stats.last_name}`}</Strikethrough>{" "}
                            (Eliminated)
                        </ErrorText>
                    ) : (
                        <>{`${stats.first_name} ${stats.last_name}`}</>
                    )}
                    {(stats.instagram || stats.twitter) && (
                        <SocialLinks
                            size="small"
                            socials={{
                                instagram: stats.instagram,
                                twitter: stats.twitter,
                            }}
                        />
                    )}
                </Col>
            </td>
            {league.draft_type === "Full Draft" && (
                <>
                    {league.drafted ? (
                        <td className="text-center d-none d-md-table-cell">
                            {eliminated ? (
                                <ErrorText>
                                    {stats.team_name ? stats.team_name : "-"}
                                </ErrorText>
                            ) : (
                                <>{stats.team_name ? stats.team_name : "-"}</>
                            )}
                        </td>
                    ) : (
                        <>
                            {leagueUser && (
                                <td className="text-center d-none d-md-table-cell">
                                    {stats.wishlist ? (
                                        <Button
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                removeFromWishlist(stats.id);
                                            }}
                                        >
                                            Remove From Wishlist
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                addToWishlist(stats.id);
                                            }}
                                        >
                                            Add To Wishlist
                                        </Button>
                                    )}
                                </td>
                            )}
                        </>
                    )}
                </>
            )}
            {categories &&
                categories.map((category: string) => (
                    <td
                        key={category}
                        className="text-center d-none d-md-table-cell"
                    >
                        {eliminated ? (
                            <ErrorText>{stats.categories[category]}</ErrorText>
                        ) : (
                            stats.categories[category]
                        )}
                    </td>
                ))}
            <td className={"text-center"}>
                {eliminated ? (
                    <ErrorText>{stats.total}</ErrorText>
                ) : (
                    stats.total
                )}
            </td>
        </tr>
    );
};

export default StatsRow;
