import styled from "styled-components";

import Avatar from "@mui/material/Avatar";

export const DraftPlayerImg = styled(Avatar)`
    width: 200px !important;
    height: 200px !important;
`;

export const DraftImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 2em 0em;
`;

export const DraftText = styled.div`
    text-align: center;
    font-size: 20px;
`;
