import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import TextArea from "../atoms/TextArea";
import SubmitButton from "../atoms/SubmitButton";
import { ModalStyled } from "../atoms";

interface DraftNoteProps {
    note: string;
    id: string;
    onSuccess: () => void;
}

const validationSchema = yup.object({
    note: yup.string(),
});

const DraftNote: React.FC<DraftNoteProps> = ({ note, id, onSuccess }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const saveNoteRequest = useApi(apiRoutes.SAVE_DRAFT_NOTE(id), {
        responseKey: "message",
        onSuccess: (response: string) => {
            setSaving(false);
            setShowModal(false);
            onSuccess();
            toast.success(response);
        },
        onFailure: () => {
            setSaving(false);
            setInitialValues({ note: "" });
        },
    });

    const [initialValues, setInitialValues] = useState({
        note: note,
    });

    return (
        <>
            <Button onClick={() => setShowModal(true)}>
                {initialValues.note && initialValues.note.length > 0
                    ? "Edit "
                    : "Add "}
                Draft Note
            </Button>
            <ModalStyled
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                className="text-center"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Draft Note</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSaving(true);
                            saveNoteRequest.request(values);
                            setInitialValues({ note: values.note });
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            submitCount,
                            errors,
                            isValid,
                        }) => (
                            <Form
                                onSubmit={(e) => {
                                    handleSubmit(e);
                                }}
                            >
                                <div className="text-left">
                                    <TextArea
                                        name="note"
                                        label="Draft Note:"
                                        placeholder="Draft Note"
                                    />
                                </div>
                                <SubmitButton
                                    title="Save"
                                    submitting={saving}
                                />{" "}
                                <Button
                                    variant="light"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </ModalStyled>
        </>
    );
};

export default DraftNote;
