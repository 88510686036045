import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

import { RootState } from "../../store";

import Row from "react-bootstrap/Row";

import { MainContainer, PageHeader } from "../atoms";
import Slider from "../molecules/Slider";
import ShowList from "../organisms/ShowList";
import MyLeaguesList from "../organisms/MyleaguesList";
import { Content } from "./styles/Home.styled";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";

const Home: React.FC = () => {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

    return (
        <div>
            <Helmet prioritizeSeoTags>
                <title>Play Reality TV Fantasy - RealTVFantasy</title>
                <meta
                    name="description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:url"
                    content={`https://realtvfantasy.com${window.location.pathname}`}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="Play Reality TV Fantasy - RealTVFantasy"
                />
                <meta
                    property="og:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
                <meta property="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:title"
                    content="Play Reality TV Fantasy - RealTVFantasy"
                />
                <meta
                    property="twitter:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="twitter:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
            </Helmet>
            <Slider />
            <MainContainer>
                <Row>
                    <Content>
                        Welcome to RealTVFantasy! We are your source for all
                        your favorite reality tv show fantasy leagues. We
                        provide you fantasy leagues for The Bachelor, The
                        Bachelorette, MTV's The Challenge, Survivor, and more!
                        If you've never been here before, read{" "}
                        <Link to="/overview">how it works</Link> to get started.
                        We are constantly trying to add more shows and improve
                        the site in any way. If you have any suggestions, please{" "}
                        <Link to="/contact">contact us</Link> and we will
                        promptly respond.
                    </Content>
                    <GoogleAd slot="7237766115" height={280} />
                    {/* <Ad /> */}
                </Row>
                {isLoggedIn && (
                    <>
                        <PageHeader>
                            <h1>Current Leagues</h1>
                        </PageHeader>
                        <MyLeaguesList type="active" />
                    </>
                )}
                <PageHeader>
                    <h1>All Shows</h1>
                </PageHeader>
                <ShowList />
                {/* <Ad fixed /> */}
                <GoogleAd fixed slot="7455698336" height={90} />
            </MainContainer>
        </div>
    );
};

export default Home;
