import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { MainContainer, PageHeader, TabsWrapper } from "../atoms";
import MyLeaguesList from "../organisms/MyleaguesList";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";

const Myleagues: React.FC = () => {
    return (
        <MainContainer>
            <Helmet prioritizeSeoTags>
                <title>My Leagues - RealTVFantasy</title>
                <meta
                    name="description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:url"
                    content={`https://realtvfantasy.com${window.location.pathname}`}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="My Leagues - RealTVFantasy"
                />
                <meta
                    property="og:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
                <meta property="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:title"
                    content="My Leagues - RealTVFantasy"
                />
                <meta
                    property="twitter:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="twitter:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
            </Helmet>
            <PageHeader>
                <h1>My Leagues</h1>
            </PageHeader>
            <div className="pb-3">
                <Link className="mr-3" to={`/myleagues/create`}>
                    <Button className="mb-1">Create New League</Button>
                </Link>
                <Link className="mr-3" to={`/myleagues/join`}>
                    <Button className="mb-1">Join Private League</Button>
                </Link>
                <Link to={`/myleagues/search`}>
                    <Button>Find Public League</Button>
                </Link>
            </div>
            {/* <Ad banner /> */}
            <GoogleAd slot="7237766115" height={90} />
            <TabsWrapper>
                <Tabs>
                    <Tab
                        tabClassName="col-6 text-center"
                        eventKey="active"
                        title="Active"
                    >
                        <MyLeaguesList type="active" />
                    </Tab>
                    <Tab
                        tabClassName="col-6 text-center"
                        eventKey="completed"
                        title="Completed"
                    >
                        <MyLeaguesList type="completed" />
                    </Tab>
                </Tabs>
            </TabsWrapper>
            {/* <Ad fixed /> */}
            <GoogleAd fixed slot="7455698336" height={90} />
        </MainContainer>
    );
};

export default Myleagues;
