import { NavDropdown } from "react-bootstrap";
import styled from "styled-components";

interface ShowImageProps {
    src: string;
    showId: number;
}

export const ShowImage = styled.div<ShowImageProps>`
    background: url(${(props) => props.src}) 50%
        ${(props) => (props.showId === 132 ? "20%" : "50%")} / cover no-repeat;
    height: 350px;
    border-radius: 20px;
`;

export const CommissionerDropdown = styled(NavDropdown)`
    .dropdown-menu {
        background-color: ${(props) => props.theme.secondary};
        max-height: 400px;
        overflow: scroll;
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        .dropdown-item {
            color: ${(props) => props.theme.textColor};

            &:hover {
                background-color: ${(props) => props.theme.primary};
            }
        }
    }

    .dropdown-menu::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }

    .dropdown-toggle::after {
        color: ${(props) => props.theme.textColor};
    }

    .nav-link {
        display: flex;
        align-items: center;
    }
`;
