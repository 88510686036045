import React from "react";

import configs from "../../config/configs";
import strings from "../../config/strings";
import { Show, ShowLimits } from "../../models/Shows";

import Select from "../atoms/Select";
import Input from "../atoms/Input";

interface LeagueTeamFormProps {
    show?: Show;
    genderLimit?: boolean;
    rookieLimit?: boolean;
    edit?: boolean;
    showLimits?: ShowLimits | null;
}

const LeagueTeamForm: React.FC<LeagueTeamFormProps> = ({
    show,
    genderLimit,
    rookieLimit,
    edit,
    showLimits,
}) => {
    let playerPerTeam = configs.leagues.playersPerTeam;
    let startersPerTeam = configs.leagues.startersPerTeam;
    if (showLimits) {
        playerPerTeam = playerPerTeam.slice(
            0,
            showLimits.max_players_per_team ?? 10
        );
        startersPerTeam = startersPerTeam.slice(
            0,
            showLimits.max_starters_per_team ?? 10
        );
    }

    return (
        <>
            <Select
                name="playersPerTeam"
                label={
                    edit
                        ? "Players Per Team: (please contact us to change)"
                        : "Players Per Team:"
                }
                items={playerPerTeam}
                formText={strings.createLeague.playersPerTeam}
                disabled={edit ?? false}
                backgroundColor={edit ? "gray" : undefined}
                field
            />
            <Select
                name="startersPerTeam"
                label="Starters Per Team:"
                items={startersPerTeam}
                formText={strings.createLeague.startersPerTeam}
                field
            />
            <Input
                name={`swaps`}
                label="Number of Player Swaps:"
                placeholder="Number of Player Swaps"
                type="number"
                formText={strings.createLeague.swaps}
            />
            <Input
                name={`lastSwapEp`}
                label="Last Episode/Week to make swaps:"
                placeholder="Last Episode/Week to make swaps"
                type="number"
                formText={strings.createLeague.lastSwapEp}
            />
            {((show && show.rookie_limit) || rookieLimit) && (
                <Select
                    name="rookieLimit"
                    label="Rookie Limit:"
                    items={configs.leagues.rookieLimit}
                    formText={strings.createLeague.rookieLimitTeam}
                />
            )}
            {((show && show.gender_limit) || genderLimit) && (
                <>
                    <Select
                        name="maleLimit"
                        label="Male Limit:"
                        items={configs.leagues.genderLimit}
                        formText={strings.createLeague.maleLimitTeam}
                    />
                    <Select
                        name="femaleLimit"
                        label="Female Limit:"
                        items={configs.leagues.genderLimit}
                        formText={strings.createLeague.femaleLimitTeam}
                    />
                </>
            )}
        </>
    );
};

export default LeagueTeamForm;
