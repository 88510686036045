import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import InfoIcon from "@mui/icons-material/Info";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FaHome } from "react-icons/fa";
import { GiTorch } from "react-icons/gi";
import { FaPlane } from "react-icons/fa";
import { IoIosRose } from "react-icons/io";
import { GiIsland } from "react-icons/gi";

import { UserBadge } from "../../models/UserBadges";
import { colors } from "../../config/colors";

import { BadgeInfo, BadgeItemWrapper } from "./styles/BadgeItem.styled";
import { Small } from ".";
import AlertModal from "./AlertModal";

interface BadgeItemProps {
    userBadge: UserBadge;
}

const BadgeItem: React.FC<BadgeItemProps> = ({ userBadge }) => {
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const determineIcon = (): React.ReactElement => {
        switch (userBadge.name) {
            case "1st Place":
                return (
                    <FontAwesomeIcon
                        fontSize={30}
                        icon={faTrophy}
                        color={colors.gold}
                    />
                );
            case "2nd Place":
                return (
                    <FontAwesomeIcon
                        fontSize={30}
                        icon={faTrophy}
                        color={colors.silver}
                    />
                );
            case "3rd Place":
                return (
                    <FontAwesomeIcon
                        fontSize={30}
                        icon={faTrophy}
                        color={colors.bronze}
                    />
                );
            case "Commissioner":
                return (
                    <LocalPoliceIcon
                        fontSize="large"
                        htmlColor={colors.button}
                    />
                );
            case "Castaway":
                return <GiTorch fontSize={35} color={colors.fire} />;
            case "Challenger":
                return <DirectionsRunIcon fontSize="large" />;
            case "Amazing Racer":
                return <FaPlane fontSize={35} color={colors.gold} />;
            case "The Bachelor":
                return <IoIosRose fontSize={30} color={colors.redRose} />;
            case "The Bachelorette":
                return <IoIosRose fontSize={30} color={colors.pinkRose} />;
            case "Bachelor in Paradise":
                return <GiIsland fontSize={30} color={colors.bip} />;
            case "Houseguest":
                return <FaHome fontSize={30} color={colors.button} />;
            default:
                return null;
        }
    };

    const iconInfo = determineIcon();

    if (!iconInfo) {
        return null;
    } else {
        return (
            <BadgeItemWrapper>
                <BadgeInfo>
                    <InfoIcon
                        fontSize="small"
                        onClick={() => setShowAlert(true)}
                    />
                </BadgeInfo>
                {iconInfo}
                <div>
                    <Small>x{userBadge.amount}</Small>
                </div>
                <AlertModal
                    title={userBadge.name}
                    showModal={showAlert}
                    setShowModal={setShowAlert}
                    body={
                        <div className="mb-4 mt-1">{userBadge.description}</div>
                    }
                />
            </BadgeItemWrapper>
        );
    }
};

export default BadgeItem;
