import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";

interface List {
    title: string;
    link: string;
    custom?: boolean; //only used for show list to add a custom badge
}

interface LinkListProps {
    list: List[];
    viewAll?: string;
    buttonTitle?: string;
    buttonLink?: string;
    empty?: string;
}

const LinkList: React.FC<LinkListProps> = ({
    list,
    viewAll,
    buttonTitle,
    buttonLink,
    empty,
}) => {
    return (
        <Row>
            {list.length <= 0 && empty && <Col md={12}>{empty}</Col>}
            {list.map((item, key) => {
                return (
                    <Col className="d-flex align-items-center" key={key} md={6}>
                        <Button
                            className="p-0"
                            key={item.link}
                            href={item.link}
                            variant="link"
                        >
                            {item.title}
                        </Button>
                        {item.custom && (
                            <Badge className="ml-2" pill variant="secondary">
                                Custom
                            </Badge>
                        )}
                    </Col>
                );
            })}
            {viewAll && list.length > 0 && (
                <Col md={12}>
                    <Button
                        className="float-right"
                        href={viewAll}
                        variant="link"
                    >
                        View All
                    </Button>
                </Col>
            )}
            {buttonTitle && buttonLink && (
                <Col className="mt-2" md={12}>
                    <Button href={buttonLink}>{buttonTitle}</Button>
                </Col>
            )}
        </Row>
    );
};

export default LinkList;
