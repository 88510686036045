import React from "react";

import ListItemText from "@mui/material/ListItemText";

import { ListHeader } from "./styles/ListItemHeader.styled";

interface ListItemHeaderProps {
    content: React.ReactNode;
}

const ListItemHeader: React.FC<ListItemHeaderProps> = ({ content }) => {
    return (
        <ListHeader disableRipple divider>
            <ListItemText primary={content} />
        </ListHeader>
    );
};

export default ListItemHeader;
