import React from "react";

import { Badge } from "@mui/material";

import { ActionItemWrapper } from "./styles/LeagueActionItem.styled";

interface LeagueActionItemProps {
    onPress: () => void;
    label: string;
    icon: React.ReactElement;
    hideBadge?: boolean;
}

const LeagueActionItem: React.FC<LeagueActionItemProps> = ({
    onPress,
    label,
    icon,
    hideBadge = true,
}) => {
    return (
        <div className="m-2">
            <Badge
                invisible={hideBadge}
                variant="dot"
                overlap="rectangular"
                color="error"
            >
                <ActionItemWrapper onClick={onPress}>
                    {icon}
                    <div>{label}</div>
                </ActionItemWrapper>
            </Badge>
        </div>
    );
};

export default LeagueActionItem;
