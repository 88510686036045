import React from "react";
import { ErrorMessage, FastField, FastFieldProps } from "formik";

import Form from "react-bootstrap/Form";

import { FormGroupWrapper } from ".";

interface InputProps {
    name: string;
    label?: string;
    placeholder?: string;
    type?: string;
    errorMessage?: boolean;
    labelAlignLeft?: boolean;
    formText?: string;
}

const Input: React.FC<InputProps> = ({
    name,
    label,
    placeholder,
    type,
    errorMessage,
    labelAlignLeft,
    formText,
}) => {
    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps) => {
                return (
                    <FormGroupWrapper
                        className={labelAlignLeft ? "text-left" : ""}
                    >
                        {label && <Form.Label>{label}</Form.Label>}
                        <Form.Control
                            placeholder={placeholder}
                            type={type}
                            value={field.value}
                            onWheel={(event) => event.currentTarget.blur()}
                            onChange={form.handleChange(name)}
                            isInvalid={
                                form.submitCount > 0 && meta.error !== undefined
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            {!errorMessage && meta.error}
                            {errorMessage && meta.error && (
                                <ErrorMessage name={meta.error} />
                            )}
                        </Form.Control.Feedback>
                        {formText && <Form.Text muted>{formText}</Form.Text>}
                    </FormGroupWrapper>
                );
            }}
        </FastField>
    );
};

export default Input;
