import React from "react";

import { TableTeamStyled } from "./styles/Table.styled";
import { CSSProperties } from "styled-components";

interface TableTeamProps {
    className?: string;
    style?: CSSProperties | undefined;
}

const TableTeam: React.FC<TableTeamProps> = ({
    className,
    style,
    children,
}) => {
    return (
        <TableTeamStyled style={style} className={className} hover>
            {children}
        </TableTeamStyled>
    );
};

export default TableTeam;
