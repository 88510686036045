import { ApiReturnType } from "./";

const customScoringRoutes = {
    ADD_CUSTOM_CHALLENGE_QUESTIONS: (leagueId: string): ApiReturnType => {
        return {
            url: `customScoring/addQuestions/${leagueId}`,
            method: "post",
        };
    },
    ADD_CUSTOM_SCORING: (leagueId: string): ApiReturnType => {
        return {
            url: `customScoring/add/${leagueId}`,
            method: "post",
        };
    },
    GET_CUSTOM_LOGGED_SCORES: (leagueId: string): ApiReturnType => {
        return {
            url: `leagues/getLoggedCustomScores/${leagueId}`,
            method: "get",
        };
    },
    GET_CUSTOM_LOGGED_ANSWERS: (leagueId: string): ApiReturnType => {
        return {
            url: `leagues/getLoggedAnswers/${leagueId}`,
            method: "get",
        };
    },
    SAVE_CUSTOM_CHALLENGE_QUESTIONS: (leagueId: string): ApiReturnType => {
        return {
            url: `customScoring/questions/save/${leagueId}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_LOGGED_QUESTIONS: (
        leagueId: string,
        episode: string
    ): ApiReturnType => {
        return {
            url: `leagues/saveLoggedCustomQuestions/${leagueId}/${episode}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_LOGGED_SCORES: (leagueId: string): ApiReturnType => {
        return {
            url: `leagues/saveLoggedCustomScores/${leagueId}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_SCORING: (leagueId: string): ApiReturnType => {
        return {
            url: `customScoring/save/${leagueId}`,
            method: "post",
        };
    },
};

export default customScoringRoutes;
