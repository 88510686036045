import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { isMobile } from "react-device-detect";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

import { Show } from "../../models/Shows";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { TradeListResponse, TradesList } from "../../models/Trades";

import Table from "../atoms/Table";
import TradeTableHeader from "../molecules/TradeTableHeader";
import TradeTableRow from "../molecules/TradeTableRow";
import { Body, Footer, Header } from "./styles/TradeList.styled";

interface TradeListProps {
    leagueUser: LeaguesLeagueUser;
    show: Show;
    open: boolean;
    setOpen: (open: boolean) => void;
    reload: () => void;
}

const TradeList: React.FC<TradeListProps> = ({
    open,
    setOpen,
    leagueUser,
    show,
    reload,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [offers, setOffers] = useState<TradesList[]>([]);
    const [proposed, setProposed] = useState<TradesList[]>([]);

    const tradeRequest = useApi(
        apiRoutes.TRADE_LIST(leagueUser.id.toString()),
        {
            onSuccess: (response: TradeListResponse) => {
                setOffers(response.offers);
                setProposed(response.proposed);
                setLoading(false);
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            tradeRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const closePanel = () => {
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={closePanel}
        >
            <div className="overflow-hidden">
                <Header style={isMobile ? { height: 100 } : {}}>
                    <h4>
                        Trades <small>{leagueUser.team_name}</small>
                    </h4>
                    {isMobile && (
                        <Button variant="light" onClick={closePanel}>
                            Close
                        </Button>
                    )}
                </Header>
                <Body
                    style={
                        isMobile
                            ? {
                                  height: "calc(100% - 100px)",
                                  top: 100,
                                  paddingBottom: 100,
                                  borderBottomRightRadius: 20,
                              }
                            : {}
                    }
                >
                    <br />
                    <Col>
                        <Alert variant="warning">
                            All Trades Will Expire {show.day_of_week} When The
                            Next Week of Episodes Airs
                        </Alert>
                    </Col>
                    {show.locked ? (
                        <Alert className="ml-2 mr-2 mt-2" variant="warning">
                            Trades Are Locked For The Week
                        </Alert>
                    ) : (
                        <>
                            <Col>
                                <h5>Trade Offers</h5>
                                {!loading && offers.length > 0 && (
                                    <Table>
                                        <TradeTableHeader type="offer" />
                                        <tbody>
                                            {offers.map((offer, key) => {
                                                return (
                                                    <TradeTableRow
                                                        key={key}
                                                        type="offer"
                                                        data={offer}
                                                        closePanel={closePanel}
                                                        reload={reload}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                )}
                                {!loading && offers.length === 0 && (
                                    <Col>
                                        You Currently Have No Trade Offers.
                                    </Col>
                                )}
                            </Col>
                            <br />
                            <Col>
                                <h5>Pending Trades</h5>
                                {!loading && proposed.length > 0 && (
                                    <Table>
                                        <TradeTableHeader type="propose" />
                                        <tbody>
                                            {proposed.map((propose, key) => {
                                                return (
                                                    <TradeTableRow
                                                        key={key}
                                                        type="propose"
                                                        data={propose}
                                                        closePanel={closePanel}
                                                        reload={reload}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                )}
                                {!loading && proposed.length === 0 && (
                                    <Col>
                                        You Currently Have No Pending Trades.
                                    </Col>
                                )}
                            </Col>
                        </>
                    )}
                </Body>
                {!isMobile && (
                    <Footer>
                        <Button variant="light" onClick={closePanel}>
                            Close
                        </Button>
                    </Footer>
                )}
            </div>
        </SlidingPanel>
    );
};

export default TradeList;
