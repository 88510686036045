import React from "react";
import { Helmet } from "react-helmet-async";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import {
    MainContainer,
    PageHeader,
    Small,
    Spinner,
    TabsWrapper,
    VerticalPadding,
    Well,
} from "../atoms";
import {
    ArticleTextSkeleton,
    CardSkeleton,
    CastCardSkeleton,
    LargeImageSkeleton,
    PageHeaderSkeleton,
    TableSkeleton,
} from "../atoms/Skeletons";
import { ShowImage } from "./styles/LeagueTemplate.styled";
// import Ad from "../atoms/Ad";
import LeagueButtons from "../molecules/LeagueButtons";
import CurrentShowLeagues from "../molecules/CurrentShowLeagues";
import { Show } from "../../models/Shows";
import GoogleAd from "../atoms/GoogleAd";

interface ShowProps {
    header: string;
    image: string;
    slug: string;
    headerSecondary?: string;
    loading?: boolean;
    skeleton?: boolean;
    show?: Show;
    sideCards: React.ReactElement;
}

const ShowTemplate: React.FC<ShowProps> = ({
    header,
    headerSecondary,
    image,
    slug,
    show,
    loading,
    skeleton,
    children,
    sideCards,
}) => {
    return (
        <MainContainer>
            {loading && skeleton && (
                <>
                    <PageHeaderSkeleton />
                    <LargeImageSkeleton />
                    <Row>
                        <Col lg={8}>
                            <TabsWrapper>
                                <Tabs>
                                    <Tab
                                        tabClassName="col-4 text-center"
                                        eventKey="overview"
                                        title="Overview"
                                    >
                                        <Well>
                                            <TableSkeleton />
                                        </Well>
                                    </Tab>
                                    <Tab
                                        tabClassName="col-4 text-center"
                                        eventKey="scoring"
                                        title="Scoring"
                                    >
                                        <Well>
                                            <ArticleTextSkeleton />
                                            <ArticleTextSkeleton />
                                        </Well>
                                    </Tab>
                                    <Tab
                                        tabClassName="col-4 text-center"
                                        eventKey="cast"
                                        title="Cast"
                                    >
                                        <Well>
                                            <Row>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                                <Col xl={4}>
                                                    <CastCardSkeleton />
                                                </Col>
                                            </Row>
                                        </Well>
                                    </Tab>
                                </Tabs>
                            </TabsWrapper>
                        </Col>
                        <Col lg={4}>
                            <CardSkeleton />
                            <CardSkeleton />
                            <CardSkeleton />
                        </Col>
                    </Row>
                </>
            )}
            {loading && !skeleton && <Spinner />}
            {!loading && (
                <>
                    <Helmet prioritizeSeoTags defer={false}>
                        <title>{`${header} - RealTVFantasy`}</title>
                        <meta
                            name="description"
                            content={`Play ${header} Fantasy Leagues with your friends on RealTVFantasy!`}
                        />
                        <meta
                            property="og:url"
                            content={`https://realtvfantasy.com${window.location.pathname}`}
                        />
                        <meta property="og:type" content="website" />
                        <meta
                            property="og:title"
                            content={`${header} - Fantasy Leagues`}
                        />
                        <meta
                            property="og:description"
                            content={`Play ${header} Fantasy Leagues with your friends on RealTVFantasy!`}
                        />
                        <meta property="og:image" content={`${image}`} />
                        <meta
                            property="twitter:card"
                            content="summary_large_image"
                        />
                        <meta
                            property="twitter:title"
                            content={`${header} - Fantasy Leagues`}
                        />
                        <meta
                            property="twitter:description"
                            content={`Play ${header} Fantasy Leagues with your friends on RealTVFantasy!`}
                        />
                        <meta property="twitter:image" content={`${image}`} />
                    </Helmet>
                    <PageHeader>
                        <h1>
                            {header}{" "}
                            {headerSecondary ? (
                                <Small>{headerSecondary}</Small>
                            ) : (
                                ""
                            )}
                        </h1>
                    </PageHeader>
                    {show && (
                        <ShowImage
                            className="mb-5"
                            src={image}
                            showId={show.id}
                        />
                    )}
                    {show && (
                        <Well className="d-sm-block d-xs-block d-md-block d-lg-none d-xl-none">
                            <LeagueButtons showId={show.id} slug={show.slug} />
                            <CurrentShowLeagues showId={show.id} />
                        </Well>
                    )}
                    {/* <Ad /> */}
                    <GoogleAd slot="7237766115" height={90} />
                    <VerticalPadding />
                    <Row>
                        <Col lg={8}>{children}</Col>
                        <Col lg={4}>{sideCards}</Col>
                    </Row>
                    <GoogleAd fixed slot="7455698336" height={90} />
                    {/* <Ad fixed /> */}
                </>
            )}
        </MainContainer>
    );
};

export default ShowTemplate;
