import React from "react";
import { useTheme } from "styled-components";
import { CardElement } from "@stripe/react-stripe-js";
import * as yup from "yup";
import { Formik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import SubmitButton from "../atoms/SubmitButton";
import Input from "../atoms/Input";
import { ModalStyled } from "../atoms/index";
import { CardElementWrapper, StripeLogo } from "./styles/StripeModal.styled";

interface StripeModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    title: string;
    description?: string | React.ReactElement;
    onSubmit: (email: string, amount: number, description: string) => void;
    amount?: number;
    submitting?: boolean;
    freeTrial?: boolean;
}

const validationSchema = yup.object({
    email: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
});

const StripeModal: React.FC<StripeModalProps> = ({
    showModal,
    setShowModal,
    title,
    description,
    amount,
    onSubmit,
    freeTrial,
    submitting,
}) => {
    const theme = useTheme();
    const initialValues = {
        email: "",
    };

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
            style={{ zIndex: 10000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {description && (
                    <div className="mb-2" style={{ whiteSpace: "pre-line" }}>
                        {description}
                    </div>
                )}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        onSubmit(values.email, amount, title);
                    }}
                >
                    {({ values, handleSubmit, setFieldValue, isValid }) => (
                        <Form onSubmit={handleSubmit}>
                            <Input name="email" placeholder="Email" />
                            <CardElementWrapper>
                                <CardElement
                                    className="p-2"
                                    options={{
                                        style: {
                                            base: {
                                                color: theme.textColor,
                                                fontSize: "16px",
                                            },
                                        },
                                    }}
                                />
                            </CardElementWrapper>
                            <StripeLogo
                                onClick={() =>
                                    window.open("https://stripe.com", "_blank")
                                }
                                height={100}
                                src="/images/stripe.svg"
                            />
                            <SubmitButton
                                className="w-100"
                                title={
                                    freeTrial
                                        ? "Try 1 month for free"
                                        : `Pay${
                                              amount
                                                  ? ` $${amount.toFixed(2)}`
                                                  : ""
                                          }`
                                }
                                submitting={submitting}
                                disabled={submitting}
                                submitText={"Submitting"}
                            />
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </ModalStyled>
    );
};

export default StripeModal;
