export const hasStandardSub = (name: string) => {
    if (
        name === "Standard_v2" ||
        name === "standardSubscription" ||
        name === "standard_subscription" ||
        name === "standard_subscription_annual" ||
        name === "standardSubscriptionAnnual" ||
        name === "standard_subscription_annual:p1a" ||
        name === "standard_subscription:p1m"
    ) {
        return true;
    } else {
        return false;
    }
};

export const hasPremiumSub = (name: string) => {
    if (
        name === "Premium" ||
        name === "premiumSubscription" ||
        name === "premium_subscription" ||
        name === "Premium Annual" ||
        name === "premiumSubscriptionAnnual" ||
        name === "premium_subscription_annual" ||
        name === "premium_subscription_annual:p2a" ||
        name === "premium_subscription:p2m"
    ) {
        return true;
    } else {
        return false;
    }
};
