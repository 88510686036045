import { Method } from "axios";
import showRoutes from "./showRoutes";
import authRoutes from "./authRoutes";
import articleRoutes from "./articleRoutes";
import scoringLogRoutes from "./scoringLogRoutes";
import commentRoutes from "./commentRoutes";
import scoresRoutes from "./scoresRoutes";
import leagueUserRoutes from "./leagueUserRoutes";
import leagueRoutes from "./leagueRoutes";
import castRoutes from "./castRoutes";
import leaderboardRoutes from "./leaderboardRoutes";
import chatRoutes from "./chatRoutes";
import homeRoutes from "./homeRoutes";
import adminRoutes from "./adminRoutes";
import preferencesRoutes from "./preferencesRoutes";
import teamRoutes from "./teamRoutes";
import tradeRoutes from "./tradeRoutes";
import lineupRoutes from "./lineupRoutes";
import draftRoutes from "./draftRoutes";
import wishlistRoutes from "./wishlistRoutes";
import notificationRoutes from "./notificationRoutes";
import stripeRoutes from "./stripeRoutes";
import subscriptionRoutes from "./subscriptionRoutes";
import customScoringRoutes from "./customScoringRoutes";
import buildRoutes from "./buildRoutes";
import analyticsRoutes from "./analyticsRoutes";
import timezoneOffsetRoutes from "./timezoneOffsetRoutes";
import profileRoutes from "./profileRoutes";
import questionRoutes from "./questionRoutes";
import answerRoutes from "./answerRoutes";
import questionScoresRoutes from "./questionScoresRoutes";
import podcastRoutes from "./podcastRoutes";
import confidencePoolRoutes from "./confidencePoolRoutes";

export interface ApiReturnType {
    url: string;
    method: Method;
}

const apiRoutes = {
    ...adminRoutes,
    ...analyticsRoutes,
    ...answerRoutes,
    ...articleRoutes,
    ...authRoutes,
    ...buildRoutes,
    ...castRoutes,
    ...chatRoutes,
    ...commentRoutes,
    ...confidencePoolRoutes,
    ...customScoringRoutes,
    ...draftRoutes,
    ...homeRoutes,
    ...leaderboardRoutes,
    ...leagueRoutes,
    ...leagueUserRoutes,
    ...lineupRoutes,
    ...notificationRoutes,
    ...podcastRoutes,
    ...preferencesRoutes,
    ...profileRoutes,
    ...questionRoutes,
    ...questionScoresRoutes,
    ...showRoutes,
    ...scoresRoutes,
    ...scoringLogRoutes,
    ...stripeRoutes,
    ...subscriptionRoutes,
    ...teamRoutes,
    ...timezoneOffsetRoutes,
    ...tradeRoutes,
    ...wishlistRoutes,
};

export default apiRoutes;
