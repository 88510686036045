import styled from "styled-components";

import Row from "react-bootstrap/Row";
import { HiHeart } from "react-icons/hi";

export const ReceivedMessage = styled.div`
    padding: 10px;
    word-wrap: break-word;
    background: ${(props) => props.theme.primary};
    border-radius: 10px;
    margin-left: 10px;
    width: calc(90%);
`;

export const ReceivedUser = styled.div`
    margin-left: 10px;
    padding-left: 5px;
`;

export const ReceivedMessageWrapper = styled(Row)`
    margin: 5px 0px 5px 0px;
    align-items: center;
`;

export const LikeButton = styled(HiHeart)`
    cursor: pointer;
`;

export const SentMessageWrapper = styled(Row)`
    margin: 5px 0px 5px 0px;
    align-items: center;
`;

export const SentMessage = styled.div`
    padding: 10px;
    word-wrap: break-word;
    background: #007bff;
    color: #fff;
    border-radius: 10px;
    margin: 5px 0px 5px 40px;
`;

export const SentUser = styled.div`
    margin-left: 40px;
    padding-left: 5px;
    padding-top: 5px;
    margin-bottom: -5px;
`;
