import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import {
    LeagueTeams,
    LeagueTeamsList,
    TradeLeagueTeams,
} from "../../models/LeagueTeams";
import { Show } from "../../models/Shows";
import { League } from "../../models/Leagues";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Divider from "@mui/material/Divider";
import Alert from "react-bootstrap/Alert";

import FinalizeTradeModal from "../molecules/FinalizeTradeModal";
import TradeListItem from "../molecules/TradeListItem";
import { FormGroupWrapper } from "../atoms";
import { Body, Footer, Header, List } from "./styles/ProposeTrade.styled";

interface ProposeTradeProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    leagueId: number | string;
    league: League;
    leagueUser: LeaguesLeagueUser;
    reload: () => void;
    show: Show;
}

const ProposeTrade: React.FC<ProposeTradeProps> = ({
    open,
    setOpen,
    leagueId,
    league,
    leagueUser,
    reload,
    show,
}) => {
    const [teams, setTeams] = useState<LeaguesLeagueUser[]>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<LeaguesLeagueUser | null>(
        null
    );
    const [yourTeam, setYourTeam] = useState<TradeLeagueTeams[]>([]);
    const [theirTeam, setTheirTeam] = useState<TradeLeagueTeams[]>([]);
    const [yourTraded, setYourTraded] = useState<TradeLeagueTeams[]>([]);
    const [theirTraded, setTheirTraded] = useState<TradeLeagueTeams[]>([]);
    const [showFinalizeTrade, setShowFinalizeTrade] = useState<boolean>(false);

    const leagueUsersRequest = useApi(
        apiRoutes.GET_LEAGUE_USERS_BY_LEAGUE(leagueId.toString()),
        {
            onSuccess: (response: LeaguesLeagueUser[]) => {
                const leagueTeams = response.filter(
                    (team: LeaguesLeagueUser) =>
                        team.user_id !== leagueUser.user_id
                );
                setTeams(leagueTeams);
            },
            responseKey: "leagueUsers",
        }
    );
    const theirTeamRequest = useApi(
        apiRoutes.GET_TEAM_BY_LEAGUE_USER(
            selectedTeam ? selectedTeam.id.toString() : "0"
        ),
        {
            onSuccess: (response: LeagueTeamsList) => {
                const team = response.map((value: LeagueTeams) => {
                    return { ...value, selected: false };
                });
                setTheirTeam(team);
            },
            responseKey: "leagueTeam",
        }
    );
    const yourTeamRequest = useApi(
        apiRoutes.GET_TEAM_BY_LEAGUE_USER(leagueUser.id.toString()),
        {
            onSuccess: (response: LeagueTeamsList) => {
                const team = response.map((value: LeagueTeams) => {
                    return { ...value, selected: false };
                });
                setYourTeam(team);
            },
            responseKey: "leagueTeam",
        }
    );
    const proposeTradeRequest = useApi(
        apiRoutes.PROPOSE_TRADE(leagueUser.id.toString()),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                toast.success(response);
                setSubmitting(false);
                closePanel();
                reload();
            },
            onFailure: () => setSubmitting(false),
        }
    );

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            leagueUsersRequest.request();
            yourTeamRequest.request({ active: true, owned: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (selectedTeam) {
            theirTeamRequest.request({ active: true, owned: true });
        } else {
            const newYourTeam = yourTeam.map((team: LeagueTeams) => {
                return { ...team, selected: false };
            });
            setYourTeam(newYourTeam);
            setTheirTeam([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam]);

    const selectPlayer = (player: TradeLeagueTeams, type: string) => {
        if (type === "theirs") {
            let team = [...theirTeam];
            if (player.selected) {
                //remove
                const teamIndex = team.findIndex(
                    (value: TradeLeagueTeams) => player.id === value.id
                );
                team[teamIndex].selected = false;
                setTheirTeam(team);
            } else {
                //add
                const teamIndex = team.findIndex(
                    (value: TradeLeagueTeams) => player.id === value.id
                );
                team[teamIndex].selected = true;
                setTheirTeam(team);
            }
        } else {
            let team = [...yourTeam];
            if (player.selected) {
                //remove
                const teamIndex = team.findIndex(
                    (value: LeagueTeams) => player.id === value.id
                );
                team[teamIndex].selected = false;
                setYourTeam(team);
            } else {
                //add
                const teamIndex = team.findIndex(
                    (value: LeagueTeams) => player.id === value.id
                );
                team[teamIndex].selected = true;
                setYourTeam(team);
            }
        }
    };

    const handleTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //grab full team from the backend
        const team = teams
            ? teams.filter(
                  (team: LeaguesLeagueUser) =>
                      parseInt(team.id.toString()) === parseInt(e.target.value)
              )[0]
            : null;
        setSelectedTeam(team);
    };

    const finalizeTrade = () => {
        const yourTrade = yourTeam.filter(
            (player: TradeLeagueTeams) => player.selected
        );
        const theirTrade = theirTeam.filter(
            (player: TradeLeagueTeams) => player.selected
        );

        setYourTraded(yourTrade);
        setTheirTraded(theirTrade);

        if (yourTrade.length <= 0 || theirTrade.length <= 0) {
            //throw error for needing to select somebody from your team
            toast.error(
                "Please select one player from your team and one player from the opposing team to trade!"
            );
        } else {
            setShowFinalizeTrade(true);
        }
    };

    const onFinalizeTrade = () => {
        const traded = yourTraded.map((trade: TradeLeagueTeams) => {
            return trade.cast_id;
        });
        const received = theirTraded.map((trade: TradeLeagueTeams) => {
            return trade.cast_id;
        });

        proposeTradeRequest.request({
            traded,
            received,
            trade_team_id: selectedTeam?.id,
        });
        setSubmitting(true);
        setShowFinalizeTrade(false);
    };

    const closePanel = () => {
        setOpen(false);
        setYourTraded([]);
        setTheirTraded([]);
        setSelectedTeam(null);
        document.body.style.overflow = "auto";
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={closePanel}
        >
            <div className="overflow-hidden">
                <Header style={isMobile ? { height: 100 } : {}}>
                    <h5>
                        Propose Trade <small>Draft</small>
                    </h5>
                    {isMobile && (
                        <>
                            <Button
                                variant="success"
                                onClick={finalizeTrade}
                                disabled={!selectedTeam || submitting}
                            >
                                {submitting
                                    ? "Finalizing..."
                                    : "Finalize Trade"}
                            </Button>{" "}
                            <Button variant="light" onClick={closePanel}>
                                Cancel
                            </Button>
                        </>
                    )}
                </Header>
                <Body
                    style={
                        isMobile
                            ? {
                                  height: "calc(100% - 100px)",
                                  top: 100,
                                  paddingBottom: 100,
                                  borderBottomRightRadius: 20,
                              }
                            : {}
                    }
                >
                    <br />
                    {league.allow_trading ? (
                        <>
                            <p className="text-center">
                                For more info on trading, read our{" "}
                                <a target="_blank" href="/tradingguide">
                                    Trading Guide
                                </a>
                            </p>
                            {show.locked ? (
                                <Alert
                                    className="ml-2 mr-2 mt-2"
                                    variant="warning"
                                >
                                    Trades Are Locked For The Week
                                </Alert>
                            ) : (
                                <>
                                    <FormGroupWrapper className="ml-5 mr-5">
                                        <Form.Control
                                            as="select"
                                            value={
                                                selectedTeam
                                                    ? selectedTeam!.id
                                                    : 0
                                            }
                                            onChange={handleTeamChange}
                                        >
                                            <option value={0}>
                                                Select Team To Trade
                                            </option>
                                            {teams &&
                                                teams.map(
                                                    (
                                                        team: LeaguesLeagueUser
                                                    ) => {
                                                        return (
                                                            <option
                                                                key={team.id}
                                                                value={team.id}
                                                            >
                                                                {team.team_name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                        </Form.Control>
                                    </FormGroupWrapper>
                                    {selectedTeam && (
                                        <Row className="mr-0 ml-0">
                                            <Col xs={6}>
                                                <h4>Your Team</h4>
                                                <List>
                                                    <Divider />
                                                    {yourTeam.length === 0 && (
                                                        <div className="mt-4">
                                                            You have nobody left
                                                            to trade
                                                        </div>
                                                    )}
                                                    {yourTeam.map(
                                                        (
                                                            player: TradeLeagueTeams
                                                        ) => {
                                                            return (
                                                                <TradeListItem
                                                                    key={
                                                                        player.id
                                                                    }
                                                                    player={
                                                                        player
                                                                    }
                                                                    selectPlayer={() =>
                                                                        selectPlayer(
                                                                            player,
                                                                            "yours"
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </List>
                                            </Col>
                                            <Col xs={6}>
                                                <h4>
                                                    {selectedTeam.team_name}
                                                </h4>
                                                <List>
                                                    <Divider />
                                                    {theirTeam.length === 0 && (
                                                        <div className="mt-4">
                                                            This user has nobody
                                                            left to trade
                                                        </div>
                                                    )}
                                                    {theirTeam.map(
                                                        (
                                                            player: TradeLeagueTeams
                                                        ) => {
                                                            return (
                                                                <TradeListItem
                                                                    key={
                                                                        player.id
                                                                    }
                                                                    player={
                                                                        player
                                                                    }
                                                                    selectPlayer={() =>
                                                                        selectPlayer(
                                                                            player,
                                                                            "theirs"
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </List>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Alert className="ml-2 mr-2 mt-2" variant="warning">
                            Trades are disabled in this league
                        </Alert>
                    )}
                </Body>
                {!isMobile && (
                    <Footer>
                        <Button
                            variant="success"
                            onClick={finalizeTrade}
                            disabled={!selectedTeam || submitting}
                        >
                            {submitting ? "Finalizing..." : "Finalize Trade"}
                        </Button>{" "}
                        <Button variant="light" onClick={closePanel}>
                            Cancel
                        </Button>
                    </Footer>
                )}
                <FinalizeTradeModal
                    yourTeam={yourTraded}
                    theirTeam={theirTraded}
                    showModal={showFinalizeTrade}
                    setShowModal={setShowFinalizeTrade}
                    onSuccess={() => onFinalizeTrade()}
                />
            </div>
        </SlidingPanel>
    );
};

export default ProposeTrade;
