import styled from "styled-components";

export const Wrapper = styled.div`
    display: inline-block;
    width: 80px;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const PlaceHolder = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #bdbdbd;
    margin-left: 15px;
`;
