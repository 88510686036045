import styled from "styled-components";

import Col from "react-bootstrap/Col";

export const Header = styled.div`
    position: fixed;
    top: 0;
    width: 90%;
    background-color: ${(props) => props.theme.secondary};
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    z-index: 100;
    height: 195px;
    text-align: center;
    border-top-right-radius: 20px;
`;

export const Body = styled.div`
    background-color: ${(props) => props.theme.inputColor};
    overflow: auto;
    position: fixed;
    width: 90%;
    bottom: 60px;
    height: calc(100% - 250px);
    top: 190px;
`;

export const Footer = styled.div`
    position: absolute;
    bottom: 0;
    padding: 10px;
    height: 60px;
    text-align: center;
    width: 90%;
    background-color: ${(props) => props.theme.secondary};
    border-bottom-right-radius: 20px;
`;

export const StarterWrapper = styled(Col)`
    height: 90px;
    white-space: nowrap;
    overflow-y: auto;
    padding: 0px;
`;
