import React from "react";

import { Modal, Button } from "react-bootstrap";

import { ModalStyled } from ".";

interface AlertModalProps {
    title: string;
    showModal: boolean;
    setShowModal: (visible: boolean) => void;
    body: any;
}

const AlertModal: React.FC<AlertModalProps> = ({
    title,
    showModal,
    setShowModal,
    body,
}) => {
    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>{body}</div>
                <Button variant="primary" onClick={() => setShowModal(false)}>
                    Ok
                </Button>{" "}
            </Modal.Body>
        </ModalStyled>
    );
};

export default AlertModal;
