import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, FastField, FastFieldProps } from "formik";
import { useTheme } from "styled-components";
import axios from "axios";
import { useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { apiPrefix, apiUrl } from "../../config";
import { RootState } from "../../store";

interface WysiwygProps {
    label: string;
    error?: string;
    name?: string;
    value: string;
    setValue: (value: string) => void;
    initialValue?: string;
    height?: number;
}

const Wysiwyg: React.FC<WysiwygProps> = ({
    label,
    error,
    name,
    setValue,
    value,
    initialValue = "",
    height,
}) => {
    const theme = useTheme();
    const editorRef = useRef(null);
    const user = useSelector((state: RootState) => state.user);

    const handleEditorChange = (state: string) => {
        setValue(state);
    };

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps) => {
                return (
                    <Form.Group>
                        <Form.Label>
                            {label}:{" "}
                            {error && name && (
                                <ErrorOutlineIcon color="error" />
                            )}
                        </Form.Label>
                        <Editor
                            ref={editorRef}
                            initialValue={initialValue}
                            value={value}
                            onEditorChange={handleEditorChange}
                            init={{
                                skin:
                                    theme.primary === "#151a30"
                                        ? "oxide-dark"
                                        : null,
                                content_css:
                                    theme.primary === "#151a30" ? "dark" : null,
                                height: height ? height : 500,
                                menubar: false,
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                    "undo redo | formatselect | " +
                                    "bold italic backcolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent | " +
                                    "removeformat | image | media | link | table | wordcount",
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                /* without images_upload_url set, Upload tab won't show up*/
                                images_upload_url: `${apiUrl}${apiPrefix}admin/uploadImage`,
                                /* we override default upload handler to simulate successful upload*/
                                images_upload_handler: function (
                                    blobInfo,
                                    success,
                                    failure
                                ) {
                                    const headers = {
                                        "content-type": "multipart/form-data",
                                        Authorization: `Bearer ${user.api_token}`,
                                    };
                                    const formData = new FormData();
                                    formData.append(
                                        "image",
                                        blobInfo.blob(),
                                        blobInfo.filename()
                                    );
                                    axios
                                        .post(
                                            `${apiUrl}${apiPrefix}admin/uploadImage`,
                                            formData,
                                            {
                                                headers: headers,
                                            }
                                        )
                                        .then((response) => {
                                            const data = response.data;
                                            if (data.success) {
                                                success(
                                                    `${apiUrl}${data.data.image}`
                                                );
                                            } else {
                                                failure(data.message);
                                            }
                                        })
                                        .catch((error) => {
                                            failure(
                                                "Unexpected error uploading image"
                                            );
                                        });
                                },
                            }}
                        />
                        {error && name && (
                            <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                            >
                                <ErrorMessage name={name} />
                            </div>
                        )}
                    </Form.Group>
                );
            }}
        </FastField>
    );
};

export default Wysiwyg;
