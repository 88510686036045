import React from "react";
import { ToastContainer } from "react-toastify";

const Toaster: React.FC = () => {
    return (
        <ToastContainer
            style={{ width: "100vw", zIndex: 105000 }}
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
};

export default Toaster;
