import React from "react";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Button from "react-bootstrap/Button";

import { Cast, DraftListItem as DLItem } from "../../models/Cast";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import DraftListItem from "./DraftListItem";
import ListItemHeader from "./ListItemHeader";
import ListItemEmpty from "./ListItemEmpty";
import GoogleAd from "../atoms/GoogleAd";

interface DraftListProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    draftee: LeaguesLeagueUser;
    draftList: DLItem[];
    finishDraft: () => void;
    setSelectedPlayer: (player: Cast) => void;
    setShowDraftPlayerModal: (show: boolean) => void;
    disabled?: boolean;
    noPicksLeft?: boolean;
}

const DraftList: React.FC<DraftListProps> = ({
    league,
    leagueUser,
    draftee,
    draftList,
    finishDraft,
    setSelectedPlayer,
    setShowDraftPlayerModal,
    disabled,
    noPicksLeft,
}) => {
    let count = 0;

    return (
        <List>
            {noPicksLeft === true && (
                <ListItemEmpty
                    content={
                        <>
                            The draft has ended. There are no picks left
                            <br />
                            {leagueUser && leagueUser.commissioner && (
                                <Button onClick={() => finishDraft()}>
                                    Finish Draft
                                </Button>
                            )}
                            {leagueUser && !leagueUser.commissioner && (
                                <>
                                    <a
                                        href={`/myleagues/view/${league.id}`}
                                        rel="noreferrer"
                                    >
                                        Return to league
                                    </a>
                                </>
                            )}
                        </>
                    }
                />
            )}
            {draftList.map((list: DLItem, index: number) => (
                <div key={index}>
                    <Divider />
                    <ListItemHeader content={list.title} />
                    {list.data.length > 0 &&
                        list.data.map((player: Cast) => {
                            count++;
                            return (
                                <React.Fragment key={player.id}>
                                    <DraftListItem
                                        disabled={disabled}
                                        key={player.id}
                                        buttonText="Draft"
                                        draftee={draftee}
                                        leagueUser={leagueUser}
                                        league={league}
                                        player={player}
                                        onClickAction={() => {
                                            setSelectedPlayer(player);
                                            setShowDraftPlayerModal(true);
                                        }}
                                    />
                                    {count % 5 === 4 && (
                                        <GoogleAd
                                            slot="7237766115"
                                            height={90}
                                            hideDislike
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    {list.data.length === 0 && (
                        <ListItemEmpty
                            content={
                                list.title === "Wishlist" ? (
                                    <>
                                        No players in your wishlist.
                                        <br />
                                        Add them{" "}
                                        <a
                                            href={`/myleagues/view/${league.id}?view=stats`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            here
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        There are no more players left
                                        <br />
                                        {leagueUser &&
                                            leagueUser.commissioner &&
                                            noPicksLeft === false && (
                                                <Button
                                                    onClick={() =>
                                                        finishDraft()
                                                    }
                                                >
                                                    Finish Draft
                                                </Button>
                                            )}
                                        {leagueUser &&
                                            !leagueUser.commissioner &&
                                            noPicksLeft === false && (
                                                <>
                                                    <a
                                                        href={`/myleagues/view/${league.id}`}
                                                        rel="noreferrer"
                                                    >
                                                        Return to league
                                                    </a>
                                                </>
                                            )}
                                    </>
                                )
                            }
                        />
                    )}
                </div>
            ))}
        </List>
    );
};

export default DraftList;
