import React, { useEffect, useState } from "react";

import Image from "react-bootstrap/Image";
import { imageUrl } from "../../config";

interface ImageUploadedProps {
    file: File | string;
    width?: number;
}

const ImageUploaded: React.FC<ImageUploadedProps> = ({ file, width }) => {
    const [src, setSrc] = useState<string | undefined>();

    useEffect(() => {
        if (typeof file === "string") {
            setSrc(file);
        }
        if (typeof file != "string") {
            let reader = new FileReader();
            reader.onloadend = () => {
                setSrc(reader.result?.toString());
            };
            reader.readAsDataURL(file);
        }
    }, [file]);

    if (src) {
        if (src.indexOf("https://") !== -1) {
            return (
                <Image width={width} className="mt-3 mw-100" src={`${src}`} />
            );
        } else if (src.indexOf("assets") > 0) {
            return (
                <Image
                    width={width}
                    className="mt-3 mw-100"
                    src={`${imageUrl}${src}`}
                />
            );
        } else {
            return (
                <Image width={width} className="mt-3 mw-100" src={`${src}`} />
            );
        }
    } else {
        return null;
    }
};

export default ImageUploaded;
