import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";

import useQuery from "../../hooks/useQuery";

import PageTemplate from "../templates/PageTemplate";

const HowItWorks: React.FC = () => {
    const query = useQuery();
    const type = query.get("type") ? query.get("type") : "site";

    return (
        <PageTemplate header="How It Works">
            <Col md={12}>
                <div>
                    <h3 className="mt-3">League Types</h3>
                    <div>
                        <b>Private:</b>
                        <br />
                        <ul>
                            <li>Only invited people can join the league.</li>
                            <li>
                                People can join by using a league code that is
                                assigned to the league after creation.
                            </li>
                        </ul>
                        <b>Public:</b>
                        <br />
                        <ul>
                            <li>Anyone can join the league.</li>
                            <li>
                                A league size can be set to limit the amount of
                                people joining.
                            </li>
                        </ul>
                    </div>
                    <h3 className="mt-3">Draft Types</h3>
                    <div>
                        <b>Weekly Redraft League</b>:<br />
                        <ul>
                            <li>
                                Every week you have to pick contestants for your
                                team. The amount of contestants you pick every
                                week is determined by you (1-10)
                            </li>
                            <li>
                                Once the episode starts, the teams are locked
                                and you will not be able to change them.
                            </li>
                            <li>
                                You only get points based on the contestants you
                                choose for that episode.
                            </li>
                            <li>
                                To make it a little more difficult, you can
                                choose a player limit. A player limit will limit
                                the amount of time you choose a contestant (1-5
                                - amount set by you). For example: if your
                                player limit is set to 3, then once someone
                                picks a contestant 3 times, they can't choose
                                them for the rest of the season. You can also
                                set this to No Limit and there won't be a limit.
                            </li>
                        </ul>
                        <b>Full Draft League</b>:<br />
                        <ul>
                            <li>
                                You set up a time that everyone in your league
                                can draft and you draft contestants in a snake
                                style draft until there's nobody left to choose
                                or the commissioner of the league finishes the
                                draft. (There's no time limit for someone to
                                draft so you could also have people draft at
                                their own leisure)
                            </li>
                            <li>
                                You get points from all of the drafted
                                contestants that are on your team every week
                                until they are eliminated.
                            </li>
                            <li>
                                You are able to trade contestants between teams.
                                For more info view the{" "}
                                <Link to="/tradingguide">Trading Guide</Link>
                            </li>
                        </ul>
                        <b>Team League</b>:<br />
                        <ul>
                            <li>
                                You pick a team at the beginning of the season
                                and decide who you want to start each week with
                                the team that you selected
                            </li>
                            <li>
                                The number of contestants on a team is
                                determined by you. 1-10 contestants
                            </li>
                            <li>
                                The number of starting contestants on a team is
                                determined by you. 1-10 contestants.
                            </li>
                            <li>
                                People in the league will be able to have the
                                same contestants on their team as another
                                person, but the scores will differ based on who
                                people start each week
                            </li>
                            <li>
                                Each week, everyone in the league will pick who
                                they will start and who will be on their bench
                            </li>
                            <li>
                                Once the episode starts, the teams are locked
                                and you will not be able to change them.
                            </li>
                            <li>
                                If you want a little more control over who
                                players can have on their team, you can set up
                                rookie or gender limits.
                                <ul>
                                    <li>
                                        Rookie limits will make users select a
                                        certain amount of rookies when selecting
                                        their initial team. For example, you can
                                        set the limit to 1 and that would force
                                        players to choose at least 1 rookie when
                                        selecting their team. This does not mean
                                        the amount of rookies they need to start
                                        each week, only the amount they need to
                                        select during their inital set up of the
                                        team. You can also set this to No Limit
                                        and there won't be a limit.
                                    </li>
                                    <li>
                                        Gender limits will make users select a
                                        certain amount of males/females when
                                        selecting their initial team. For
                                        example, you can set the limit to 1 male
                                        and 1 female and that would force
                                        players to choose at least 1 male and 1
                                        female when selecting their team. This
                                        does not mean the amount of
                                        males/females they need to start each
                                        week, only the amount they need to
                                        select during their inital set up of the
                                        team. You can also set this to No Limit
                                        and there won't be a limit.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                (Optional) you can allow teams to swap out
                                contestants during the season if certain
                                contestants get eliminated. The number of swaps
                                are determined by you and you can set a last
                                episode for someone to make a swap.
                            </li>
                        </ul>
                        <b>Weekly Budget League</b>
                        :<br />
                        <ul>
                            <li>
                                Every week you have $100 (fake money) to pick
                                contestants to be on your team weekly.
                            </li>
                            <li>
                                Each contestants is assigned a $ amount based on
                                how well they have done in fantasy up to that
                                point in the season. The better fantasy
                                contestants will cost more, while the worse
                                fantasy contestants will cost less.
                            </li>
                            <li>
                                Once the episode starts, the teams are locked
                                and you will not be able to change them.
                            </li>
                            <li>
                                You only get points based on the contestants you
                                choose for that episode.
                            </li>
                        </ul>
                        <b>Confidence Pool League</b>
                        :<br />
                        <ul>
                            <li>
                                Every week you rank contestants based on how
                                confident you are that they will survive each
                                week.
                            </li>
                            <li>
                                Once the episode starts, the picks are locked
                                and you will not be able to change them.
                            </li>
                            <li>
                                The more confident you are that a contestant
                                will survive each week, the more points you will
                                receive if they do survive.
                            </li>
                        </ul>
                        <b>Eliminated Confidence Pool League</b>
                        :<br />
                        <ul>
                            <li>
                                Every week you rank contestants based on how
                                confident you are that they will survive each
                                week.
                            </li>
                            <li>
                                You will only get points for the contestant(s)
                                that get eliminated that week.
                            </li>
                            <li>
                                Once the episode starts, the picks are locked
                                and you will not be able to change them.
                            </li>
                            <li>
                                The more confident you are that a contestant
                                will be eliminated each week, the more points
                                you will receive if they do get eliminated.
                            </li>
                        </ul>
                        {/* <b>
                            Stock Market League (only a public league currently)
                        </b>
                        :<br />
                        <ul>
                            <li>
                                Everyone starts out with $1,000 (fake money) at
                                the beginning of the season (or whenever you
                                join the league)
                            </li>
                            <li>
                                Stock prices for each contestant will start at
                                $5 at the beginning of the season and won't be
                                updated until after the first episode. After
                                that, they are updated every night at 12am CST
                            </li>
                            <li>
                                Everyday you can make 1 transaction to buy/sell
                                contestants stocks. (Only exception being no
                                transactions made when scores are still being
                                logged for the previous episode)
                            </li>
                            <li>
                                The stock price of a share of each player is
                                determined by taking the number of shareholders
                                for a contestant, dividing that by the total
                                number of shareholders for all contestants and
                                then multiplying by 100 (Once someone buys
                                shares for a contestant, the price goes up for
                                that contestant and down for every other
                                contestant and vice versa with selling shares)
                            </li>
                            <li>The lowest a share price can be is $1</li>
                            <li>
                                Share prices for each contestant will be updated
                                every night at 12am CST. The only exception to
                                this is share prices won't be updated whenever
                                scores are being logged for an episode.
                            </li>
                            <li>
                                Based on the fantasy points a contestant gets in
                                an episode, dividends will be paid out to the
                                shareholders of that contestant (amount of
                                shares x dividend amount). The dividend amount
                                is determined by taking the fantasy points that
                                contestant earned in that episode, dividing that
                                by the total fantasy points earned that episode
                                and then multiplying that by 100.
                            </li>
                            <li>
                                Once the episode starts, your stocks will be
                                locked and you won't be able to make a new
                                transaction until the scores are logged for that
                                episode.
                            </li>
                            <li>
                                Once a contestant gets eliminated, their stock
                                goes down to $0 and they won't pay out dividends
                                in that episode
                            </li>
                            <li>
                                The ultimate winner of the season (if there can
                                be one) will have a stock price of $100 after
                                the final episode, with all others left in the
                                game having their stocks go to $0, but still pay
                                out dividends since they never were eliminated
                            </li>
                            <li>
                                The winner of the league will be whoever has the
                                most money combined at the end of the season
                            </li>
                        </ul> */}
                    </div>
                    {type === "app" ? (
                        <>
                            <h3 className="mt-3">Creating a League</h3>
                            <div>
                                If you want to create a league, navigate to the
                                "My Leagues" tab and then click "Create" in the
                                top left of the screen. From there you will be
                                given a form to fill out about the league. That
                                will include the show, league name, your own
                                team name, what type of league you want, the
                                settings for the league type that you choose,
                                the scoring categories and the points you want
                                for each of those (you will be able to change
                                these later as well). Once you complete the
                                form, you will be ready to go!
                            </div>
                            <h3 className="mt-3">
                                Joining/Inviting Friends to a League
                            </h3>
                            <div>
                                Once you have created a league, you will want to
                                invite your friends to enjoy the fun with you.
                                To do this, you go to your league and you will
                                see a "Invite Friends" button. Click that and
                                you will have a popup that includes a league
                                code. You will need to copy that league code and
                                send it to your friends. For them to join they
                                will login, go to "My Leagues" tab, click "Join"
                                in the top right of the screen and input the
                                code there. From there, they can choose their
                                team name and then they're good to go!
                            </div>
                            <h3 className="mt-3">Drafting/Setting Lineup</h3>
                            <div>
                                Depending on which league you choose (Weekly
                                Redraft, Full Draft, Weekly Budget, Team,
                                Confidence Pool or Eliminated Confidence Pool),
                                you will have six different draft processes.
                                <ul>
                                    <li>
                                        <b>Weekly Redraft:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will have to set your lineup of
                                            1-10 contestants every week by
                                            clicking "Set Lineup" and then
                                            choosing the contestants you want
                                            and then clicking "Save Lineup".
                                            These lineups will be locked right
                                            when the show starts on the east
                                            coast that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Full Draft</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will need to set up a time with
                                            your league to draft. To get to the
                                            draft board, you will need to click
                                            "Join Draft". The commissioner will
                                            then start the draft by clicking
                                            "Start Draft" and will have to
                                            select the order they want everyone
                                            to draft in (everyone will draft in
                                            a snake draft). The draft will end
                                            whenever the commissioner clicks
                                            "Finish Draft" or there are no
                                            contestants left to draft. Full
                                            drafts are set up to be live,
                                            meaning you shouldn't need to reload
                                            the page to get updates, but if
                                            you're still having issues, you can
                                            just reload the page to get the
                                            updated draft board
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Team Select:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will need to pick who you want
                                            to start and who you want to bench
                                            each week. To do this, you will
                                            click "Select Team" and then move
                                            contestants from starters to the
                                            bench or from the bench to starters.
                                            Once you're finished, click "Save
                                            Team". These starters will be locked
                                            right when the show starts on the
                                            east coast that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Weekly Budget:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will have to set your lineup of
                                            2-5 contestants every week (this
                                            number is determined based on how
                                            many contestants are left) by
                                            clicking "Set Lineup" and then
                                            choosing the contestants you want
                                            and fit your budget of $100 (fake
                                            money) and clicking "Save Lineup".
                                            These lineups will be locked right
                                            when the show starts on the east
                                            coast that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Confidence Pool:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will rank contestant each week
                                            based on how confident you are that
                                            they will survive each week. You can
                                            do this by clicking "Make Picks" and
                                            then drag and drop contestants until
                                            they are ranked how you would like
                                            them to be and then clicking "Save
                                            Lineup". The picks will be locked
                                            right when the show starts on the
                                            east coast that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Eliminated Confidence Pool:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will rank contestant each week
                                            based on how confident you are that
                                            they will survive each week. You can
                                            do this by clicking "Make Picks" and
                                            then drag and drop contestants until
                                            they are ranked how you would like
                                            them to be and then clicking "Save
                                            Lineup". The lower down you rank a
                                            contestant that gets eliminated, the
                                            more points you will receive. The
                                            picks will be locked right when the
                                            show starts on the east coast that
                                            week.
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <>
                            <h3 className="mt-3">Creating a League</h3>
                            <div>
                                If you want to create a league, just go to the
                                top menu, click on the show you want, and then
                                click the "Create New League" button. From there
                                you will be given a form to fill out about the
                                league. That will include the show, league name,
                                your own team name, what type of league you
                                want, the settings for the league type that you
                                choose, the scoring categories and the points
                                you want for each of those (you will be able to
                                change these later as well). Once you complete
                                the form, you will be ready to go!
                            </div>
                            <h3 className="mt-3">
                                Joining/Inviting Friends to a League
                            </h3>
                            <div>
                                Once you have created a league, you will want to
                                invite your friends to enjoy the fun with you.
                                To do this, you go to your league and you will
                                see an "Invite Friends" button. Click that and
                                you will have a popup that includes a link to
                                send your friends that will send them through
                                the sign up process or a league code. For the
                                league code, you will need to copy that and send
                                it to your friends. For them to join they will
                                login, go to "My Leagues", click "Join Private
                                League" and input the code there. From there,
                                they can choose their team name and then they're
                                good to go!
                            </div>
                            <h3 className="mt-3">Drafting/Setting Lineup</h3>
                            <div>
                                Depending on which league you choose (Weekly
                                Redraft, Full Draft, Weekly Budget, Team,
                                Confidence Pool, Eliminated Confidence Pool),
                                you will have six different draft processes.
                                <ul>
                                    <li>
                                        <b>Weekly Redraft:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will have to set your lineup of
                                            1-10 contestants every week by
                                            clicking "Set Lineup" and then
                                            choosing the contestants you want
                                            and then clicking "Save Lineup".
                                            These lineups will be locked right
                                            when the show starts on the east
                                            coast that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Full Draft</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will need to set up a time with
                                            your league to draft. To get to the
                                            draft board, you will need to click
                                            "Join Draft". The commissioner will
                                            then start the draft by clicking
                                            "Start Draft" and will have to
                                            select the order they want everyone
                                            to draft in (everyone will draft in
                                            a snake draft). The draft will end
                                            whenever the commissioner clicks
                                            "Finish Draft" or there are no
                                            contestants left to draft.Full
                                            drafts are set up to be live,
                                            meaning you shouldn't need to reload
                                            the page to get updates, but if
                                            you're still having issues, you can
                                            just reload the page to get the
                                            updated draft board
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Team Select:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            Whenever a user first joins, they
                                            will be prompted to select an
                                            initial team. From there, you will
                                            need to pick who you want to start
                                            and who you want to bench each week.
                                            To do this, you will click "Select
                                            Team" and then move contestants from
                                            starters to the bench or from the
                                            bench to starters. Once you're
                                            finished, click "Save Team". These
                                            starters will be locked right when
                                            the show starts on the east coast
                                            that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Weekly Budget:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will have to set your lineup of
                                            2-5 contestants every week (this
                                            number is determined based on how
                                            many contestants are left) by
                                            clicking "Set Lineup" and then
                                            choosing the contestants you want
                                            and fit your budget of $100 (fake
                                            money) and clicking "Save Lineup".
                                            These lineups will be locked right
                                            when the show starts on the east
                                            coast that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Confidence Pool:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will rank contestant each week
                                            based on how confident you are that
                                            they will survive each week. You can
                                            do this by clicking "Make Picks" and
                                            then drag and drop contestants until
                                            they are ranked how you would like
                                            them to be and then clicking "Save
                                            Lineup". The picks will be locked
                                            right when the show starts on the
                                            east coast that week.
                                        </li>
                                    </ul>
                                    <li>
                                        <b>Eliminated Confidence Pool:</b>
                                    </li>
                                    <ul>
                                        <li>
                                            You will rank contestant each week
                                            based on how confident you are that
                                            they will survive each week. You can
                                            do this by clicking "Make Picks" and
                                            then drag and drop contestants until
                                            they are ranked how you would like
                                            them to be and then clicking "Save
                                            Lineup". The lower down you rank a
                                            contestant that gets eliminated, the
                                            more points you will receive. The
                                            picks will be locked right when the
                                            show starts on the east coast that
                                            week.
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </Col>
        </PageTemplate>
    );
};

export default HowItWorks;
