import ListItemButton from "@mui/material/ListItemButton";
import styled from "styled-components";

export const ListHeader = styled(ListItemButton)`
    text-align: center !important;
    background-color: ${(props) => props.theme.primary} !important;
    &:hover {
        //you want this to be the same as the backgroundColor above
        background-color: ${(props) => props.theme.primary} !important;
    }
`;
