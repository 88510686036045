import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import echo from "../../config/echo";
import useApi from "../../hooks/useApi";
import { CommentItem, CommentList } from "../../models/Comments";
import { LiveScore } from "../../models/LiveScores";
import apiRoutes from "../../services/apiRoutes";
import { RootState } from "../../store";

import { Spinner } from "../atoms";
import Message from "./Message";
import MessageForm from "./MessageForm";

interface LiveScoreCommentsProps {
    id: number;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const LiveScoreComments: React.FC<LiveScoreCommentsProps> = ({
    id,
    open,
    setOpen,
}) => {
    const user = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [comments, setComments] = useState<CommentList>();

    const getComments = useApi(
        apiRoutes.GET_LIVE_SCORE_COMMENTS(id.toString()),
        {
            onSuccess: (response: CommentList) => {
                setLoading(false);
                setComments(response);
            },
            onFailure: () => {
                setLoading(false);
                toast.error("There was an issue grabbing comments");
            },
            responseKey: "comments",
        }
    );
    const saveComment = useApi(
        apiRoutes.SAVE_LIVE_SCORES_COMMENT(id.toString()),
        {
            onSuccess: (comment: CommentItem) => {
                setSubmitting(false);
                setComments((current) => [comment, ...current]);
            },
            onFailure: () => {
                toast.error("There was an issue saving comment");
                setSubmitting(false);
            },
            responseKey: "comment",
        }
    );

    useEffect(() => {
        const liveScoreCommentChannel = echo.channel(`liveScoreComment.${id}`);

        //register socket events
        liveScoreCommentChannel.listen(
            ".LiveScoreComment",
            (data: { liveScore: LiveScore; userId: number }) => {
                if (id === data.liveScore.id && data.userId !== user.id) {
                    getComments.request();
                }
            }
        );

        if (open) {
            setLoading(true);
            getComments.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    if (open) {
        return (
            <>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="text-center">
                            <Button
                                variant="link"
                                onClick={() => setOpen(false)}
                            >
                                Hide Comments
                            </Button>
                        </div>
                        {comments && comments.length > 0 ? (
                            <>
                                {user.isLoggedIn && (
                                    <div>
                                        <MessageForm
                                            onSubmit={(comment: string) => {
                                                setSubmitting(true);
                                                saveComment.request({
                                                    comment,
                                                });
                                            }}
                                            submitting={submitting}
                                            label="Leave a Comment:"
                                        />
                                        <div
                                            style={{
                                                maxHeight: 300,
                                                overflow: "auto",
                                            }}
                                        >
                                            {comments.map((comment) => {
                                                return (
                                                    <Message
                                                        key={comment.id}
                                                        message={comment}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {user.isLoggedIn && (
                                    <div>
                                        <MessageForm
                                            onSubmit={(comment: string) => {
                                                setSubmitting(true);
                                                saveComment.request({
                                                    comment,
                                                });
                                            }}
                                            submitting={submitting}
                                            label="Leave a Comment:"
                                        />
                                        <div className="text-center w-100">
                                            No Comments
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    } else {
        return null;
    }
};

export default LiveScoreComments;
