import React from "react";

import { QuestionScoreItem } from "../../models/QuestionScores";

import { Row } from "react-bootstrap";

import CastImage from "../atoms/CastImage";
import { PlayerName } from "./styles/EliminatedPlayers.styled";

interface ChallengeQuestionResultsProps {
    results: QuestionScoreItem[];
}

const ChallengeQuestionResults: React.FC<ChallengeQuestionResultsProps> = ({
    results,
}) => {
    return (
        <div className="mb-5">
            <h5>Results:</h5>
            {results.map((player) => {
                return (
                    <Row
                        key={player.cast_id}
                        className="align-items-center mb-2"
                    >
                        <CastImage src={player.pic} />
                        <PlayerName className="ml-2">
                            {` ${player.first_name} ${
                                player.last_name ? player.last_name : ""
                            }`}
                        </PlayerName>
                    </Row>
                );
            })}
        </div>
    );
};

export default ChallengeQuestionResults;
