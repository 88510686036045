import styled from "styled-components";

export const PageContainer = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.textColor};
    min-height: 100%;
`;

export const Content = styled.div`
    padding-bottom: 550px;
    min-height: 100vh;
`;
