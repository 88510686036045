import moment from "moment-timezone";

import { Show } from "../models/Shows";
import { TimezoneOffset } from "../models/TimezoneOffset";

const determineCountdown = (
    offsets: TimezoneOffset,
    show?: Show,
    live?: boolean
): number | null => {
    if (show) {
        const timeNow = new Date();
        const offsetInHours = timeNow.getTimezoneOffset() / 60;
        let showTime =
            show.ls_show_time !== null && live
                ? show.ls_show_time
                : show.show_time;
        if (offsetInHours >= offsets.hawaii && show.hawaii_offset > 0 && live) {
            showTime = showTime + show.hawaii_offset;
        } else if (
            offsetInHours >= offsets.alaska &&
            show.alaska_offset > 0 &&
            live
        ) {
            showTime = showTime + show.alaska_offset;
        } else if (
            offsetInHours >= offsets.pacific &&
            show.pacific_offset > 0 &&
            live
        ) {
            showTime = showTime + show.pacific_offset;
        } else if (
            offsetInHours >= offsets.mountain &&
            show.mountain_offset > 0 &&
            live
        ) {
            showTime = showTime + show.mountain_offset;
        }
        const minute =
            show.ls_minute !== null && live ? show.ls_minute : show.minute;
        const dayOfWeek =
            show.ls_day_of_week !== null && live
                ? show.ls_day_of_week
                : show.day_of_week;

        const now = moment().format("YYYY/MM/DD HH:mm:ss");
        const airDate = moment(
            `${show.year}-${displayNumber(
                show.month.toString()
            )}-${displayNumber(show.day.toString())} ${displayNumber(
                showTime.toString()
            )}:${displayNumber(minute.toString())}:00`
        ).format("YYYY/MM/DD HH:mm:ss");
        if (airDate > now) {
            const offset = getOffset(airDate);

            return new Date(
                moment(airDate)
                    .add(offset, "minutes")
                    .format(`YYYY/MM/DD HH:mm:ss`)
            ).getTime();
        } else {
            const day = moment();
            if (day.weekday() === getWeekdayNumber(dayOfWeek)) {
                const lock = moment(
                    moment().format(
                        `YYYY/MM/DD ${displayNumber(
                            showTime.toString()
                        )}:${displayNumber(minute.toString())}:00`
                    )
                ).format("YYYY/MM/DD HH:mm:ss");
                if (lock < now && offsetInHours > 5) {
                    const nextLock = moment()
                        .add(7, "d")
                        .format(
                            `YYYY/MM/DD ${displayNumber(
                                showTime.toString()
                            )}:${displayNumber(minute.toString())}:00`
                        );
                    const offset = getOffset(nextLock);

                    return new Date(
                        moment(nextLock)
                            .add(offset, "minutes")
                            .format(`YYYY/MM/DD HH:mm:ss`)
                    ).getTime();
                } else {
                    const offset = getOffset(lock);

                    return new Date(
                        moment(lock)
                            .add(offset, "minutes")
                            .format(`YYYY/MM/DD HH:mm:ss`)
                    ).getTime();
                }
            } else if (day.weekday() > getWeekdayNumber(dayOfWeek)) {
                const nextLock = moment()
                    .add(7 - (day.weekday() - getWeekdayNumber(dayOfWeek)), "d")
                    .format(
                        `YYYY/MM/DD ${displayNumber(
                            showTime.toString()
                        )}:${displayNumber(minute.toString())}:00`
                    );
                const offset = getOffset(nextLock);

                return new Date(
                    moment(nextLock)
                        .add(offset, "minutes")
                        .format(`YYYY/MM/DD HH:mm:ss`)
                ).getTime();
            } else {
                const nextLock = moment()
                    .add(getWeekdayNumber(dayOfWeek) - day.weekday(), "d")
                    .format(
                        `YYYY/MM/DD ${displayNumber(
                            showTime.toString()
                        )}:${displayNumber(minute.toString())}:00`
                    );

                const offset = getOffset(nextLock);

                return new Date(
                    moment(nextLock)
                        .add(offset, "minutes")
                        .format(`YYYY/MM/DD HH:mm:ss`)
                ).getTime();
            }
        }
    }
    return null;
};

const getOffset = (date: any) => {
    return (
        getTimeZoneOffset(
            new Date(moment(date).format("YYYY/MM/DD HH:mm:ss")),
            "America/Chicago"
        ) -
        new Date(moment(date).format("YYYY/MM/DD HH:mm:ss")).getTimezoneOffset()
    );
};

const getTimeZoneOffset = (date: any, timeZone: string) => {
    // Abuse the Intl API to get a local ISO 8601 string for a given time zone.
    let iso = date
        .toLocaleString("en-CA", { timeZone, hour12: false })
        .replace(", ", "T");

    // Include the milliseconds from the original timestamp
    iso += "." + date.getMilliseconds().toString().padStart(3, "0");

    // Lie to the Date object constructor that it's a UTC time.
    const lie: any = new Date(iso + "Z");

    // Return the difference in timestamps, as minutes
    // Positive values are West of GMT, opposite of ISO 8601
    // this matches the output of `Date.getTimeZoneOffset`
    return -(lie - date) / 60 / 1000;
};

const displayNumber = (value: string): string => {
    if (value.length > 1) {
        return value;
    } else {
        return `0${value}`;
    }
};

export const getHoursLeft = (dateTime: number): number => {
    return Math.floor(dateTime / (1000 * 60 * 60));
};

const getWeekdayNumber = (weekday: string): number => {
    switch (weekday) {
        case "Monday":
            return 1;
        case "Tuesday":
            return 2;
        case "Wednesday":
            return 3;
        case "Thursday":
            return 4;
        case "Friday":
            return 5;
        case "Saturday":
            return 6;
        case "Sunday":
            return 0;
        default:
            return 1;
    }
};

export default determineCountdown;
