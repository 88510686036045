import styled from "styled-components";

import Col from "react-bootstrap/Col";
import Avatar from "@mui/material/Avatar";

export const AvatarItem = styled(Avatar)`
    margin-left: 15px;
`;

export const Header = styled.div`
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    z-index: 100;
`;

export const StarterWrapper = styled(Col)`
    white-space: nowrap;
    overflow-y: auto;
    padding: 0px;
`;

export const Wrapper = styled.div`
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    width: 80px;
    text-align: center;
    padding-top: 10px;
    font-size: 12px;
`;
