import React from "react";
import { Helmet } from "react-helmet-async";

import { Tab, Tabs } from "react-bootstrap";

import { MainContainer, PageHeader, TabsWrapper } from "../atoms";
import GoogleAd from "../atoms/GoogleAd";
import Articles from "./Articles";
import Podcasts from "./Podcasts";

const News: React.FC = () => {
    return (
        <MainContainer>
            <Helmet prioritizeSeoTags>
                <title>News - RealTVFantasy</title>
                <meta
                    name="description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:url"
                    content={`https://realtvfantasy.com${window.location.pathname}`}
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="News - RealTVFantasy" />
                <meta
                    property="og:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="og:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="News - RealTVFantasy" />
                <meta
                    property="twitter:description"
                    content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                />
                <meta
                    property="twitter:image"
                    content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                />
            </Helmet>
            <PageHeader>
                <h1>News</h1>
            </PageHeader>
            <GoogleAd slot="7237766115" height={90} />
            <TabsWrapper>
                <Tabs>
                    <Tab
                        tabClassName="col-6 text-center"
                        eventKey="articles"
                        title="Articles"
                    >
                        <Articles type="all" tab />
                    </Tab>
                    <Tab
                        tabClassName="col-6 text-center"
                        eventKey="podcasts"
                        title="Podcasts"
                    >
                        <Podcasts />
                    </Tab>
                </Tabs>
            </TabsWrapper>
            <GoogleAd fixed slot="7455698336" height={90} />
        </MainContainer>
    );
};

export default News;
