import {
    LOG_IN_FULFILLED,
    LOG_OUT,
    UPDATE_USER,
    UserActionTypes,
    UserState,
} from "./types";
import http from "../../services";

export function login(user: UserState): UserActionTypes {
    http.setToken(user.api_token);

    return {
        type: LOG_IN_FULFILLED,
        payload: {
            user,
        },
    };
}

export function logout(): UserActionTypes {
    localStorage.clear();
    window.location.href = "/";

    return {
        type: LOG_OUT,
    };
}

export function updateUser(user: UserState): UserActionTypes {
    return {
        type: UPDATE_USER,
        payload: {
            user,
        },
    };
}
