import styled from "styled-components";

import { IoMdSend } from "react-icons/io";

export const ChatWrapper = styled.div`
    position: fixed;
    right: 10px;
    bottom: 110px;
    height: calc(100% - 190px);
    max-height: 590px;
    min-height: 200px;
    width: 600px;
    max-width: 95%;
    z-index: 14000;
    background-color: ${(props) => props.theme.secondary};
    border-radius: 10px;
    box-shadow: 0px 0px 5px #000;
`;

export const ChatButtonWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 20px;
    z-index: 14000;
`;

export const ChatIcon = styled.div`
    background-color: #007bff;
    padding: 15px;
    border-radius: 35px;
    width: 70px;
    box-shadow: 0px 0px 5px #000;
    &:hover {
        cursor: pointer;
    }
`;

export const Footer = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: ${(props) => props.theme.primary};
`;

export const MessageInput = styled.textarea`
    width: 90%;
    border: transparent;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.textColor};
    resize: vertical;
    max-height: 100px;
    min-height: 45px;

    &:focus {
        outline: none;
    }
`;

export const SendIcon = styled(IoMdSend)`
    position: absolute;
    right: 10px;
    bottom: 15px;
    &:hover {
        cursor: pointer;
    }
`;

export const MessagesWrapper = styled.div`
    overflow: auto;
    height: calc(100% - 52px);
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
`;
