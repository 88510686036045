import { ApiReturnType } from "./";

const timezoneOffsetRoutes = {
    OFFSET: (slug?: string): ApiReturnType => {
        if (slug) {
            return {
                url: `timezoneOffset/${slug}`,
                method: "get",
            };
        } else {
            return {
                url: `timezoneOffset`,
                method: "get",
            };
        }
    },
};

export default timezoneOffsetRoutes;
