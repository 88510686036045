import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Score } from "../../models/Scores";

interface ScoringListProps {
    scoring: Score[];
}

const ScoringList: React.FC<ScoringListProps> = ({ scoring }) => {
    let category: string;
    let newCategory: boolean = true;

    return (
        <>
            {scoring &&
                scoring.map((score, key) => {
                    if (category !== score.category) {
                        newCategory = true;
                        category = score.category;
                    } else {
                        newCategory = false;
                    }
                    return (
                        <React.Fragment key={key}>
                            {newCategory && (
                                <h4 className="col-sm-12 pt-4">{category}:</h4>
                            )}
                            {score.score !== 0 && (
                                <Col key={score.id} md={6}>
                                    <Row className="p-1 mr-1 ml-1">
                                        <Col xs={10} sm={10} md={10}>
                                            {score.description}:
                                        </Col>
                                        <Col
                                            className="text-right"
                                            xs={2}
                                            sm={2}
                                            md={2}
                                        >
                                            <b>{score.score}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </React.Fragment>
                    );
                })}
        </>
    );
};

export default ScoringList;
