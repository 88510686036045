import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ChallengeQuestion } from "../../models/ChallengeQuestions";

interface QuestionListProps {
    questions: ChallengeQuestion[];
    customQuestions?: ChallengeQuestion[];
}

const QuestionList: React.FC<QuestionListProps> = ({
    questions,
    customQuestions = [],
}) => {
    const QuestionListItem = ({
        question,
    }: {
        question: ChallengeQuestion;
    }) => (
        <>
            {question.score !== 0 && (
                <Col key={question.id} md={6}>
                    <Row className="p-1 mr-1 ml-1">
                        <Col xs={10} sm={10} md={10}>
                            {question.description}:
                        </Col>
                        <Col className="text-right" xs={2} sm={2} md={2}>
                            <b>{question.score}</b>
                        </Col>
                    </Row>
                </Col>
            )}
        </>
    );

    return (
        <>
            {questions.length > 0 && (
                <>
                    <h4 className="col-sm-12 pt-4">Challenge Questions:</h4>
                    {questions.map((question, key) => {
                        return (
                            <QuestionListItem
                                key={question.id}
                                question={question}
                            />
                        );
                    })}
                </>
            )}
            {customQuestions.length > 0 && (
                <>
                    <h4 className="col-sm-12 pt-4">
                        Custom Challenge Questions:
                    </h4>
                    {customQuestions.map((question, key) => {
                        return (
                            <QuestionListItem
                                key={question.id}
                                question={question}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

export default QuestionList;
