import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { deleteNotification } from "../../store/notifications/actions";

import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DeleteIcon from "@mui/icons-material/Delete";

import { Small } from "../atoms";

const NotificationList: React.FC = () => {
    const [selectedId, setSelectedId] = useState<string>();
    const dispatch = useDispatch();
    const history = useHistory();
    const notifications = useSelector(
        (state: RootState) => state.notifications
    );

    const deleteNotificationRequest = useApi(
        apiRoutes.DELETE_NOTIFICATION(selectedId),
        {
            responseKey: "message",
            onSuccess: (message) => {
                dispatch(deleteNotification(selectedId));
            },
        }
    );

    useEffect(() => {
        if (selectedId) {
            deleteNotificationRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    const deleteNote = (e, id: number) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedId(id.toString());
    };

    return (
        <>
            {notifications &&
                notifications.map((notification) => (
                    <NavDropdown.Item
                        className="notifications-dropdown"
                        key={notification.id}
                        onClick={(e) => {
                            deleteNote(e, notification.id);
                            history.push(notification.link);
                        }}
                    >
                        <Row
                            style={{
                                alignItems: "center",
                            }}
                        >
                            <Col
                                style={{
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                }}
                                xs={10}
                            >
                                {notification.notification}
                                <br />
                                <Small>
                                    {moment(notification.created_at).fromNow()}
                                </Small>
                            </Col>
                            <Col xs={2}>
                                <DeleteIcon
                                    onClick={(e) =>
                                        deleteNote(e, notification.id)
                                    }
                                />
                            </Col>
                        </Row>
                    </NavDropdown.Item>
                ))}
        </>
    );
};

export default NotificationList;
