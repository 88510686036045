import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Input from "../atoms/Input";
import { ScoreItem, ShowFormFields } from "../pages/CreateCustomShow";

interface ScoringInfoFormProps {
    index: number;
    score: ScoreItem;
    values: ShowFormFields;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const CustomScoringInfoForm: React.FC<ScoringInfoFormProps> = ({
    index,
    score,
    setFieldValue,
    values,
}) => {
    return (
        <>
            <Row style={{ alignItems: "center" }} key={score.id}>
                <Col md={4}>
                    <Input
                        name={`scoring.${index}.description`}
                        label="Description:"
                        placeholder="Description"
                    />
                </Col>
                <Col md={4}>
                    <Input
                        name={`scoring.${index}.category`}
                        label="Category:"
                        placeholder="Category"
                    />
                </Col>
                <Col md={4}>
                    <Input
                        name={`scoring.${index}.score`}
                        label="Score:"
                        placeholder="Score"
                        type="number"
                    />
                </Col>
            </Row>
        </>
    );
};

export default CustomScoringInfoForm;
