import React from "react";
import { useTheme } from "styled-components";

import Avatar from "@mui/material/Avatar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import { imageUrl } from "../../config";
import { TeamLeagueTeams } from "../../models/TeamLeagueTeams";

import { ErrorText, Strikethrough } from "../atoms";
import TableTeam from "../atoms/TableTeam";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

interface TeamLineupListItemProps {
    player: TeamLeagueTeams;
    swap: (id: number) => void;
    mainMovingId: number;
    secondaryMovingId: number;
    setMovingId: (id: number) => void;
    currentEpisode: number;
}

const TeamLineupListItem: React.FC<TeamLineupListItemProps> = ({
    player,
    swap,
    mainMovingId,
    secondaryMovingId,
    setMovingId,
    currentEpisode,
}) => {
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);

    return (
        <>
            <TableTeam>
                <tbody>
                    <tr>
                        <td className="pl-4">
                            <Row className="align-items-center">
                                <Avatar
                                    style={{ height: 60, width: 60 }}
                                    src={`${imageUrl}${player.pic}`}
                                />
                                <div className="pl-2">
                                    {player.episode_eliminated !== null &&
                                    player.episode_eliminated <
                                        currentEpisode ? (
                                        <ErrorText>
                                            <Strikethrough>{`${
                                                player.first_name &&
                                                player.first_name
                                            } ${
                                                player.last_name &&
                                                player.last_name
                                            }`}</Strikethrough>{" "}
                                            (Eliminated)
                                        </ErrorText>
                                    ) : (
                                        <span
                                            style={{ color: theme.textColor }}
                                        >{`${
                                            player.first_name &&
                                            player.first_name
                                        } ${
                                            player.last_name && player.last_name
                                        }`}</span>
                                    )}
                                </div>
                            </Row>
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <div
                                        className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none"
                                        style={{ fontSize: 14 }}
                                    >
                                        {player.average && (
                                            <>
                                                Average Score:{" "}
                                                <b>
                                                    {player.average.toFixed(2)}
                                                </b>
                                            </>
                                        )}
                                        <br />
                                        Starting <small>
                                            (site wide)
                                        </small>: <b>{player.team_started}%</b>
                                        <br />
                                        Starting <small>
                                            (this league)
                                        </small>:{" "}
                                        <b>{player.team_started_league}%</b>
                                    </div>
                                )}
                        </td>
                        {subscription && hasPremiumSub(subscription.name) && (
                            <td
                                className="d-none d-md-table-cell"
                                style={{
                                    color: theme.textColor,
                                }}
                            >
                                {player.average && (
                                    <>
                                        Average Score:{" "}
                                        <b>{player.average.toFixed(2)}</b>
                                        <br />
                                    </>
                                )}
                                Starting <small>(site wide)</small>:{" "}
                                <b>{player.team_started}%</b>
                                <br />
                                Starting <small>(this league)</small>:{" "}
                                <b>{player.team_started_league}%</b>
                            </td>
                        )}
                        <td className="text-right" style={{ width: 100 }}>
                            {mainMovingId > 0 ? (
                                <Button onClick={() => swap(player.id)}>
                                    Here
                                </Button>
                            ) : (
                                <>
                                    {secondaryMovingId === player.id && (
                                        <Button onClick={() => setMovingId(0)}>
                                            Cancel
                                        </Button>
                                    )}
                                    {secondaryMovingId !== player.id &&
                                        secondaryMovingId !== 0 && (
                                            <Button disabled>Move</Button>
                                        )}
                                    {secondaryMovingId === 0 && (
                                        <Button
                                            onClick={() =>
                                                setMovingId(player.id)
                                            }
                                        >
                                            Move
                                        </Button>
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                </tbody>
            </TableTeam>
        </>
    );
};

export default TeamLineupListItem;
