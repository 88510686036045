import { ApiReturnType } from "./";

const analyticsRoutes = {
    CAST_LEAGUE: (cast_id: string): ApiReturnType => {
        return {
            url: `analytics/castLeague/${cast_id}`,
            method: "get",
        };
    },
    LEAGUE_OVERVIEW: (league_id: string): ApiReturnType => {
        return {
            url: `analytics/leagueOverview/${league_id}`,
            method: "get",
        };
    },
    SITE_OVERVIEW: (show_id: string): ApiReturnType => {
        return {
            url: `analytics/siteOverview/${show_id}`,
            method: "get",
        };
    },
    CAST_SCORES_BREAKDOWN: (
        cast_id: string,
        type?: "episode" | "scoring"
    ): ApiReturnType => {
        return {
            url: `analytics/castScoresBreakdown/${cast_id}/${
                type ?? "episode"
            }`,
            method: "get",
        };
    },
};

export default analyticsRoutes;
