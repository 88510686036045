import { ApiReturnType } from "./";

const scoresRoutes = {
    GET_CAST_SCORES: (cast_id: string): ApiReturnType => {
        return {
            url: `scores/byCast/${cast_id}`,
            method: "get",
        };
    },
    GET_EPISODE_SCORES: (slug: string, episode: string): ApiReturnType => {
        return {
            url: `scores/byEpisode/${slug}/${episode}`,
            method: "get",
        };
    },
    GET_LEAGUE_SCORING: (leagueId: string): ApiReturnType => {
        return {
            url: `scores/byLeague/${leagueId}`,
            method: "get",
        };
    },
    GET_LIVE_SCORE: (): ApiReturnType => {
        return {
            url: `scores/liveScore/get`,
            method: "get",
        };
    },
    GET_TOP_LIVE_SCORERS: (slug: string, episode: string): ApiReturnType => {
        return {
            url: `scores/top/${slug}/${episode}`,
            method: "get",
        };
    },
    GET_TEAM_LIVE: (leagueId: string, episode: string): ApiReturnType => {
        return {
            url: `scores/liveTeam/${leagueId}/${episode}`,
            method: "get",
        };
    },
    GET_TOP_SCORERS: (slug: string, episode: string): ApiReturnType => {
        return {
            url: `scores/topScorers/${slug}/${episode}`,
            method: "get",
        };
    },
    SAVE_LIVE_SCORES_COMMENT: (id: string): ApiReturnType => {
        return {
            url: `scores/live/saveComment/${id}`,
            method: "post",
        };
    },
    SAVE_LIVE_SCORES_LIKE: (id: string): ApiReturnType => {
        return {
            url: `scores/live/saveLike/${id}`,
            method: "post",
        };
    },
};

export default scoresRoutes;
