import React, { useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import DeleteIcon from "@mui/icons-material/Delete";

import { RootState } from "../../store";
import { LiveScore, LiveScoreTeams } from "../../models/LiveScores";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { CardStyled, NeutralScores, Small } from "../atoms";
import CastImage from "../atoms/CastImage";
import AreYouSureModal from "../atoms/AreYouSureModal";
import LiveScoreActions from "./LiveScoreActions";
import LiveScoreComments from "./LiveScoreComments";
import LiveScoreOwnedLeagues from "./LiveScoreOwnedLeagues";
import ScoreValue from "../atoms/ScoreValue";

interface LiveScoreItemProps {
    score: LiveScore;
    leagueId: string;
    liveScoreTeams: LiveScoreTeams;
}

const LiveScoreItem: React.FC<LiveScoreItemProps> = ({
    score,
    leagueId,
    liveScoreTeams,
}) => {
    const admin = useSelector((state: RootState) => state.user.admin);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [commentOpen, setCommentOpen] = useState<boolean>(false);

    const deleteArticleRequest = useApi(
        apiRoutes.DELETE_LIVE_SCORE(score.id.toString()),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                toast.success(message);
                setShowDeleteModal(false);
                setDeleting(false);
            },
            onFailure: () => {
                toast.error("Unable to delete live score");
                setDeleting(false);
                setShowDeleteModal(false);
            },
        }
    );

    const deleteScore = () => {
        setDeleting(true);
        deleteArticleRequest.request();
    };

    return (
        <>
            <CardStyled key={score.id} className="mb-5 zoom-in">
                <Card.Header className="text-left">
                    <Row className="align-items-center">
                        <Col xs="10">
                            <Row className="align-items-center ml-1">
                                <CastImage src={score.pic} />
                                <div className="ml-2">
                                    <div>{` ${score.name}`}</div>
                                    <Small>
                                        <Moment fromNow>
                                            {score.created_at}
                                        </Moment>
                                    </Small>
                                </div>
                            </Row>
                        </Col>
                        {admin && (
                            <Col xs="2" className="text-right">
                                <DeleteIcon
                                    onClick={() => {
                                        setShowDeleteModal(true);
                                    }}
                                />
                            </Col>
                        )}
                    </Row>
                </Card.Header>
                <Card.Body className="flex-column text-left">
                    <Row className="align-items-center">
                        <Col lg="9" xs="8">
                            {score.description}
                        </Col>
                        <Col lg="3" xs="4" className="text-right">
                            {score.limit ? (
                                <>
                                    <NeutralScores>{`+0`}</NeutralScores>
                                    <br />
                                    <Badge pill variant="secondary">
                                        Limit Reached
                                    </Badge>
                                </>
                            ) : (
                                <ScoreValue score={score.score} />
                            )}
                        </Col>
                    </Row>
                    {score.type === "score" && (
                        <LiveScoreOwnedLeagues
                            liveScoreTeams={liveScoreTeams}
                            castId={score.cast_id}
                        />
                    )}
                    <LiveScoreActions
                        setCommentOpen={setCommentOpen}
                        liveScore={score}
                        leagueId={leagueId}
                    />
                    <LiveScoreComments
                        open={commentOpen}
                        setOpen={setCommentOpen}
                        id={score.id}
                    />
                </Card.Body>
            </CardStyled>
            <AreYouSureModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                body={`Are you sure you want to delete <b>${score.name}</b>: <b>${score.description}</b>?`}
                onSuccess={() => deleteScore()}
                submitting={deleting}
                submitText={"Deleting"}
            />
        </>
    );
};

export default LiveScoreItem;
