import React from "react";

import Avatar from "@mui/material/Avatar";

import { imageUrl } from "../../config";

interface CastImageProps {
    src: string;
}

const CastImage: React.FC<CastImageProps> = (props) => {
    return (
        <Avatar
            style={{ width: 75, height: 75 }}
            src={`${imageUrl}${props.src}`}
        />
    );
};

export default CastImage;
