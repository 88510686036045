export interface ThemeState {
    primary: string;
    secondary: string;
    secondaryHeader: string;
    border: string;
    textColor: string;
    selected: string;
    inputColor: string;
}

export const SET_THEME = "SET_THEME";

export interface SetTheme {
    type: typeof SET_THEME;
    payload: ThemeState;
}

export type ThemeActionTypes = SetTheme;
