const configs = {
    leagues: {
        playerLimit: ["No Limit", 1, 2, 3, 4, 5],
        playersPerTeam: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        playersPerWeek: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        startersPerTeam: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        rookieLimit: ["No Limit", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        genderLimit: ["No Limit", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        draftTypes: ["Weekly Redraft", "Full Draft", "Team", "Weekly Budget"],
    },
    image_formats: [
        undefined,
        null,
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
        "image/webp",
    ],
    file_size: 5000000,
};

export default configs;
