import { Avatar } from "@mui/material";
import React from "react";
import { Row } from "react-bootstrap";
import { useTheme } from "styled-components";
import { imageUrl } from "../../config";
import { Cast } from "../../models/Cast";
import { ErrorText, Strikethrough } from "../atoms";

interface AnalyticsListItemProps {
    player: Cast;
    rightText: string;
    currentEpisode: number;
}

const AnalyticsListItem: React.FC<AnalyticsListItemProps> = ({
    player,
    currentEpisode,
    rightText,
}) => {
    const theme = useTheme();

    return (
        <tr>
            <td className="pl-4">
                <Row className="align-items-center">
                    <Avatar
                        style={{
                            height: 60,
                            width: 60,
                        }}
                        src={`${imageUrl}${player.pic}`}
                    />
                    <div className="pl-2">
                        {player.episode_eliminated !== null &&
                        player.episode_eliminated < currentEpisode ? (
                            <ErrorText>
                                <Strikethrough>{`${
                                    player.first_name && player.first_name
                                } ${
                                    player.last_name && player.last_name
                                }`}</Strikethrough>{" "}
                                (Eliminated)
                            </ErrorText>
                        ) : (
                            <span
                                style={{
                                    color: theme.textColor,
                                }}
                            >{`${player.first_name && player.first_name} ${
                                player.last_name && player.last_name
                            }`}</span>
                        )}
                    </div>
                </Row>
            </td>
            <td className="pl-5">
                <b>{rightText}</b>
            </td>
        </tr>
    );
};

export default AnalyticsListItem;
