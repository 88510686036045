import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface ListItemEmptyProps {
    content: React.ReactNode;
}

const ListItemEmpty: React.FC<ListItemEmptyProps> = ({ content }) => {
    return (
        <ListItem className="text-center" disableRipple button divider>
            <ListItemText primary={content} />
        </ListItem>
    );
};

export default ListItemEmpty;
