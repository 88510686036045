import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { VerticalPadding } from ".";
import { RootState } from "../../store";

interface GoogleAdProps {
    slot: string;
    height: number;
    fixed?: boolean;
    hideDislike?: boolean;
}

const GoogleAd: React.FC<GoogleAdProps> = ({
    slot,
    height,
    fixed,
    hideDislike,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);

    useEffect(() => {
        if (!subscription) {
            //@ts-ignore
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, [subscription]);

    if (subscription) {
        // Get the element you want to remove
        var element = document.getElementById("google-ads-script");
        // Get the parent and remove the element since it is a child of the parent
        if (element) {
            element.parentNode.removeChild(element);
        }
        return null;
    } else {
        if (fixed) {
            return (
                <div
                    style={{
                        position: "fixed",
                        zIndex: 100,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        marginBottom: -7,
                    }}
                    className="text-center w-100 justify-content-center"
                >
                    <ins
                        className="adsbygoogle"
                        style={{
                            display: "inline-block",
                            height: height,
                            width: "100%",
                            maxWidth: 1500,
                        }}
                        data-ad-client="ca-pub-3563612899201763"
                        data-ad-slot={slot}
                    ></ins>
                </div>
            );
        } else {
            return (
                <div className="text-center w-100 mb-3 justify-content-center">
                    <ins
                        className="adsbygoogle"
                        style={{
                            display: "inline-block",
                            height: height,
                            width: "100%",
                        }}
                        data-ad-client="ca-pub-3563612899201763"
                        data-ad-slot={slot}
                    ></ins>
                    <VerticalPadding />
                    {!hideDislike && (
                        <Link to={"/subscribe"}>
                            Dislike Ads? Subscribe Today!
                        </Link>
                    )}
                </div>
            );
        }
    }
};

export default GoogleAd;
