import { TimezoneOffset } from "../../models/TimezoneOffset";

export type TimezoneOffsetState = TimezoneOffset;

export const UPDATE_TIMEZONE_OFFSETS = "UPDATE_TIMEZONE_OFFSETS";

export interface UpdateTimezoneOffsets {
    type: typeof UPDATE_TIMEZONE_OFFSETS;
    payload: TimezoneOffset;
}

export type TimezoneOffsetActionTypes = UpdateTimezoneOffsets;
