import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { League } from "../../models/Leagues";
import { RootState } from "../../store";
import useQuery from "../../hooks/useQuery";

import Input from "../atoms/Input";
import SubmitButton from "../atoms/SubmitButton";
import LoginModal from "../molecules/LoginModal";
import PageTemplate from "../templates/PageTemplate";

const validationSchema = yup.object({
    leagueCode: yup.string().required("League Code is required"),
});

interface Values {
    leagueCode: string;
}

const LeagueCodeForm: React.FC = () => {
    const query = useQuery();
    const code = query.get("code") ? query.get("code") : "";
    const codeUsed = query.get("code") ? true : false;
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const checkCode = useApi(apiRoutes.CHECK_LEAGUE_CODE(), {
        onSuccess: (response: League) => {
            history.push(`/myleagues/join/${response.id}`, {
                state: { league: response },
            });
            setSubmitting(false);
        },
        onFailure: () => setSubmitting(false),
        responseKey: "league",
    });
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);

    const initialValues: Values = {
        leagueCode: code,
    };

    useEffect(() => {
        if (codeUsed && code !== "" && user.isLoggedIn) {
            checkCode.request({ league_code: code });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, codeUsed, user.isLoggedIn]);

    return (
        <PageTemplate header="Join Private League">
            {user.isLoggedIn ? (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setSubmitting(true);
                        checkCode.request({ league_code: values.leagueCode });
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form
                            onSubmit={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            <Input
                                name="leagueCode"
                                label="League Code:"
                                placeholder="League Code"
                            />
                            <SubmitButton
                                title="Join League"
                                submitting={submitting}
                                submitText="Joining"
                            />{" "}
                            <Link to="/myleagues">
                                <Button variant="light">Cancel</Button>
                            </Link>
                        </Form>
                    )}
                </Formik>
            ) : (
                <div className="mx-auto">
                    <p className="text-center">
                        <Button onClick={() => setShowLogin(true)} block>
                            Login To Join League
                        </Button>{" "}
                    </p>
                </div>
            )}
            <LoginModal
                show={showLogin}
                onHide={() => setShowLogin(false)}
                message={"You must login to join league"}
            />
        </PageTemplate>
    );
};

export default LeagueCodeForm;
