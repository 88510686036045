import React, { useEffect, useState } from "react";
import { BsChatFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Col, Row } from "react-bootstrap";

import echo from "../../config/echo";
import { LiveScore } from "../../models/LiveScores";
import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { LikeButton } from "./styles/Chat.styled";

interface LiveScoreActionsProps {
    liveScore: LiveScore;
    leagueId: string;
    setCommentOpen: (value: boolean) => void;
}

const LiveScoreActions: React.FC<LiveScoreActionsProps> = ({
    liveScore,
    leagueId,
    setCommentOpen,
}) => {
    const user = useSelector((state: RootState) => state.user);
    const [likeCount, setLikeCount] = useState<number>(0);
    const [commentCount, setCommentCount] = useState<number>(0);
    const [score, setScore] = useState<LiveScore>(liveScore);
    const [liked, setLiked] = useState<boolean>(false);

    const getLiveScore = useApi(apiRoutes.GET_LIVE_SCORE(), {
        responseKey: "liveScore",
        onSuccess(response: LiveScore) {
            setScore(response);
            setUpCounts(response);
        },
    });
    const saveLike = useApi(
        apiRoutes.SAVE_LIVE_SCORES_LIKE(score.id.toString()),
        {
            onSuccess: (liveScore: LiveScore) => {
                setScore(liveScore);
                setUpCounts(liveScore);
            },
            onFailure: () => {
                toast.error("There was an issue saving like");
            },
            responseKey: "liveScore",
        }
    );

    const setUpCounts = (score: LiveScore) => {
        const likedScore = score.likers.filter((liker) => {
            return liker.user_id === user.id;
        });

        setLiked(likedScore.length > 0);
        setLikeCount(score.likers.length);
        setCommentCount(score.comment_count);
    };

    useEffect(() => {
        setUpCounts(liveScore);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveScore]);

    useEffect(() => {
        const liveScoreCommentChannel = echo.channel(
            `liveScoreComment.${liveScore.id}`
        );
        const liveScoreLikeChannel = echo.channel(
            `liveScoreLike.${liveScore.id}`
        );

        //register socket events
        liveScoreCommentChannel.stopListening(".LiveScoreComment");
        liveScoreLikeChannel.stopListening(".LiveScoreLiked");
        liveScoreCommentChannel.listen(
            ".LiveScoreComment",
            (data: { liveScore: LiveScore; userId: number }) => {
                if (liveScore.id === data.liveScore.id) {
                    getLiveScore.request({
                        id: data.liveScore.id,
                        league_id: leagueId === "default" ? 0 : leagueId,
                    });
                }
            }
        );
        liveScoreLikeChannel.listen(
            ".LiveScoreLiked",
            (data: { liveScore: LiveScore; userId: number }) => {
                if (liveScore.id === data.liveScore.id) {
                    getLiveScore.request({
                        id: data.liveScore.id,
                        league_id: leagueId === "default" ? 0 : leagueId,
                    });
                }
            }
        );

        return () => {
            liveScoreCommentChannel.stopListening(".LiveScoreComment");
            liveScoreLikeChannel.stopListening(".LiveScoreLiked");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveScore, leagueId]);

    return (
        <Row className="mt-3">
            <Col className="text-right">
                <BsChatFill
                    onClick={() => setCommentOpen(true)}
                    className="mr-1"
                    style={{ cursor: "pointer" }}
                    color={"grey"}
                    size={25}
                />
                <small className="mr-2">{commentCount}</small>
                <LikeButton
                    onClick={() => {
                        saveLike.request({ league_id: leagueId });
                    }}
                    color={liked ? "red" : "grey"}
                    size={30}
                />
                <small>{likeCount}</small>
            </Col>
        </Row>
    );
};
export default LiveScoreActions;
