import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { Question } from "../../models/Questions";
import { QuestionAnswerResponse } from "../../models/QuestionAnswers";
import { League } from "../../models/Leagues";
import { Show } from "../../models/Shows";
import { LeagueUser } from "../../models/LeagueUsers";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

import ChallengeQuestionList from "./ChallengeQuestionList";
import { AccordionStyled } from "../atoms";

interface WeeklyChallengeQuestionListProps {
    league: League;
    questions: Question[];
    responses: QuestionAnswerResponse[];
    show: Show;
    leagueUser: LeagueUser;
}

const WeeklyChallengeQuestionList: React.FC<
    WeeklyChallengeQuestionListProps
> = ({ league, questions, responses, show, leagueUser }) => {
    const subscription = useSelector((state: RootState) => state.subscription);
    const list = [];

    const calculateTotal = (episode: number): number => {
        let total = 0;

        questions.forEach((q) => {
            const userAnswers = responses.filter((sq) => {
                return (
                    sq.question_id === q.id &&
                    sq.episode === episode &&
                    sq.match > 0
                );
            });
            total += userAnswers.length * q.score;
            if (subscription && hasPremiumSub(subscription.name)) {
                const liveUserAnswers = responses.filter((sq) => {
                    return (
                        sq.question_id === q.id &&
                        sq.episode === episode &&
                        sq.live_match > 0
                    );
                });

                total += liveUserAnswers.length * q.score;
            }
        });

        return total;
    };

    for (let ep = show.current_episode; ep >= league.episode_started; ep--) {
        list.push(
            <AccordionStyled
                key={ep}
                defaultExpanded={ep === show.current_episode}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <h4 className="mb-0 d-flex justify-content-center align-items-center">
                        {show.type === "week" ? "Week" : "Episode"} {ep}{" "}
                        {/* <Fab
                        hidden={showFAB(item)}
                        onClick={() => {
                            setSelectedEpisode(item.episode);
                            setShowAddPlayer(true);
                        }}
                        className="ml-3"
                        size="small"
                        style={{
                            background: "#198754",
                            color: "#fff",
                        }}
                    >
                        <AddIcon />
                    </Fab> */}
                    </h4>
                    <div
                        style={{ flex: 1, fontSize: 18 }}
                        className="text-right pr-3"
                    >
                        {calculateTotal(ep)}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <ChallengeQuestionList
                        questions={questions}
                        responses={responses}
                        episode={ep}
                        leagueUser={leagueUser}
                        show={show}
                        league={league}
                    />
                </AccordionDetails>
            </AccordionStyled>
        );
    }

    return <>{list}</>;
};

export default WeeklyChallengeQuestionList;
