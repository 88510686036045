import React from "react";

import Form from "react-bootstrap/Form";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import { FormGroupWrapper } from ".";

interface RadioButtonsProps {
    buttons: Array<string>;
    label: string;
    name: string;
    value: string;
    formText?: string;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
    name,
    label,
    buttons,
    value,
    formText,
    setFieldValue,
}) => {
    return (
        <FormGroupWrapper>
            <Form.Label>{label}:</Form.Label>
            <RadioGroup row name="row-radio-buttons-group">
                {buttons.map((button) => (
                    <FormControlLabel
                        key={button}
                        checked={value === button}
                        value={button}
                        control={
                            <Radio
                                onClick={() => {
                                    setFieldValue(name, button);
                                }}
                            />
                        }
                        label={button}
                    />
                ))}
            </RadioGroup>
            {formText && <Form.Text muted>{formText}</Form.Text>}
        </FormGroupWrapper>
    );
};

export default RadioButtons;
