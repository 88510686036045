import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { UserModel } from "../../models/User";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { ModalStyled, Small } from "../atoms";
import Input from "../atoms/Input";
import SubmitButton from "../atoms/SubmitButton";

interface AddCoownerProps {
    teamId: string | number;
    teamName: string;
    setShowModal: (show: boolean) => void;
    showModal: boolean;
    onAddCoOwner: (coOwner: UserModel) => void;
}

interface SuccessProps {
    message: string;
    coOwner: UserModel;
}

const validationSchema = yup.object({
    userCode: yup.string().required("User Code is required"),
});

const AddCoowner: React.FC<AddCoownerProps> = ({
    teamId,
    teamName,
    setShowModal,
    showModal,
    onAddCoOwner,
}) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const addCoownerRequest = useApi(
        apiRoutes.ADD_CO_OWNER(teamId.toString()),
        {
            onSuccess,
            onFailure: () => setSubmitting(false),
        }
    );

    const initialValues = {
        userCode: "",
    };

    function onSuccess(response: SuccessProps) {
        setSubmitting(false);
        onAddCoOwner(response.coOwner);
        toast.success(response.message);
    }

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Co-Owner <Small>{teamName}</Small>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setSubmitting(true);
                        addCoownerRequest.request({
                            user_code: values.userCode,
                        });
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Input
                                name="userCode"
                                label="User Code:"
                                placeholder="User Code"
                            />
                            <p>
                                User codes can be found on the user preferences{" "}
                                <a target="_blank" href="/preferences">
                                    page
                                </a>
                                . Have the user you are trying to add give
                                theirs to you to put in the box above
                            </p>
                            <SubmitButton
                                title="Add Co-Owner"
                                submitText="Adding"
                                submitting={submitting}
                            />{" "}
                            <Button
                                variant="light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </ModalStyled>
    );
};

export default AddCoowner;
