import React from "react";

import PageTemplate from "../templates/PageTemplate";
import { Content, Title } from "./styles/PageNotFound.styled";

const PageNotFound: React.FC = () => {
    return (
        <PageTemplate header="404" headerSecondary="Page Not Found">
            <Title>404</Title>
            <Content>The page you're looking for could not be found.</Content>
        </PageTemplate>
    );
};

export default PageNotFound;
