import React, { ChangeEvent, useEffect, useState } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    Bar,
} from "recharts";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { useTheme } from "styled-components";

interface CastBreakdownChartProps {
    castId: number;
    type: string;
    leagueId: string | number;
}

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: "#fff", padding: 10 }}>
                <div style={{ color: "#000" }}>
                    <b>{`${
                        payload[0].payload.description ??
                        payload[0].payload.label
                    }`}</b>
                </div>
                <div style={{ color: "#000" }}>
                    Points: <b>{payload[0].value}</b>
                </div>
                <div style={{ color: "#000" }}>
                    % of Total:{" "}
                    <b>
                        {(
                            (payload[0].payload.Points /
                                payload[0].payload.total) *
                            100
                        ).toFixed(2)}
                        %
                    </b>
                </div>
            </div>
        );
    }

    return null;
};

const CastBreakdownChart: React.FC<CastBreakdownChartProps> = ({
    castId,
    type,
    leagueId,
}) => {
    const theme = useTheme();

    const [data, setData] = useState<any>();
    const [filterType, setFilterType] = useState<string>("episode");

    const getEpisodeBreakdown = useApi(
        apiRoutes.CAST_SCORES_BREAKDOWN(castId.toString(), "episode"),
        {
            responseKey: "breakdown",
            onSuccess: (
                response: {
                    id: number;
                    label: string;
                    total: number;
                    Points: number;
                }[]
            ) => {
                setData(response);
            },
        }
    );

    const getScoringBreakdown = useApi(
        apiRoutes.CAST_SCORES_BREAKDOWN(castId.toString(), "scoring"),
        {
            responseKey: "breakdown",
            onSuccess: (
                response: {
                    id: number;
                    label: string;
                    total: number;
                    Points: number;
                }[]
            ) => {
                setData(response);
            },
        }
    );

    useEffect(() => {
        if (filterType === "episode") {
            getEpisodeBreakdown.request({ league_id: leagueId });
        } else {
            getScoringBreakdown.request({ league_id: leagueId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, leagueId]);

    const onTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilterType(event.target.value);
    };

    return (
        <div>
            <h3 className="text-center mb-4">Scoring Analytics:</h3>
            <Col className="d-flex justify-content-center">
                <Form.Group>
                    <Form.Control
                        style={{
                            backgroundColor: theme.inputColor,
                            color: theme.textColor,
                        }}
                        as="select"
                        onChange={onTypeChange}
                        value={filterType}
                    >
                        <option value="episode">Scoring By {type}</option>
                        <option value="scoring">
                            Scoring By Scoring Field
                        </option>
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col>
                <ResponsiveContainer width="100%" height={600}>
                    {filterType === "episode" ? (
                        <LineChart
                            className="text-center"
                            width={600}
                            height={480}
                            data={data}
                        >
                            <Line
                                stroke="#007bff"
                                type="monotone"
                                dataKey="Points"
                            />
                            <CartesianGrid
                                stroke="#ccc"
                                strokeDasharray="5 5"
                            />
                            <XAxis
                                angle={45}
                                textAnchor="start"
                                height={80}
                                interval={0}
                                dataKey="label"
                            />
                            <YAxis dataKey="Points" width={30} />
                            <Tooltip
                                content={(props) => (
                                    <CustomTooltip
                                        active={props.active}
                                        payload={props.payload}
                                    />
                                )}
                                labelStyle={{ color: "#000" }}
                            />
                        </LineChart>
                    ) : (
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid
                                stroke="#ccc"
                                strokeDasharray="5 5"
                            />
                            <XAxis
                                dataKey={(props) =>
                                    props.label && props.label.length > 10
                                        ? `${props.label.substring(0, 10)}...`
                                        : props.label
                                }
                                interval={0}
                                angle={45}
                                height={80}
                                textAnchor="start"
                            />
                            <YAxis dataKey="Points" width={25} />
                            <Tooltip
                                content={(props) => (
                                    <CustomTooltip
                                        active={props.active}
                                        payload={props.payload}
                                    />
                                )}
                                labelStyle={{ color: "#000" }}
                            />
                            <Bar dataKey="Points" fill="#007bff" />
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </Col>
        </div>
    );
};

export default CastBreakdownChart;
