import React from "react";
import { useTheme } from "styled-components";

import { imageUrl } from "../../config";
import { League } from "../../models/Leagues";
import { Cast } from "../../models/Cast";

import Row from "react-bootstrap/Row";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import TableTeam from "../atoms/TableTeam";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { Badge, Col } from "react-bootstrap";

interface SelectTeamListItemProps {
    player: Cast;
    startingCast: Cast[];
    league: League;
    removePlayer: (id: number) => void;
    addPlayer: (player: Cast) => void;
}

const SelectTeamListItem: React.FC<SelectTeamListItemProps> = ({
    startingCast,
    player,
    league,
    removePlayer,
    addPlayer,
}) => {
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);

    const isStarting = (id: number) => {
        const isStarting = startingCast.filter((cast: Cast) => cast.id === id);
        if (isStarting.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <TableTeam className={isStarting(player.id) ? "selected" : ""}>
            <tbody>
                <tr>
                    <td className="pl-4">
                        <Row className="align-items-center">
                            <Avatar
                                style={{ height: 60, width: 60 }}
                                src={`${imageUrl}${player.pic}`}
                            />
                            <Col className="pl-2">
                                <div style={{ color: theme.textColor }}>{`${
                                    player.first_name && player.first_name
                                } ${
                                    player.last_name && player.last_name
                                }`}</div>
                                <div>
                                    {league?.rookie_limit !== undefined &&
                                        league.rookie_limit !== null &&
                                        league.rookie_limit !== 0 &&
                                        player?.rookie && (
                                            <Badge pill variant="dark">
                                                Rookie
                                            </Badge>
                                        )}
                                </div>
                            </Col>
                        </Row>
                        {subscription && hasPremiumSub(subscription.name) && (
                            <div
                                className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none"
                                style={{ fontSize: 14 }}
                            >
                                <br />
                                Owned <small>(site wide)</small>:{" "}
                                <b>{player.team_owned ?? "0.00"}%</b>
                                <br />
                                Owned <small>(this league)</small>:{" "}
                                <b>{player.team_owned_league ?? "0.00"}%</b>
                            </div>
                        )}
                    </td>
                    {subscription && hasPremiumSub(subscription.name) && (
                        <td
                            className="d-none d-md-table-cell"
                            style={{
                                color: theme.textColor,
                            }}
                        >
                            Owned <small>(site wide)</small>:{" "}
                            <b>{player.team_owned ?? "0.00"}%</b>
                            <br />
                            Owned <small>(this league)</small>:{" "}
                            <b>{player.team_owned_league ?? "0.00"}%</b>
                        </td>
                    )}
                    <td className="text-right" style={{ width: 80 }}>
                        <Fab
                            disabled={
                                !isStarting(player.id) &&
                                startingCast.length === league.players_per_team
                            }
                            onClick={() =>
                                isStarting(player.id)
                                    ? removePlayer(player.id)
                                    : addPlayer(player)
                            }
                            size="small"
                            style={
                                !isStarting(player.id) &&
                                startingCast.length === league.players_per_team
                                    ? {
                                          background: "#ccc",
                                      }
                                    : {
                                          background: isStarting(player.id)
                                              ? "#dc3545"
                                              : "#198754",
                                          color: "#fff",
                                      }
                            }
                        >
                            {isStarting(player.id) ? (
                                <RemoveIcon />
                            ) : (
                                <AddIcon />
                            )}
                        </Fab>
                    </td>
                </tr>
            </tbody>
        </TableTeam>
    );
};

export default SelectTeamListItem;
