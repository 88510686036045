import { Link } from "react-router-dom";
import styled from "styled-components";

import { colors } from "../../../config/colors";

export const FooterContainer = styled.footer`
    background-color: #1f1f1f;
    box-shadow: 0 0 1rem 0 #000;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 6em !important;
`;

export const FooterLink = styled(Link)`
    color: ${colors.white};
    padding: 0px 5px;
    &:hover {
        color: ${colors.white};
    }
`;
