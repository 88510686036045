import React from "react";
import { Helmet } from "react-helmet-async";

import { MainContainer, PageHeader, Small, Spinner, Well } from "../atoms";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";
import { PageHeaderSkeleton } from "../atoms/Skeletons";

interface PageProps {
    header: string | React.ReactElement;
    headerSecondary?: string;
    headerButtons?: React.ReactElement;
    loading?: boolean;
    skeleton?: React.ReactElement;
    bareContent?: boolean;
}

const PageTemplate: React.FC<PageProps> = ({
    header,
    headerSecondary,
    headerButtons,
    loading,
    skeleton,
    children,
    bareContent,
}) => {
    return (
        <MainContainer>
            {loading && skeleton && (
                <>
                    <PageHeaderSkeleton />
                    {bareContent ? skeleton : <Well>{skeleton}</Well>}
                </>
            )}
            {loading && !skeleton && <Spinner />}
            {!loading && (
                <>
                    <Helmet prioritizeSeoTags>
                        <title>{`${header} - RealTVFantasy`}</title>
                        <meta
                            name="description"
                            content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                        />
                        <meta
                            property="og:url"
                            content={`https://realtvfantasy.com${window.location.pathname}`}
                        />
                        <meta
                            property="og:title"
                            content="Play Reality TV Fantasy - RealTVFantasy"
                        />
                        <meta
                            property="og:description"
                            content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                        />
                        <meta
                            property="og:image"
                            content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                        />
                        <meta
                            property="twitter:card"
                            content="summary_large_image"
                        />
                        <meta
                            property="twitter:title"
                            content="Play Reality TV Fantasy - RealTVFantasy"
                        />
                        <meta
                            property="twitter:description"
                            content="Welcome to RealTVFantasy! We are your source for all your favorite reality tv show fantasy leagues!"
                        />
                        <meta
                            property="twitter:image"
                            content="https://api.realtvfantasy.com/assets/images/RealTVFantasy_icon.png"
                        />
                    </Helmet>
                    <PageHeader>
                        <h1>
                            {header}{" "}
                            {headerSecondary ? (
                                <Small>{headerSecondary}</Small>
                            ) : (
                                ""
                            )}
                            {headerButtons && (
                                <span className="ml-2">{headerButtons}</span>
                            )}
                        </h1>
                    </PageHeader>
                    {bareContent ? children : <Well>{children}</Well>}
                    {/* <Ad fixed /> */}
                    <GoogleAd fixed slot="7455698336" height={90} />
                </>
            )}
        </MainContainer>
    );
};

export default PageTemplate;
