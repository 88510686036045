export interface UserState {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    user_code: string;
    profile_pic: string;
    admin: boolean;
    log_scores: string;
    articles: string;
    fb_id: string;
    google_id: string;
    apple_id: string;
    remember_token: string;
    dark_mode: boolean;
    unsubscribe: boolean;
    unsubscribe_article: boolean;
    unsubscribe_scores: boolean;
    unsubscribe_shows: boolean;
    unsubscribe_lineups: boolean;
    updated_preferences: boolean;
    plus_league: number;
    premium_league: number;
    social_popup: boolean;
    sponsored: number;
    app_popup: boolean;
    free_trial_used: boolean;
    api_token: string;
    expo_notification_token: string;
    updated_at: Date;
    created_at: Date;
    stripe_id: string;
    card_brand: string;
    card_last_four: string;
    trial_ends_at: Date;
    isLoggedIn: boolean;
    instagram?: null | string;
    twitter?: null | string;
    tiktok?: null | string;
    podcast?: null | string;
}

export const LOG_IN_FULFILLED = "LOG_IN_FULFILLED";
export const LOG_IN_REJECTED = "LOG_IN_REJECTED";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_USER = "UPDATE_USER";

export interface Login {
    type: typeof LOG_IN_FULFILLED;
    payload: {
        user: UserState;
    };
}

export interface Logout {
    type: typeof LOG_OUT;
}

export interface UpdateUser {
    type: typeof UPDATE_USER;
    payload: {
        user: UserState;
    };
}

export type UserActionTypes = Login | Logout | UpdateUser;
