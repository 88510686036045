import React from "react";

import { imageUrl } from "../../config";
import { TradePlayerList } from "../../models/Trades";

import { CastImg } from "./styles/TradeTablePlayer.styled";

interface TradeTablePlayerProps {
    data: TradePlayerList;
}

const TradeTablePlayer: React.FC<TradeTablePlayerProps> = ({ data }) => {
    return (
        <div className="mb-2 mt-2">
            <CastImg width={60} src={`${imageUrl}${data.pic}`} />
            <br />
            <span>{`${data.first_name}${
                data.last_name ? ` ${data.last_name}` : ""
            }`}</span>
        </div>
    );
};

export default TradeTablePlayer;
