import React from "react";
import { ButtonProps } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import CircularProgress from "@mui/material/CircularProgress";

interface SubmitButtonProps extends ButtonProps {
    title: string;
    submitting?: boolean;
    disabled?: boolean;
    submitText?: string;
    variant?: string;
    className?: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
    title,
    submitting,
    disabled,
    submitText,
    variant,
    className,
}) => {
    const submit = submitText ? submitText : "Saving";

    return (
        <Button
            className={className}
            type="submit"
            variant={variant ? variant : "success"}
            disabled={submitting || disabled ? true : false}
        >
            {submitting ? (
                <>
                    {submit} <CircularProgress size={12} color="inherit" />
                </>
            ) : (
                title
            )}
        </Button>
    );
};

export default SubmitButton;
