import React from "react";

import { imageUrl } from "../../config";
import { TradePlayerList } from "../../models/Trades";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import { ModalStyled } from "../atoms";
import { AvatarItem } from "./styles/Trade.styled";

interface AcceptTradeProps {
    yourTeam: TradePlayerList[];
    theirTeam: TradePlayerList[];
    showModal: boolean;
    setShowModal: (visible: boolean) => void;
    onSuccess: () => void;
    submitting: boolean;
}

const AcceptTradeModal: React.FC<AcceptTradeProps> = ({
    yourTeam,
    theirTeam,
    showModal,
    setShowModal,
    onSuccess,
    submitting,
}) => {
    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
            style={{ zIndex: 10000000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Accept Trade</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3">
                    <p>
                        Are you sure you want to accept this trade?{" "}
                        {yourTeam &&
                            yourTeam
                                .map((e: TradePlayerList) => {
                                    return `${e.first_name && e.first_name} ${
                                        e.last_name && e.last_name
                                    }`;
                                })
                                .join(", ")}{" "}
                        for{" "}
                        {theirTeam &&
                            theirTeam
                                .map((e: TradePlayerList) => {
                                    return `${e.first_name && e.first_name} ${
                                        e.last_name && e.last_name
                                    }`;
                                })
                                .join(", ")}
                        ?
                    </p>
                    <Row style={{ justifyContent: "center", margin: 5 }}>
                        {yourTeam &&
                            yourTeam.map((player: TradePlayerList) => (
                                <AvatarItem
                                    key={`${player.league_user_id}-${player.cast_id}`}
                                    style={{ height: 60, width: 60 }}
                                    src={`${imageUrl}${player.pic}`}
                                />
                            ))}
                    </Row>
                    <SwapHorizIcon fontSize="large" />
                    <Row style={{ justifyContent: "center", margin: 5 }}>
                        {theirTeam &&
                            theirTeam.map((player: TradePlayerList) => (
                                <AvatarItem
                                    key={`${player.league_user_id}-${player.cast_id}`}
                                    style={{ height: 60, width: 60 }}
                                    src={`${imageUrl}${player.pic}`}
                                />
                            ))}
                    </Row>
                </div>
                <Button
                    variant="primary"
                    onClick={onSuccess}
                    disabled={submitting}
                >
                    {submitting ? "Accepting..." : "Yes"}
                </Button>{" "}
                <Button variant="light" onClick={() => setShowModal(false)}>
                    No
                </Button>
            </Modal.Body>
        </ModalStyled>
    );
};

export default AcceptTradeModal;
