import {
    NotificationState,
    NotificationActionTypes,
    SET_NOTIFICATIONS,
    DELETE_NOTIFICATION,
} from "./types";

export function setNotifications(
    notifications: NotificationState
): NotificationActionTypes {
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications,
    };
}

export function deleteNotification(id: string): NotificationActionTypes {
    return {
        type: DELETE_NOTIFICATION,
        payload: id,
    };
}
