import React from "react";

import { ChallengeQuestion } from "../../models/ChallengeQuestions";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Input from "../atoms/Input";
import { SectionHeader } from "../atoms";

interface QuestionsFormProps {
    questions: ChallengeQuestion[];
    show: string;
    edit?: boolean;
}

const QuestionsForm: React.FC<QuestionsFormProps> = ({
    questions,
    show,
    edit,
}) => {
    return (
        <>
            <SectionHeader>Challenge Questions</SectionHeader>
            {!edit && (
                <Form.Text className="mb-1" muted>
                    This is the default scoring for {show} leagues. If you like
                    the scoring you can keep it, or you can customize it to your
                    liking. If you don't want a question asked, set it to 0. If
                    you don't want any questions, turn off the "Include
                    Challenge Questions" toggle.
                </Form.Text>
            )}
            <Row>
                {questions.map((question, index) => {
                    return (
                        <Col key={question.id} md={6}>
                            <Row className="p-1 mr-1 ml-1">
                                <Col xs={6}>{question.description}:</Col>
                                <Col className="text-right" xs={6}>
                                    <Input
                                        name={`questions.${index}.score`}
                                        type="number"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default QuestionsForm;
