import React, { ReactNode } from "react";

import useQuery from "../../hooks/useQuery";

import Toaster from "../atoms/Toaster";
import Footer from "../molecules/Footer";
import Navigation from "../molecules/Navigation";
import { Content, PageContainer } from "./styles/AppWrapper.styled";

interface PageProps {
    showLogin: boolean;
    setShowLogin: (showLogin: boolean) => void;
    children?: ReactNode;
}

const AppWrapper: React.FC<PageProps> = ({
    showLogin,
    setShowLogin,
    children,
}) => {
    const query = useQuery();
    const template = query.get("template") ? query.get("template") : "normal";

    return (
        <PageContainer>
            {template === "normal" && (
                <Navigation setShowLogin={setShowLogin} />
            )}
            <Toaster />
            <Content>{children}</Content>
            {template === "normal" && <Footer />}
        </PageContainer>
    );
};

export default AppWrapper;
