import styled from "styled-components";

export const ListItem = styled.div`
    border: 1px solid #c5c5c5;
    background: ${(props) => props.theme.primary};
    font-weight: 400px;
    color: ${(props) => props.theme.textColor};
    z-index: 100000;
    padding: 10px;
    text-align: left;
    cursor: move;
`;
