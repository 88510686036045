import React from "react";

import { CommentList } from "../../models/Comments";

import Message from "./Message";

interface MessagesProps {
    messages: CommentList;
}

const MessagesList: React.FC<MessagesProps> = ({ messages }) => {
    return (
        <>
            {messages.map((message) => {
                return <Message key={message.id} message={message} />;
            })}
        </>
    );
};

export default MessagesList;
