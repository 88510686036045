import React from "react";
import { Link } from "react-router-dom";

import PageTemplate from "../templates/PageTemplate";

const About: React.FC = () => {
    return (
        <PageTemplate header="About">
            <h2>About RealTVFantasy</h2>
            <p>
                RealTVFantasy allows you to better enjoy watching some of your
                favorite reality shows by playing fantasy games with your
                friends.
            </p>
            <p>
                You can create a league from any of the shows that we provide on
                the site. Each show has it's own scoring categories, that you
                can customize to your own liking, where you can gain points if
                contestants on your team do those things. The scores will be
                updated for shows 24-48 hours after the end of episodes.
            </p>
            <p>
                We are constantly looking to improve, so if you have any
                suggestions for new shows, scoring that we aren't accounting
                for, or for just improvements to the site, please{" "}
                <Link to="/contact">contact us</Link>.
            </p>
        </PageTemplate>
    );
};

export default About;
