//@ts-nocheck
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga";
import { HelmetProvider, Helmet } from "react-helmet-async";
import CacheBuster from "react-cache-buster";
import { useDetectAdBlock } from "adblock-detect-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Spinner } from "react-bootstrap";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";

import { googleKey } from "./config";
import PublicRouter from "./router/Public";
import { RootState } from "./store";
import { setTheme } from "./store/theme/actions";
import { ThemeState } from "./store/theme/types";
import packageJson from "../package.json";
import apiRoutes from "./services/apiRoutes";
import useApi from "./hooks/useApi";

import darkTheme from "./themes/dark";
import lightTheme from "./themes/light";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.scss";
// import { ModalStyled } from "./components/atoms";

declare module "styled-components" {
    interface DefaultTheme extends ThemeState {}
}

ReactGA.initialize("UA-90814923-1");
ReactGA.pageview(window.location.pathname + window.location.search);
const stripePromise = loadStripe("pk_live_JXvGw24TYgeKpwgDYqcb2NiH");
// const stripePromise = loadStripe("pk_test_f4SoVmNqgDDLG3GTwEq3XM7W");

function App() {
    const isProduction = process.env.NODE_ENV === "production";

    const adBlockDetected = useDetectAdBlock();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const theme = useSelector((state: RootState) => state.theme);
    const subscription = useSelector((state: RootState) => state.subscription);
    // const [showModal, setShowModal] = useState<boolean>(false);

    const saveAdBlock = useApi(apiRoutes.SAVE_AD_BLOCK(user.id), {
        onSuccess: () => {},
    });

    useEffect(() => {
        dispatch(setTheme(user.dark_mode ? darkTheme : lightTheme));
        document.body.style.backgroundColor = theme.primary;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.dark_mode]);

    useEffect(() => {
        if (subscription) {
            // Get the element you want to remove
            var element = document.querySelectorAll(".google-ads-script");
            // Get the parent and remove the element since it is a child of the parent
            if (element) {
                element.forEach((ele) => {
                    ele.remove();
                });
            }
        }
    }, [subscription]);

    useEffect(() => {
        if (
            adBlockDetected &&
            user.isLoggedIn &&
            window.location.pathname !== "/subscribe" &&
            window.location.pathname !== "/terms" &&
            window.location.pathname !== "/about" &&
            window.location.pathname !== "/contact" &&
            window.location.pathname !== "/media" &&
            window.location.pathname !== "/preferences" &&
            window.location.pathname !== "/overview" &&
            window.location.pathname !== "/instabio"
        ) {
            // setShowModal(true);
            saveAdBlock.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adBlockDetected, user]);

    return (
        <CacheBuster
            currentVersion={packageJson.version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={true} //If true, the library writes verbose logs to console.
            loadingComponent={
                <div className="text-center" style={{ marginTop: "20%" }}>
                    <Spinner animation="grow" variant="primary" size="sm" />
                </div>
            } //If not pass, nothing appears at the time of new version check.
        >
            <HelmetProvider>
                <ThemeProvider theme={theme}>
                    <Elements stripe={stripePromise}>
                        {!subscription && (
                            <Helmet>
                                <script
                                    id="google-ads-script"
                                    async
                                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3563612899201763"
                                    crossorigin="anonymous"
                                ></script>
                            </Helmet>
                        )}
                        <GoogleOAuthProvider clientId={googleKey}>
                            <PublicRouter />
                        </GoogleOAuthProvider>
                        {/* <ModalStyled
                            show={showModal}
                            size="lg"
                            className="text-center"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title>
                                    It looks like you're using an ad-blocker
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="mb-3">
                                    We rely on ads to bring you a free reality
                                    tv fantasy experience.
                                    <br />
                                    Please allow ads or go Ad Free by
                                    subscribing.
                                </div>
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        (window.location.href = "/subscribe")
                                    }
                                >
                                    Subscribe
                                </Button>{" "}
                                <Button
                                    variant="light"
                                    onClick={() => window.location.reload()}
                                >
                                    I Have Disabled Ad Blocker
                                </Button>
                            </Modal.Body>
                        </ModalStyled> */}
                    </Elements>
                </ThemeProvider>
            </HelmetProvider>
        </CacheBuster>
    );
}

export default App;
