import {
    DELETE_NOTIFICATION,
    NotificationActionTypes,
    SET_NOTIFICATIONS,
} from "./types";

const INITIAL_STATE = [];

export function notificationReducer(
    state = INITIAL_STATE,
    action: NotificationActionTypes
) {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return action.payload;
        case DELETE_NOTIFICATION:
            const newNotifications = state.filter((notification) => {
                return notification.id.toString() !== action.payload;
            });

            return newNotifications;
        default:
            return state;
    }
}
