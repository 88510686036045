import React from "react";

interface TradeTableHeaderProps {
    type: "offer" | "propose";
}

const TradeTableHeader: React.FC<TradeTableHeaderProps> = ({ type }) => {
    return (
        <thead>
            <tr>
                <th style={{ width: "20%" }} className="text-center">
                    {type === "offer" ? "From" : "To"}
                </th>
                <th style={{ width: "60%" }} className="text-center">
                    Trade
                </th>
                <th style={{ width: "20%" }} className="text-center">
                    Action
                </th>
            </tr>
        </thead>
    );
};

export default TradeTableHeader;
