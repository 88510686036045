import { ApiReturnType } from "./";

const homeRoutes = {
    CONTACT: (): ApiReturnType => {
        return {
            url: `contact`,
            method: "post",
        };
    },
};

export default homeRoutes;
