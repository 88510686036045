import React, { ChangeEvent } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ImageUpload from "../atoms/ImageUpload";
import Input from "../atoms/Input";
import { CastItem, ShowFormFields } from "../pages/CreateCustomShow";
import Select from "../atoms/Select";
import Switch from "../atoms/Switch";

interface CastInfoFormProps {
    values: ShowFormFields;
    index: number;
    player: CastItem;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const gender = ["N/A", "F", "M"];

const CustomCastInfoForm: React.FC<CastInfoFormProps> = ({
    values,
    index,
    player,
    setFieldValue,
}) => {
    const onCastImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        field: string,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue(field, files[0]);
        }
    };

    return (
        <>
            <Row style={{ alignItems: "center" }} key={player.id}>
                <Col md={12}>
                    <ImageUpload
                        name={`cast.${index}.pic`}
                        label="Pic"
                        image={values.cast[index].pic}
                        handleChange={(e) =>
                            onCastImageChange(
                                e,
                                `cast.${index}.pic`,
                                setFieldValue
                            )
                        }
                        width={150}
                    />
                </Col>
                {values.genderLimits && (
                    <Col md={12}>
                        <Select
                            name={`cast.${index}.gender`}
                            label="Gender:"
                            items={gender}
                        />
                    </Col>
                )}
                {values.rookieLimits && (
                    <Col md={12}>
                        <Switch label="Rookie" name={`cast.${index}.rookie`} />
                    </Col>
                )}
                <Col xs={6} md={6}>
                    <Input
                        name={`cast.${index}.first_name`}
                        label="First Name:"
                        placeholder="First Name"
                    />
                </Col>
                <Col xs={6} md={6}>
                    <Input
                        name={`cast.${index}.last_name`}
                        label="Last Name:"
                        placeholder="Last Name"
                    />
                </Col>
            </Row>
        </>
    );
};

export default CustomCastInfoForm;
