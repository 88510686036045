import { ApiReturnType } from "./";

const scoringLogRoutes = {
    SCORING_LOG_BY_SHOW: (slug: string): ApiReturnType => {
        return {
            url: `scoringLog/byShow/${slug}`,
            method: "get",
        };
    },
};

export default scoringLogRoutes;
