import { ApiReturnType } from "./";

const leaderboardRoutes = {
    GET_LEADERBOARD_BY_LEAGUE: (leagueId: string): ApiReturnType => {
        return {
            url: `leaderboard/league/${leagueId}`,
            method: "get",
        };
    },
    GET_LEADERBOARD_RANK: (leagueUserId: string): ApiReturnType => {
        return {
            url: `leaderboard/rank/${leagueUserId}`,
            method: "get",
        };
    },
};

export default leaderboardRoutes;
