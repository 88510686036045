import React from "react";

import { TradeLeagueTeams } from "../../models/LeagueTeams";
import { imageUrl } from "../../config";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import { ModalStyled } from "../atoms";
import { AvatarItem } from "./styles/Trade.styled";

interface FinalizeTradeProps {
    yourTeam: TradeLeagueTeams[];
    theirTeam: TradeLeagueTeams[];
    showModal: boolean;
    setShowModal: (visible: boolean) => void;
    onSuccess: () => void;
    submitting?: boolean;
}

const FinalizeTradeModal: React.FC<FinalizeTradeProps> = ({
    showModal,
    setShowModal,
    yourTeam,
    theirTeam,
    onSuccess,
    submitting,
}) => {
    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
            style={{ zIndex: 10000000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Finalize Trade</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3">
                    <p>
                        Are you sure you want to trade{" "}
                        {yourTeam &&
                            yourTeam
                                .map((e: TradeLeagueTeams) => {
                                    return `${e.first_name && e.first_name} ${
                                        e.last_name && e.last_name
                                    }`;
                                })
                                .join(", ")}{" "}
                        for{" "}
                        {theirTeam &&
                            theirTeam
                                .map((e: TradeLeagueTeams) => {
                                    return `${e.first_name && e.first_name} ${
                                        e.last_name && e.last_name
                                    }`;
                                })
                                .join(", ")}
                        ?
                    </p>
                    <Row style={{ justifyContent: "center", margin: 5 }}>
                        {yourTeam &&
                            yourTeam.map((player: TradeLeagueTeams) => (
                                <AvatarItem
                                    key={`${player.id}${player.league_teams_id}`}
                                    style={{ height: 60, width: 60 }}
                                    src={`${imageUrl}${player.pic}`}
                                />
                            ))}
                    </Row>
                    <SwapHorizIcon fontSize="large" />
                    <Row style={{ justifyContent: "center", margin: 5 }}>
                        {theirTeam &&
                            theirTeam.map((player: TradeLeagueTeams) => (
                                <AvatarItem
                                    key={`${player.id}${player.league_teams_id}`}
                                    style={{ height: 60, width: 60 }}
                                    src={`${imageUrl}${player.pic}`}
                                />
                            ))}
                    </Row>
                </div>
                <Button
                    variant="primary"
                    onClick={onSuccess}
                    disabled={submitting}
                >
                    {submitting ? "Finalizing Trade..." : "Yes"}
                </Button>{" "}
                <Button variant="light" onClick={() => setShowModal(false)}>
                    No
                </Button>
            </Modal.Body>
        </ModalStyled>
    );
};

export default FinalizeTradeModal;
