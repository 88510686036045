import React from "react";

interface DateTimeDisplayProps {
    value: number;
    type: string;
    isDanger: boolean;
}

const DateTimeDisplay: React.FC<DateTimeDisplayProps> = ({
    value,
    type,
    isDanger,
}) => {
    return (
        <div className="text-center ml-1">
            <div>
                {type === "secs" && "and "}
                {value} {type}
                {type !== "secs" && ", "}
            </div>
        </div>
    );
};

export default DateTimeDisplay;
