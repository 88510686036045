import React from "react";

import { LoggedScoresReturn } from "../../models/ScoringLog";

import LogScoresSelect from "./LogScoresSelect";

interface LogScoresItemProps {
    index: number;
    cast: LoggedScoresReturn;
    category: string;
    values: any;
}

const LogScoresList: React.FC<LogScoresItemProps> = ({
    index,
    cast,
    values,
    category,
}) => {
    return (
        <>
            {cast.scores.map((score, scoreIndex) => {
                let displayCategory = false;
                if (category !== score.category) {
                    displayCategory = true;
                    category = score.category;
                }
                return (
                    <React.Fragment key={scoreIndex}>
                        {displayCategory && (
                            <>
                                <div className="pt-3 pb-3 text-center mb-0">
                                    <h3>{score.category}</h3>
                                </div>
                            </>
                        )}
                        <LogScoresSelect
                            key={`${cast.id}score${score.id}`}
                            name={`log.${index}.scores.${scoreIndex}.num_scored`}
                            label={score.description}
                            amount={score.amount}
                            colored={
                                values.log[index].scores[scoreIndex]
                                    .num_scored > 0
                            }
                            odd={scoreIndex % 2 === 1}
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default LogScoresList;
