import React from "react";

import { Score } from "../../models/Scores";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Input from "../atoms/Input";
import { SectionHeader } from "../atoms";

interface ScoringFormProps {
    scores: Score[];
    show: string;
    edit?: boolean;
}

const ScoringForm: React.FC<ScoringFormProps> = ({ scores, show, edit }) => {
    let category: string;
    let newCategory: boolean = true;

    return (
        <>
            <SectionHeader>Scoring</SectionHeader>
            {!edit && (
                <Form.Text className="mb-1" muted>
                    This is the default scoring for {show} leagues. If you like
                    the scoring you can keep it, or you can customize it to your
                    liking.
                </Form.Text>
            )}
            <Row>
                {scores.map((score, index) => {
                    if (category !== score.category) {
                        newCategory = true;
                        category = score.category;
                    } else {
                        newCategory = false;
                    }
                    return (
                        <React.Fragment key={index}>
                            {newCategory && (
                                <h4 className="col-sm-12 pt-4 pb-3">
                                    {category}:
                                </h4>
                            )}
                            <Col key={score.id} md={6}>
                                <Row className="p-1 mr-1 ml-1">
                                    <Col xs={6}>{score.description}:</Col>
                                    <Col className="text-right" xs={6}>
                                        <Input
                                            name={`scores.${index}.score`}
                                            type="number"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </React.Fragment>
                    );
                })}
            </Row>
        </>
    );
};

export default ScoringForm;
