import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { ShowList as ShowListModel } from "../../models/Shows";

import { CardSkeleton } from "../atoms/Skeletons";
import CardList from "../molecules/CardList";

interface CardListArray {
    header: string;
    image?: string;
    buttonText: string;
    buttonUrl: string;
    description?: string;
}

const ShowList: React.FC = () => {
    const getShows = useApi(apiRoutes.GET_SHOWS_BY_TYPE("active"), {
        onSuccess: (shows: ShowListModel[]) => {
            const list = shows.map((show: ShowListModel) => {
                return {
                    header: show.show,
                    description:
                        show.description.length >= 250
                            ? show.description
                                  .replace(/(<([^>]+)>)/gi, "")
                                  .substring(0, 250) + "..."
                            : show.description.replace(/(<([^>]+)>)/gi, ""),
                    buttonText: "Play Now",
                    buttonUrl: `/shows/view/${show.slug}`,
                };
            });
            setShowList(list);
        },
        responseKey: "shows",
    });
    const [showList, setShowList] = useState<CardListArray[] | []>([]);

    useEffect(() => {
        getShows.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return getShows.loading ? (
        <Row>
            <Col sm={6} md={6} lg={4}>
                <CardSkeleton />
            </Col>
            <Col sm={6} md={6} lg={4}>
                <CardSkeleton />
            </Col>
        </Row>
    ) : (
        <CardList sm={6} md={6} lg={4} list={showList} />
    );
};

export default ShowList;
