import React from "react";

import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";

import { useCountdown } from "../../hooks/useCountdown";

import DateTimeDisplay from "../atoms/DateTimeDisplay";

interface ShowCounterProps {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    live?: boolean;
}

const ShowCounter: React.FC<ShowCounterProps> = ({
    days,
    hours,
    minutes,
    seconds,
    live,
}) => {
    return (
        <Alert variant="warning">
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                {live ? (
                    <div>Live scoring starts in </div>
                ) : (
                    <div>Lineups lock in </div>
                )}
                <DateTimeDisplay
                    value={days}
                    type={"days"}
                    isDanger={days <= 3}
                />
                <DateTimeDisplay
                    value={hours}
                    type={"hours"}
                    isDanger={false}
                />
                <DateTimeDisplay
                    value={minutes}
                    type={"mins"}
                    isDanger={false}
                />
                <DateTimeDisplay
                    value={seconds}
                    type={"secs"}
                    isDanger={false}
                />
            </Row>
        </Alert>
    );
};

interface CountdownTimerProps {
    targetDate: number | null;
    live?: boolean;
    episode?: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
    targetDate,
    live,
    episode,
}) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (episode) {
        if (episode === 1) {
            if (days + hours + minutes + seconds <= 0) {
                return null;
            } else {
                return (
                    <ShowCounter
                        live={live}
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                    />
                );
            }
        } else {
            if (days + hours + minutes + seconds <= 0) {
                return null;
            } else {
                if (days <= 5) {
                    return (
                        <ShowCounter
                            live={live}
                            days={days}
                            hours={hours}
                            minutes={minutes}
                            seconds={seconds}
                        />
                    );
                } else {
                    return null;
                }
            }
        }
    } else {
        if (days + hours + minutes + seconds <= 0) {
            return null;
        } else {
            return (
                <ShowCounter
                    live={live}
                    days={days}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                />
            );
        }
    }
};

export default CountdownTimer;
