import React from "react";

import { ScoringLog } from "../../models/Scores";

import { Col, Row, Card } from "react-bootstrap";

import CastImage from "../atoms/CastImage";
import ScoreValue from "../atoms/ScoreValue";
import { CardStyled } from "../atoms";

interface ScoreCardProps {
    header: string;
    headerImage?: string;
    scores: ScoringLog[];
    type: "cast" | "episode";
}

const ScoreCard: React.FC<ScoreCardProps> = ({
    header,
    headerImage,
    scores,
    type,
}) => {
    scores = scores.sort((a, b) => {
        return a.num_scored * a.score - b.num_scored * b.score;
    });

    const calculateTotal = (): number => {
        let total = 0;
        scores.forEach((score) => {
            total += score.score * score.num_scored;
        });
        return total;
    };

    return (
        <CardStyled className="mb-5">
            <Card.Header className="text-left">
                <Row className="align-items-center ml-1">
                    {type === "episode" && <CastImage src={headerImage} />}
                    {type === "episode" && " "}
                    <b style={{ paddingLeft: 10, fontSize: 16 }}>{header}</b>
                </Row>
            </Card.Header>
            <Card.Body className="flex-column text-left">
                {scores.length > 0 ? (
                    <ul>
                        {scores.map((score, key) => {
                            return (
                                <li className="pb-1" key={key}>
                                    <Row>
                                        <Col xs={8}>
                                            {score.description} (
                                            {score.num_scored}
                                            ):
                                        </Col>
                                        <Col xs={4} className="text-right pr-5">
                                            <ScoreValue
                                                score={
                                                    score.num_scored *
                                                    score.score
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <>No Scores</>
                )}
            </Card.Body>
            <Card.Footer className="font-weight-bold">
                <Row>
                    <Col className="text-left pl-4">
                        <b style={{ fontSize: 16 }}>Total:</b>
                    </Col>
                    <Col style={{ fontSize: 16 }} className="text-right pr-5">
                        {calculateTotal()}
                    </Col>
                </Row>
            </Card.Footer>
        </CardStyled>
    );
};

export default ScoreCard;
