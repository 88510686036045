import React from "react";
import ReactFacebookLogin, {
    ReactFacebookLoginInfo,
} from "react-facebook-login";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import AppleSignin from "react-apple-signin-auth";
import AppleLogin from "react-apple-login";
import { useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";

import { baseUrl, facebookKey } from "../../config";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { setTheme } from "../../store/theme/actions";
import { login as LoginAction } from "../../store/user/actions";
import darkTheme from "../../themes/dark";
import lightTheme from "../../themes/light";
import { UserState } from "../../store/user/types";
import { SubscriptionState } from "../../store/subscription/types";
import { updateSubscription } from "../../store/subscription/actions";
import { toast } from "react-toastify";

interface LoginModalProps {
    show: boolean;
    onHide: () => void;
    message?: string;
}

const LoginModal: React.FC<LoginModalProps> = (props) => {
    const dispatch = useDispatch();

    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse: TokenResponse) =>
            responseGoogle(tokenResponse),
    });

    const login = useApi(apiRoutes.LOGIN(), {
        onSuccess: onLogin,
    });

    async function onLogin(response: {
        user: UserState;
        subscription: SubscriptionState;
    }) {
        await dispatch(LoginAction(response.user));
        await dispatch(
            setTheme(response.user.dark_mode ? darkTheme : lightTheme)
        );
        await dispatch(updateSubscription(response.subscription));
        props.onHide();
    }

    const responseFacebook = (response: ReactFacebookLoginInfo) => {
        if (response.accessToken !== undefined) {
            const firstName = response.name.split(" ").slice(0, -1).join(" ");
            const lastName = response.name.split(" ").slice(-1).join(" ");

            const postData = {
                login_type: "facebook",
                id: response.id,
                first_name: firstName,
                last_name: lastName,
                profile_pic: response.picture.data.url,
            };

            login.request(postData);
        } else {
            //throw error
            console.log("error");
        }
    };

    const responseGoogle = async (tokenResponse: TokenResponse) => {
        const userInfo = await fetch(
            "https://www.googleapis.com/userinfo/v2/me",
            {
                headers: {
                    Authorization: `Bearer ${tokenResponse.access_token}`,
                },
            }
        );
        userInfo
            .json()
            .then((data) => {
                if (data && data.id) {
                    login.request({
                        login_type: "google",
                        id: data.id,
                        first_name: data.given_name,
                        last_name: data.family_name,
                        email: data.email,
                        profile_pic: data.picture,
                    });
                }
            })
            .catch((err) => {
                toast.error("We're sorry there was an issue logging in");
            });
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Login
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-auto">
                {props.message && (
                    <div className="text-center">{props.message}</div>
                )}
                <div className="row mb-2" style={{ maxWidth: 300 }}>
                    <ReactFacebookLogin
                        appId={facebookKey}
                        autoLoad={false}
                        fields="id,name,first_name,last_name,email,picture.type(large)"
                        scope="public_profile"
                        callback={responseFacebook}
                        icon="fa-facebook"
                        textButton="Sign in with Facebook"
                        isMobile={false}
                    />
                </div>
                <div className="row mb-2" style={{ maxWidth: 300 }}>
                    <GoogleButton
                        style={{ width: "100%", fontSize: 18 }}
                        onClick={() => googleLogin()}
                    >
                        Sign in with Google
                    </GoogleButton>
                </div>
                <div className="row d-none" style={{ maxWidth: 300 }}>
                    <AppleLogin
                        clientId="com.realtvfantasy.loginservice"
                        redirectURI={baseUrl}
                        scope="email name"
                        responseType="id_token code"
                        responseMode="form_post"
                        usePopup={true}
                        callback={(response) => console.log(response)}
                    />
                    <AppleSignin
                        authOptions={{
                            clientId: "com.realtvfantasy.loginservice",
                            scope: "email name",
                            redirectURI: baseUrl,
                            state: "state",
                            nonce: "nonce",
                            usePopup: true,
                        }}
                        uiType="dark"
                        className="apple-auth-btn"
                        noDefaultStyle={false}
                        buttonExtraChildren="Sign in with Apple"
                        onSuccess={(response) =>
                            console.log("response", response)
                        }
                        onError={(error) => console.log("error", error)}
                        skipScript={false}
                        iconprop={{ style: { marginTop: "10px" } }}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
