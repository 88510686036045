import { useState } from "react";
import { toast } from "react-toastify";
import http from "../services";
import { ApiReturnType } from "../services/apiRoutes";

interface OptionProps {
    onFailure?: (message: string) => void;
    onSuccess?: (response: any) => void;
    responseKey?: string;
}

// custom hook for performing request
const useApi = (
    route: ApiReturnType,
    { onSuccess, onFailure, responseKey }: OptionProps
) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const request = async function (options = {}, headers = {}) {
        try {
            setLoading(true);
            const response = await http[route.method](
                route.url,
                options,
                headers
            );
            if (response.status === 200) {
                if (response.data.success) {
                    if (responseKey) {
                        setData(response.data.data[responseKey]);
                        if (onSuccess) {
                            onSuccess(response.data.data[responseKey]);
                        }
                    } else {
                        setData(response.data.data);
                        if (onSuccess) {
                            onSuccess(response.data.data);
                        }
                    }
                } else {
                    setError(response.data.message);
                    if (onFailure) {
                        onFailure(response.data.message);
                    }
                }
            }
        } catch (err) {
            toast.error(err);
            if (onFailure) {
                onFailure(err);
            }
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { loading, data, error, request };
};

export default useApi;
