import React from "react";

import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";

interface StatsTableHeaderProps {
    league: League;
    categories: string[];
    leagueUser: LeaguesLeagueUser;
}

const StatsTableHeader: React.FC<StatsTableHeaderProps> = ({
    league,
    categories,
    leagueUser,
}) => {
    return (
        <thead>
            <tr>
                <th>Player</th>
                {league.draft_type === "Full Draft" && leagueUser && (
                    <>
                        {league.drafted ? (
                            <th className="text-center d-none d-md-table-cell">
                                Team
                            </th>
                        ) : (
                            <th className="text-center d-none d-md-table-cell">
                                Wishlist
                            </th>
                        )}
                    </>
                )}
                {categories &&
                    categories.map((category: string) => {
                        return (
                            <th
                                key={category}
                                className="text-center d-none d-md-table-cell"
                            >
                                {category}
                            </th>
                        );
                    })}
                <th className="text-center">Total</th>
            </tr>
        </thead>
    );
};

export default StatsTableHeader;
