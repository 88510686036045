import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { LeaguesLeagueUser } from "../../models/LeagueUsers";

import {
    AvatarItem,
    Header,
    StarterWrapper,
    Wrapper,
} from "./styles/DraftOrder.styled";
import { imageUrl } from "../../config";

interface DraftOrderProps {
    order: LeaguesLeagueUser[];
}

const DraftOrder: React.FC<DraftOrderProps> = ({ order }) => {
    if (order && order.length > 0) {
        return (
            <Header>
                <StarterWrapper>
                    {order.map((user, index) => {
                        return (
                            <Wrapper
                                className={index === 0 ? "order_start" : ""}
                                key={index}
                            >
                                <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="tooltip-player">
                                            {user.team_name}
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        <AvatarItem
                                            src={
                                                user.profile_pic &&
                                                user.profile_pic.includes(
                                                    "https"
                                                )
                                                    ? user.profile_pic
                                                    : `${imageUrl}${user.profile_pic}`
                                            }
                                        />
                                        <div>
                                            {user.team_name.length > 9
                                                ? `${user.team_name.substr(
                                                      0,
                                                      9
                                                  )}...`
                                                : user.team_name}
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </Wrapper>
                        );
                    })}
                </StarterWrapper>
            </Header>
        );
    } else {
        return null;
    }
};

export default DraftOrder;
