import React from "react";

import Spinner from "react-bootstrap/Spinner";

interface SpinnerProps {
    variant?: string;
    size?: "sm";
}

const SpinnerDefault: React.FC<SpinnerProps> = ({
    variant = "primary",
    size,
}) => {
    return (
        <div className="text-center mt-5">
            <Spinner animation={"grow"} variant={variant} size={size} />
        </div>
    );
};

export default SpinnerDefault;
