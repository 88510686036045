import React from "react";
import Moment from "react-moment";
import AutoLink from "@uiw/react-auto-link";

import { Chat } from "../../models/Chat";

import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
    LikeButton,
    SentMessage,
    SentMessageWrapper,
    SentUser,
} from "./styles/Chat.styled";

interface SentChatProps {
    message: Chat;
    likeChat: (id: number, liked: number) => void;
}

const SentChat: React.FC<SentChatProps> = ({ message, likeChat }) => {
    const likers = message.likers.map((liker) => {
        return `${liker.user.first_name ?? liker.user.first_name} ${
            liker.user.last_name ? liker.user.last_name.substr(0, 1) : ""
        }`;
    });

    return (
        <SentMessageWrapper key={message.id}>
            <Col>
                <SentUser>
                    {message.user_id > 0 &&
                        `${message.first_name && message.first_name} ${
                            message.last_name &&
                            message.last_name.substring(0, 1)
                        }`}
                    <small>
                        {message.team_name ? ` (${message.team_name}) ` : ` `}
                        <Moment format="MMMM D, YYYY [at] h:mm a">
                            {message.created_at}
                        </Moment>
                    </small>
                </SentUser>
                <SentMessage key={message.id}>
                    <AutoLink target="_blank" text={message.message} />
                </SentMessage>
            </Col>
            <OverlayTrigger
                trigger={["hover", "focus"]}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    likers && likers.length > 0 ? (
                        <Tooltip style={{ zIndex: 150000 }} id="button-tooltip">
                            {likers.join(", ")}
                        </Tooltip>
                    ) : (
                        <Tooltip style={{ zIndex: 150000 }} id="button-tooltip">
                            No Likes
                        </Tooltip>
                    )
                }
            >
                <LikeButton
                    onClick={() =>
                        message.liked
                            ? likeChat(message.id, 0)
                            : likeChat(message.id, 1)
                    }
                    size={20}
                    color={message.liked ? "red" : "grey"}
                />
            </OverlayTrigger>
            <small>{message.likers.length}</small>
        </SentMessageWrapper>
    );
};

export default SentChat;
