import React, { ChangeEvent, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import Form from "react-bootstrap/Form";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import Input from "../atoms/Input";
import ImageUpload from "../atoms/ImageUpload";
import PageTemplate from "../templates/PageTemplate";
import SubmitButton from "../atoms/SubmitButton";

const validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    URL: yup.string().required("URL is required"),
    image: yup.mixed().required("Image is required"),
});

const AddInstagramBioForm: React.FC = () => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const history = useHistory();
    const saveInstaBioRequest = useApi(apiRoutes.SAVE_INSTA_BIO(), {
        onSuccess: (response: string) => {
            toast.success(response);
            history.push(`/instabio`);
            setSubmitting(false);
        },
        onFailure: () => {
            setSubmitting(false);
        },
        responseKey: "message",
    });

    const initialValues = {
        title: "",
        URL: "",
        image: undefined,
    };

    const onImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue("image", files[0]);
        }
    };

    return (
        <PageTemplate header="Add Instagram Bio">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setSubmitting(true);
                    const headers = { "content-type": "multipart/form-data" };
                    const data = new FormData();
                    data.append("image", values.image);
                    data.append("title", values.title);
                    data.append("url", values.URL);
                    saveInstaBioRequest.request(data, headers);
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <Form
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <Input
                            name="title"
                            label="Title:"
                            placeholder="Title"
                        />
                        <Input name="URL" label="URL:" placeholder="URL" />
                        <ImageUpload
                            name="image"
                            label="Instagram Image"
                            image={values.image}
                            handleChange={(e) =>
                                onImageChange(e, setFieldValue)
                            }
                        />
                        <SubmitButton
                            title="Save Instagram Bio"
                            submitText="Saving"
                            submitting={submitting}
                        />
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default AddInstagramBioForm;
