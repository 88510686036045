import { ApiReturnType } from "./";

const subscriptionRoutes = {
    CANCEL_SUBSCRIPTION: (type?: string): ApiReturnType => {
        return {
            url: `subscriptions/cancel/${type ? type : "default"}`,
            method: "post",
        };
    },
    GET_SUBSCRIPTION: (): ApiReturnType => {
        return {
            url: `subscriptions`,
            method: "get",
        };
    },
    SAVE_AD_BLOCK: (userId: number): ApiReturnType => {
        return {
            url: `subscriptions/saveAdBlock/${userId}`,
            method: "post",
        };
    },
};

export default subscriptionRoutes;
