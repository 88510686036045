import React, { ChangeEventHandler, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";

import useApi from "../../hooks/useApi";
import { LeagueUser, LeagueUserList } from "../../models/LeagueUsers";
import apiRoutes from "../../services/apiRoutes";
import { RootState } from "../../store";

import Form from "react-bootstrap/Form";

interface ScoringBreakdownProps {
    leagueId?: string;
    showId: number;
    onChange: ChangeEventHandler;
}

const ScoringBreakdown: React.FC<ScoringBreakdownProps> = ({
    leagueId = "0",
    onChange,
    showId,
}) => {
    const theme = useTheme();
    const [leagueUsers, setLeagueUsers] = useState<LeagueUserList>();
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

    const getLeagueUsers = useApi(apiRoutes.GET_LEAGUE_USERS_BY_SHOW(showId), {
        onSuccess,
        responseKey: "leagueUsers",
    });

    useEffect(() => {
        if (isLoggedIn) {
            getLeagueUsers.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    function onSuccess(response: LeagueUserList) {
        setLeagueUsers(response);
    }

    const determineLeagueName = (leagueUser: LeagueUser): string => {
        if (leagueUser.league_id.toString() === "0") {
            return `(Public Weekly Redraft League)`;
        } else if (leagueUser.league_id.toString() === "team") {
            return `(Public Team League)`;
        } else if (leagueUser.league_id.toString() === "budget") {
            return `(Public Weekly Budget League)`;
        } else {
            return `(${leagueUser.name ? leagueUser.name : ""})`;
        }
    };

    return (
        <div className="mb-5">
            <h6>View Scoring Breakdown As:</h6>
            <Form.Group>
                <Form.Control
                    style={{
                        backgroundColor: theme.inputColor,
                        color: theme.textColor,
                    }}
                    as="select"
                    onChange={onChange}
                    value={leagueId}
                >
                    <option value="default">Default Scoring</option>
                    {leagueUsers &&
                        leagueUsers.map((leagueUser) => {
                            return (
                                <option
                                    key={leagueUser.id}
                                    value={leagueUser.league_id}
                                >
                                    {leagueUser.team_name}{" "}
                                    {determineLeagueName(leagueUser)}
                                </option>
                            );
                        })}
                </Form.Control>
            </Form.Group>
        </div>
    );
};

export default ScoringBreakdown;
