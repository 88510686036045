import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { imageUrl } from "../../config";

import Carousel from "react-bootstrap/Carousel";

//TODO: need to add in offsets to fit into the slider perfectly

const Slider: React.FC = () => {
    const shows = useSelector((state: RootState) => state.shows);
    return (
        <Carousel>
            {shows.map((show) => {
                return (
                    <Carousel.Item key={show.id}>
                        <img
                            className="d-block w-100"
                            style={
                                show.id === 132
                                    ? { transform: `translateY(-5%)` }
                                    : { transform: `translateY(-25%)` }
                            }
                            src={`${imageUrl}${show.img}`}
                            alt={show.show}
                        />
                    </Carousel.Item>
                );
            })}
        </Carousel>
    );
};

export default Slider;
