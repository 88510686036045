import React from "react";

import Button from "react-bootstrap/Button";

import { FullShow } from "../../models/Shows";

interface AdminShowHeaderProps {
    type: string;
    show: FullShow;
    deactivating: boolean;
    activating: boolean;
    onDeactivate: () => void;
    onActivate: () => void;
}

const AdminShowHeader: React.FC<AdminShowHeaderProps> = ({
    type,
    show,
    deactivating,
    activating,
    onDeactivate,
    onActivate,
}) => {
    return (
        <>
            {type === "edit" && show && (
                <>
                    {show.active ? (
                        <Button
                            disabled={deactivating}
                            onClick={() => {
                                onDeactivate();
                            }}
                        >
                            {deactivating
                                ? "Deactivating..."
                                : "Deactivate Show"}
                        </Button>
                    ) : (
                        <Button
                            disabled={activating}
                            onClick={() => {
                                onActivate();
                            }}
                        >
                            {activating ? "Activating..." : "Activate Show"}
                        </Button>
                    )}
                </>
            )}
        </>
    );
};

export default AdminShowHeader;
