import React from "react";

import BC from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

import Card from "./Card";

interface CardListArray {
    header: string;
    image?: string;
    buttonText: string;
    buttonUrl: string;
    description?: string;
}

interface CardListProps {
    list: CardListArray[];
    md: number;
    sm?: number;
    lg?: number;
}

const CardList: React.FC<CardListProps> = ({ list, md, sm, lg }) => {
    let chunk = 12 / md;
    if (lg) {
        chunk = 12 / lg;
    }
    const cards = array_chunk(list, chunk);

    function array_chunk(arr: CardListArray[], size: number) {
        var result = [];
        for (var i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, size + i));
        }

        return result;
    }

    const displayEmptyCards = (card: CardListArray[]) => {
        let emptyCards = [];

        for (let i = 0; i < 3 - card.length; i++) {
            emptyCards.push(
                <BC
                    className="border-transparent bg-transparent border-0"
                    key={`empty-${i}`}
                ></BC>
            );
        }

        return emptyCards;
    };

    return (
        <>
            {cards.map((card, i) => (
                <Row key={i}>
                    {card.map((col, key) => (
                        <Card
                            key={key}
                            header={col.header}
                            buttonText={col.buttonText}
                            buttonUrl={col.buttonUrl}
                            description={col.description}
                            image={col.image}
                            md={md}
                            sm={sm ? sm : 12}
                            lg={lg ? lg : 12}
                        />
                    ))}
                    {displayEmptyCards(card)}
                </Row>
            ))}
        </>
    );
};

export default CardList;
