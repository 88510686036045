import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";
import InfoIcon from "@mui/icons-material/Info";

import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";
import { Show } from "../../models/Shows";

import {
    MainContainer,
    PageHeader,
    Small,
    Spinner,
    TabsWrapper,
    Well,
} from "../atoms";
import {
    ArticleTextSkeleton,
    LargeImageSkeleton,
    PageHeaderSkeleton,
    TableSkeleton,
} from "../atoms/Skeletons";
import { ShowImage } from "./styles/LeagueTemplate.styled";
// import Ad from "../atoms/Ad";
import { imageUrl } from "../../config";
import GoogleAd from "../atoms/GoogleAd";
import LeagueInfo from "../molecules/LeagueInfo";
import CommissionerActions from "../organisms/CommissionerActions";

interface LeagueProps {
    header: string;
    image: string;
    league: League;
    show: Show;
    headerSecondary?: string;
    loading?: boolean;
    skeleton?: boolean;
    leagueActions: React.ReactElement;
    warnings?: string[];
    reloadLeague: () => void;
    commissionerNote: string;
    setCommissionerNote: (note: string) => void;
    leagueUser: LeaguesLeagueUser;
}

const LeagueTemplate: React.FC<LeagueProps> = ({
    header,
    headerSecondary,
    image,
    league,
    show,
    loading,
    skeleton,
    leagueActions,
    children,
    warnings,
    reloadLeague,
    commissionerNote,
    setCommissionerNote,
    leagueUser,
}) => {
    const [showInfo, setShowInfo] = useState<boolean>(false);

    return (
        <MainContainer>
            {loading && skeleton && (
                <>
                    <PageHeaderSkeleton />
                    <LargeImageSkeleton />
                    <TabsWrapper>
                        <Tabs>
                            <Tab
                                tabClassName="col-3 text-center"
                                eventKey="overview"
                                title="Overview"
                            >
                                <Well>
                                    <TableSkeleton />
                                    <TableSkeleton />
                                </Well>
                            </Tab>
                            <Tab
                                tabClassName="col-3 text-center"
                                eventKey="stats"
                                title="Stats"
                            >
                                <Well>
                                    <TableSkeleton />
                                    <TableSkeleton />
                                </Well>
                            </Tab>
                            <Tab
                                tabClassName="col-3 text-center"
                                eventKey="scoring"
                                title="Scoring"
                            >
                                <Well>
                                    <ArticleTextSkeleton />
                                    <ArticleTextSkeleton />
                                </Well>
                            </Tab>
                            <Tab
                                tabClassName="col-3 text-center"
                                eventKey="log"
                                title="Log"
                            >
                                <Well>
                                    <ArticleTextSkeleton />
                                    <ArticleTextSkeleton />
                                </Well>
                            </Tab>
                        </Tabs>
                    </TabsWrapper>
                </>
            )}
            {loading && !skeleton && <Spinner />}
            {!loading && (
                <>
                    <div className="mt-5">
                        {warnings &&
                            warnings.length > 0 &&
                            warnings.map((warning: string, index: number) => {
                                return (
                                    <Alert key={index} variant="warning">
                                        {warning}
                                    </Alert>
                                );
                            })}
                    </div>
                    <div className="mb-3">
                        {league && show && (
                            <Helmet prioritizeSeoTags>
                                <title>{`${header} - RealTVFantasy`}</title>
                                <meta
                                    name="description"
                                    content={`${show.show} Fantasy League`}
                                />
                                <meta
                                    property="og:url"
                                    content={`https://realtvfantasy.com${window.location.pathname}`}
                                />
                                <meta property="og:type" content="website" />
                                <meta
                                    property="og:title"
                                    content={`${league.name} - RealTVFantasy`}
                                />
                                <meta
                                    property="og:description"
                                    content={`${show.show} Fantasy League`}
                                />
                                <meta
                                    property="og:image"
                                    content={`${imageUrl}${show.img}`}
                                />
                                <meta
                                    property="twitter:card"
                                    content="summary_large_image"
                                />
                                <meta
                                    property="twitter:title"
                                    content={`${league.name} - RealTVFantasy`}
                                />
                                <meta
                                    property="twitter:description"
                                    content={`${show.show} Fantasy League`}
                                />
                                <meta
                                    property="twitter:image"
                                    content={`${imageUrl}${show.img}`}
                                />
                            </Helmet>
                        )}
                        <PageHeader>
                            <h1>
                                {header}{" "}
                                {headerSecondary ? (
                                    <Small>{headerSecondary}</Small>
                                ) : (
                                    ""
                                )}{" "}
                                {league && (
                                    <InfoIcon
                                        style={{ cursor: "pointer" }}
                                        fontSize={"large"}
                                        onClick={() => setShowInfo(true)}
                                    />
                                )}
                            </h1>
                            {leagueUser &&
                                league &&
                                show &&
                                leagueUser.commissioner &&
                                (league.active || league.custom) && (
                                    <CommissionerActions
                                        commissionerNote={commissionerNote}
                                        setCommissionerNote={
                                            setCommissionerNote
                                        }
                                        league={league}
                                        show={show}
                                        reloadLeague={reloadLeague}
                                    />
                                )}
                        </PageHeader>
                        {show && (
                            <ShowImage
                                showId={show.id}
                                className="mb-3"
                                src={
                                    league.img
                                        ? `${imageUrl}${league.img
                                              .replace(/ /g, "%20")
                                              .replace(/\(/g, "%28")
                                              .replace(/\)/g, "%29")}`
                                        : image
                                }
                            />
                        )}
                        {/* <Ad /> */}
                        <GoogleAd slot="7237766115" height={90} />
                        {leagueActions}
                    </div>
                    {children}
                    {/* <Ad fixed /> */}
                    <GoogleAd fixed slot="7455698336" height={90} />
                </>
            )}
            {league && show && (
                <LeagueInfo
                    show={show}
                    showInfo={showInfo}
                    setShowInfo={setShowInfo}
                    league={league}
                />
            )}
        </MainContainer>
    );
};

export default LeagueTemplate;
