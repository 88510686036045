import React from "react";

import Col from "react-bootstrap/Col";

import PageTemplate from "../templates/PageTemplate";

const Media: React.FC = () => {
    const links = [
        {
            url: "https://podcasts.apple.com/us/podcast/reality-served-real/id1624555757?i=1000595065484",
            title: "Reality Served Real Podcast: Special Guest Peter Brune",
            date: "January 17, 2023",
        },
        {
            url: "https://gameskeys.net/underrated-sports-games-to-play-on-ios/",
            title: "Underrated Sports Games To Play On IOS",
            date: "Oct 26, 2020",
        },
        {
            url: "https://podcasts.apple.com/us/podcast/ep-2-peter-brune-from-realtvfantasy/id1523895208?i=1000487892319",
            title: "Explain Yourself Podcast: EP 2: Peter Brune from RealTVFantasy",
            date: "Aug 12, 2020",
        },
        {
            url: "https://espn1170am.com/join-the-mtv-the-challenge-total-madness-fantasy-league/",
            title: "ESPN 1170: MTV The Challenge: Total Madness Fantasy League",
            date: "Mar 27, 2020",
        },
    ];

    return (
        <PageTemplate header="In the Media">
            <Col md={12}>
                <ul>
                    {links.map((link, key) => {
                        return (
                            <li key={key}>
                                <a
                                    href={link.url}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {link.date} - {link.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </Col>
        </PageTemplate>
    );
};

export default Media;
