import styled from "styled-components";
import { colors } from "../../../config/colors";

export const ProfileWrapper = styled.div`
    background-color: ${(props) => props.theme.secondary};
    height: 100%;
    text-align: center;
    position: relative;
    overflow: auto;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
`;

export const ProfileHeader = styled.div`
    background-color: ${colors.borderDark};
    width: 100%;
    height: 100px;
    position: absolute;
`;
