import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import { Article } from "../../models/Articles";
import { Show } from "../../models/Shows";
import { UserModel } from "../../models/User";
import apiRoutes from "../../services/apiRoutes";

import { ArticleListSkeleton } from "../atoms/Skeletons";
import PaginationMenu from "../molecules/Pagination";
import ArticleListItem from "../organisms/ArticleListItem";
import { Well } from "../atoms";
import PageTemplate from "../templates/PageTemplate";

interface ArticleProps {
    type: "all" | "author" | "show";
    tab?: boolean;
}

interface ShowArticleResponse {
    articles: Article[];
    count: number;
    show: Show;
}

interface AllArticleResponse {
    articles: Article[];
    count: number;
}

interface AuthorArticleResponse {
    articles: Article[];
    author: UserModel;
    count: number;
}

interface ParamTypes {
    slug?: string;
    author_id?: string;
}

const Articles: React.FC<ArticleProps> = (props) => {
    const { type } = props;
    const { slug, author_id } = useParams<ParamTypes>();
    const query = useQuery();
    const limit = query.get("limit") ? parseInt(query.get("limit")) : 10;
    const [page, setPage] = useState<number>(
        query.get("page") ? parseInt(query.get("page")) : 1
    );
    const [articles, setArticles] = useState<Article[]>([]);
    const [numPages, setNumPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [header, setHeader] = useState<string>();
    const [secondaryHeader, setSecondaryHeader] = useState<string>();

    const allArticles = useApi(apiRoutes.GET_ARTICLES(), {
        onSuccess: onAllSuccess,
    });

    const showArticles = useApi(apiRoutes.GET_ARTICLES_BY_SHOW(slug), {
        onSuccess: onShowSuccess,
    });

    const authorArticles = useApi(apiRoutes.GET_ARTICLES_BY_AUTHOR(author_id), {
        onSuccess: onAuthorSuccess,
    });

    function onAllSuccess(response: AllArticleResponse) {
        setArticles(response.articles);
        setNumPages(Math.ceil(response.count / limit));
        setLoading(false);
    }

    function onShowSuccess(response: ShowArticleResponse) {
        setArticles(response.articles);
        setNumPages(Math.ceil(response.count / limit));
        setHeader(response.show.show);
        setSecondaryHeader("All Articles");
        setLoading(false);
    }

    function onAuthorSuccess(response: AuthorArticleResponse) {
        setLoading(false);
        setArticles(response.articles);
        setNumPages(Math.ceil(response.count / limit));
        setHeader(`${response.author.first_name}
        ${
            response.author.last_name !== null &&
            ` ${response.author.last_name.charAt(0)}'s`
        }
        Articles`);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        if (type === "show") {
            showArticles.request({ page, limit });
        } else if (type === "author") {
            authorArticles.request({ page, limit });
        } else {
            allArticles.request({ page, limit });
            setHeader("All Articles");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, page]);

    if (props.tab) {
        if (loading) {
            return (
                <Well>
                    <ArticleListSkeleton />
                    <ArticleListSkeleton />
                </Well>
            );
        }

        return (
            <Well>
                {articles.length > 0 &&
                    articles.map((article: Article) => {
                        return (
                            <ArticleListItem
                                key={article.id}
                                article={article}
                            />
                        );
                    })}
                {articles.length === 0 && (
                    <p>There are currently no articles</p>
                )}
                <PaginationMenu
                    setPage={setPage}
                    page={page}
                    numPages={numPages}
                />
            </Well>
        );
    } else {
        return (
            <PageTemplate
                header={header}
                headerSecondary={secondaryHeader}
                loading={loading}
                skeleton={
                    <>
                        <ArticleListSkeleton />
                        <ArticleListSkeleton />
                    </>
                }
            >
                {!loading && (
                    <>
                        {articles.length > 0 &&
                            articles.map((article: Article) => {
                                return (
                                    <ArticleListItem
                                        key={article.id}
                                        article={article}
                                    />
                                );
                            })}
                        {articles.length === 0 && (
                            <p>There are currently no articles</p>
                        )}
                        <PaginationMenu
                            setPage={setPage}
                            page={page}
                            numPages={numPages}
                        />
                    </>
                )}
            </PageTemplate>
        );
    }
};

export default Articles;
