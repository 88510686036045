import React from "react";
import { useTheme } from "styled-components";

interface RoundHeaderProps {
    round: number;
    colspan: number;
}

const RoundHeader: React.FC<RoundHeaderProps> = ({ round, colspan }) => {
    const theme = useTheme();

    return (
        <tr>
            <td
                style={{
                    backgroundColor: theme.primary,
                    fontWeight: "bold",
                }}
                className="text-center"
                colSpan={colspan}
            >
                Round {round}
            </td>
        </tr>
    );
};

export default RoundHeader;
