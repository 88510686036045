import React from "react";
import { Modal } from "react-bootstrap";

import { baseUrl } from "../../config";

import { ModalStyled } from "../atoms";

interface InviteFriendsProps {
    leagueCode: string;
    leagueName: string;
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const InviteFriends: React.FC<InviteFriendsProps> = ({
    leagueCode,
    leagueName,
    showModal,
    setShowModal,
}) => {
    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Invite Friends <small>{leagueName}</small>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Send your friends this link: (
                    <a
                        href={`${baseUrl}/myleagues/join?code=${leagueCode}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {baseUrl}/myleagues/join?code={leagueCode}
                    </a>
                    ). From there they can enter their team name and then click
                    "Join League"
                </p>
                <p>
                    Alternatively, send your friends the league code:{" "}
                    <b>{leagueCode}</b>. To join, they just need to login, go to
                    MyLeagues and click "Join Private League" and enter the
                    league code.
                </p>
            </Modal.Body>
        </ModalStyled>
    );
};

export default InviteFriends;
