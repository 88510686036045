import React from "react";

interface HTMLViewProps {
    content: string;
    length?: number;
}

const HTMLView: React.FC<HTMLViewProps> = ({ content, length }) => {
    //might need to consider whatever I'm doing on the current web to strip out images
    if (length) {
        content = `${content.substr(0, length)} ...`;
    }

    return (
        <div
            style={{ wordWrap: "break-word" }}
            dangerouslySetInnerHTML={{ __html: content }}
        />
    );
};

export default HTMLView;
