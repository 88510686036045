import React from "react";
import { useTheme } from "styled-components";
import Multiselect from "multiselect-react-dropdown";

import { Row, Col } from "react-bootstrap";

import { Cast } from "../../models/Cast";
import { LeagueChallengeQuestions } from "../../models/ChallengeQuestions";
import { QuestionAnswerCast } from "../../models/Questions";

import { FormGroupWrapper } from "../atoms";

interface LogAnswersProps {
    questions: LeagueChallengeQuestions[];
    cast: Cast[];
    answers: {
        question_id: number;
        cast: QuestionAnswerCast[];
    }[];
    setValues: (list: QuestionAnswerCast[], questionId: number) => void;
}

const LogAnswers: React.FC<LogAnswersProps> = ({
    questions,
    cast,
    answers,
    setValues,
}) => {
    const theme = useTheme();

    const onSelect = (
        selectedList: QuestionAnswerCast[],
        selectedItem: {
            id: number;
            name: string;
            eliminated: boolean;
            episode_eliminated: null | number;
        },
        questionId: number
    ) => {
        setValues(selectedList, questionId);
    };

    return (
        <>
            {questions &&
                questions.map((question, index) => {
                    const selectedAnswer = answers.find(
                        (a) => a.question_id === question.id
                    );
                    return (
                        <FormGroupWrapper
                            key={index}
                            style={
                                index % 2 === 1
                                    ? {
                                          backgroundColor: theme.primary,
                                      }
                                    : {}
                            }
                            className="pt-3 pb-3 mb-0 pr-2 pl-2 align-items-center"
                            as={Row}
                        >
                            <Col sm={6} xs={6}>
                                {question.description}
                            </Col>
                            <Col sm={6} xs={6}>
                                <Multiselect
                                    style={{
                                        searchBox: {
                                            color: theme.textColor,
                                        },
                                        inputField: {
                                            color: theme.textColor,
                                        },
                                        chips: {
                                            background: theme.secondary,
                                        },
                                        multiselectContainer: {
                                            color: theme.textColor,
                                            background: theme.primary,
                                        },
                                        optionContainer: {
                                            background: theme.primary,
                                        },
                                        option: {
                                            color: theme.textColor,
                                        },
                                    }}
                                    options={cast} // Options to display in the dropdown
                                    selectedValues={selectedAnswer.cast} // Preselected value to persist in dropdown
                                    onSelect={(
                                        selectedList: QuestionAnswerCast[],
                                        selectedItem: {
                                            id: number;
                                            name: string;
                                            eliminated: boolean;
                                            episode_eliminated: null | number;
                                        }
                                    ) =>
                                        onSelect(
                                            selectedList,
                                            selectedItem,
                                            question.id
                                        )
                                    } // Function will trigger on select event
                                    onRemove={(
                                        selectedList: QuestionAnswerCast[],
                                        removedItem: {
                                            id: number;
                                            name: string;
                                            eliminated: boolean;
                                            episode_eliminated: null | number;
                                        }
                                    ) =>
                                        onSelect(
                                            selectedList,
                                            removedItem,
                                            question.id
                                        )
                                    } // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                            </Col>
                        </FormGroupWrapper>
                    );
                })}
        </>
    );
};

export default LogAnswers;
