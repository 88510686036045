import React from "react";

import { PlaceHolder, Wrapper } from "./styles/StarterEmpty.styled";

const StarterEmpty: React.FC = () => {
    return (
        <Wrapper>
            <PlaceHolder />
        </Wrapper>
    );
};

export default StarterEmpty;
