import { ApiReturnType } from "./";

const tradeRoutes = {
    PROCESS_TRADE: (tradeId: string): ApiReturnType => {
        return {
            url: `trades/process/${tradeId}`,
            method: "post",
        };
    },
    PROPOSE_TRADE: (teamId: string): ApiReturnType => {
        return {
            url: `trades/propose/${teamId}`,
            method: "post",
        };
    },
    REMOVE_TRADE: (tradeId: string): ApiReturnType => {
        return {
            url: `trades/remove/${tradeId}`,
            method: "post",
        };
    },
    TRADE_LIST: (teamId: string): ApiReturnType => {
        return {
            url: `trades/${teamId}`,
            method: "get",
        };
    },
};

export default tradeRoutes;
