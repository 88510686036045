import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import moment from "moment";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

import apiRoutes from "../../services/apiRoutes";
import useApi from "../../hooks/useApi";
import useUnload from "../../hooks/useUnload";
import { FullShow } from "../../models/Shows";
import { Cast } from "../../models/Cast";
import { Score } from "../../models/Scores";
import configs from "../../config/configs";

import ShowInfoForm from "../organisms/ShowInfoForm";
import PageTemplate from "../templates/PageTemplate";
import SubmitButton from "../atoms/SubmitButton";
import AdminShowHeader from "../atoms/AdminShowHeader";
import CastInfoForm from "../organisms/CastInfoForm";
import ScoringInfoForm from "../organisms/ScoringInfoForm";
import AreYouSureModal from "../atoms/AreYouSureModal";
import ChallengeQuestionForm from "../organisms/ChallengeQuestionForm";
import { ChallengeQuestion } from "../../models/ChallengeQuestions";

export interface ShowFormFields {
    title: string;
    slug: string;
    image: any; //? don't really know this type yet
    day: string;
    hour: number;
    min: number;
    dateMonth: string;
    dateDay: number;
    dateYear: number | string;
    draftTypes: string[];
    genderLimits: boolean;
    rookieLimits: boolean;
    pacificOffset: number;
    mountainOffset: number;
    hawaiiOffset: number;
    alaskaOffset: number;
    featured: "Yes" | "No";
    channel: string;
    episodes: number;
    currentEpisode: number;
    description: string;
    overview: string;
    cast: Cast[];
    scoring: Score[];
    questions: ChallengeQuestion[];
}

const supportedFormats = configs.image_formats;

const validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    slug: yup.string().required("Slug is required"),
    image: yup
        .mixed()
        .required("Image is required")
        .test("fileType", "Unsupported File Format", function (value) {
            if (value === undefined || value === null) {
                return true;
            } else {
                return supportedFormats.includes(value.type);
            }
        }),
    day: yup.string().required("Day is required"),
    hour: yup.number().required("Hour is required"),
    min: yup.number().required("Minute is required"),
    dateMonth: yup.string().required("Month is required"),
    dateDay: yup.number().required("Day is required"),
    dateYear: yup.string().required("Year is required"),
    genderLimits: yup.boolean(),
    rookieLimits: yup.boolean(),
    draftTypes: yup
        .array()
        .of(yup.string())
        .required("Draft Types is required"),
    featured: yup.string(),
    channel: yup.string().required("Channel is required"),
    episodes: yup
        .number()
        .min(1, "Episodes must be greater than 0")
        .required("Episodes is required"),
    currentEpisode: yup
        .number()
        .min(1, "Currrent Episode must be greater than 0")
        .required("Current Episode is required"),
    description: yup.string().required("Description is required"),
    overview: yup.string().required("Overview is required"),
    pacificOffset: yup.number().nullable(),
    mountainOffset: yup.number().nullable(),
    hawaiiOffset: yup.number().nullable(),
    alaskaOffset: yup.number().nullable(),
    cast: yup.array().of(
        yup.object().shape({
            first_name: yup.string().required("First Name is required"),
            last_name: yup.string(),
            pic: yup
                .mixed()
                .test("fileType", "Unsupported File Format", function (value) {
                    if (value === undefined || value === null) {
                        return true;
                    } else {
                        return supportedFormats.includes(value.type);
                    }
                }),
            description: yup.string().nullable(),
            twitter: yup.string().nullable(),
            instagram: yup.string().nullable(),
        })
    ),
    scores: yup.array().of(
        yup.object().shape({
            description: yup.string().required("Description is required"),
            category: yup.string().required("Category is required"),
            score: yup.number().required("Score is required"),
            amount: yup.number().required("Amount is required"),
        })
    ),
    questions: yup.array().of(
        yup.object().shape({
            description: yup.string().required("Description is required"),
            type: yup.string().required("Type is required"),
            score: yup.number().required("Score is required"),
        })
    ),
});

const draftTypes = [
    "Weekly Redraft",
    "Team",
    "Full Draft",
    "Weekly Budget",
    "Confidence Pool",
    "Eliminated Confidence Pool",
];

interface ParamTypes {
    type: string;
    id?: string;
}

const AdminShow: React.FC = () => {
    const { id, type } = useParams<ParamTypes>();
    const history = useHistory();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [deactivating, setDeactivating] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [activating, setActivating] = useState<boolean>(false);
    const [show, setShow] = useState<FullShow>();

    useUnload((e) => {
        e.preventDefault();
        const exit = window.confirm(
            "Are you sure you want to leave? Changes you made will not be saved."
        );
        if (exit) window.close();
    });

    const [initialValues, setInitialValues] = useState<ShowFormFields>({
        title: "",
        slug: "",
        image: undefined,
        day: "Sunday",
        hour: 0,
        min: 0,
        genderLimits: true,
        rookieLimits: true,
        dateMonth: "January",
        dateDay: 1,
        dateYear: new Date().getFullYear(),
        draftTypes: draftTypes,
        featured: "No",
        channel: "",
        episodes: 10,
        currentEpisode: 1,
        pacificOffset: 0,
        mountainOffset: 0,
        hawaiiOffset: 0,
        alaskaOffset: 0,
        description: "",
        overview: "",
        cast: [],
        scoring: [],
        questions: [],
    });

    const getMonth = (value: string) => {
        switch (value) {
            case "1":
                return "January";
            case "2":
                return "February";
            case "3":
                return "March";
            case "4":
                return "April";
            case "5":
                return "May";
            case "6":
                return "June";
            case "7":
                return "July";
            case "8":
                return "August";
            case "9":
                return "September";
            case "10":
                return "October";
            case "11":
                return "November";
            case "12":
                return "December";
            default:
                return "January";
        }
    };

    const showRequest = useApi(apiRoutes.GET_SHOW(id), {
        responseKey: "show",
        onSuccess: (response: FullShow) => {
            setShow(response);
            setInitialValues({
                title: response.show,
                slug: response.slug,
                image: response.img,
                day: response.day_of_week,
                hour: response.show_time,
                min: response.minute,
                genderLimits: response.gender_limit,
                rookieLimits: response.rookie_limit,
                dateMonth: response.month
                    ? getMonth(response.month.toString())
                    : "",
                dateDay: response.day,
                dateYear: response.year,
                draftTypes: response.draft_types.split(","),
                featured: response.featured ? "Yes" : "No",
                pacificOffset: response.pacific_offset,
                mountainOffset: response.mountain_offset,
                hawaiiOffset: response.hawaii_offset,
                alaskaOffset: response.alaska_offset,
                channel: response.channel,
                episodes: response.episodes,
                currentEpisode: response.current_episode,
                description: response.description,
                overview: response.overview,
                cast: response.cast,
                scoring: response.scoring,
                questions: response.questions,
            });
        },
    });
    const saveShowRequest = useApi(apiRoutes.SAVE_SHOW(type, id), {
        responseKey: "message",
        onSuccess: (response: string) => {
            setSubmitting(false);
            toast.success(response);
            history.push("/admin/shows");
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });
    const deleteShowRequest = useApi(apiRoutes.DELETE_SHOW(id), {
        responseKey: "message",
        onSuccess: (message: string) => {
            setDeleting(false);
            toast.success(message);
            history.push("/admin/shows");
        },
        onFailure: () => {
            setDeleting(false);
            toast.error("There was an issue deleting the show");
        },
    });
    const deactivateShowRequest = useApi(apiRoutes.DEACTIVATE_SHOW(id), {
        responseKey: "message",
        onSuccess: (response: string) => {
            setSubmitting(false);
            toast.success(response);
            history.push(`/admin/shows`);
            setDeactivating(false);
        },
        onFailure: () => {
            setSubmitting(false);
            setDeactivating(false);
        },
    });
    const activateShowRequest = useApi(apiRoutes.ACTIVATE_SHOW(id), {
        responseKey: "message",
        onSuccess: (response: string) => {
            setSubmitting(false);
            toast.success(response);
            history.push(`/admin/shows`);
            setActivating(false);
        },
        onFailure: () => {
            setSubmitting(false);
            setActivating(false);
        },
    });

    useEffect(() => {
        if (type === "edit") {
            showRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const addCast = (
        cast: Cast[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const castCount = cast.length;
        const newCast = {
            id: `new-${castCount + 1}`,
            reference: `${moment().format("YYYYMMDDhhmmss")}new${castCount}`,
            first_name: "",
            last_name: "",
            pic: undefined,
            description: "",
            gender: "N/A",
            rookie: false,
            socials: {
                instagram: "",
                twitter: "",
            },
        };
        setFieldValue("cast", [newCast, ...cast]);
    };

    const addScoring = (
        scoring: Score[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const scoreCount = scoring.length;
        const newScoring = {
            id: `new-${scoreCount + 1}`,
            description: "",
            category: "",
            score: 0,
            amount: 10,
        };
        setFieldValue("scoring", [newScoring, ...scoring]);
    };

    const addQuestion = (
        questions: ChallengeQuestion[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const questionCount = questions.length;
        const newQuestion = {
            id: `new-${questionCount + 1}`,
            description: "",
            type: "Episode",
            score: 0,
        };
        setFieldValue("questions", [newQuestion, ...questions]);
    };

    return (
        <PageTemplate
            header={type === "edit" ? show && show.show : "Add New Show"}
            headerButtons={
                <AdminShowHeader
                    show={show}
                    type={type}
                    onActivate={() => {
                        setActivating(true);
                        activateShowRequest.request();
                    }}
                    onDeactivate={() => {
                        setDeactivating(true);
                        deactivateShowRequest.request();
                    }}
                    activating={activating}
                    deactivating={deactivating}
                />
            }
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    setSubmitting(true);
                    const headers = { "content-type": "multipart/form-data" };
                    const data = new FormData();
                    data.append("draft_types", values.draftTypes.join(","));
                    data.append(
                        "current_episode",
                        values.currentEpisode.toString()
                    );
                    data.append("channel", values.channel);
                    data.append("episodes", values.episodes.toString());
                    data.append("day_of_week", values.day);
                    data.append("month", values.dateMonth);
                    data.append("day", values.dateDay.toString());
                    data.append("year", values.dateYear.toString());
                    data.append("description", values.description);
                    data.append("featured", values.featured);
                    data.append(
                        "show_time",
                        values.hour === 0 ? "0" : values.hour.toString()
                    );
                    data.append(
                        "minute",
                        values.min.toString() === "0"
                            ? "00"
                            : values.min.toString()
                    );
                    data.append("overview", values.overview);
                    data.append("slug", values.slug);
                    data.append("show", values.title);
                    data.append("img", values.image);
                    data.append("cast", JSON.stringify(values.cast));
                    data.append("scoring", JSON.stringify(values.scoring));
                    data.append("questions", JSON.stringify(values.questions));
                    data.append("gender_limit", values.genderLimits.toString());
                    data.append("rookie_limit", values.rookieLimits.toString());
                    data.append(
                        "pacific_offset",
                        values.pacificOffset.toString()
                    );
                    data.append(
                        "mountain_offset",
                        values.mountainOffset.toString()
                    );
                    data.append(
                        "hawaii_offset",
                        values.hawaiiOffset.toString()
                    );
                    data.append(
                        "alaska_offset",
                        values.alaskaOffset.toString()
                    );
                    values.cast.forEach((cast) => {
                        if (typeof cast.pic === "object") {
                            if (cast.reference !== null) {
                                data.append(
                                    `castImg-${cast.reference}`,
                                    cast.pic
                                );
                            } else {
                                data.append(`castImgId-${cast.id}`, cast.pic);
                            }
                        }
                    });
                    saveShowRequest.request(data, headers);
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    submitCount,
                    errors,
                    isValid,
                }) => {
                    return (
                        <Form
                            onSubmit={(e) => {
                                if (!isValid) {
                                    alert("Please fix the errors");
                                }
                                handleSubmit(e);
                            }}
                        >
                            <h4>Show Info</h4>
                            <ShowInfoForm
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                            <h4>
                                Cast{" "}
                                <Fab
                                    style={{
                                        background: "#198754",
                                        color: "#fff",
                                    }}
                                    size="small"
                                    onClick={() =>
                                        addCast(values.cast, setFieldValue)
                                    }
                                >
                                    <AddIcon />
                                </Fab>
                            </h4>
                            {values.cast.length > 0 &&
                                values.cast.map((player, index) => {
                                    return (
                                        <CastInfoForm
                                            key={index}
                                            values={values}
                                            index={index}
                                            player={player}
                                            setFieldValue={setFieldValue}
                                        />
                                    );
                                })}
                            <h4>
                                Scoring{" "}
                                <Fab
                                    style={{
                                        background: "#198754",
                                        color: "#fff",
                                    }}
                                    size="small"
                                    onClick={() =>
                                        addScoring(
                                            values.scoring,
                                            setFieldValue
                                        )
                                    }
                                >
                                    <AddIcon />
                                </Fab>
                            </h4>
                            {values.scoring.length > 0 &&
                                values.scoring.map((score, index) => {
                                    return (
                                        <ScoringInfoForm
                                            key={index}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            index={index}
                                            score={score}
                                        />
                                    );
                                })}
                            <h4>
                                Challenge Questions{" "}
                                <Fab
                                    style={{
                                        background: "#198754",
                                        color: "#fff",
                                    }}
                                    size="small"
                                    onClick={() =>
                                        addQuestion(
                                            values.questions,
                                            setFieldValue
                                        )
                                    }
                                >
                                    <AddIcon />
                                </Fab>
                            </h4>
                            {values.questions.length > 0 &&
                                values.questions.map((question, index) => {
                                    return (
                                        <ChallengeQuestionForm
                                            key={index}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            index={index}
                                            question={question}
                                        />
                                    );
                                })}
                            <Row>
                                <SubmitButton
                                    className="ml-2"
                                    title="Save Show"
                                    submitting={submitting}
                                />
                                {id && (
                                    <Button
                                        className="ml-2"
                                        variant="danger"
                                        disabled={deleting}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowDelete(true);
                                        }}
                                    >
                                        {deleting
                                            ? "Deleting..."
                                            : "Delete Show"}
                                    </Button>
                                )}
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
            <AreYouSureModal
                showModal={showDelete}
                setShowModal={setShowDelete}
                body="Are you sure you want to delete this show?"
                submitting={deleting}
                submitText="Deleting"
                onSuccess={() => {
                    setDeleting(true);
                    deleteShowRequest.request();
                }}
            />
        </PageTemplate>
    );
};

export default AdminShow;
