import React, { useEffect, useState } from "react";

import { UserBadge } from "../../models/UserBadges";

import BadgeItem from "../atoms/BadgeItem";
import { Row } from "react-bootstrap";

interface BadgeListProps {
    userBadges: UserBadge[];
}

const BadgeList: React.FC<BadgeListProps> = ({ userBadges }) => {
    const [chunks, setChunks] = useState<Array<UserBadge[]>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const chunkSize = 2;
        const chunks = [];
        for (let i = 0; i < userBadges.length; i += chunkSize) {
            const chunk = userBadges.slice(i, i + chunkSize);
            chunks.push(chunk);
        }
        setChunks(chunks);
        setLoading(false);
    }, [userBadges]);

    return (
        <>
            {!loading && chunks.length === 0 ? (
                <div style={{ fontSize: 12 }} className="text-center">
                    No badges
                </div>
            ) : (
                <>
                    {chunks.map((chunk, index) => {
                        return (
                            <Row className="align-items-center" key={index}>
                                {chunk.map((userBadge) => {
                                    return (
                                        <BadgeItem
                                            key={userBadge.id}
                                            userBadge={userBadge}
                                        />
                                    );
                                })}
                            </Row>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default BadgeList;
