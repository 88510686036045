import styled from "styled-components";

export const BadgeItemWrapper = styled.div`
    flex: 1;
    position: relative;
    background-color: ${(props) => props.theme.primary};
    margin: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
`;

export const BadgeInfo = styled.div`
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 5px;
`;
