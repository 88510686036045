import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { FullShow } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { imageUrl } from "../../config";

import { TabsWrapper, Well } from "../atoms";
// import LeagueDifference from "../molecules/LeagueDifference";
import LeagueButtons from "../molecules/LeagueButtons";
import CurrentShowLeagues from "../molecules/CurrentShowLeagues";
import ShowLinkList from "../organisms/ShowLinkList";
import CastList from "../molecules/CastList";
import ShowScoring from "../molecules/ShowScoring";
import ShowTemplate from "../templates/ShowTemplate";
import GoogleAd from "../atoms/GoogleAd";

interface ParamTypes {
    slug: string;
}

const Show: React.FC = () => {
    const { slug } = useParams<ParamTypes>();
    const [show, setShow] = useState<FullShow>();
    const showRequest = useApi(apiRoutes.GET_SHOW_BY_SLUG(slug), {
        responseKey: "show",
        onSuccess: (response: FullShow) => setShow(response),
    });

    useEffect(() => {
        showRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ShowTemplate
            header={show ? show.show : ""}
            loading={showRequest.loading}
            slug={show ? show.slug : ""}
            image={show ? `${imageUrl}${show.img}` : ""}
            show={show}
            skeleton={true}
            sideCards={
                show && (
                    <>
                        {show.active && (
                            <Well className="d-none d-lg-block d-xl-block">
                                {/* <LeagueDifference /> */}
                                <LeagueButtons
                                    showId={show.id}
                                    slug={show.slug}
                                />
                                <CurrentShowLeagues showId={show.id} />
                            </Well>
                        )}
                        {/* <Ad /> */}
                        <GoogleAd slot="2424423133" height={280} />
                        <ShowLinkList
                            type="articles"
                            show={show}
                            viewAll={`/shows/article/${show.slug}`}
                        />
                        <ShowLinkList type="scores" show={show} />
                    </>
                )
            }
        >
            {show && (
                <TabsWrapper>
                    <Tabs>
                        <Tab
                            tabClassName="col-4 text-center"
                            eventKey="overview"
                            title="Overview"
                        >
                            <Well>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: show.description,
                                    }}
                                />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: show.overview,
                                    }}
                                />
                            </Well>
                        </Tab>
                        <Tab
                            tabClassName="col-4 text-center"
                            eventKey="scoring"
                            title="Scoring"
                        >
                            <ShowScoring
                                questions={show.questions}
                                scoring={show.scoring}
                            />
                        </Tab>
                        <Tab
                            tabClassName="col-4 text-center"
                            eventKey="cast"
                            title="Cast"
                        >
                            <CastList
                                currentEpisode={show.current_episode}
                                cast={show.cast}
                            />
                        </Tab>
                    </Tabs>
                </TabsWrapper>
            )}
        </ShowTemplate>
    );
};

export default Show;
