import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { imageUrl } from "../../config";
import { QuestionAnswerResponse } from "../../models/QuestionAnswers";

import { Avatar } from "@mui/material";

interface ChallengeQuestionRowProps {
    episode: number;
    userAnswer: QuestionAnswerResponse;
    score: number;
}

const ChallengeQuestionRow: React.FC<ChallengeQuestionRowProps> = ({
    userAnswer,
    score,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);

    return (
        <tr>
            <td className="text-center">
                {userAnswer.cast ? (
                    <>
                        <Avatar
                            style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                            }}
                            src={`${imageUrl}${userAnswer.cast.pic}`}
                        />
                        <span>{`${userAnswer.cast.first_name} ${userAnswer.cast.last_name}`}</span>
                    </>
                ) : (
                    `No Answer`
                )}
            </td>
            {subscription && hasPremiumSub(subscription.name) ? (
                <td className="text-center">
                    {userAnswer.live_match > 0 || userAnswer.match ? score : 0}
                </td>
            ) : (
                <td className="text-center">
                    {userAnswer.match > 0 ? score : 0}
                </td>
            )}
        </tr>
    );
};

export default ChallengeQuestionRow;
