import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";

import ProfileTemplate from "../templates/ProfileTemplate";
import BadgeList from "../molecules/BadgeList";
import { Spinner, VerticalPadding } from "../atoms";
import { UserBadge } from "../../models/UserBadges";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

const Profile: React.FC = () => {
    const user = useSelector((state: RootState) => state.user);
    const [userBadges, setUserBadges] = useState<UserBadge[]>([]);
    const [badgesLoading, setBadgesLoading] = useState<boolean>(true);

    const getUserBadges = useApi(apiRoutes.GET_USER_BADGES(), {
        responseKey: "userBadges",
        onSuccess: (response: UserBadge[]) => {
            setUserBadges(response);
            setBadgesLoading(false);
        },
    });

    useEffect(() => {
        getUserBadges.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ProfileTemplate header user={user} edit>
            <VerticalPadding />
            <h5 className="text-center font-weight-bold">Badges:</h5>
            {badgesLoading ? (
                <Spinner />
            ) : (
                <BadgeList userBadges={userBadges} />
            )}
        </ProfileTemplate>
    );
};

export default Profile;
