import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
// import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./rootReducer";
import { UserState } from "./user/types";
import { ThemeState } from "./theme/types";
import { ShowState } from "./shows/types";
import { NotificationState } from "./notifications/types";
import { SubscriptionState } from "./subscription/types";
import { TimezoneOffsetState } from "./timezoneOffsets/types";

const persistConfig = {
    key: "root",
    storage: storage,
};

//used for logging to test
// const loggerMiddleware = createLogger();

const pReducer = persistReducer(persistConfig, rootReducer);

export interface RootState {
    user: UserState;
    theme: ThemeState;
    shows: ShowState;
    notifications: NotificationState;
    subscription: SubscriptionState;
    timezoneOffsets: TimezoneOffsetState;
}

export const store = createStore(pReducer, applyMiddleware(thunkMiddleware));

export const persistor = persistStore(store);
