import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import useApi from "../../hooks/useApi";
import { CurrentLeaguesList } from "../../models/Leagues";
import apiRoutes from "../../services/apiRoutes";
import { RootState } from "../../store";

interface CurrentShowLeaguesProps {
    showId: number;
}

const CurrentShowLeagues: React.FC<CurrentShowLeaguesProps> = ({ showId }) => {
    const user = useSelector((state: RootState) => state.user);

    const [leagues, setLeagues] = useState<CurrentLeaguesList>();
    const getCurrentShowLeagues = useApi(apiRoutes.GET_CURRENT_LEAGUES(), {
        onSuccess: (response: CurrentLeaguesList) => {
            setLeagues(response);
        },
        responseKey: "leagues",
    });

    useEffect(() => {
        if (user.isLoggedIn) {
            getCurrentShowLeagues.request({ show_id: showId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showId]);

    //don't do anything if they aren't logged in
    if (!user.isLoggedIn) {
        return null;
    }

    return (
        <div className="mt-4">
            <h4>Current Leagues:</h4>
            {!getCurrentShowLeagues.loading && (
                <>
                    {leagues &&
                        leagues.length > 0 &&
                        leagues.map((league) => {
                            return (
                                <div key={league.id}>
                                    <Link
                                        className="mb-2 pl-0"
                                        to={`/myleagues/view/${league.id}${
                                            league.id === "0" ||
                                            league.id === 0 ||
                                            league.id === "team" ||
                                            league.id === "budget"
                                                ? `/${league.show_id}`
                                                : ""
                                        }`}
                                    >
                                        {league.name}
                                    </Link>
                                </div>
                            );
                        })}
                    {leagues === undefined ||
                        (leagues && leagues.length === 0 && (
                            <p>You haven't joined any leagues yet</p>
                        ))}
                </>
            )}
        </div>
    );
};

export default CurrentShowLeagues;
