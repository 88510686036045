import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Form from "react-bootstrap/Form";

import useApi from "../../hooks/useApi";
import { LeagueOverviewAnalytics } from "../../models/Analytics";
import { League } from "../../models/Leagues";
import apiRoutes from "../../services/apiRoutes";

import { FormGroupWrapper } from "../atoms";
import PageTemplate from "../templates/PageTemplate";
import TableTeam from "../atoms/TableTeam";
import { useTheme } from "styled-components";
import AnalyticsListItem from "../molecules/AnalyticsListItem";
import { TableSkeleton } from "../atoms/Skeletons";
import EpisodeAnalyticsSelect from "../atoms/EpisodeAnalyticsSelect";
import { Show } from "../../models/Shows";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

interface ParamTypes {
    id: string;
    show_id?: string;
}

const Analytics: React.FC = () => {
    const { id, show_id } = useParams<ParamTypes>();
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);
    const [type, setType] = useState<string>("site");
    const [analyticsType, setAnalyticsType] =
        useState<string>("avgDraftPosition");
    const [league, setLeague] = useState<League>();
    const [showId, setShowId] = useState<string>(show_id);
    const [show, setShow] = useState<Show>();
    const [episode, setEpisode] = useState<number>();
    const [leagueSet, setLeagueSet] = useState<boolean>(false);
    const [analytics, setAnalytics] = useState<LeagueOverviewAnalytics>();
    const [loading, setLoading] = useState<boolean>(false);

    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(id, show_id), {
        onSuccess: (response: { league: League; show: Show }) => {
            setLeague(response.league);
            setShowId(response.league.show_id.toString());
            setShow(response.show);
            setEpisode(response.show.current_episode);
            setLeagueSet(true);
        },
    });
    const leagueOverviewRequest = useApi(apiRoutes.LEAGUE_OVERVIEW(id), {
        onSuccess: (response: LeagueOverviewAnalytics) => {
            setLoading(false);
            setAnalytics(response);
        },
        onFailure: () => {
            setLoading(false);
        },
    });
    const siteOverviewRequest = useApi(apiRoutes.SITE_OVERVIEW(showId), {
        onSuccess: (response: LeagueOverviewAnalytics) => {
            setLoading(false);
            setAnalytics(response);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        leagueRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (
            !subscription ||
            (subscription && !hasPremiumSub(subscription.name))
        ) {
            window.location.href = "/";
        }
    }, [subscription]);

    useEffect(() => {
        if (leagueSet) {
            if (type === "site") {
                setLoading(true);
                siteOverviewRequest.request({ episode: episode });
            } else {
                setLoading(true);
                leagueOverviewRequest.request({
                    league: true,
                    episode: episode,
                    show_id: show_id,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, leagueSet, episode, show_id]);

    return (
        <PageTemplate
            header="Analytics"
            headerSecondary={league && league.name}
        >
            <>
                {league && (
                    <Link
                        className="mb-5"
                        to={`/myleagues/view/${league.id}${
                            league.id === 0 ||
                            league.id === "0" ||
                            league.id === "team" ||
                            league.id === "budget"
                                ? `/${league.show_id}`
                                : ""
                        }`}
                    >
                        <Button variant="light">Back</Button>
                    </Link>
                )}
                <FormGroupWrapper className="mt-3">
                    <Form.Control
                        as="select"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setAnalyticsType(event.target.value);
                        }}
                        value={analyticsType}
                    >
                        <option value="avgDraftPosition">
                            Average Draft Position (Full Draft Leagues)
                        </option>
                        <option value="avgRank">
                            Average Rank (Confidence Pool Leagues)
                        </option>
                        <option value="weeklyBudgetStarting">
                            Weekly Budget Starting Percentage (Weekly Budget
                            Leagues)
                        </option>
                        <option value="weeklyStarting">
                            Weekly Redraft Starting Percentage (Weekly Redraft
                            Leagues)
                        </option>
                        <option value="teamStarting">
                            Team Starting Percentage (Team Leagues)
                        </option>
                        <option value="teamOwned">
                            Team Owned Percentage (Team Leagues)
                        </option>
                    </Form.Control>
                </FormGroupWrapper>
                {analyticsType !== "avgDraftPosition" &&
                    analyticsType !== "avgRank" && (
                        <>
                            <FormGroupWrapper>
                                <Form.Control
                                    as="select"
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setType(event.target.value);
                                    }}
                                    value={type}
                                >
                                    <option value="site">Site Wide</option>
                                    <option value="league">This League</option>
                                </Form.Control>
                            </FormGroupWrapper>
                            {analyticsType !== "teamOwned" && (
                                <EpisodeAnalyticsSelect
                                    show={show}
                                    episode={episode}
                                    onChange={(event) =>
                                        setEpisode(parseInt(event.target.value))
                                    }
                                />
                            )}
                        </>
                    )}
                {loading && (
                    <>
                        <TableSkeleton />
                        <TableSkeleton />
                    </>
                )}
                {!loading && (
                    <>
                        {analytics && analyticsType === "avgDraftPosition" && (
                            <>
                                <TableTeam>
                                    <thead
                                        style={{
                                            backgroundColor: theme.primary,
                                        }}
                                    >
                                        <th style={{ border: "none" }}>
                                            Player
                                        </th>
                                        <th
                                            style={{ border: "none" }}
                                            className="pl-5"
                                        >
                                            Avg Draft Position
                                        </th>
                                    </thead>
                                    <tbody>
                                        {analytics &&
                                            analytics.avgDraftPosition.map(
                                                (position) => (
                                                    <AnalyticsListItem
                                                        key={position.id}
                                                        currentEpisode={
                                                            show.current_episode
                                                        }
                                                        player={position}
                                                        rightText={
                                                            position.average_draft_position ??
                                                            "-"
                                                        }
                                                    />
                                                )
                                            )}
                                    </tbody>
                                </TableTeam>
                            </>
                        )}
                        {analytics && analyticsType === "avgRank" && (
                            <>
                                <TableTeam>
                                    <thead
                                        style={{
                                            backgroundColor: theme.primary,
                                        }}
                                    >
                                        <th style={{ border: "none" }}>
                                            Player
                                        </th>
                                        <th
                                            style={{ border: "none" }}
                                            className="pl-5"
                                        >
                                            Avg Rank
                                        </th>
                                    </thead>
                                    <tbody>
                                        {analytics &&
                                            analytics.avgRank.map(
                                                (position) => (
                                                    <AnalyticsListItem
                                                        currentEpisode={
                                                            show.current_episode
                                                        }
                                                        player={position}
                                                        rightText={
                                                            position.average
                                                                ? position.average.toString()
                                                                : "-"
                                                        }
                                                    />
                                                )
                                            )}
                                    </tbody>
                                </TableTeam>
                            </>
                        )}
                        {analytics && analyticsType === "weeklyStarting" && (
                            <>
                                <TableTeam>
                                    <thead
                                        style={{
                                            backgroundColor: theme.primary,
                                        }}
                                    >
                                        <th style={{ border: "none" }}>
                                            Player
                                        </th>
                                        <th
                                            style={{ border: "none" }}
                                            className="pl-5"
                                        >
                                            Weekly Redraft Starting Percentage
                                        </th>
                                    </thead>
                                    <tbody>
                                        {analytics &&
                                            analytics.weeklyRedraftStarted.map(
                                                (position) => (
                                                    <AnalyticsListItem
                                                        key={position.id}
                                                        currentEpisode={
                                                            show.current_episode
                                                        }
                                                        player={position}
                                                        rightText={
                                                            position.weekly_started &&
                                                            (league.draft_type ===
                                                                "Weekly Redraft" ||
                                                                type === "site")
                                                                ? `${position.weekly_started}%`
                                                                : "N/A"
                                                        }
                                                    />
                                                )
                                            )}
                                    </tbody>
                                </TableTeam>
                            </>
                        )}
                        {analytics &&
                            analyticsType === "weeklyBudgetStarting" && (
                                <>
                                    <TableTeam>
                                        <thead
                                            style={{
                                                backgroundColor: theme.primary,
                                            }}
                                        >
                                            <th style={{ border: "none" }}>
                                                Player
                                            </th>
                                            <th
                                                style={{ border: "none" }}
                                                className="pl-5"
                                            >
                                                Weekly Budget Starting
                                                Percentage
                                            </th>
                                        </thead>
                                        <tbody>
                                            {analytics &&
                                                analytics.weeklyBudgetStarted.map(
                                                    (position) => (
                                                        <AnalyticsListItem
                                                            key={position.id}
                                                            currentEpisode={
                                                                show.current_episode
                                                            }
                                                            player={position}
                                                            rightText={
                                                                position.weekly_started &&
                                                                (league.draft_type ===
                                                                    "Weekly Budget" ||
                                                                    type ===
                                                                        "site")
                                                                    ? `${position.weekly_started}%`
                                                                    : "N/A"
                                                            }
                                                        />
                                                    )
                                                )}
                                        </tbody>
                                    </TableTeam>
                                </>
                            )}
                        {analytics && analyticsType === "teamStarting" && (
                            <>
                                <TableTeam>
                                    <thead
                                        style={{
                                            backgroundColor: theme.primary,
                                        }}
                                    >
                                        <th style={{ border: "none" }}>
                                            Player
                                        </th>
                                        <th
                                            style={{ border: "none" }}
                                            className="pl-5"
                                        >
                                            Team Starting Percentage
                                        </th>
                                    </thead>
                                    <tbody>
                                        {analytics &&
                                            analytics.teamStarted.map(
                                                (position) => (
                                                    <AnalyticsListItem
                                                        key={position.id}
                                                        currentEpisode={
                                                            show.current_episode
                                                        }
                                                        player={position}
                                                        rightText={
                                                            position.team_started &&
                                                            (league.draft_type ===
                                                                "Team" ||
                                                                type === "site")
                                                                ? `${position.team_started}%`
                                                                : "N/A"
                                                        }
                                                    />
                                                )
                                            )}
                                    </tbody>
                                </TableTeam>
                            </>
                        )}
                        {analytics && analyticsType === "teamOwned" && (
                            <>
                                <TableTeam>
                                    <thead
                                        style={{
                                            backgroundColor: theme.primary,
                                        }}
                                    >
                                        <th style={{ border: "none" }}>
                                            Player
                                        </th>
                                        <th
                                            style={{ border: "none" }}
                                            className="pl-5"
                                        >
                                            Team Owned Percentage
                                        </th>
                                    </thead>
                                    <tbody>
                                        {analytics &&
                                            analytics.teamOwned.map(
                                                (position) => (
                                                    <AnalyticsListItem
                                                        key={position.id}
                                                        currentEpisode={
                                                            show.current_episode
                                                        }
                                                        player={position}
                                                        rightText={
                                                            position.team_owned &&
                                                            (league.draft_type ===
                                                                "Team" ||
                                                                type === "site")
                                                                ? `${position.team_owned}%`
                                                                : "N/A"
                                                        }
                                                    />
                                                )
                                            )}
                                    </tbody>
                                </TableTeam>
                            </>
                        )}
                    </>
                )}
            </>
        </PageTemplate>
    );
};

export default Analytics;
