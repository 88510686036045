import React from "react";
import { FastField, FastFieldProps } from "formik";

import Form from "react-bootstrap/Form";

import { FormGroupWrapper } from ".";

interface TextAreaProps {
    name: string;
    label: string;
    placeholder?: string;
    rows?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
    name,
    label,
    placeholder,
    rows,
}) => {
    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps) => {
                return (
                    <FormGroupWrapper>
                        {label && <Form.Label>{label}</Form.Label>}
                        <Form.Control
                            placeholder={placeholder}
                            value={field.value === null ? "" : field.value}
                            onChange={form.handleChange(name)}
                            isInvalid={
                                form.submitCount > 0 && meta.error !== undefined
                            }
                            as="textarea"
                            rows={rows ? rows : 3}
                        />
                        <Form.Control.Feedback type="invalid">
                            {meta.error}
                        </Form.Control.Feedback>
                    </FormGroupWrapper>
                );
            }}
        </FastField>
    );
};

export default TextArea;
