import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import Divider from "@mui/material/Divider";

import { LeagueScoringLog } from "../../models/Leagues";

import { VerticalPadding } from "../atoms";

interface ScoreActivityItemProps {
    score: LeagueScoringLog;
    leagueId: string;
}

const ScoreActivityItem: React.FC<ScoreActivityItemProps> = ({
    score,
    leagueId,
}) => {
    return (
        <div>
            <VerticalPadding key={`${score.id}${score.episode}`}>
                <b>
                    <Moment format="ddd, MMM Do [at] h:mm A">
                        {score.created_at}
                    </Moment>
                    :{" "}
                </b>
                <Link
                    to={`/shows/scores/${score.slug}/${score.episode}?league_id=${leagueId}`}
                >
                    {score.type === "week" ? "Week" : "Episode"} {score.episode}{" "}
                    Scores Logged
                </Link>
            </VerticalPadding>
            <Divider />
        </div>
    );
};

export default ScoreActivityItem;
