import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";

import { imageUrl } from "../../config";
import { Team } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import { Strikethrough } from "../atoms";
import PlayerRowData from "../atoms/PlayerRowData";

interface PlayerRowProps {
    commissioner: boolean;
    league: League;
    player: Team;
    onDeleteClick: (e: any) => void;
    currentEpisode: number;
    episode?: number;
}

const PlayerRow: React.FC<PlayerRowProps> = ({
    commissioner,
    league,
    player,
    onDeleteClick,
    currentEpisode,
    episode,
}) => {
    const history = useHistory();
    const [eliminated, setEliminated] = useState<boolean>(false);

    useEffect(() => {
        if (episode) {
            if (episode > 0) {
                if (
                    player.episode_eliminated !== null &&
                    player.episode_eliminated <= episode
                ) {
                    setEliminated(true);
                } else {
                    setEliminated(false);
                }
            } else {
                if (
                    player.episode_eliminated !== null &&
                    player.episode_eliminated < currentEpisode
                ) {
                    setEliminated(true);
                } else {
                    setEliminated(false);
                }
            }
        } else {
            if (
                player.episode_eliminated !== null &&
                player.episode_eliminated < currentEpisode
            ) {
                setEliminated(true);
            } else {
                setEliminated(false);
            }
        }
    }, [episode, player, currentEpisode]);

    return (
        <tr
            key={player.cast_id}
            onClick={() =>
                history.push(
                    `/shows/castscores/${player.cast_id}?league_id=${league.id}`
                )
            }
        >
            <td>
                <Avatar src={`${imageUrl}${player.pic}`} />
                <PlayerRowData
                    player={player}
                    eliminated={eliminated}
                    value={player.name}
                    league={league}
                    errorOverride={
                        <>
                            <Strikethrough>{player.name}</Strikethrough>{" "}
                            (Eliminated){" "}
                        </>
                    }
                />
                {commissioner && league.active && (
                    <DeleteIcon onClick={onDeleteClick} />
                )}
            </td>
            {Object.keys(player.scores).map((category: string, i: number) => (
                <td
                    key={category}
                    className="text-center d-none d-md-table-cell"
                >
                    <PlayerRowData
                        player={player}
                        eliminated={eliminated}
                        value={player.scores[category]}
                        league={league}
                    />
                </td>
            ))}
            <td className="text-right pr-5">
                <PlayerRowData
                    player={player}
                    eliminated={eliminated}
                    value={player.total}
                    league={league}
                />
            </td>
        </tr>
    );
};

export default PlayerRow;
