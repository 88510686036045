import React, { useState } from "react";
import { useTheme } from "styled-components";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import MUBadge from "@mui/material/Badge";
import InfoIcon from "@mui/icons-material/Info";

import { CardStyled, Sup, Small } from ".";
import { SubscribeTitle } from "./styles/PriceCard.styled";
import AlertModal from "./AlertModal";

interface PriceCardProps {
    title: string;
    price?: number;
    badge?: string;
    listGroup: string[];
    subscription?: boolean;
    buttonText: string;
    onButtonPress: () => void;
    disabled?: boolean;
    buttonCount?: number;
    annual?: boolean;
}

const PriceCard: React.FC<PriceCardProps> = ({
    title,
    price,
    badge,
    buttonCount,
    listGroup,
    subscription,
    buttonText,
    onButtonPress,
    annual,
    disabled,
}) => {
    const theme = useTheme();
    const [showAlert, setShowAlert] = useState<boolean>(false);

    return (
        <Col className="mb-3" md={4}>
            <CardStyled style={{ textAlign: "center" }}>
                <Card.Header className="align-items-center">
                    {title}{" "}
                    {badge ? <Badge variant="success">{badge}</Badge> : ""}
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        {price ? (
                            <SubscribeTitle>
                                <Sup>$</Sup>
                                {price}
                                <Sup>00</Sup>
                            </SubscribeTitle>
                        ) : (
                            <SubscribeTitle>Free</SubscribeTitle>
                        )}
                    </Card.Title>
                    {subscription ? (
                        annual ? (
                            <>
                                <Card.Text>PER YEAR</Card.Text>
                                <Small>
                                    ${(price / 12).toFixed(2)} per month, billed
                                    annually
                                </Small>
                            </>
                        ) : (
                            <Card.Text>PER MONTH</Card.Text>
                        )
                    ) : (
                        ""
                    )}
                </Card.Body>
                <ListGroup className="list-group-flush">
                    {listGroup.map((string: string, index: number) => {
                        return (
                            <ListGroupItem
                                key={index}
                                style={{ backgroundColor: theme.secondary }}
                            >
                                {string}
                                {string === "Analytics" && (
                                    <InfoIcon
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            setShowAlert(true);
                                        }}
                                        className="ml-1"
                                    />
                                )}
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
                <Card.Footer>
                    <MUBadge
                        overlap="rectangular"
                        badgeContent={buttonCount ? buttonCount : 0}
                        color="error"
                    >
                        <Button disabled={disabled} onClick={onButtonPress}>
                            {buttonText}
                        </Button>
                    </MUBadge>
                </Card.Footer>
            </CardStyled>
            {/* TODO: change this to an image of an example of what you get. Or an article explaining it */}
            <AlertModal
                title="Analytics"
                showModal={showAlert}
                setShowModal={setShowAlert}
                body={
                    <>
                        <h4>League Analytics</h4>
                        <ul className="text-left">
                            <li>
                                Average draft position for a contestant in a
                                Full Draft Leagues
                            </li>
                            <li>
                                Percentage of a contestant being owned on teams
                                in Team Leagues (site wide and your league)
                            </li>
                            <li>
                                Percentage of a contestant being started on
                                teams in Team Leagues (site wide and your
                                league)
                            </li>
                            <li>
                                Percentage of a contestant being selected on
                                teams in Weekly Redraft Leagues (site wide and
                                your league)
                            </li>
                            <li>Average rank in Confidence Pools</li>
                        </ul>
                        <h4>Cast Scoring Analytics</h4>
                        <ul className="text-left">
                            <li>
                                Scoring by episode per contestant graphed on a
                                line chart
                            </li>
                            <li>
                                Scoring by scoring field per contestant graphed
                                on a bar chart
                            </li>
                        </ul>
                        <h4>Plus much more coming!</h4>
                    </>
                }
            />
        </Col>
    );
};

export default PriceCard;
