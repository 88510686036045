import styled from "styled-components";

import Avatar from "@mui/material/Avatar";

export const Wrapper = styled.div`
    position: relative;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    width: 80px;
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
`;

export const AvatarItem = styled(Avatar)`
    margin-left: 15px;
`;
