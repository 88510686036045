import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRankingStar } from "@fortawesome/free-solid-svg-icons";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { determineTimezone } from "../../helpers/timezones";

interface LeagueRankProps {
    leagueUserId: number;
}

const LeagueRank: React.FC<LeagueRankProps> = ({ leagueUserId }) => {
    const history = useHistory();
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [rank, setRank] = useState<string>("-");
    const [total, setTotal] = useState<string>("-");
    const [points, setPoints] = useState<string>("-");

    const getRankRequest = useApi(
        apiRoutes.GET_LEADERBOARD_RANK(leagueUserId.toString()),
        {
            onSuccess: ({
                total,
                rank,
                points,
            }: {
                total: number;
                rank: number;
                points: number;
            }) => {
                setTotal(total.toString());
                setRank(rank.toString());
                setPoints(points.toString());
                setLoading(false);
            },
            onFailure: () => setLoading(false),
        }
    );

    useEffect(() => {
        getRankRequest.request(determineTimezone(timezoneOffsets));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueUserId, timezoneOffsets]);

    if (!loading) {
        return (
            <>
                <Row
                    className="justify-content-center mt-2"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                        history.push(`/myleagues/team/${leagueUserId}`)
                    }
                >
                    <FontAwesomeIcon
                        fontSize={20}
                        icon={faRankingStar}
                        className="mr-2"
                    />
                    <div>
                        Rank: {rank}/{total} ({points} points)
                    </div>
                </Row>
            </>
        );
    } else {
        return <></>;
    }
};

export default LeagueRank;
