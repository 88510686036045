import styled from "styled-components";

import Image from "react-bootstrap/Image";

export const CardElementWrapper = styled.div`
    border: 1px solid ${(props) => props.theme.textColor};
    border-radius: 5px;
`;

export const StripeLogo = styled(Image)`
    margin: -10px;
`;
