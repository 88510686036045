import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";

import useApi from "../../hooks/useApi";
import { Show } from "../../models/Shows";
import { CommentList } from "../../models/Comments";
import { Cast } from "../../models/Cast";
import { ScoringLogList } from "../../models/Scores";
import apiRoutes from "../../services/apiRoutes";
import useQuery from "../../hooks/useQuery";
import { QuestionScores } from "../../models/QuestionScores";

import { Well } from "../atoms";
import CurrentShowLeagues from "../molecules/CurrentShowLeagues";
import LeagueButtons from "../molecules/LeagueButtons";
// import LeagueDifference from "../molecules/LeagueDifference";
import ShowLinkList from "../organisms/ShowLinkList";
import Comments from "../organisms/Comments";
import EliminatedPlayers from "../molecules/EliminatedPlayers";
import ScoreList from "../molecules/ScoreList";
import ScoringBreakdown from "../molecules/ScoringBreakdown";
import ScoresTemplate from "../templates/ScoresTemplate";
import { ScoreCardSkeleton } from "../atoms/Skeletons";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";
import TopScorers from "../molecules/TopScorers";
import ChallengeQuestionsScoresList from "../organisms/ChallengeQuestionsScoresList";

interface ParamTypes {
    slug: string;
    episode: string;
}

interface ScoresBreakdown {
    eliminated: Cast[];
    scores: ScoringLogList[];
    leagueType: string;
}

const Scores: React.FC = () => {
    const query = useQuery();
    const [leagueId, setLeagueId] = useState(
        query.get("league_id") ? query.get("league_id") : "default"
    );

    const [scores, setScores] = useState<ScoringLogList[]>();
    const [questionScores, setQuestionScores] = useState<QuestionScores>([]);
    const [scoresLoading, setScoresLoading] = useState<boolean>(true);
    const [eliminated, setEliminated] = useState<Cast[]>();
    const [show, setShow] = useState<Show>();
    const [showType, setShowType] = useState("Episode");
    const [comments, setComments] = useState<CommentList>();
    const [leagueType, setLeagueType] = useState<string>("");
    const { slug, episode } = useParams<ParamTypes>();

    const getShow = useApi(apiRoutes.GET_SHOW_BY_SLUG(slug), {
        onSuccess: onShowSuccess,
        responseKey: "show",
    });

    const getScoreComments = useApi(
        apiRoutes.GET_SCORE_COMMENTS(slug, episode),
        {
            onSuccess: onCommentSuccess,
            responseKey: "comments",
        }
    );
    const getScores = useApi(apiRoutes.GET_EPISODE_SCORES(slug, episode), {
        onSuccess,
    });
    const getQuestionScores = useApi(
        apiRoutes.GET_QUESTION_SCORES_BY_EPISODE(slug, episode),
        {
            responseKey: "scores",
            onSuccess: onQuestionScoresSuccess,
        }
    );

    useEffect(() => {
        setScoresLoading(true);
        getShow.request();
        getScoreComments.request();
        getScores.request({ league_id: leagueId === "default" ? 0 : leagueId });
        getQuestionScores.request({
            league_id: leagueId === "default" ? 0 : leagueId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, episode]);

    useEffect(() => {
        setScoresLoading(true);
        getScores.request({ league_id: leagueId === "default" ? 0 : leagueId });
        getQuestionScores.request({
            league_id: leagueId === "default" ? 0 : leagueId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId]);

    function onShowSuccess(response: Show) {
        setShow(response);
        setShowType(response.type === "week" ? "Week" : "Episode");
    }

    function onCommentSuccess(response: CommentList) {
        setComments(response);
    }

    function onQuestionScoresSuccess(response: QuestionScores) {
        setQuestionScores(response);
    }

    function onSuccess(response: ScoresBreakdown) {
        setScores(response.scores);
        setEliminated(response.eliminated);
        setScoresLoading(false);
        setLeagueType(response.leagueType);
    }

    const onLeagueIdChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLeagueId(event.target.value);
    };

    return (
        <ScoresTemplate
            show={show && show}
            loading={
                getShow.loading && getScores.loading && getScoreComments.loading
            }
            header={
                show && showType
                    ? `${show.show}: ${showType} ${episode} Scores`
                    : ""
            }
            skeleton={true}
            sideCards={
                show && (
                    <>
                        {leagueType !== "Confidence Pool" &&
                            leagueType !== "Eliminated Confidence Pool" && (
                                <TopScorers
                                    side
                                    show={show}
                                    episode={episode}
                                    leagueId={leagueId}
                                />
                            )}
                        {show.active && (
                            <Well className="d-none d-lg-block d-xl-block">
                                {/* <LeagueDifference /> */}
                                <LeagueButtons
                                    showId={show.id}
                                    slug={show.slug}
                                />
                                <CurrentShowLeagues showId={show.id} />
                            </Well>
                        )}
                        {/* <Ad /> */}
                        <GoogleAd slot="2424423133" height={280} />
                        <ShowLinkList
                            type="articles"
                            show={show}
                            viewAll={`/shows/article/${show.slug}`}
                        />
                        <ShowLinkList type="scores" show={show} />
                    </>
                )
            }
        >
            <>
                {show && (
                    <ScoringBreakdown
                        leagueId={leagueId}
                        showId={show.id}
                        onChange={onLeagueIdChange}
                    />
                )}
                {show && show.active && (
                    <Well className="d-sm-block d-xs-block d-md-block d-lg-none d-xl-none">
                        {/* <LeagueDifference /> */}
                        <LeagueButtons showId={show.id} slug={show.slug} />
                        <CurrentShowLeagues showId={show.id} />
                    </Well>
                )}
                {eliminated && <EliminatedPlayers players={eliminated} />}
                {questionScores && questionScores.length > 0 && (
                    <ChallengeQuestionsScoresList
                        questionScores={questionScores}
                        leagueId={leagueId}
                    />
                )}
                {show &&
                    leagueType !== "Confidence Pool" &&
                    leagueType !== "Eliminated Confidence Pool" && (
                        <TopScorers
                            show={show}
                            episode={episode}
                            leagueId={leagueId}
                        />
                    )}
                <div className="d-sm-block d-xs-block d-md-block d-lg-none d-xl-none">
                    {/* <Ad /> */}
                    <GoogleAd slot="2424423133" height={280} />
                </div>
                <h2>Scores:</h2>
                {scoresLoading && (
                    <>
                        <ScoreCardSkeleton />
                        <ScoreCardSkeleton />
                        <ScoreCardSkeleton />
                        <ScoreCardSkeleton />
                    </>
                )}
                {scores && !scoresLoading && (
                    <ScoreList
                        list={scores}
                        type={"episode"}
                        leagueType={leagueType}
                    />
                )}
                <Well>
                    {comments && (
                        <Comments
                            comments={comments}
                            id={`${slug}${episode}`}
                            type={"score"}
                            slug={slug}
                            episode={episode}
                        />
                    )}
                </Well>
            </>
        </ScoresTemplate>
    );
};

export default Scores;
