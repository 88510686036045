import React, { useState } from "react";

import useApi from "../../hooks/useApi";
import { CommentList } from "../../models/Comments";
import apiRoutes from "../../services/apiRoutes";

import MessageForm from "../molecules/MessageForm";
import MessagesList from "../molecules/MessagesList";

interface CommentsProps {
    id: number | string;
    comments: CommentList;
    type: "article" | "score";
    slug?: string;
    episode?: string;
}

const Comments: React.FC<CommentsProps> = ({
    comments,
    id,
    type,
    slug,
    episode,
}) => {
    const saveComment = useApi(apiRoutes.SAVE_COMMENT(type, id), {
        responseKey: "comments",
        onSuccess,
    });
    const [submitting, setSubmitting] = useState(false);
    const [commentList, setCommentList] = useState<CommentList>(comments);

    const onSubmit = async (comment: string) => {
        setSubmitting(true);
        if (type === "article") {
            await saveComment.request({ comment });
        } else {
            await saveComment.request({ comment, show_slug: slug, episode });
        }
        setSubmitting(false);
    };

    function onSuccess(response: CommentList) {
        setCommentList(response);
    }

    return (
        <div className="w-100">
            <MessageForm
                label="Leave a Comment:"
                onSubmit={onSubmit}
                submitting={submitting}
            />
            <MessagesList messages={commentList} />
        </div>
    );
};

export default Comments;
