import React from "react";

import { Well } from "../atoms";

interface EpisodeEliminatedProps {
    episode: number;
    type: string;
}

const EpisodeEliminated: React.FC<EpisodeEliminatedProps> = ({
    type,
    episode,
}) => {
    return (
        <>
            <h2>{type} Eliminated:</h2>
            <Well>
                {episode ? (
                    <>
                        {type} {episode}
                    </>
                ) : (
                    "Not Eliminated"
                )}
            </Well>
        </>
    );
};

export default EpisodeEliminated;
