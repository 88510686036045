import React from "react";

import Alert from "react-bootstrap/Alert";

import { ScoringLogList } from "../../models/Scores";

import GoogleAd from "../atoms/GoogleAd";
import ScoreCard from "./ScoreCard";

interface ScoreListProps {
    list?: ScoringLogList[];
    type: "cast" | "episode";
    leagueType?: string;
}

const ScoreList: React.FC<ScoreListProps> = ({
    list = [],
    type,
    leagueType,
}) => {
    if (list?.length > 0) {
        return (
            <>
                {leagueType &&
                    (leagueType === "Confidence Pool" ||
                        leagueType === "Eliminated Confidence Pool") && (
                        <Alert variant="warning">
                            There are no scores for {leagueType} leagues.
                            {leagueType} scores are based on how you rank
                            players each week.
                        </Alert>
                    )}
                {leagueType !== "Confidence Pool" &&
                    leagueType !== "Eliminated Confidence Pool" &&
                    list.map((col, key) => {
                        if (key % 5 === 4) {
                            return (
                                <React.Fragment key={key}>
                                    <GoogleAd slot="4055686110" height={90} />
                                    <ScoreCard
                                        key={key}
                                        scores={col.scores}
                                        header={col.header}
                                        headerImage={col.headerImage}
                                        type={type}
                                    />
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <ScoreCard
                                    key={key}
                                    scores={col.scores}
                                    header={col.header}
                                    headerImage={col.headerImage}
                                    type={type}
                                />
                            );
                        }
                    })}
            </>
        );
    } else {
        return <div>No Scores</div>;
    }
};

export default ScoreList;
