import React from "react";
import { FastField, FastFieldProps } from "formik";

import Form from "react-bootstrap/Form";

interface CheckboxProps {
    name: string;
    label: string;
    group?: string;
    groupList?: string[];
}

const Checkbox: React.FC<CheckboxProps> = ({
    name,
    label,
    group,
    groupList,
}) => {
    return (
        <FastField name={group ? group : name}>
            {({ field, form, meta }: FastFieldProps) => {
                let value = false;
                if (
                    field.value === 1 ||
                    field.value === true ||
                    (Array.isArray(field.value) && field.value.includes("on"))
                ) {
                    value = true;
                }
                return (
                    <Form.Check
                        type={"checkbox"}
                        id={`checkbox-${name}`}
                        name={group ? group : name}
                        label={label}
                        checked={
                            group && groupList
                                ? groupList.length > 0 &&
                                  groupList.filter((e) => e === name).length > 0
                                : value
                        }
                        onChange={() => {
                            if (group && groupList) {
                                if (
                                    groupList.filter((e) => e === name).length >
                                    0
                                ) {
                                    //remove it
                                    const list = groupList.filter(
                                        (e) => e !== name
                                    );
                                    form.setFieldValue(group, list);
                                } else {
                                    //add it
                                    const list = [...groupList];
                                    list.push(name);
                                    form.setFieldValue(group, list);
                                }
                            } else {
                                form.setFieldValue(name, !value);
                            }
                        }}
                    />
                );
            }}
        </FastField>
    );
};

export default Checkbox;
