import { ApiReturnType } from "./";

const buildRoutes = {
    CREATE_NEW_SHOW: (type: string): ApiReturnType => {
        return {
            url: `build/add/${type}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_LEAGUE: (id?: string): ApiReturnType => {
        if (id) {
            return {
                url: `build/save/${id}`,
                method: "post",
            };
        } else {
            return {
                url: `build/save`,
                method: "post",
            };
        }
    },
};

export default buildRoutes;
