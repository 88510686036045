import React from "react";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";

import { RootState } from "../../store";
import { League } from "../../models/Leagues";
import { LeagueUser, Team } from "../../models/LeagueUsers";

interface TeamHeaderProps {
    league: League;
    commissioner: boolean;
    setShowFinishDraftModal: (show: boolean) => void;
    setSelectedEpisode: (episode: number) => void;
    setShowAddPlayer: (show: boolean) => void;
    currentTeam: Team[];
    leagueUser: LeagueUser;
}

const TeamHeader: React.FC<TeamHeaderProps> = ({
    league,
    commissioner,
    setShowFinishDraftModal,
    setSelectedEpisode,
    setShowAddPlayer,
    currentTeam,
    leagueUser,
}) => {
    const user = useSelector((state: RootState) => state.user);

    return (
        <>
            {commissioner &&
                league.draft_type !== "Weekly Redraft" &&
                league.active && (
                    <>
                        {league.draft_type === "Full Draft" &&
                        !league.drafted ? (
                            <>
                                <p>
                                    If you want to add players manually, please{" "}
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setShowFinishDraftModal(true);
                                        }}
                                    >
                                        Finish Draft
                                    </Button>
                                </p>
                            </>
                        ) : (
                            <>
                                {league.draft_type === "Full Draft" ||
                                (league.draft_type === "Team" &&
                                    leagueUser.user_id === user.id &&
                                    currentTeam.length > 0) ||
                                (league.draft_type === "Team" &&
                                    leagueUser.user_id !== user.id) ? (
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setSelectedEpisode(0);
                                            setShowAddPlayer(true);
                                        }}
                                    >
                                        Add Player
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </>
                )}
            {league.draft_type === "Full Draft" && (
                <div className="text-center">
                    * Indicates player was traded away
                </div>
            )}
        </>
    );
};

export default TeamHeader;
