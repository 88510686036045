const strings = {
    createLeague: {
        weeklyRedraft:
            "Weekly Redraft: Every week you have to pick players for your team. The amount of players you pick every week is determined by you (1-10).",
        weeklyBudget:
            "Weekly Budget: Every week you have to pick players for your team with $100. Dollar amounts for players are determined based on how well they've done in fantasy up to that point",
        confidencePool:
            "Confidence Pool: Rank players every week on how confident you are with them surviving every week. The more confident you are, the more points they get for surviving.",
        eliminatedConfidencePool:
            "Eliminated Confidence Pool: Rank players every week on how confident you are with them surviving every week. The lower you rank the player who gets eliminated, the more points you will receive each week.",
        fullDraftNotice:
            "* Full Drafts started after first episode has already aired will only allow drafting players who are not elimintated!",
        fullDraft:
            "Full Draft: Everyone in your league drafts players, in a snake style draft, to be on their team until there are no more players left. Teams never change after that.",
        team: "Team: At the beginning of the season/league, each person will select a team (amount of players determined by you 2-10). Then every week people in the league will pick who they want to start (amount of players determined by you 1-5).",
        playersPerWeek:
            "Players Per Week: The amount of players you can pick per week (1-10).",
        playerLimit:
            "Player Limit: Limits the amount of time you choose a player (1-5). For example: if it's set to 2, you can't choose a player more than 2 weeks during the whole season. Set limit to \"No Limit\" if you don't want a limit.",
        playersPerTeam:
            "Players Per Team: The amount of players you can draft on a team (1-10).",
        startersPerTeam:
            "Starters Per Week: The amount of players you can start on a team per week (1-10).",
        rookieLimit:
            "Rookie Limit: If turned on, you're given the ability to choose a limit to the amount of rookie competitors that players can select for their team (only applies to Team leagues)",
        genderLimit:
            "Gender Limit: If turned on, you're given the ability to choose a limit to the amount of male/female competitors that players can select for their team (only applies to Team leagues)",
        rookieLimitTeam:
            'Rookie Limit: Limits how many rookies you must choose when choosing your team. Set limit to "No Limit" if you don\'t want a limit.',
        swaps: "Number of player swaps: The amount of times that you want to allow teams in your league to swap players on their teams (this doesn't mean swapping in and out starters, it's changing which players are on their teams). If you don't want to allow swaps, leave empty",
        lastSwapEp:
            "Last episode/week you are allowed to make swaps. If you want to always allow swaps, leave empty",
        rookieLimitWeekly:
            "Rookie Limit: Limits how many rookies you must choose when selecting your team each week. If there's less rookies than your limit, the limit will be the amount of rookies left. If there's no rookies left, you won't have to choose any. Set limit to \"No Limit\" if you don't want a limit.",
        maleLimitWeekly:
            "Male Limit: Limits how many males you must choose when selecting your team each week. If there's less males than your limit, the limit will be the amount of males left. If there's no males left, you won't have to choose any. Set limit to \"No Limit\" if you don't want a limit.",
        maleLimitTeam:
            'Male Limit: Limits how many males you must choose when choosing your team. Set limit to "No Limit" if you don\'t want a limit.',
        femaleLimitWeekly:
            "Female Limit: Limits how many females you must choose when selecting your team each week. If there's less females than your limit, the limit will be the amount of females left. If there's no females left, you won't have to choose any. Set limit to \"No Limit\" if you don't want a limit.",
        femaleLimitTeam:
            'Female Limit: Limits how many females you must choose when choosing your team. Set limit to "No Limit" if you don\'t want a limit.',
        leagueSize:
            "League Size: The max amount of people you would like in your league. Leave blank if you don't want a limit.",
        private:
            "Private League: People can only join your league with a league code provided to you after you create the league.",
        public: "Public League: Anyone can join your league. You can set a League Size if you want to limit the amount of people.",
        sponsored:
            "Sponsored League: You can add your own league image and social links to promote your podcast, site, social media, etc. You will be displayed at the top of the public leagues search. Anyone can join your league. You can set a League Size if you want to limit the amount of people.",
        allowTrading:
            "Do you want to allow trades in your league? You can change this later in the season if you want to disable trades later in the season.",
        challengeQuestions:
            "Do you want to include challenge questions in your league? Challenge questions are questions asked at the beginning of the season or every week that gives users a change to score extra points.",
    },
    leagueDescriptions: {
        fullDraft:
            "Full Draft: Everyone in your league drafts players, in a snake style draft, to be on their team until there are no more players left. Teams never change after that.",
        weeklyRedraft:
            "Weekly Redraft: Every week you have to pick players for your team. The amount of players you pick every week is determined by you.",
        team: "Team: You pick a team when you sign up for the league (2-10 players). Adjust your lineup every week to start a players each week (1-5 players). Only starters points will count towards your total points.",
    },
    show: {
        scoringDefault:
            "All point totals are customizable whenever creating league (these point totals are the default)",
        scoringNotice:
            "* Scoring categories/fields are subject to change/be added once more info is known about the format of the show. *",
    },
    draft: {
        notStarted:
            "Draft Has Not Started Yet. Contact Your Commissioner To Start The Draft.",
    },
    league: {
        updatingScores:
            "Updating scores for your league. This may take a couple minutes. When this message goes away, please reload the page to get updated scores.",
    },
};

export default strings;
