import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";

import { RootState } from "../../store";
import { updateUser } from "../../store/user/actions";
import strings from "../../config/strings";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { FullShow, DraftTypes, ShowLimits } from "../../models/Shows";
import { CreateLeague } from "../../models/Leagues";
import { UserModel } from "../../models/User";
import { Cast } from "../../models/Cast";
import configs from "../../config/configs";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

import Input from "../atoms/Input";
import Select from "../atoms/Select";
import { ArticleTextSkeleton } from "../atoms/Skeletons";
import SubmitButton from "../atoms/SubmitButton";
import { SectionHeader } from "../atoms";
import ScoringForm from "../molecules/ScoringForm";
import LeagueTeamForm from "../molecules/LeagueTeamForm";
import LeagueWeeklyRedraftForm from "../molecules/LeagueWeeklyRedraftForm";
import PageTemplate from "../templates/PageTemplate";
import { validGenderLimits, validRookieLimit } from "../../helpers/limitChecks";
import RadioButtons from "../atoms/RadioButtons";
import LeagueFullDraftForm from "../molecules/LeagueFullDraftForm";
import StripeModal from "../molecules/StripeModal";
import SponsoredLeagueForm from "./SponsoredLeagueForm";
import QuestionsForm from "../molecules/QuestionsForm";
import Switch from "../atoms/Switch";

const supportedFormats = configs.image_formats;

const validationSchema = yup.object({
    show: yup
        .number()
        .moreThan(0, "Show is required")
        .required("Show is required"),
    leagueName: yup.string().required("League Name is required"),
    teamName: yup.string().required("Team Name is required"),
    draftType: yup.string().required("Draft Type is required"),
    leagueSize: yup.mixed().nullable(),
    img: yup
        .mixed()
        .test("fileType", "Unsupported File Format", function (value) {
            if (value === undefined || value === null) {
                return true;
            } else {
                return supportedFormats.includes(value.type);
            }
        }),
    playersPerWeek: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Weekly Redraft",
        then: yup.number().required("Players Per Week is required"),
        otherwise: yup.number().nullable(),
    }),
    playerLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) =>
            draftType === "Weekly Redraft" || draftType === "Weekly Budget",
        then: yup.mixed().required("Player Limit is required").nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    playersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.number().required("Players Per Team is required").nullable(),
        otherwise: yup.number().nullable(),
    }),
    startersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup
            .number()
            .required("Starters Per Team is required")
            .test(
                "starters_less_than_players",
                "Starters Per Team must be less than or equal to Players Per Team",
                function (value) {
                    const { playersPerTeam } = this.parent;
                    if (value) {
                        if (playersPerTeam < value) {
                            return false;
                        }
                    }
                    return true;
                }
            )
            .nullable(),
        otherwise: yup.number().nullable(),
    }),
    rookieLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().required("Rookie Limit is required").nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    maleLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().required("Male Limit is required").nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    femaleLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().required("Female Limit is required").nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    allowTrading: yup.boolean().optional(),
    numPicks: yup.mixed().optional(),
    swaps: yup.mixed().optional(),
    lastSwapEp: yup.mixed().optional(),
    scores: yup.array().of(
        yup.object().shape({
            id: yup.number(),
            description: yup.string(),
            score: yup
                .string()
                .matches(/^-?\d*$/g, "Invalid Score")
                .required("Invalid Score"),
        })
    ),
    includeQuestions: yup.boolean().optional(),
    questions: yup.array().of(
        yup.object().shape({
            id: yup.number(),
            description: yup.string(),
            score: yup
                .string()
                .matches(/^-?\d*$/g, "Invalid Score")
                .required("Invalid Score"),
        })
    ),
    instagram: yup.string().nullable(),
    twitter: yup.string().nullable(),
    tiktok: yup.string().nullable(),
    podcast: yup
        .string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter a valid url!"
        )
        .nullable(),
});

interface ParamTypes {
    slug: string;
}

const CreateLeagueForm: React.FC = () => {
    const { slug } = useParams<ParamTypes>();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);
    const subscription = useSelector((state: RootState) => state.subscription);

    const dispatch = useDispatch();
    const elements = useElements();
    const stripe = useStripe();

    const [showStripeModal, setShowStripeModal] = useState<boolean>(false);
    const [draftTypes, setDraftTypes] = useState<string[]>([]);
    const [show, setShow] = useState<FullShow>();
    const [castCount, setCastCount] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [activeShows, setActiveShows] = useState<FullShow[]>();
    const [leagueType, setLeagueType] = useState<string>("Private");
    const [showLimits, setShowLimits] = useState<ShowLimits>({
        max_player_limit: 5,
        max_players_per_week: 10,
        max_players_per_team: 10,
        max_starters_per_team: 10,
    });
    const [initialValues, setInitialValues] = useState({
        show: slug ? 1 : "", //definitely a hack setting it to 1 so yup doesn't error out. Will update this value before sending it to save
        leagueName: "",
        teamName: "",
        draftType: "",
        leagueSize: null,
        img: null,
        playersPerWeek: 1,
        allowTrading: true,
        numPicks: "N/A",
        playerLimit: "No Limit",
        rookieLimit: "No Limit",
        maleLimit: "No Limit",
        femaleLimit: "No Limit",
        playersPerTeam: 2,
        startersPerTeam: 1,
        scores: [],
        includeQuestions: false,
        questions: [],
        instagram: null,
        twitter: null,
        tiktok: null,
        podcast: null,
        swaps: null,
        lastSwapEp: null,
    });

    const activeShowsRequest = useApi(apiRoutes.GET_SHOWS_BY_TYPE("active"), {
        responseKey: "shows",
        onSuccess: (response: FullShow[]) => setActiveShows(response),
    });
    const showRequest = useApi(apiRoutes.GET_SHOW_BY_SLUG(slug ? slug : ""), {
        responseKey: "show",
        onSuccess: (response: FullShow) => {
            const leagueTypes = response.draft_types.split(",");
            setInitialValues({
                ...initialValues,
                includeQuestions: response.questions.length > 0 ? true : false,
                questions: response.questions,
                scores: response.scoring,
                draftType: leagueTypes[0],
            });
            if (response.show_limits) {
                setShowLimits({
                    max_players_per_week:
                        response.show_limits?.max_players_per_week ?? 10,
                    max_starters_per_team:
                        response.show_limits?.max_starters_per_team ?? 10,
                    max_players_per_team:
                        response.show_limits?.max_players_per_team ?? 10,
                    max_player_limit:
                        response.show_limits?.max_player_limit ?? 5,
                });
            }
            setDraftTypes(leagueTypes);
            setShow(response);
        },
    });
    const createLeagueRequest = useApi(apiRoutes.CREATE_LEAGUE(), {
        onSuccess: (response: CreateLeague) => {
            toast.success(response.message);
            setSubmitting(false);
            history.push(`/myleagues/view/${response.leagueId}`);
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });
    const addSponsoredRequest = useApi(apiRoutes.ADD_SPONSORED_LEAGUE(), {
        onSuccess: (response: { user: UserModel; message: string }) => {
            dispatch(updateUser({ ...response.user, isLoggedIn: true }));
            setLeagueType("Sponsored");
            toast.success(response.message);
            setSubmitting(false);
            setShowStripeModal(false);
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });
    const paymentIntentRequest = useApi(apiRoutes.GET_PAYMENT_INTENT(), {
        responseKey: "intent",
        onSuccess: async (response: { client_secret: string }) => {
            const clientSecret = response.client_secret;

            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            if (error) {
                toast.error(error.message);
                setSubmitting(false);
            } else {
                addSponsoredRequest.request();
            }
        },
        onFailure: () => {
            toast.error("There was an issue submitting payment");
            setSubmitting(false);
        },
    });

    useEffect(() => {
        if (slug) {
            showRequest.request();
        } else {
            activeShowsRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    const onShowChange = (id: number, setFieldValue: any) => {
        const selectedShow = activeShows.filter(
            (show: FullShow) => show.id.toString() === id.toString()
        )[0];
        setShow(selectedShow);
        if (id > 0 && selectedShow) {
            const availableCast = selectedShow.cast.filter((cast: Cast) => {
                return cast.eliminated === false;
            });
            const leagueTypes = selectedShow.draft_types.split(",");
            setShowLimits({
                max_players_per_week:
                    selectedShow.show_limits?.max_players_per_week ?? 10,
                max_starters_per_team:
                    selectedShow.show_limits?.max_starters_per_team ?? 10,
                max_players_per_team:
                    selectedShow.show_limits?.max_players_per_team ?? 10,
                max_player_limit:
                    selectedShow.show_limits?.max_player_limit ?? 5,
            });
            setFieldValue("playersPerTeam", 2);
            setFieldValue("startersPerTeam", 1);
            setFieldValue("playersPerWeek", 1);
            setFieldValue("playerLimit", "No Limit");
            setFieldValue("scores", selectedShow.scoring);
            setCastCount(availableCast.length);
            setFieldValue("draftType", leagueTypes[0]);
            setFieldValue("questions", selectedShow.questions);
            setFieldValue(
                "includeQuestions",
                selectedShow.questions.length > 0 ? true : false
            );
            setDraftTypes(leagueTypes);
        } else {
            setShowLimits({
                max_players_per_week: 10,
                max_starters_per_team: 10,
                max_players_per_team: 10,
                max_player_limit: 5,
            });
            setShow(undefined);
            setFieldValue("draftType", "");
            setFieldValue("playersPerTeam", 2);
            setFieldValue("startersPerTeam", 1);
            setFieldValue("playersPerWeek", 1);
            setFieldValue("playerLimit", "No Limit");
            setDraftTypes([]);
            setFieldValue("scores", []);
            setFieldValue("questions", []);
            setFieldValue("includeQuestions", false);
            setCastCount(0);
        }
    };

    const getDraftText = (draftType: string) => {
        if (draftType === "Weekly Redraft") {
            return strings.createLeague.weeklyRedraft;
        } else if (draftType === "Full Draft") {
            return strings.createLeague.fullDraft;
        } else if (draftType === "Team") {
            return strings.createLeague.team;
        } else if (draftType === "Weekly Budget") {
            return strings.createLeague.weeklyBudget;
        } else if (draftType === "Confidence Pool") {
            return strings.createLeague.confidencePool;
        } else if (draftType === "Eliminated Confidence Pool") {
            return strings.createLeague.eliminatedConfidencePool;
        } else {
            return "";
        }
    };

    const isLoading = () => {
        if (slug) {
            if (!showRequest.loading && show) {
                return false;
            } else {
                return true;
            }
        } else {
            if (!activeShowsRequest.loading && activeShows) {
                return false;
            } else {
                return true;
            }
        }
    };

    const addSponsored = async (
        email: string,
        amount: number,
        description: string
    ) => {
        setSubmitting(true);
        if (!stripe || !elements) {
            toast.error("There was an issue submitting payment");
            setSubmitting(false);
            return;
        }

        paymentIntentRequest.request({ email, amount, description });
    };

    return (
        <PageTemplate
            header="Create New League"
            headerSecondary={slug && show ? show.show : ""}
            loading={isLoading()}
            skeleton={
                <>
                    <ArticleTextSkeleton />
                    <ArticleTextSkeleton />
                    <ArticleTextSkeleton />
                </>
            }
        >
            <>
                {!isLoading() && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setFieldError }) => {
                            if (
                                values.draftType !== "Team" ||
                                (validGenderLimits(
                                    values.maleLimit === "No Limit"
                                        ? 0
                                        : parseInt(values.maleLimit),
                                    values.femaleLimit === "No Limit"
                                        ? 0
                                        : parseInt(values.femaleLimit),
                                    values.playersPerTeam
                                ) &&
                                    validRookieLimit(
                                        values.rookieLimit === "No Limit"
                                            ? 0
                                            : parseInt(values.rookieLimit),
                                        values.playersPerTeam
                                    ))
                            ) {
                                setFieldError("maleLimit", undefined);
                                setFieldError("femaleLimit", undefined);
                                setFieldError("rookieLimit", undefined);
                                const headers = {
                                    "content-type": "multipart/form-data",
                                };
                                const data = new FormData();
                                data.append("show", show.id.toString());
                                data.append("leagueType", leagueType);
                                data.append("teamName", values.teamName);
                                data.append("leagueName", values.leagueName);
                                data.append("draftType", values.draftType);
                                data.append("leagueSize", values.leagueSize);
                                data.append("img", values.img);
                                data.append("maleLimit", values.maleLimit);
                                data.append("femaleLimit", values.femaleLimit);
                                data.append("rookieLimit", values.rookieLimit);
                                data.append("numPicks", values.numPicks);
                                data.append(
                                    "scores",
                                    JSON.stringify(values.scores)
                                );
                                if (values.instagram)
                                    data.append("instagram", values.instagram);
                                if (values.twitter)
                                    data.append("twitter", values.twitter);
                                if (values.tiktok)
                                    data.append("tiktok", values.tiktok);
                                if (values.podcast)
                                    data.append("podcast", values.podcast);
                                data.append(
                                    "allowTrading",
                                    values.allowTrading.toString()
                                );
                                data.append(
                                    "playersPerWeek",
                                    values.playersPerWeek.toString()
                                );
                                data.append(
                                    "playerLimit",
                                    values.playerLimit.toString()
                                );
                                data.append(
                                    "playersPerTeam",
                                    values.playersPerTeam.toString()
                                );
                                data.append(
                                    "startersPerTeam",
                                    values.startersPerTeam.toString()
                                );
                                if (values.swaps)
                                    data.append(
                                        "swaps",
                                        values.swaps.toString()
                                    );
                                if (values.lastSwapEp)
                                    data.append(
                                        "lastSwapEp",
                                        values.lastSwapEp.toString()
                                    );
                                if (values.includeQuestions)
                                    data.append(
                                        "includeQuestions",
                                        values.includeQuestions.toString()
                                    );
                                data.append(
                                    "questions",
                                    values.includeQuestions
                                        ? JSON.stringify(values.questions)
                                        : JSON.stringify([])
                                );
                                createLeagueRequest.request(data, headers);
                                setSubmitting(true);
                            } else {
                                if (
                                    values.draftType === "Team" &&
                                    !validGenderLimits(
                                        values.maleLimit === "No Limit"
                                            ? 0
                                            : parseInt(values.maleLimit),
                                        values.femaleLimit === "No Limit"
                                            ? 0
                                            : parseInt(values.femaleLimit),
                                        values.playersPerTeam
                                    )
                                ) {
                                    setFieldError(
                                        "maleLimit",
                                        "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                                    );
                                    setFieldError(
                                        "femaleLimit",
                                        "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                                    );
                                    toast.error(
                                        "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                                    );
                                }
                                if (
                                    values.draftType === "Team" &&
                                    !validRookieLimit(
                                        values.rookieLimit === "No Limit"
                                            ? 0
                                            : parseInt(values.rookieLimit),
                                        values.playersPerTeam
                                    )
                                ) {
                                    setFieldError(
                                        "rookieLimit",
                                        "Rookie Limit cannot be more than Players Per Team"
                                    );
                                    toast.error(
                                        "Rookie Limit cannot be more than Players Per Team"
                                    );
                                }
                            }
                        }}
                    >
                        {({ values, handleSubmit, setFieldValue, isValid }) => (
                            <Form
                                onSubmit={(e) => {
                                    if (!isValid) {
                                        toast.error("Please fix the errors");
                                    }
                                    handleSubmit(e);
                                }}
                            >
                                {!slug && (
                                    <>
                                        <SectionHeader>Show</SectionHeader>
                                        <div className="mb-5">
                                            <Select
                                                name="show"
                                                label="Show:"
                                                items={activeShows}
                                                selectKey="id"
                                                selectValue="show"
                                                defaultKey={"0"}
                                                defaultValue="Choose Show"
                                                handleChange={(id: number) =>
                                                    onShowChange(
                                                        id,
                                                        setFieldValue
                                                    )
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                                <SectionHeader>General</SectionHeader>
                                <div className="mb-5">
                                    <Input
                                        name="leagueName"
                                        label="League Name:"
                                        placeholder="League Name"
                                    />
                                    <Input
                                        name="teamName"
                                        label="Team Name:"
                                        placeholder="Team Name"
                                    />
                                    <RadioButtons
                                        buttons={[
                                            "Private",
                                            "Public",
                                            "Sponsored",
                                        ]}
                                        name="leagueType"
                                        label="League Type"
                                        value={leagueType}
                                        setFieldValue={(
                                            field: string,
                                            value: string
                                        ) => {
                                            if (
                                                value === "Sponsored" &&
                                                user.sponsored <= 0 &&
                                                ((subscription &&
                                                    !hasPremiumSub(
                                                        subscription.name
                                                    )) ||
                                                    !subscription)
                                            ) {
                                                setShowStripeModal(true);
                                            } else {
                                                setLeagueType(value);
                                            }
                                        }}
                                        formText={
                                            leagueType === "Public"
                                                ? strings.createLeague.public
                                                : leagueType === "Private"
                                                ? strings.createLeague.private
                                                : strings.createLeague.sponsored
                                        }
                                    />
                                    {(leagueType === "Public" ||
                                        leagueType === "Sponsored") && (
                                        <Input
                                            label="League Size"
                                            placeholder="League Size"
                                            name={`leagueSize`}
                                            type="number"
                                            formText={
                                                strings.createLeague.leagueSize
                                            }
                                        />
                                    )}
                                    {leagueType === "Sponsored" && (
                                        <SponsoredLeagueForm
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                    {show && (
                                        <Select
                                            name="draftType"
                                            label="Draft Type:"
                                            items={draftTypes}
                                            formText={getDraftText(
                                                values.draftType
                                            )}
                                            field
                                        />
                                    )}
                                    {values.draftType === "Full Draft" && (
                                        <LeagueFullDraftForm
                                            castCount={castCount}
                                            numPicksChange={(value) =>
                                                setFieldValue("numPicks", value)
                                            }
                                            numPick={values.numPicks}
                                        />
                                    )}
                                    {(values.draftType === "Weekly Redraft" ||
                                        values.draftType ===
                                            "Weekly Budget") && (
                                        <LeagueWeeklyRedraftForm
                                            showLimits={showLimits}
                                            show={show}
                                            budget={
                                                values.draftType ===
                                                "Weekly Budget"
                                            }
                                        />
                                    )}
                                    {values.draftType === "Team" && (
                                        <LeagueTeamForm
                                            showLimits={showLimits}
                                            show={show}
                                        />
                                    )}
                                </div>
                                {show && show.questions.length > 0 && (
                                    <Switch
                                        name="includeQuestions"
                                        label="Include Challenge Questions?"
                                        formText={
                                            strings.createLeague
                                                .challengeQuestions
                                        }
                                    />
                                )}
                                {show &&
                                    show.questions.length > 0 &&
                                    values.includeQuestions && (
                                        <QuestionsForm
                                            questions={values.questions}
                                            show={show.show}
                                        />
                                    )}
                                {show &&
                                    values.draftType !== "Confidence Pool" &&
                                    values.draftType !==
                                        "Eliminated Confidence Pool" && (
                                        <ScoringForm
                                            scores={values.scores}
                                            show={show.show}
                                        />
                                    )}
                                <SubmitButton
                                    title="Create League"
                                    submitText="Creating"
                                    submitting={submitting}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </>
            <StripeModal
                showModal={showStripeModal}
                setShowModal={setShowStripeModal}
                title="Sponsored League"
                description={
                    <div>
                        You've selected a sponsored league. Sponsored leagues
                        are $3 and provide you with everything described below.
                        If you wish to create a free league, Private and Public
                        leagues are free of charge.
                        <br />
                        <br />
                        <b>Sponsored League Features:</b>
                        <ul>
                            <li>
                                You can add your own league image and social
                                links to promote your podcast, site, social
                                media, etc.
                            </li>
                            <li>
                                You will be displayed at the top of the public
                                leagues search.
                            </li>
                            <li>Anyone can join your league.</li>
                            <li>
                                You can set a League Size if you want to limit
                                the amount of people in your league.
                            </li>
                        </ul>
                    </div>
                }
                onSubmit={addSponsored}
                amount={3}
                submitting={submitting}
            />
        </PageTemplate>
    );
};

export default CreateLeagueForm;
