import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import useQuery from "../../hooks/useQuery";
import { InstagramBio } from "../../models/InstagramBio";
import { imageUrl } from "../../config";

import { CardSkeleton } from "../atoms/Skeletons";
import CardList from "../molecules/CardList";
// import PaginationMenu from "../molecules/Pagination";
import PageTemplate from "../templates/PageTemplate";

interface CardListArray {
    header: string;
    image?: string;
    buttonText: string;
    buttonUrl: string;
    description?: string;
}

const InstagramBioList: React.FC = () => {
    const query = useQuery();
    const limit = query.get("limit") ? parseInt(query.get("limit")) : 12;
    const page = query.get("page") ? parseInt(query.get("page")) : 1;
    // const [numPages, setNumPages] = useState(0);

    const getInstaBioRequest = useApi(apiRoutes.GET_INSTA_BIO(), {
        onSuccess,
    });
    const [list, setList] = useState<CardListArray[] | []>([]);

    useEffect(() => {
        getInstaBioRequest.request({ page, limit });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSuccess(response: { instaBio: InstagramBio[]; count: number }) {
        const cardList = response.instaBio.map((item: InstagramBio) => {
            return {
                header: item.title,
                image: `${imageUrl}${item.img}`,
                buttonText: "View",
                buttonUrl: item.url,
            };
        });
        // setNumPages(Math.ceil(response.count / limit));
        setList(cardList);
    }

    return (
        <PageTemplate
            loading={getInstaBioRequest.loading}
            header="Instagram Bio"
            bareContent
            skeleton={
                <Row>
                    <Col md={4}>
                        <CardSkeleton />
                    </Col>
                    <Col md={4}>
                        <CardSkeleton />
                    </Col>
                    <Col md={4}>
                        <CardSkeleton />
                    </Col>
                    <Col md={4}>
                        <CardSkeleton />
                    </Col>
                    <Col md={4}>
                        <CardSkeleton />
                    </Col>
                    <Col md={4}>
                        <CardSkeleton />
                    </Col>
                </Row>
            }
        >
            {!getInstaBioRequest.loading && list && (
                <>
                    <CardList md={4} lg={4} list={list} />
                    {/* <PaginationMenu page={page} numPages={numPages} /> */}
                </>
            )}
        </PageTemplate>
    );
};

export default InstagramBioList;
