import { LOG_OUT, UserActionTypes } from "../user/types";
import {
    TimezoneOffsetActionTypes,
    TimezoneOffsetState,
    UPDATE_TIMEZONE_OFFSETS,
} from "./types";

const INITIAL_STATE: TimezoneOffsetState = {
    slug: "default",
    pacific: 7,
    mountain: 6,
    hawaii: 10,
    alaska: 9,
};

export function timezoneOffsetReducer(
    state = INITIAL_STATE,
    action: TimezoneOffsetActionTypes | UserActionTypes
) {
    switch (action.type) {
        case UPDATE_TIMEZONE_OFFSETS:
            state = action.payload;

            if (state === null) {
                return null;
            } else {
                return {
                    ...action.payload,
                };
            }
        case LOG_OUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
