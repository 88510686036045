import React from "react";
import { useTheme } from "styled-components";

import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Fab from "@mui/material/Fab";

import { imageUrl } from "../../config";
import { TradeLeagueTeams } from "../../models/LeagueTeams";

import { ListItemAvatar } from "./styles/Trade.styled";

interface TradeListItemProps {
    player: TradeLeagueTeams;
    selectPlayer: (player: TradeLeagueTeams, type: string) => void;
}

const TradeListItem: React.FC<TradeListItemProps> = ({
    player,
    selectPlayer,
}) => {
    const theme = useTheme();

    return (
        <ListItem
            disableRipple
            button
            divider
            style={
                player.selected
                    ? {
                          backgroundColor: theme.selected,
                      }
                    : {}
            }
        >
            <ListItemAvatar>
                <>
                    <Avatar
                        style={{ height: 60, width: 60 }}
                        src={`${imageUrl}${player.pic}`}
                    />
                    <ListItemText
                        primary={`${player.first_name && player.first_name} ${
                            player.last_name && player.last_name
                        }`}
                    />
                </>
            </ListItemAvatar>
            <ListItemSecondaryAction
                onClick={() => selectPlayer(player, "yours")}
            >
                <Fab
                    size="small"
                    style={{
                        background: player.selected ? "#dc3545" : "#198754",
                        color: "#fff",
                    }}
                >
                    {player.selected ? <RemoveIcon /> : <AddIcon />}
                </Fab>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default TradeListItem;
