import { ShowList } from "../../models/Shows";

export type ShowState = ShowList[];

export const SET_SHOWS = "SET_SHOWS";

export interface SetShows {
    type: typeof SET_SHOWS;
    payload: ShowState;
}

export type ShowActionTypes = SetShows;
