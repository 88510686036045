import React, { ChangeEvent } from "react";
import { FastField, FastFieldProps } from "formik";

import Form from "react-bootstrap/Form";

import ImageUploaded from "./ImageUploaded";

interface ImageUploadProps {
    name: string;
    label: string;
    image?: File | string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    width?: number;
    formText?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
    name,
    label,
    image,
    handleChange,
    width,
    formText,
}) => {
    const isLessThanTheMB = (fileSize: number): boolean => {
        const isOk = fileSize / 1024 / 1024 < 5.12;
        return isOk;
    };

    const pickImage = async (e: ChangeEvent<any>) => {
        const files = e.target.files;
        const cancel = !files.length;

        if (cancel) return;

        const [{ size }] = files;

        if (isLessThanTheMB(size)) {
            handleChange(e);
        } else {
            alert(
                `Image is too large. Max file size is 5.12MB. Your file size was ${(
                    size /
                    1024 /
                    1024
                ).toFixed(2)}MB`
            );
        }
    };

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps) => {
                return (
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>{label}:</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={pickImage}
                            isInvalid={meta.error !== undefined}
                        />
                        <Form.Control.Feedback type="invalid">
                            {meta.error}
                        </Form.Control.Feedback>
                        {formText && <Form.Text muted>{formText}</Form.Text>}
                        {image && <ImageUploaded width={width} file={image} />}
                    </Form.Group>
                );
            }}
        </FastField>
    );
};

export default ImageUpload;
