import React from "react";

interface PlayerTableHeaderProps {
    categories: string[];
    totalOverride?: string;
}

const PlayerTableHeader: React.FC<PlayerTableHeaderProps> = ({
    categories,
    totalOverride,
}) => {
    return (
        <thead>
            <tr>
                <th>Player</th>
                {categories.map((category: string) => {
                    return (
                        <th
                            key={category}
                            className="text-center d-none d-md-table-cell"
                        >
                            {category}
                        </th>
                    );
                })}
                <th className="text-right pr-5">
                    {totalOverride ? totalOverride : "Total"}
                </th>
            </tr>
        </thead>
    );
};

export default PlayerTableHeader;
