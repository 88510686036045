import styled from "styled-components";

import { colors } from "../../../config/colors";

import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "@mui/material/Badge";

export const NavbarContainer = styled(Navbar)`
    background-color: ${colors.header};
    box-shadow: 0 0 1rem 0 #000;
    padding-left: 5%;
    padding-right: 5%;
`;

export const SiteImageWrapper = styled(Navbar.Brand)`
    display: flex;
    align-items: center;
`;

export const NavUser = styled(NavDropdown)`
    .dropdown-menu {
        background-color: ${(props) => props.theme.secondary};
        max-height: 400px;
        overflow: scroll;
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        .dropdown-item {
            color: ${(props) => props.theme.textColor};

            &:hover {
                background-color: ${(props) => props.theme.primary};
            }
        }
    }

    .dropdown-menu::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }

    .nav-link {
        display: flex;
        align-items: center;
    }
`;

export const AdminDropdown = styled(NavDropdown)`
    .dropdown-menu {
        background-color: ${(props) => props.theme.secondary};

        .dropdown-item {
            color: ${(props) => props.theme.textColor};

            &:hover {
                background-color: ${(props) => props.theme.secondary};
            }
        }
    }
`;

export const NotificationBadge = styled(Badge)`
    .MuiBadge-badge {
        margin-top: 10px;
    }
`;
