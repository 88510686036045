import { TimezoneOffset } from "../../models/TimezoneOffset";
import { TimezoneOffsetActionTypes, UPDATE_TIMEZONE_OFFSETS } from "./types";

export function updateTimezoneOffsets(
    timezoneOffset: TimezoneOffset
): TimezoneOffsetActionTypes {
    return {
        type: UPDATE_TIMEZONE_OFFSETS,
        payload: timezoneOffset,
    };
}
