import styled from "styled-components";

import BootstrapTable from "react-bootstrap/Table";

export const TableStyled = styled(BootstrapTable)`
    table-layout: fixed;

    & thead th {
        color: ${(props) => props.theme.textColor};
        border-bottom: 0px solid ${(props) => props.theme.border};
        border-top: 0px solid ${(props) => props.theme.border};
    }

    & tbody tr:hover td,
    .table-hover tbody tr:hover th {
        /* background-color: ${(props) => props.theme.primary}; */
        cursor: pointer;
    }

    & tbody tr.selected {
        background-color: ${(props) => props.theme.primary};
    }

    & tbody td {
        vertical-align: middle;
        color: ${(props) => props.theme.textColor};
        border-top: 0px solid ${(props) => props.theme.border};
    }
`;

export const TableTeamStyled = styled(BootstrapTable)`
    table-layout: fixed;
    margin-bottom: 0px;

    &.selected {
        background-color: rgb(25, 135, 84);
    }

    & thead th {
        color: ${(props) => props.theme.textColor};
    }

    &:not(.selected) tbody tr:hover td,
    .table-hover tbody tr:hover th {
        background-color: ${(props) => props.theme.secondary};
    }

    & tbody tr.selected {
        background-color: ${(props) => props.theme.primary};
    }

    & tbody td {
        vertical-align: middle;
        color: ${(props) => props.theme.textColor};
        border-top: none;
    }
`;
