import { ApiReturnType } from "./";

const stripeRoutes = {
    CREATE_SUBSCRIPTION: (): ApiReturnType => {
        return {
            url: `subscriptions/charge`,
            method: "post",
        };
    },
    GET_PAYMENT_INTENT: (): ApiReturnType => {
        return {
            url: `stripe/intent`,
            method: "get",
        };
    },
};

export default stripeRoutes;
