import { SubscriptionActionTypes, UPDATE_SUBSCRIPTION } from "./types";
import { Subscription } from "../../models/Subscriptions";

export function updateSubscription(
    subscription: Subscription
): SubscriptionActionTypes {
    return {
        type: UPDATE_SUBSCRIPTION,
        payload: {
            subscription,
        },
    };
}
