import React from "react";

import { Cast } from "../../models/Cast";
import { imageUrl } from "../../config";

import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { ModalStyled } from "../atoms";
import {
    DraftImgWrapper,
    DraftPlayerImg,
    DraftText,
} from "./styles/DraftPlayerModal.styled";

interface DraftPlayerModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    player?: Cast;
    draftPlayer: (player: Cast) => void;
    drafting: boolean;
}

const DraftPlayerModal: React.FC<DraftPlayerModalProps> = ({
    showModal,
    setShowModal,
    player,
    drafting,
    draftPlayer,
}) => {
    const submitText = "Drafting";

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
            style={{ zIndex: 10000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Draft Player</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3">
                    {player && (
                        <>
                            <DraftText>
                                Are you sure you want to draft{" "}
                                {player.first_name}
                                {player.last_name ? ` ${player.last_name}` : ``}
                                ?
                            </DraftText>
                            <DraftImgWrapper>
                                <DraftPlayerImg
                                    src={`${imageUrl}${player.pic}`}
                                />
                            </DraftImgWrapper>
                            <div className="mt-3">
                                <Button
                                    variant="primary"
                                    disabled={drafting}
                                    onClick={() => draftPlayer(player)}
                                >
                                    {drafting ? (
                                        <>
                                            {submitText}{" "}
                                            <CircularProgress
                                                size={12}
                                                color="inherit"
                                            />
                                        </>
                                    ) : (
                                        "Draft"
                                    )}
                                </Button>{" "}
                                <Button
                                    variant="light"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default DraftPlayerModal;
