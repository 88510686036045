import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import TextArea from "../atoms/TextArea";
import SubmitButton from "../atoms/SubmitButton";
import { ModalStyled } from "../atoms";

interface CommissionerNoteProps {
    leagueId: string | number;
    note: string;
    setShowModal: (show: boolean) => void;
    setCommissionerNote: (note: string) => void;
    showModal: boolean;
}

interface Values {
    note: string;
}

const validationSchema = yup.object({
    note: yup.string(),
});

const CommissionerNote: React.FC<CommissionerNoteProps> = ({
    note,
    leagueId,
    showModal,
    setShowModal,
    setCommissionerNote,
}) => {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const initialValues: Values = {
        note: note ? note : "",
    };

    const saveCommissionerNote = useApi(
        apiRoutes.SAVE_COMMISSIONER_NOTE(leagueId.toString()),
        {
            onSuccess: (note: string) => {
                toast.success("Saved Commissioner Note!");
                setSubmitting(false);
                setShowModal(false);
                setCommissionerNote(note);
            },
            onFailure: () => setSubmitting(false),
            responseKey: "note",
        }
    );

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Commissioner Note</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setSubmitting(true);
                        saveCommissionerNote.request(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form
                            onSubmit={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            <div className="text-left">
                                <TextArea
                                    name="note"
                                    label="Commissioner Note:"
                                />
                            </div>
                            <SubmitButton
                                title="Save"
                                submitting={submitting}
                                submitText="Saving"
                            />{" "}
                            <Button
                                variant="light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </ModalStyled>
    );
};

export default CommissionerNote;
