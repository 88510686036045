import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import useQuery from "../../hooks/useQuery";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Show } from "../../models/Shows";
import { ScoringLogList } from "../../models/Scores";
import { Cast } from "../../models/Cast";
import { imageUrl } from "../../config";
import { RootState } from "../../store";
import { Socials } from "../../models/Socials";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { determineTimezone } from "../../helpers/timezones";

import { Well } from "../atoms";
import CurrentShowLeagues from "../molecules/CurrentShowLeagues";
import LeagueButtons from "../molecules/LeagueButtons";
// import LeagueDifference from "../molecules/LeagueDifference";
import ScoreList from "../molecules/ScoreList";
import ScoringBreakdown from "../molecules/ScoringBreakdown";
import EpisodeEliminated from "../molecules/EpisodeEliminated";
import ShowLinkList from "../organisms/ShowLinkList";
import ScoresTemplate from "../templates/ScoresTemplate";
import { CastImg } from "./styles/CastScores.styled";
import CastBreakdownChart from "../molecules/CastBreakdownChart";
import SocialLinks from "../atoms/SocialLinks";
import GoogleAd from "../atoms/GoogleAd";
import { Alert } from "react-bootstrap";

interface ParamTypes {
    cast_id: string;
}

interface CastResponse {
    cast: Cast;
    show: Show;
    socials?: Socials;
}

const CastScores: React.FC = () => {
    const query = useQuery();
    const subscription = useSelector((state: RootState) => state.subscription);
    const [leagueId, setLeagueId] = useState(
        query.get("league_id") ? query.get("league_id") : "default"
    );
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );

    const [cast, setCast] = useState<Cast>();
    const [scores, setScores] = useState<ScoringLogList[]>();
    const [socials, setSocials] = useState<Socials>();
    const [show, setShow] = useState<Show>();
    const [showType, setShowType] = useState("Episode");
    const [draftType, setDraftType] = useState<string>("");
    const { cast_id } = useParams<ParamTypes>();

    const getCast = useApi(apiRoutes.GET_CAST(cast_id), {
        onSuccess: onCastSuccess,
    });

    const getScores = useApi(apiRoutes.GET_CAST_SCORES(cast_id), {
        onSuccess,
    });

    useEffect(() => {
        getCast.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cast_id]);

    useEffect(() => {
        const timezones = determineTimezone(timezoneOffsets);
        getScores.request({
            league_id: leagueId === "default" ? 0 : leagueId,
            ...timezones,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId]);

    function onCastSuccess(response: CastResponse) {
        setShow(response.show);
        setShowType(response.show.type === "week" ? "Week" : "Episode");
        setCast(response.cast);
        setSocials(response.socials);
    }

    function onSuccess(response: {
        scores: ScoringLogList[];
        draftType: string;
    }) {
        setScores(response.scores);
        setDraftType(response.draftType);
    }

    const onLeagueIdChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLeagueId(event.target.value);
    };

    return (
        <ScoresTemplate
            show={show && show}
            loading={getCast.loading && getScores.loading}
            header={
                show && cast
                    ? `${show.show}: ${cast.first_name} ${cast.last_name} Scores`
                    : ""
            }
            sideCards={
                show && (
                    <>
                        {show.active && (
                            <Well>
                                {/* <LeagueDifference /> */}
                                <LeagueButtons
                                    showId={show.id}
                                    slug={show.slug}
                                />
                                <CurrentShowLeagues showId={show.id} />
                            </Well>
                        )}
                        <Well>
                            <GoogleAd slot="2424423133" height={280} />
                        </Well>
                        <ShowLinkList
                            type="articles"
                            show={show}
                            viewAll={`/shows/article/${show.slug}`}
                        />
                        <ShowLinkList type="scores" show={show} />
                    </>
                )
            }
            skeleton={true}
        >
            <>
                {!getCast.loading && (
                    <>
                        <div className="d-flex justify-content-center mb-5">
                            <CastImg src={`${imageUrl}${cast.pic}`} />
                        </div>
                        {socials && (
                            <div className="d-flex justify-content-center mb-5">
                                <SocialLinks size="large" socials={socials} />
                            </div>
                        )}
                        <ScoringBreakdown
                            leagueId={leagueId}
                            showId={show.id}
                            onChange={onLeagueIdChange}
                        />
                        {subscription && hasPremiumSub(subscription.name) && (
                            <CastBreakdownChart
                                type={showType}
                                castId={cast.id}
                                leagueId={leagueId}
                            />
                        )}
                        {show &&
                            cast.episode_eliminated !== null &&
                            cast.episode_eliminated < show.current_episode && (
                                <EpisodeEliminated
                                    type={showType}
                                    episode={cast.episode_eliminated}
                                />
                            )}
                    </>
                )}
                <h2>Scores:</h2>
                {!getScores.loading && (
                    <>
                        {draftType === "Confidence Pool" ||
                        draftType === "Eliminated Confidence Pool" ? (
                            <Alert variant="warning">
                                {`There are no cast scores for ${draftType} leagues. Scores are based on how you rank contestants each week.`}
                            </Alert>
                        ) : (
                            <ScoreList list={scores} type={"cast"} />
                        )}
                    </>
                )}
            </>
        </ScoresTemplate>
    );
};

export default CastScores;
