import React, { useState } from "react";
import { toast } from "react-toastify";

import DeleteIcon from "@mui/icons-material/Delete";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { UserModel } from "../../models/User";

import AreYouSureModal from "../atoms/AreYouSureModal";

interface CoownerListProps {
    id: string;
    coOwners: UserModel[];
    onDeleteSuccess: (coOwners: UserModel[]) => void;
}

interface DeleteProps {
    message: string;
    coOwners: UserModel[];
}

const CoownerList: React.FC<CoownerListProps> = ({
    id,
    coOwners,
    onDeleteSuccess,
}) => {
    const [coOwnerId, setCoOwnerId] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);

    const deleteCoOwnerRequest = useApi(apiRoutes.DELETE_CO_OWNER(id), {
        onSuccess: (response: DeleteProps) => {
            toast.success(response.message);
            onDeleteSuccess(response.coOwners);
            setShowModal(false);
        },
    });

    return (
        <div>
            {coOwners && coOwners.length > 0 && (
                <>
                    <label>Co-Owners:</label>
                    <ul>
                        {coOwners.map((coowner: UserModel) => (
                            <li key={coowner.id}>
                                {coowner.first_name} {coowner.last_name}{" "}
                                <DeleteIcon
                                    onClick={() => {
                                        setCoOwnerId(coowner.id);
                                        setShowModal(true);
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            )}
            <AreYouSureModal
                showModal={showModal}
                setShowModal={setShowModal}
                body={`Are you sure you want to delete this co-owner from your team?`}
                onSuccess={() =>
                    deleteCoOwnerRequest.request({ co_owner_id: coOwnerId })
                }
            />
        </div>
    );
};

export default CoownerList;
