import React, { ChangeEvent } from "react";

import { Show } from "../../models/Shows";

import Form from "react-bootstrap/Form";

import { FormGroupWrapper } from ".";

interface EpisodeAnalyticsSelectProps {
    show: Show;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    episode: number;
}

const EpisodeAnalyticsSelect: React.FC<EpisodeAnalyticsSelectProps> = ({
    onChange,
    episode,
    show,
}) => {
    return (
        <FormGroupWrapper>
            <Form.Control as="select" onChange={onChange} value={episode}>
                {Array.from(Array(show.episodes).keys(), (episode: number) => {
                    return (
                        <option key={episode + 1} value={episode + 1}>
                            {`${show.type === "week" ? "Week" : "Episode"} ${
                                episode + 1
                            }${
                                episode + 1 === show.current_episode
                                    ? " (current)"
                                    : ""
                            }`}
                        </option>
                    );
                })}
            </Form.Control>
        </FormGroupWrapper>
    );
};

export default EpisodeAnalyticsSelect;
