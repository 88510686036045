import React from "react";
import Moment from "react-moment";

import Divider from "@mui/material/Divider";

import { LeagueActivity } from "../../models/Leagues";

import { VerticalPadding } from "../atoms";

interface ActivityListItemProps {
    item: LeagueActivity;
}

const ActivityListItem: React.FC<ActivityListItemProps> = ({ item }) => {
    return (
        <div>
            <VerticalPadding>
                <b>
                    <Moment format="ddd, MMM Do [at] h:mm A">
                        {item.created_at}
                    </Moment>
                    :{" "}
                </b>
                {item.action}
            </VerticalPadding>
            <Divider />
        </div>
    );
};

export default ActivityListItem;
