import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { CurrentLeagues } from "../../models/Leagues";
import { determineTimezone } from "../../helpers/timezones";
import { RootState } from "../../store";

import { Well } from "../atoms";
import {
    LeaguesListItemSkeleton,
    LeaguesListTitleSkeleton,
} from "../atoms/Skeletons";
import MyleaguesRow from "../molecules/MyleaguesRow";
import { ShowHeader } from "./styles/MyleaguesList.styled";
import CastImage from "../atoms/CastImage";

interface MyLeaguesListProps {
    type: "active" | "completed";
}

interface Myleagues {
    show: string;
    show_img: string;
    leagues: CurrentLeagues[];
}

const MyLeaguesList: React.FC<MyLeaguesListProps> = ({ type }) => {
    const route =
        type === "active"
            ? apiRoutes.GET_ACTIVE_LEAGUES()
            : apiRoutes.GET_COMPLETED_LEAGUES();
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const leagues = useApi(route, {
        responseKey: "leagues",
    });

    useEffect(() => {
        const timezones = determineTimezone(timezoneOffsets);
        leagues.request({ ...timezones });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Well>
            {leagues.loading && (
                <>
                    <LeaguesListTitleSkeleton />
                    <LeaguesListItemSkeleton />
                    <LeaguesListItemSkeleton />
                    <LeaguesListTitleSkeleton />
                    <LeaguesListItemSkeleton />
                    <LeaguesListItemSkeleton />
                </>
            )}
            {!leagues.loading && leagues.data && (
                <>
                    {leagues.data.length === 0 && type === "active" && (
                        <div className="text-center m-4">
                            You currently haven't joined any leagues.
                        </div>
                    )}
                    {leagues.data.length === 0 && type === "completed" && (
                        <div className="text-center m-4">
                            You haven't completed any leagues.
                        </div>
                    )}
                    {leagues.data.length > 0 &&
                        leagues.data.map((data: Myleagues) => {
                            return (
                                <React.Fragment key={data.show}>
                                    <ShowHeader>
                                        <CastImage src={data.show_img} />
                                        <h3 className="ml-3 mb-0">
                                            {data.show}:
                                        </h3>
                                    </ShowHeader>
                                    {data.leagues.map((league) => {
                                        return (
                                            <MyleaguesRow
                                                key={league.id}
                                                league={league}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                </>
            )}
        </Well>
    );
};

export default MyLeaguesList;
