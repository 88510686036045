import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import { RootState } from "../store";

interface AdminRouteProps extends RouteProps {}

const AdminRoute: React.FC<AdminRouteProps> = ({ ...rest }) => {
    const user = useSelector((state: RootState) => state.user);

    if (user && (user.admin || user.log_scores || user.articles)) {
        return <Route {...rest} />;
    } else {
        return <Redirect to="/" />;
    }
};

export default AdminRoute;
