import React from "react";
import {
    ErrorMessage,
    FastField,
    FastFieldProps,
    Field,
    FieldProps,
} from "formik";
import { useTheme } from "styled-components";

import Form from "react-bootstrap/Form";

interface SelectProps {
    name: string;
    label?: string;
    items: any;
    errorMessage?: boolean;
    formText?: string;
    selectKey?: string;
    selectValue?: string;
    defaultKey?: string | number;
    defaultValue?: string;
    handleChange?: (item: any) => void;
    backgroundColor?: string;
    disabled?: boolean;
    field?: boolean;
}

const Select: React.FC<SelectProps> = ({
    name,
    label,
    items,
    errorMessage,
    formText,
    selectKey,
    selectValue,
    defaultKey,
    defaultValue,
    handleChange,
    backgroundColor,
    disabled,
    field,
}) => {
    const theme = useTheme();

    if (field) {
        return (
            <Field name={name}>
                {({ field, form, meta }: FieldProps) => {
                    return (
                        <Form.Group>
                            {label && <Form.Label>{label}</Form.Label>}
                            <Form.Control
                                disabled={disabled ?? false}
                                style={
                                    backgroundColor
                                        ? {
                                              backgroundColor: backgroundColor,
                                              color: theme.textColor,
                                          }
                                        : {
                                              backgroundColor: theme.inputColor,
                                              color: theme.textColor,
                                          }
                                }
                                as="select"
                                value={field.value}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (handleChange) {
                                        handleChange(e.target.value);
                                    }
                                    form.setFieldValue(name, e.target.value);
                                }}
                                isInvalid={
                                    form.submitCount > 0 &&
                                    meta.error !== undefined
                                }
                            >
                                {defaultKey !== undefined &&
                                    defaultValue !== undefined && (
                                        <option value={defaultKey}>
                                            {defaultValue}
                                        </option>
                                    )}
                                {items.map((item: any) => {
                                    if (selectKey && selectValue) {
                                        return (
                                            <option
                                                key={item[selectKey]}
                                                value={item[selectKey]}
                                            >
                                                {item[selectValue]}
                                            </option>
                                        );
                                    } else {
                                        return (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        );
                                    }
                                })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {!errorMessage && meta.error}
                                {errorMessage && meta.error && (
                                    <ErrorMessage name={meta.error} />
                                )}
                            </Form.Control.Feedback>
                            {formText && (
                                <Form.Text muted>{formText}</Form.Text>
                            )}
                        </Form.Group>
                    );
                }}
            </Field>
        );
    } else {
        return (
            <FastField name={name}>
                {({ field, form, meta }: FastFieldProps) => {
                    return (
                        <Form.Group>
                            {label && <Form.Label>{label}</Form.Label>}
                            <Form.Control
                                disabled={disabled ?? false}
                                style={
                                    backgroundColor
                                        ? {
                                              backgroundColor: backgroundColor,
                                              color: theme.textColor,
                                          }
                                        : {
                                              backgroundColor: theme.inputColor,
                                              color: theme.textColor,
                                          }
                                }
                                as="select"
                                value={field.value}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (handleChange) {
                                        handleChange(e.target.value);
                                    }
                                    form.setFieldValue(name, e.target.value);
                                }}
                                isInvalid={
                                    form.submitCount > 0 &&
                                    meta.error !== undefined
                                }
                            >
                                {defaultKey !== undefined &&
                                    defaultValue !== undefined && (
                                        <option value={defaultKey}>
                                            {defaultValue}
                                        </option>
                                    )}
                                {items.map((item: any) => {
                                    if (selectKey && selectValue) {
                                        return (
                                            <option
                                                key={item[selectKey]}
                                                value={item[selectKey]}
                                            >
                                                {item[selectValue]}
                                            </option>
                                        );
                                    } else {
                                        return (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        );
                                    }
                                })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {!errorMessage && meta.error}
                                {errorMessage && meta.error && (
                                    <ErrorMessage name={meta.error} />
                                )}
                            </Form.Control.Feedback>
                            {formText && (
                                <Form.Text muted>{formText}</Form.Text>
                            )}
                        </Form.Group>
                    );
                }}
            </FastField>
        );
    }
};

export default Select;
