import React from "react";
import { NegativeScores, NeutralScores, PositiveScores } from ".";

interface ScoreValueProps {
    score: number;
}

const ScoreValue: React.FC<ScoreValueProps> = ({ score }) => {
    if (score > 0) {
        return <PositiveScores>+{score}</PositiveScores>;
    } else if (score === 0) {
        return <NeutralScores>{score}</NeutralScores>;
    } else {
        return <NegativeScores>{score}</NegativeScores>;
    }
};

export default ScoreValue;
