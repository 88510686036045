import styled from "styled-components";

export const LeagueBadgeWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px !important;
    font-size: 14px;
    color: white;
`;
