import styled from "styled-components";

import Avatar from "@mui/material/Avatar";
import { CardStyled } from "../../atoms";

export const CastImg = styled(Avatar)`
    height: 150px !important;
    width: 150px !important;
`;

export const CastCard = styled(CardStyled)`
    /* border: ${(props) => `1px solid ${props.theme.border}`}; */
    background-color: ${(props) => props.theme.primary};
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
`;
