import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import RemoveIcon from "@mui/icons-material/Remove";

import { imageUrl } from "../../config";
import { Cast } from "../../models/Cast";

import { AvatarItem, Wrapper } from "./styles/WeeklyStarter.styled";
import { HeaderRemoveButton } from "../organisms/styles/SetWeeklyLineup.styled";

interface WeeklyStarterProps {
    player: Cast;
    removePlayer: (id: number) => void;
    locked?: boolean;
}

const WeeklyStarter: React.FC<WeeklyStarterProps> = ({
    player,
    removePlayer,
    locked,
}) => {
    const name = `${player.first_name && player.first_name} ${
        player.last_name && player.last_name
    }`;

    return (
        <Wrapper>
            <OverlayTrigger
                placement="top"
                trigger={["hover", "focus"]}
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="tooltip-player">{name}</Tooltip>}
            >
                <div style={{ borderWidth: 2 }}>
                    <AvatarItem
                        style={{ height: 60, width: 60 }}
                        src={`${imageUrl}${player.pic}`}
                    />
                    <div>
                        {name.length > 9 ? `${name.substr(0, 9)}...` : name}
                    </div>
                    {!locked && (
                        <HeaderRemoveButton
                            onClick={() => removePlayer(player.id)}
                        >
                            <RemoveIcon fontSize="small" />
                        </HeaderRemoveButton>
                    )}
                </div>
            </OverlayTrigger>
        </Wrapper>
    );
};

export default WeeklyStarter;
