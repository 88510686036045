import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { RootState } from "../../store";
import { DraftTypes, Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { League } from "../../models/Leagues";
import { validGenderLimits, validRookieLimit } from "../../helpers/limitChecks";
import configs from "../../config/configs";

import { Small } from "../atoms";
import CustomLeagueForm from "../organisms/CustomLeagueForm";
import PageTemplate from "../templates/PageTemplate";
import SubmitButton from "../atoms/SubmitButton";

export interface CastItem {
    id?: number;
    reference: string;
    first_name: string;
    last_name: string;
    pic: string;
    gender?: string;
    rookie?: boolean;
}

export interface ScoreItem {
    id?: number;
    description: string;
    category: string;
    score: number;
}

export interface ShowFormFields {
    leagueName: string;
    draftType: string;
    teamName?: string;
    showName: string;
    showImage: any; //? don't really know this type yet
    showDay: string;
    showHour: number;
    showMin: number;
    dateMonth: string;
    dateDay: number;
    genderLimits: boolean;
    rookieLimits: boolean;
    maleLimit: string | number;
    femaleLimit: string | number;
    rookieLimit: string | number;
    dateYear: number | string;
    playersPerWeek?: number;
    playersPerTeam?: number;
    startersPerTeam?: number;
    playerLimit?: number | string;
    swaps: number | null;
    lastSwapEp: number | null;
    allowTrading: boolean;
    cast: CastItem[];
    scoring: ScoreItem[];
}

interface ParamTypes {
    type: string;
}

const supportedFormats = configs.image_formats;

const validationSchema = yup.object({
    leagueName: yup.string().required("League Name is required"),
    draftType: yup.string().required("Draft Type is required"),
    teamName: yup.string().required("Team Name is required"),
    showName: yup.string().required("Show Name is required"),
    showImage: yup
        .mixed()
        .required("Show Image is required")
        .test("fileType", "Unsupported File Format", function (value) {
            if (value === undefined) {
                return true;
            } else {
                return supportedFormats.includes(value.type);
            }
        }),
    showDay: yup.string().required("Day is required"),
    showHour: yup.number().required("Hour is required"),
    showMin: yup.number().required("Minute is required"),
    dateMonth: yup.string().required("Month is required"),
    dateDay: yup.number().required("Day is required"),
    dateYear: yup.string().required("Year is required"),
    genderLimits: yup.boolean(),
    rookieLimits: yup.boolean(),
    playersPerWeek: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Weekly Redraft",
        then: yup.number().required("Players Per Week is required"),
        otherwise: yup.number().nullable(),
    }),
    playerLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) =>
            draftType === "Weekly Redraft" || draftType === "Weekly Budget",
        then: yup.mixed().required("Player Limit is required").nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    playersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.number().required("Players Per Team is required").nullable(),
        otherwise: yup.number().nullable(),
    }),
    startersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup
            .number()
            .required("Starters Per Team is required")
            .test(
                "starters_less_than_players",
                "Starters Per Team must be less than or equal to Players Per Team",
                function (value) {
                    const { playersPerTeam } = this.parent;
                    if (value) {
                        if (playersPerTeam < value) {
                            return false;
                        }
                    }
                    return true;
                }
            )
            .nullable(),
        otherwise: yup.number().nullable(),
    }),
    swaps: yup.mixed().optional(),
    lastSwapEp: yup.mixed().optional(),
    allowTrading: yup.boolean().optional(),
    cast: yup.array().of(
        yup.object().shape({
            first_name: yup.string(),
            last_name: yup.string(),
            pic: yup
                .mixed()
                .test("fileType", "Unsupported File Format", function (value) {
                    if (value === undefined) {
                        return true;
                    } else {
                        return supportedFormats.includes(value.type);
                    }
                }),
            rookie: yup.boolean().optional(),
            gender: yup.string().optional().nullable(),
        })
    ),
    scores: yup.array().of(
        yup.object().shape({
            description: yup.string(),
            category: yup.string(),
            score: yup.number(),
        })
    ),
});

interface CreateCustomResponse {
    league: League;
    show: Show;
    message: string;
}

const CreateCustomShow: React.FC = () => {
    const { type } = useParams<ParamTypes>();
    const history = useHistory();
    const [castCount, setCastCount] = useState<number>(0);
    const [scoresCount, setScoresCount] = useState<number>(0);
    const user = useSelector((state: RootState) => state.user);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const initialValues: ShowFormFields = {
        leagueName: "",
        draftType: "Weekly Redraft",
        playersPerWeek: 1,
        playersPerTeam: 2,
        startersPerTeam: 1,
        playerLimit: "No Limit",
        maleLimit: "No Limit",
        femaleLimit: "No Limit",
        rookieLimit: "No Limit",
        swaps: null,
        lastSwapEp: null,
        genderLimits: false,
        rookieLimits: false,
        teamName: "",
        showName: "",
        showImage: undefined,
        showDay: "Sunday",
        showHour: 0,
        showMin: 0,
        dateMonth: "January",
        dateDay: 1,
        dateYear: new Date().getFullYear(),
        allowTrading: true,
        cast: [],
        scoring: [],
    };

    const saveCustomLeagueRequest = useApi(apiRoutes.SAVE_CUSTOM_LEAGUE(), {
        onSuccess: (response: CreateCustomResponse) => {
            setSubmitting(false);
            toast.success(response.message);
            history.push(`/myleagues/view/${response.league.id}`);
        },
        onFailure: (message: string) => {
            setSubmitting(false);
            toast.error(message);
        },
    });

    useEffect(() => {
        if (type === "free") {
            setCastCount(15);
            setScoresCount(5);
        } else if (type === "plus") {
            if (user.plus_league > 0) {
                setCastCount(30);
                setScoresCount(15);
            } else {
                history.push("/");
            }
        } else if (type === "premium") {
            if (user.premium_league > 0) {
                setCastCount(50);
                setScoresCount(50);
            } else {
                history.push("/");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return (
        <PageTemplate header="Create Custom League">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, { setFieldError }) => {
                    const maleLimit =
                        values.maleLimit !== null
                            ? values.maleLimit.toString()
                            : "No Limit";
                    const femaleLimit =
                        values.femaleLimit !== null
                            ? values.femaleLimit.toString()
                            : "No Limit";
                    const rookieLimit =
                        values.rookieLimit !== null
                            ? values.rookieLimit.toString()
                            : "No Limit";
                    if (
                        values.draftType !== "Team" ||
                        (validGenderLimits(
                            maleLimit === "No Limit" ? 0 : parseInt(maleLimit),
                            femaleLimit === "No Limit"
                                ? 0
                                : parseInt(femaleLimit),
                            values.playersPerTeam
                        ) &&
                            validRookieLimit(
                                rookieLimit === "No Limit"
                                    ? 0
                                    : parseInt(rookieLimit),
                                values.playersPerTeam
                            ))
                    ) {
                        setFieldError("maleLimit", undefined);
                        setFieldError("femaleLimit", undefined);
                        setFieldError("rookieLimit", undefined);
                        setSubmitting(true);
                        const headers = {
                            "content-type": "multipart/form-data",
                        };
                        const data = new FormData();
                        data.append("leagueName", values.leagueName);
                        data.append("teamName", values.teamName);
                        data.append("draftType", values.draftType);
                        data.append("showName", values.showName);
                        data.append("showImage", values.showImage);
                        data.append("showDay", values.showDay);
                        data.append("showHour", values.showHour.toString());
                        data.append("showMin", values.showMin.toString());
                        data.append("month", values.dateMonth);
                        data.append("day", values.dateDay.toString());
                        data.append("year", values.dateYear.toString());
                        data.append("cast", JSON.stringify(values.cast));
                        data.append("scoring", JSON.stringify(values.scoring));
                        data.append("maleLimit", maleLimit);
                        data.append("femaleLimit", femaleLimit);
                        data.append("rookieLimit", rookieLimit);
                        data.append("createType", type);
                        data.append(
                            "allowTrading",
                            values.allowTrading.toString()
                        );
                        data.append(
                            "gender_limit",
                            values.genderLimits.toString()
                        );
                        data.append(
                            "rookie_limit",
                            values.rookieLimits.toString()
                        );
                        if (values.draftType === "Weekly Redraft") {
                            data.append(
                                "playersPer",
                                values.playersPerWeek.toString()
                            );
                            data.append(
                                "playerLimit",
                                values.playerLimit.toString()
                            );
                        } else if (values.draftType === "Team") {
                            data.append(
                                "playersPer",
                                values.playersPerTeam.toString()
                            );
                            data.append(
                                "startersPerWeek",
                                values.startersPerTeam.toString()
                            );
                            if (values.swaps)
                                data.append("swaps", values.swaps.toString());
                            if (values.lastSwapEp)
                                data.append(
                                    "lastSwapEp",
                                    values.lastSwapEp.toString()
                                );
                        }
                        values.cast.forEach((cast) => {
                            if (typeof cast.pic === "object") {
                                if (cast.pic !== null) {
                                    data.append(
                                        `castImg-${cast.reference}`,
                                        cast.pic
                                    );
                                }
                            }
                        });
                        saveCustomLeagueRequest.request(data, headers);
                    } else {
                        if (
                            values.draftType === "Team" &&
                            !validGenderLimits(
                                values.maleLimit === "No Limit"
                                    ? 0
                                    : parseInt(values.maleLimit.toString()),
                                values.femaleLimit === "No Limit"
                                    ? 0
                                    : parseInt(values.femaleLimit.toString()),
                                values.playersPerTeam
                            )
                        ) {
                            setFieldError(
                                "maleLimit",
                                "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                            );
                            setFieldError(
                                "femaleLimit",
                                "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                            );
                            toast.error(
                                "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                            );
                        }
                        if (
                            values.draftType === "Team" &&
                            !validRookieLimit(
                                values.rookieLimit === "No Limit"
                                    ? 0
                                    : parseInt(values.rookieLimit.toString()),
                                values.playersPerTeam
                            )
                        ) {
                            setFieldError(
                                "rookieLimit",
                                "Rookie Limit cannot be more than Players Per Team"
                            );
                            toast.error(
                                "Rookie Limit cannot be more than Players Per Team"
                            );
                        }
                    }
                }}
            >
                {({ handleSubmit, isValid, values, errors, setFieldValue }) => {
                    return (
                        <Form
                            onSubmit={(e) => {
                                if (!isValid) {
                                    toast.error("Please fix the errors");
                                }
                                handleSubmit(e);
                            }}
                        >
                            <CustomLeagueForm
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                canAddCast={values.cast.length < castCount}
                                canAddScoring={
                                    values.scoring.length < scoresCount
                                }
                            />
                            <Small>
                                * Saving may take a while due to image uploades.
                                Please wait a couple minutes.
                            </Small>
                            <Row className="mt-2">
                                <SubmitButton
                                    className="ml-2"
                                    title="Save Custom League"
                                    submitting={submitting}
                                />
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </PageTemplate>
    );
};

export default CreateCustomShow;
