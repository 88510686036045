import React from "react";

import BC from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

import { CardStyled } from "../atoms";

type ColSpec =
    | number
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "10"
    | "11"
    | "12";

interface CardProps {
    header: string;
    lg?: ColSpec;
    md?: ColSpec;
    sm?: ColSpec;
    image?: string;
    description?: string;
    buttonText: string;
    buttonUrl: string;
}

const Card: React.FC<CardProps> = (props) => {
    return (
        <Col lg={props.lg} md={props.md} sm={props.sm}>
            <CardStyled className="mb-5">
                <BC.Header className="text-left">{props.header}</BC.Header>
                <BC.Body
                    style={{ height: 250 }}
                    className="flex-column text-left"
                >
                    {props.description && (
                        <BC.Text>{props.description}</BC.Text>
                    )}
                    {props.image && (
                        <Image
                            width={"100%"}
                            height={"100%"}
                            src={props.image}
                        />
                    )}
                </BC.Body>
                <BC.Footer className="bg-transparent border-0">
                    <a href={props.buttonUrl}>
                        <Button>{props.buttonText}</Button>
                    </a>
                </BC.Footer>
            </CardStyled>
        </Col>
    );
};

export default Card;
