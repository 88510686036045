import React, { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import { Article } from "../../models/Articles";
import { ScoreList } from "../../models/ScoringLog";
import { ShowList } from "../../models/Shows";
import apiRoutes from "../../services/apiRoutes";

import { Well } from "../atoms";
import LinkList from "../molecules/LinkList";

interface ShowLinkListProps {
    show: ShowList;
    type: "articles" | "scores";
    viewAll?: string;
}

const ShowLinkList: React.FC<ShowLinkListProps> = ({ show, type, viewAll }) => {
    const [list, setList] = useState([]);
    const getArticles = useApi(apiRoutes.GET_ARTICLES_BY_SHOW(show.slug), {
        responseKey: "articles",
        onSuccess: (response: Article[]) => {
            const articleList = response.map((article) => {
                return {
                    title: article.title,
                    link: `/shows/article/${show.slug}/${article.id}`,
                };
            });

            setList(articleList);
        },
    });
    const getScores = useApi(apiRoutes.SCORING_LOG_BY_SHOW(show.slug), {
        responseKey: "episodes",
        onSuccess: (response: ScoreList[]) => {
            const scoreList = response.map((score) => {
                return {
                    title: `${show.type === "week" ? "Week" : "Episode"} ${
                        score.episode
                    } Scores`,
                    link: `/shows/scores/${show.slug}/${score.episode}`,
                };
            });

            setList(scoreList);
        },
    });

    useEffect(() => {
        if (type === "articles") {
            getArticles.request({ limit: 6 });
        } else {
            getScores.request({ limit: 6 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return (
        <Well>
            <h4 className="text-capitalize">
                {show.show} {type}:
            </h4>
            <LinkList
                empty={type === "articles" ? "No Articles" : "No Scores"}
                list={list}
                viewAll={viewAll}
            />
        </Well>
    );
};

export default ShowLinkList;
