import { LOG_OUT, UserActionTypes } from "../user/types";
import { SubscriptionActionTypes, UPDATE_SUBSCRIPTION } from "./types";

const INITIAL_STATE = null;

export function subscriptionReducer(
    state = INITIAL_STATE,
    action: SubscriptionActionTypes | UserActionTypes
) {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION:
            state = action.payload.subscription;
            if (state === null) {
                return null;
            } else {
                return {
                    ...state,
                };
            }
        case LOG_OUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
