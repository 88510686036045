import { ApiReturnType } from "./";

const questionRoutes = {
    GET_QUESTION_SCORES_BY_EPISODE: (
        slug: string,
        episode: string
    ): ApiReturnType => {
        return {
            url: `question/scores/byEpisode/${slug}/${episode}`,
            method: "get",
        };
    },
};

export default questionRoutes;
