import React, { useEffect, useState } from "react";
import moment from "moment";

import { LiveScore, LiveScoreTeams } from "../../models/LiveScores";
import { Show } from "../../models/Shows";

import LiveScoreItem from "../molecules/LiveScoreItem";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface LiveScoresListProps {
    liveScores: LiveScore[];
    liveScoreTeams: LiveScoreTeams;
    show: Show;
    leagueId: string;
}

const LiveScoresList: React.FC<LiveScoresListProps> = ({
    liveScores,
    show,
    leagueId,
    liveScoreTeams,
}) => {
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [currentTime, setCurrentTime] = useState(moment());

    const date = new Date();
    const offsetInHours = date.getTimezoneOffset() / 60;

    useEffect(() => {
        if (
            show &&
            liveScores.length > 0 &&
            offsetInHours >= timezoneOffsets.hawaii &&
            show.hawaii_offset > 0
        ) {
            const end = moment(liveScores[0].created_at);
            const d = currentTime.diff(end);
            const duration = moment.duration(d);
            if (duration.asHours() < show.hawaii_offset) {
                const interval = setInterval(() => {
                    setCurrentTime(moment());
                }, 1000);

                return () => clearInterval(interval);
            }
        } else if (
            show &&
            liveScores.length > 0 &&
            offsetInHours >= timezoneOffsets.alaska &&
            show.alaska_offset > 0
        ) {
            const end = moment(liveScores[0].created_at);
            const d = currentTime.diff(end);
            const duration = moment.duration(d);
            if (duration.asHours() < show.alaska_offset) {
                const interval = setInterval(() => {
                    setCurrentTime(moment());
                }, 1000);

                return () => clearInterval(interval);
            }
        } else if (
            show &&
            liveScores.length > 0 &&
            offsetInHours >= timezoneOffsets.pacific &&
            show.pacific_offset > 0
        ) {
            const end = moment(liveScores[0].created_at);
            const d = currentTime.diff(end);
            const duration = moment.duration(d);
            if (duration.asHours() < show.pacific_offset) {
                const interval = setInterval(() => {
                    setCurrentTime(moment());
                }, 1000);

                return () => clearInterval(interval);
            }
        } else if (
            show &&
            liveScores.length > 0 &&
            offsetInHours >= timezoneOffsets.mountain &&
            show.mountain_offset > 0
        ) {
            const end = moment(liveScores[0].created_at);
            const d = currentTime.diff(end);
            const duration = moment.duration(d);
            if (duration.asHours() < show.mountain_offset) {
                const interval = setInterval(() => {
                    setCurrentTime(moment());
                }, 1000);

                return () => clearInterval(interval);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <>
            {liveScores.length > 0 && show ? (
                liveScores.map((score, index) => {
                    if (
                        offsetInHours >= timezoneOffsets.hawaii &&
                        show.hawaii_offset > 0
                    ) {
                        const endTime = moment(score.created_at);
                        const diff = currentTime.diff(endTime);
                        const diffDuration = moment.duration(diff);
                        if (diffDuration.asHours() >= show.hawaii_offset) {
                            return (
                                <LiveScoreItem
                                    liveScoreTeams={liveScoreTeams}
                                    leagueId={leagueId}
                                    key={score.id}
                                    score={score}
                                />
                            );
                        } else {
                            return null;
                        }
                    } else if (
                        offsetInHours >= timezoneOffsets.alaska &&
                        show.alaska_offset > 0
                    ) {
                        const endTime = moment(score.created_at);
                        const diff = currentTime.diff(endTime);
                        const diffDuration = moment.duration(diff);
                        if (diffDuration.asHours() >= show.alaska_offset) {
                            return (
                                <LiveScoreItem
                                    liveScoreTeams={liveScoreTeams}
                                    leagueId={leagueId}
                                    key={score.id}
                                    score={score}
                                />
                            );
                        } else {
                            return null;
                        }
                    } else if (
                        offsetInHours >= timezoneOffsets.pacific &&
                        show.pacific_offset > 0
                    ) {
                        const endTime = moment(score.created_at);
                        const diff = currentTime.diff(endTime);
                        const diffDuration = moment.duration(diff);
                        if (diffDuration.asHours() >= show.pacific_offset) {
                            return (
                                <LiveScoreItem
                                    liveScoreTeams={liveScoreTeams}
                                    leagueId={leagueId}
                                    key={score.id}
                                    score={score}
                                />
                            );
                        } else {
                            return null;
                        }
                    } else if (
                        offsetInHours >= timezoneOffsets.mountain &&
                        show.mountain_offset > 0
                    ) {
                        const endTime = moment(score.created_at);
                        const diff = currentTime.diff(endTime);
                        const diffDuration = moment.duration(diff);
                        if (diffDuration.asHours() >= show.mountain_offset) {
                            return (
                                <LiveScoreItem
                                    liveScoreTeams={liveScoreTeams}
                                    leagueId={leagueId}
                                    key={score.id}
                                    score={score}
                                />
                            );
                        } else {
                            return null;
                        }
                    } else {
                        return (
                            <LiveScoreItem
                                liveScoreTeams={liveScoreTeams}
                                leagueId={leagueId}
                                key={score.id}
                                score={score}
                            />
                        );
                    }
                })
            ) : (
                <div className="text-center mt-3 pt-3 pb-3 mb-3">
                    No Live Scores
                </div>
            )}
        </>
    );
};

export default LiveScoresList;
