import { ApiReturnType } from "./";

const adminRoutes = {
    ACTIVATE_SHOW: (id: string): ApiReturnType => {
        return {
            url: `/admin/activateShow/${id}`,
            method: "post",
        };
    },
    CONVERT_LIVE_SCORING: (showId: string, episode: string): ApiReturnType => {
        return {
            url: `/admin/liveScoring/convert/${showId}/${episode}`,
            method: "post",
        };
    },
    DEACTIVATE_SHOW: (id: string): ApiReturnType => {
        return {
            url: `/admin/deactivateShow/${id}`,
            method: "post",
        };
    },
    DELETE_ARTICLE: (id: string): ApiReturnType => {
        return {
            url: `admin/deleteArticle/${id}`,
            method: "post",
        };
    },
    DELETE_LIVE_SCORE: (id: string): ApiReturnType => {
        return {
            url: `/admin/liveScoring/delete/${id}`,
            method: "post",
        };
    },
    DELETE_SHOW: (id: string): ApiReturnType => {
        return {
            url: `admin/deleteShow/${id}`,
            method: "post",
        };
    },
    GET_INSTA_BIO: (): ApiReturnType => {
        return {
            url: `instabio`,
            method: "get",
        };
    },
    GET_LOGGED_SCORES: (show_id: string): ApiReturnType => {
        return {
            url: `admin/getLoggedScores/${show_id}`,
            method: "get",
        };
    },
    GET_ARTICLES_LIST: (): ApiReturnType => {
        return {
            url: `admin/articleList`,
            method: "get",
        };
    },
    GET_LIVE_SCORES: (slug: string, episode: string): ApiReturnType => {
        return {
            url: `scores/live/${slug}/${episode}`,
            method: "get",
        };
    },
    GET_LIVE_SCORES_LIST: (): ApiReturnType => {
        return {
            url: `admin/liveScoresList`,
            method: "get",
        };
    },
    GET_SCORES_LIST: (): ApiReturnType => {
        return {
            url: `admin/scoresList`,
            method: "get",
        };
    },
    GET_SHOWS_LIST: (): ApiReturnType => {
        return {
            url: `admin/showList`,
            method: "get",
        };
    },
    PUBLISH_ARTICLE: (id: string): ApiReturnType => {
        return {
            url: `admin/publishArticle/${id}`,
            method: "post",
        };
    },
    SAVE_ARTICLE: (type: string, id: string): ApiReturnType => {
        if (type === "add") {
            return {
                url: `admin/saveArticle/${type}`,
                method: "post",
            };
        } else {
            return {
                url: `admin/saveArticle/${id}`,
                method: "post",
            };
        }
    },
    SAVE_LOGGED_SCORES: (): ApiReturnType => {
        return {
            url: `admin/saveLoggedScores`,
            method: "post",
        };
    },
    SAVE_LIVE_SCORES: (showId: string, episode: string): ApiReturnType => {
        return {
            url: `admin/liveScoring/save/${showId}/${episode}`,
            method: "post",
        };
    },
    SAVE_SHOW: (type: string, id: string): ApiReturnType => {
        if (type === "add") {
            return {
                url: `admin/saveShow`,
                method: "post",
            };
        } else {
            return {
                url: `admin/saveShow/${id}`,
                method: "post",
            };
        }
    },
    SAVE_INSTA_BIO: (): ApiReturnType => {
        return {
            url: `admin/saveInstaBio`,
            method: "post",
        };
    },
    SEND_PUSH_NOTIFICATION: (): ApiReturnType => {
        return {
            url: `admin/sendPushNotification`,
            method: "post",
        };
    },
    UNPUBLISH_ARTICLE: (id: string): ApiReturnType => {
        return {
            url: `admin/unpublishArticle/${id}`,
            method: "post",
        };
    },
    UPLOAD_CAST_IMAGE: (): ApiReturnType => {
        return {
            url: `admin/uploadCastImg`,
            method: "post",
        };
    },
};

export default adminRoutes;
