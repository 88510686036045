import React from "react";

import { QuestionScore, QuestionScores } from "../../models/QuestionScores";

import { Card } from "react-bootstrap";

import { CardStyled } from "../atoms";
import ChallengeQuestionResults from "../molecules/ChallengeQuestionResults";
import ChallengeQuestionPicks from "../molecules/ChallengeQuestionPicks";

interface ChallengeQuestionsScoresListProps {
    questionScores: QuestionScores;
    leagueId: string;
}

const ChallengeQuestionsScoresList: React.FC<
    ChallengeQuestionsScoresListProps
> = ({ questionScores, leagueId }) => {
    return (
        <>
            <h2>Challenge Questions:</h2>
            {questionScores.map((score: QuestionScore) => {
                return (
                    <CardStyled key={score.question} className="mb-5">
                        <Card.Header className="text-left">
                            <h4 className="font-weight-bold">
                                {score.question}
                            </h4>
                        </Card.Header>
                        <Card.Body className="flex-column text-left pl-5 pr-5">
                            <ChallengeQuestionResults results={score.scores} />
                            {leagueId !== "default" && (
                                <ChallengeQuestionPicks score={score} />
                            )}
                        </Card.Body>
                    </CardStyled>
                );
            })}
        </>
    );
};

export default ChallengeQuestionsScoresList;
